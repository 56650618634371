import React, { FC, ReactNode } from 'react'
import cx from 'classnames'

export interface ParagraphProps {
	paragraphs: ReactNode[]
	paragraphClass?: string
}

export const P: FC<ParagraphProps> = (props) => {
	return (
		<>
			{props.paragraphs.map((text, index) => {
				const innerHtml = text?.toString()
				if (innerHtml && typeof text === 'string') {
					return (
						<p
							className={cx('paragraph', props.paragraphClass)}
							key={index}
							dangerouslySetInnerHTML={{ __html: innerHtml }}
						></p>
					)
				} else {
					return (
						<p
							className={cx('paragraph', props.paragraphClass)}
							key={index}
						>
							{text}
						</p>
					)
				}
			})}
		</>
	)
}
