import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.04995 3.1717C3.80702 2.92648 3.41129 2.92464 3.16608 3.16757C2.92087 3.41051 2.91902 3.80624 3.16196 4.05145L8.51519 9.45484H3.60596V10.9157C3.60596 15.5951 7.36412 19.3884 12 19.3884C16.636 19.3884 20.3941 15.5951 20.3941 10.9157V9.45484H9.65823C9.78259 9.21973 9.74661 8.92173 9.54953 8.7228L4.04995 3.1717Z"
			fill={color}
		/>
	</svg>
)

export default icon
