import { useEffect, useState } from 'react'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { scrollLocked } from 'utils/jss'

export const useManageShopSticky = (ids = ['shop', 'vanity-hero', 'easily-cooling', 'new-standard', 'footer', 'personal-sleep']) => {
	const [stickyPreventionInView, setStickyPreventionInView] = useState(false)
	const headerWiped = useScrollThreshold(50)

	useEffect(() => {
		const header = document.querySelector('header')

		const resizeListener = () => {
			if (header && window.innerWidth >= 1024 && headerWiped && !stickyPreventionInView) {
				document.body.style.setProperty('--nav-translate', `-100%`)
			} else if (!scrollLocked) {
				document.body.style.setProperty('--nav-translate', `0px`)
			}
		}

		resizeListener()

		const observer = new IntersectionObserver((entries) => {
			setStickyPreventionInView(entries.some((entry) => entry.isIntersecting))
		})

		for (const id of ids) {
			const selectionSummaryElement = document.getElementById(id)
			if (selectionSummaryElement) {
				observer.observe(selectionSummaryElement)
			}
		}

		window.addEventListener('resize', resizeListener)
		return () => {
			observer.disconnect()
			window.removeEventListener('resize', resizeListener)
		}
	}, [headerWiped, stickyPreventionInView])

	return stickyPreventionInView
}
