import { FC } from 'react'
import styles from './SelectionModalButton.module.scss'
import { SelectionModalButtonProps } from './types'
import { Button } from 'components/Phantom/Button'

export const SelectionModalButton: FC<SelectionModalButtonProps> = (props) => {
	const { modalKey, text } = props

	if (!text || !modalKey) {
		return null
	}

	return (
		<Button.PlusSmall
			className={styles.container}
			id={`modal-trigger-${modalKey}`}
			onClick={props.onClick}
		>
			{text}
		</Button.PlusSmall>
	)
}
