export const VARIABLE_CURRENCY_MAP = {
	SEK: {
		25: 350,
		50: 700,
		75: 1000,
		100: 1400,
		125: 1750,
		150: 2100,
		200: 2800,
		250: 3500,
		275: 3800,
		300: 4200,
		350: 4900,
		400: 5600,
		450: 6300,
		500: 7000,
		600: 8400,
		650: 9100,
		700: 9800,
	},
	DKK: {
		25: 350,
		50: 450,
		75: 700,
		100: 950,
		125: 1150,
		150: 1400,
		200: 1900,
		250: 2350,
		275: 2500,
		300: 2700,
		350: 3100,
		400: 3800,
		450: 4400,
		500: 4750,
		600: 5700,
		650: 6500,
		700: 7100,
	},
}

export const getRandomString = (length = 15) => {
	return [...Array(length)].map((i) => Math.trunc(Math.random() * 36).toString(36)).join('')
}
