import { MembershipPricing, PlanData } from 'shop/Shop.types'
import { getSubscriptionFeatureNiceName } from 'components/_utils/subscriptionUtils'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'

export const getStandardPlan = (currentPlanData: PlanData, standardPricing: MembershipPricing): CardSelectionItemProps => {
	if (Object.keys(currentPlanData).length && !currentPlanData.showStandardPlan) {
		return {
			id: 'autopilot_standard',
			key: 'autopilot_standard',
			type: 'standard',
			data: {
				subtitle: currentPlanData.currentPriceMonthly,
				description: currentPlanData.currentPrice,
				title: 'Current',
				checklist: currentPlanData.currentBenefits.map((b) => getSubscriptionFeatureNiceName(b)),
			},
		}
	}

	return {
		key: 'autopilot_standard',
		type: 'standard',
		data: {
			subtitle: standardPricing.monthly + ' /mo',
			description: standardPricing.yearly + ' billed annually',
			title: 'Standard',
			checklist: ['Automatic temperature adjustments', 'Sleep & health reports', 'Vibration and thermal alarms', 'Snoring detection & mitigation', '2-year warranty'],
			// product: standard,
		},
	}
}

export const getEnhancedPlan = (enhancedPricing: MembershipPricing): CardSelectionItemProps => {
	return {
		key: 'autopilot_enhanced',
		type: 'standard',
		data: {
			title: 'Enhanced',
			badge: 'Popular',
			subtitle: enhancedPricing.monthly + ' /mo',
			description: enhancedPricing.yearly + ' billed annually',
			// product: enhanced,
		},
	}
}

export const getMembershipTooltip = (warrantyLength = 5) => {
	return `One plan for you and your partner. Autopilot is the intelligence behind the Pod that automatically helps you sleep longer and more deeply. ${warrantyLength} year warranty included.`
}
