import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M21 21.0001L15 15.0001M17 10.0001C17 10.9193 16.8189 11.8296 16.4672 12.6788C16.1154 13.5281 15.5998 14.2998 14.9497 14.9498C14.2997 15.5998 13.5281 16.1154 12.6788 16.4672C11.8295 16.819 10.9193 17.0001 10 17.0001C9.08075 17.0001 8.1705 16.819 7.32122 16.4672C6.47194 16.1154 5.70026 15.5998 5.05025 14.9498C4.40024 14.2998 3.88463 13.5281 3.53284 12.6788C3.18106 11.8296 3 10.9193 3 10.0001C3 8.14355 3.7375 6.36307 5.05025 5.05031C6.36301 3.73756 8.14348 3.00006 10 3.00006C11.8565 3.00006 13.637 3.73756 14.9497 5.05031C16.2625 6.36307 17 8.14355 17 10.0001Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
