import { IconComponentType } from '../../types'

export const AlarmCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M11.9996 22.0016C10.7496 22.0016 9.57878 21.7641 8.48711 21.2891C7.39544 20.8141 6.44544 20.1724 5.63711 19.3641C4.82878 18.5557 4.18711 17.6057 3.71211 16.5141C3.23711 15.4224 2.99961 14.2516 2.99961 13.0016C2.99961 11.7516 3.23711 10.5807 3.71211 9.48906C4.18711 8.3974 4.82878 7.4474 5.63711 6.63906C6.44544 5.83073 7.39544 5.18906 8.48711 4.71406C9.57878 4.23906 10.7496 4.00156 11.9996 4.00156C13.2496 4.00156 14.4204 4.23906 15.5121 4.71406C16.6038 5.18906 17.5538 5.83073 18.3621 6.63906C19.1704 7.4474 19.8121 8.3974 20.2871 9.48906C20.7621 10.5807 20.9996 11.7516 20.9996 13.0016C20.9996 14.2516 20.7621 15.4224 20.2871 16.5141C19.8121 17.6057 19.1704 18.5557 18.3621 19.3641C17.5538 20.1724 16.6038 20.8141 15.5121 21.2891C14.4204 21.7641 13.2496 22.0016 11.9996 22.0016ZM14.7996 17.2016L16.1996 15.8016L12.9996 12.6016V8.00156H10.9996V13.4016L14.7996 17.2016ZM5.59961 2.35156L6.99961 3.75156L2.74961 8.00156L1.34961 6.60156L5.59961 2.35156ZM18.3996 2.35156L22.6496 6.60156L21.2496 8.00156L16.9996 3.75156L18.3996 2.35156ZM11.9996 20.0016C13.9496 20.0016 15.6038 19.3224 16.9621 17.9641C18.3204 16.6057 18.9996 14.9516 18.9996 13.0016C18.9996 11.0516 18.3204 9.3974 16.9621 8.03906C15.6038 6.68073 13.9496 6.00156 11.9996 6.00156C10.0496 6.00156 8.39544 6.68073 7.03711 8.03906C5.67878 9.3974 4.99961 11.0516 4.99961 13.0016C4.99961 14.9516 5.67878 16.6057 7.03711 17.9641C8.39544 19.3224 10.0496 20.0016 11.9996 20.0016Z"
			fill={color}
		/>
	</svg>
)
