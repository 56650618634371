import { FC } from 'react'
import { ExampleModalProps } from './types'

export const ExampleModal: FC<ExampleModalProps> = (props) => {
	const {} = props
	return (
		<div>
			<h2>Which Pod is right for you?</h2>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipisicing elit. A asperiores assumenda aut, deserunt dolores ea eos esse eum excepturi inventore laudantium libero nulla officia optio praesentium
				quaerat quam quibusdam quos recusandae reprehenderit rerum sapiente sed similique sint tenetur, unde ut vel veniam voluptas voluptate. Consectetur esse explicabo itaque maiores possimus.
			</p>
		</div>
	)
}
