import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { PaymentOption } from 'shop/Shop.types'
import { ClientOnly } from 'components/ClientOnly'
import Script from 'next/script'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import * as React from 'react'
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js'
import { STRIPE_CLIENT_KEY } from 'stripe_lib'
import { loadStripe } from '@stripe/stripe-js'
import { Currency } from 'prices/types'

export const Pod3: CardSelectionItemProps = {
	key: 'pod_3_cover_perfect',
	type: 'standard',
	data: {
		title: 'Pod 3',
		description: 'Cover + Hub',
		checklist: ['Previous generation technology'],
	},
}

export const Pod4: CardSelectionItemProps = {
	key: 'pod_4',
	type: 'standard',
	data: {
		title: 'Pod 4',
		badge: 'New',
		description: 'Cover + Hub',
		checklist: ['Cools and heats', 'Two zones', 'Fits any mattress', 'Control without a phone'],
	},
}

export const Pod4Ultra: CardSelectionItemProps = {
	key: 'pod_4_ultra',
	type: 'standard',
	data: {
		title: 'Pod 4 Ultra',
		badge: 'New',
		description: 'Cover + Hub + Base',
		checklistTitle: 'Everything in Pod 4, plus:',
		checklist: ['Sleeping, reading, and custom positioning', 'Snoring mitigation'],
	},
}

export const getModelOptions = (): CardSelectionItemProps[] => {
	return [Pod3, Pod4, Pod4Ultra]
}

const getRegionCookie = () => {
	if (typeof window == 'undefined') return 'US'
	const cookie = document.cookie.split(';').find((it) => it.startsWith('geo-country='))
	if (cookie) {
		return cookie.split('=')[1]
	}
	return 'US'
}

export const getModelDescription = (paymentOption: PaymentOption, financingPriceString: string, price: number, currency: Currency) => {
	switch (paymentOption) {
		case 'affirm':
			return (
				<>
					*Buy now and pay over time with <AffirmLogo />. Starts as low as {financingPriceString}.
					<Elements stripe={loadStripe(STRIPE_CLIENT_KEY)}>
						<PaymentMethodMessagingElement
							id={'affirm-info-cta'}
							options={{
								amount: price,
								currency,
								paymentMethodTypes: ['affirm'],
								countryCode: getRegionCookie() as any,
							}}
						/>
					</Elements>
				</>
			)
		case 'klarna':
			return 'Buy now and pay over 3 payments'
		case 'truemed':
			return (
				<>
					{/**Save an average of 33% when you pay with HSA/FSA.{' '}*/}
					{/*<button*/}
					{/*	onClick={() => {*/}
					{/*		document.getElementById('truemed-instructions')?.click()*/}
					{/*	}}*/}
					{/*>*/}
					{/*	Learn more.*/}
					{/*</button>*/}
					<ClientOnly>
						<p
							id="truemed-instructions"
							icon-height="11"
							style={{ margin: '0', lineHeight: '1' }}
						></p>
						<Script
							src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/eight-sleep-pdp-widget.min.js"
							strategy="afterInteractive"
						></Script>
					</ClientOnly>
				</>
			)
		case 'cash':
		default:
			return null
	}
}
