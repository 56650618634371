import * as React from 'react'
import { FC, ReactNode, useId, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { amClickedFAQ } from 'events/amplitude'
import styles from './SlideDown.module.scss'

export interface SlideDownProps {
	// textPosition: 'left' | 'center' | 'right'
	children?: React.ReactNode
	top: React.ReactNode
	arrow?: boolean
	onOpenClick?: () => void
	onCloseClick?: () => void
	topWidth?: string[] | number[]
	animationDuration?: number
	className: string
	role?: string
	chevronSrc?: string
	inverted?: boolean
}

const SlideDown: FC<SlideDownProps> = (props) => {
	const { top, children, arrow, topWidth, className, role, chevronSrc, animationDuration, inverted } = props
	const { onOpenClick, onCloseClick } = props

	const chevron = chevronSrc ?? 'https://eightsleep.imgix.net/chevron-down.svg?v=1633019879'
	const [open, setOpen] = useState(false)
	const id = useId()

	const handleClick = () => {
		setOpen((prev) => !prev)

		const getNodeText = (node: ReactNode) => {
			if (['string', 'number'].includes(typeof node)) return node
			if (node instanceof Array) return node.map(getNodeText).join('')
			if (typeof node === 'object' && node && 'props' in node) {
				const { children } = node.props
				return getNodeText(children)
			}
		}

		amClickedFAQ(getNodeText(top))

		const is_closed = !open
		const is_open = open
		const has_open_click = onOpenClick !== undefined
		const has_close_click = onCloseClick !== undefined

		if (is_closed && has_open_click) {
			onOpenClick()
		}
		if (is_open && has_close_click) {
			onCloseClick()
		}
	}

	return (
		<div
			className={className}
			role={role}
			aria-live={'polite'}
		>
			<div
				className={'legacy_box'}
				style={{
					backgroundColor: 'inherit',
					color: 'inherit',
				}}
			>
				<h3 className={styles.accordion_header}>
					<button
						onClick={() => handleClick()}
						className={styles.top}
						aria-expanded={open}
						aria-controls={id}
						id={`accordion-button-for-${id}`}
					>
						{top}
						<img
							className={`${open ? 'arrow_slide_down_up' : 'arrow_slide_down_down'} ${inverted && 'inverted'}`}
							src={chevron}
							alt={open ? 'close' : 'expand'}
						/>
					</button>
				</h3>
			</div>
			<AnimateHeight
				duration={animationDuration || 100}
				height={open ? 'auto' : 0}
			>
				<div
					id={id}
					aria-labelledby={`accordion-button-for-${id}`}
				>
					{children}
				</div>
			</AnimateHeight>
		</div>
	)
}

export default SlideDown
