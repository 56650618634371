import { useEffect, useRef } from 'react'

/**
 * Hook that will return the previous value of a provided stateful value
 * @param value A stateful value
 */
export const usePrevious = (value: any) => {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}
