import { MutableRefObject } from 'react'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

export interface AnimateInOnScrollSettings {
	fromState?: any
	start?: string
	scrub?: number
	delay?: number
	topAdjust?: number
	markers?: boolean
	reversible?: boolean
	disable?: boolean
}

/**
 * A hook that animates an element by fading it and moving it up when it comes into view.
 * *If you're using this hook in a component that is visible on page load, you should set visibility: hidden on the element you're animating.*
 * @param ref
 * @param settings
 */
export const useAnimateInOnScroll = (ref: MutableRefObject<HTMLElement | null>, settings: AnimateInOnScrollSettings = {}) => {
	const { fromState, start = `top 85%${settings.topAdjust ? `+=${settings.topAdjust}px` : ''}`, scrub, markers = false, reversible = true, delay = 0, disable = false } = settings

	useGSAP(() => {
		if (!ref.current || disable) return
		gsap.registerPlugin(ScrollTrigger)

		gsap.from(ref.current, {
			y: 30,
			ease: 'power1.out',
			duration: 0.9,
			delay,
			...fromState,
			scrollTrigger: {
				trigger: ref.current,
				start,
				scrub,
				markers,
				toggleActions: reversible ? 'restart none none reverse' : 'play none none none',
			},
		})

		gsap.from(ref.current, {
			opacity: 0,
			autoAlpha: 0,
			ease: 'power2.out',
			duration: 0.7,
			delay,
			...fromState,
			scrollTrigger: {
				trigger: ref.current,
				start,
				scrub,
				markers,
				toggleActions: reversible ? 'restart none none reverse' : 'play none none none',
			},
		})
	})
}
