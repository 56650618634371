import * as THREE from 'three'

export const resizeRenderer = (renderer: THREE.WebGLRenderer) => {
	const canvas = renderer.domElement
	const width = canvas.clientWidth
	const height = canvas.clientHeight
	const needResize = canvas.width !== width || canvas.height !== height
	if (needResize) {
		renderer.setSize(width, height, false)
	}
	return needResize
}

export const render = (renderer: THREE.WebGLRenderer, camera: THREE.PerspectiveCamera, scene: THREE.Scene) => {
	if (resizeRenderer(renderer)) {
		const canvas = renderer.domElement
		camera.aspect = canvas.clientWidth / canvas.clientHeight
		camera.updateProjectionMatrix()
	}

	renderer.render(scene, camera)

	window.requestAnimationFrame(() => render(renderer, camera, scene))
}

export const isWebGLAvailable = () => {
	try {
		const canvas = document.createElement('canvas')
		return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
	} catch (e) {
		return false
	}
}
