import { RegionShort } from 'utils/internationalization'
import { ReactNode } from 'react'

export interface Country {
	flag: ReactNode
	name: string
	region: RegionShort
	currencyTitle: string
}

export const us = (
	<>
		<rect
			width="21"
			height="15.0022"
			transform="translate(0 0.498901)"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.00122 0.498413V1.49856H21.0012V0.498413H9.00122ZM9.00122 2.4987V3.49885H21.0012V2.4987H9.00122ZM9.00122 4.49899V5.49914H21.0012V4.49899H9.00122ZM9.00122 6.49929V7.49943H21.0012V6.49929H9.00122ZM0.0012207 8.49958V9.49972H21.0012V8.49958H0.0012207ZM0.0012207 10.4999V11.5H21.0012V10.4999H0.0012207ZM0.0012207 12.5002V13.5003H21.0012V12.5002H0.0012207ZM0.0012207 14.5004V15.5006H21.0012V14.5004H0.0012207Z"
			fill="#F93939"
		/>

		<rect
			width="11.2"
			height="8.00116"
			transform="translate(0.0012207 0.000976562)"
			fill="#1A47B8"
		/>
		<path
			d="M1.32154 1.30599L0.96167 1.56739L1.09901 1.14429L1.32154 1.30599Z"
			fill="white"
		/>
		<path
			d="M1.16895 1.35685L0.809082 1.09546H1.25385L1.16895 1.35685Z"
			fill="white"
		/>
		<path
			d="M1.06543 1.22864L1.20277 0.805542L1.34012 1.22864H1.06543Z"
			fill="white"
		/>
		<path
			d="M1.16943 1.09546H1.6142L1.25433 1.35685L1.16943 1.09546Z"
			fill="white"
		/>
		<path
			d="M1.32458 1.14429L1.46192 1.56739L1.10205 1.30599L1.32458 1.14429Z"
			fill="white"
		/>
		<path
			d="M3.05396 1.30599L2.69409 1.56739L2.83143 1.14429L3.05396 1.30599Z"
			fill="white"
		/>
		<path
			d="M2.90138 1.35685L2.5415 1.09546H2.98627L2.90138 1.35685Z"
			fill="white"
		/>
		<path
			d="M2.80396 1.22559L2.9413 0.80249L3.07864 1.22559H2.80396Z"
			fill="white"
		/>
		<path
			d="M2.90161 1.09546H3.34638L2.98651 1.35685L2.90161 1.09546Z"
			fill="white"
		/>
		<path
			d="M3.057 1.14429L3.19435 1.56739L2.83447 1.30599L3.057 1.14429Z"
			fill="white"
		/>
		<path
			d="M4.77564 1.30904L4.41577 1.57044L4.5534 1.14734L4.77564 1.30904Z"
			fill="white"
		/>
		<path
			d="M4.62916 1.35685L4.26929 1.09546H4.71406L4.62916 1.35685Z"
			fill="white"
		/>
		<path
			d="M4.52563 1.22864L4.66298 0.805542L4.80061 1.22864H4.52563Z"
			fill="white"
		/>
		<path
			d="M4.62939 1.09546H5.07416L4.71429 1.35685L4.62939 1.09546Z"
			fill="white"
		/>
		<path
			d="M4.7845 1.14429L4.92184 1.56739L4.56226 1.30599L4.7845 1.14429Z"
			fill="white"
		/>
		<path
			d="M6.51364 1.30599L6.15405 1.56739L6.2914 1.14429L6.51364 1.30599Z"
			fill="white"
		/>
		<path
			d="M6.36134 1.35685L6.00146 1.09546H6.44624L6.36134 1.35685Z"
			fill="white"
		/>
		<path
			d="M6.26392 1.22559L6.40155 0.80249L6.53889 1.22559H6.26392Z"
			fill="white"
		/>
		<path
			d="M6.36157 1.09546H6.80634L6.44647 1.35685L6.36157 1.09546Z"
			fill="white"
		/>
		<path
			d="M6.51667 1.14429L6.65431 1.56739L6.29443 1.30599L6.51667 1.14429Z"
			fill="white"
		/>
		<path
			d="M8.23829 1.3099L7.87842 1.57129L8.01576 1.14819L8.23829 1.3099Z"
			fill="white"
		/>
		<path
			d="M8.09181 1.35771L7.73193 1.09631H8.1767L8.09181 1.35771Z"
			fill="white"
		/>
		<path
			d="M7.98828 1.2295L8.12591 0.806396L8.26326 1.2295H7.98828Z"
			fill="white"
		/>
		<path
			d="M8.09204 1.09631H8.53681L8.17694 1.35771L8.09204 1.09631Z"
			fill="white"
		/>
		<path
			d="M8.24743 1.14514L8.38478 1.56824L8.0249 1.30685L8.24743 1.14514Z"
			fill="white"
		/>
		<path
			d="M9.97535 1.30685L9.61548 1.56824L9.75282 1.14514L9.97535 1.30685Z"
			fill="white"
		/>
		<path
			d="M9.82247 1.35771L9.46289 1.09631H9.90766L9.82247 1.35771Z"
			fill="white"
		/>
		<path
			d="M9.72534 1.22644L9.86269 0.803345L10 1.22644H9.72534Z"
			fill="white"
		/>
		<path
			d="M9.823 1.09631H10.2681L9.90819 1.35771L9.823 1.09631Z"
			fill="white"
		/>
		<path
			d="M9.97839 1.14514L10.1157 1.56824L9.75586 1.30685L9.97839 1.14514Z"
			fill="white"
		/>
		<path
			d="M2.19093 2.04716L1.83105 2.30884L1.9684 1.88574L2.19093 2.04716Z"
			fill="white"
		/>
		<path
			d="M2.03805 2.0986L1.67847 1.83691H2.12324L2.03805 2.0986Z"
			fill="white"
		/>
		<path
			d="M1.94092 1.96692L2.07826 1.54382L2.2156 1.96692H1.94092Z"
			fill="white"
		/>
		<path
			d="M2.03857 1.83691H2.48334L2.12376 2.0986L2.03857 1.83691Z"
			fill="white"
		/>
		<path
			d="M2.19397 1.88574L2.33131 2.30884L1.97144 2.04716L2.19397 1.88574Z"
			fill="white"
		/>
		<path
			d="M3.92311 2.04716L3.56323 2.30884L3.70058 1.88574L3.92311 2.04716Z"
			fill="white"
		/>
		<path
			d="M3.77052 2.0986L3.41064 1.83691H3.85541L3.77052 2.0986Z"
			fill="white"
		/>
		<path
			d="M3.6731 1.96692L3.81044 1.54382L3.94807 1.96692H3.6731Z"
			fill="white"
		/>
		<path
			d="M3.77075 1.83691H4.21552L3.85565 2.0986L3.77075 1.83691Z"
			fill="white"
		/>
		<path
			d="M3.92614 1.88574L4.06349 2.30884L3.70361 2.04716L3.92614 1.88574Z"
			fill="white"
		/>
		<path
			d="M5.65089 2.04716L5.29102 2.30884L5.42865 1.88574L5.65089 2.04716Z"
			fill="white"
		/>
		<path
			d="M5.4983 2.0986L5.13843 1.83691H5.5832L5.4983 2.0986Z"
			fill="white"
		/>
		<path
			d="M5.40088 1.96692L5.53851 1.54382L5.67585 1.96692H5.40088Z"
			fill="white"
		/>
		<path
			d="M5.49854 1.83691H5.94331L5.58343 2.0986L5.49854 1.83691Z"
			fill="white"
		/>
		<path
			d="M5.65364 1.88574L5.79127 2.30884L5.4314 2.04716L5.65364 1.88574Z"
			fill="white"
		/>
		<path
			d="M7.38302 2.04716L7.02344 2.30884L7.16078 1.88574L7.38302 2.04716Z"
			fill="white"
		/>
		<path
			d="M7.23048 2.0986L6.87061 1.83691H7.31538L7.23048 2.0986Z"
			fill="white"
		/>
		<path
			d="M7.1333 1.96692L7.27093 1.54382L7.40828 1.96692H7.1333Z"
			fill="white"
		/>
		<path
			d="M7.23096 1.83691H7.67573L7.31585 2.0986L7.23096 1.83691Z"
			fill="white"
		/>
		<path
			d="M7.38606 1.88574L7.52369 2.30884L7.16382 2.04716L7.38606 1.88574Z"
			fill="white"
		/>
		<path
			d="M9.11305 2.0474L8.75317 2.30909L8.89052 1.88599L9.11305 2.0474Z"
			fill="white"
		/>
		<path
			d="M8.96017 2.09884L8.60059 1.83716H9.04536L8.96017 2.09884Z"
			fill="white"
		/>
		<path
			d="M8.85693 1.97034L8.99428 1.54724L9.13162 1.97034H8.85693Z"
			fill="white"
		/>
		<path
			d="M8.96069 1.83716H9.40546L9.04588 2.09884L8.96069 1.83716Z"
			fill="white"
		/>
		<path
			d="M9.11608 1.88599L9.25343 2.30909L8.89355 2.0474L9.11608 1.88599Z"
			fill="white"
		/>
		<path
			d="M1.3252 2.78793L0.965332 3.04932L1.10267 2.62622L1.3252 2.78793Z"
			fill="white"
		/>
		<path
			d="M1.17262 2.83879L0.812744 2.57739H1.25751L1.17262 2.83879Z"
			fill="white"
		/>
		<path
			d="M1.06909 2.71058L1.20643 2.28748L1.34378 2.71058H1.06909Z"
			fill="white"
		/>
		<path
			d="M1.1731 2.57739H1.61787L1.25799 2.83879L1.1731 2.57739Z"
			fill="white"
		/>
		<path
			d="M1.32824 2.62622L1.46559 3.04932L1.10571 2.78793L1.32824 2.62622Z"
			fill="white"
		/>
		<path
			d="M3.05763 2.78793L2.69775 3.04932L2.8351 2.62622L3.05763 2.78793Z"
			fill="white"
		/>
		<path
			d="M2.90504 2.83879L2.54517 2.57739H2.98994L2.90504 2.83879Z"
			fill="white"
		/>
		<path
			d="M2.80762 2.70752L2.94496 2.28442L3.0823 2.70752H2.80762Z"
			fill="white"
		/>
		<path
			d="M2.90527 2.57739H3.35004L2.99017 2.83879L2.90527 2.57739Z"
			fill="white"
		/>
		<path
			d="M3.06066 2.62622L3.19801 3.04932L2.83813 2.78793L3.06066 2.62622Z"
			fill="white"
		/>
		<path
			d="M4.77931 2.79098L4.41943 3.05237L4.55707 2.62927L4.77931 2.79098Z"
			fill="white"
		/>
		<path
			d="M4.63282 2.83879L4.27295 2.57739H4.71772L4.63282 2.83879Z"
			fill="white"
		/>
		<path
			d="M4.5293 2.71058L4.66664 2.28748L4.80427 2.71058H4.5293Z"
			fill="white"
		/>
		<path
			d="M4.63306 2.57739H5.07783L4.71795 2.83879L4.63306 2.57739Z"
			fill="white"
		/>
		<path
			d="M4.78816 2.62622L4.9255 3.04932L4.56592 2.78793L4.78816 2.62622Z"
			fill="white"
		/>
		<path
			d="M6.5173 2.78793L6.15771 3.04932L6.29506 2.62622L6.5173 2.78793Z"
			fill="white"
		/>
		<path
			d="M6.365 2.83879L6.00513 2.57739H6.4499L6.365 2.83879Z"
			fill="white"
		/>
		<path
			d="M6.26758 2.70752L6.40521 2.28442L6.54255 2.70752H6.26758Z"
			fill="white"
		/>
		<path
			d="M6.36523 2.57739H6.81L6.45013 2.83879L6.36523 2.57739Z"
			fill="white"
		/>
		<path
			d="M6.52034 2.62622L6.65797 3.04932L6.2981 2.78793L6.52034 2.62622Z"
			fill="white"
		/>
		<path
			d="M8.24195 2.79073L7.88208 3.05213L8.01942 2.62903L8.24195 2.79073Z"
			fill="white"
		/>
		<path
			d="M8.09547 2.83854L7.7356 2.57715H8.18037L8.09547 2.83854Z"
			fill="white"
		/>
		<path
			d="M7.99194 2.71033L8.12958 2.28723L8.26692 2.71033H7.99194Z"
			fill="white"
		/>
		<path
			d="M8.0957 2.57715H8.54047L8.1806 2.83854L8.0957 2.57715Z"
			fill="white"
		/>
		<path
			d="M8.2511 2.62598L8.38844 3.04908L8.02856 2.78768L8.2511 2.62598Z"
			fill="white"
		/>
		<path
			d="M9.97901 2.78768L9.61914 3.04908L9.75648 2.62598L9.97901 2.78768Z"
			fill="white"
		/>
		<path
			d="M9.82614 2.83854L9.46655 2.57715H9.91132L9.82614 2.83854Z"
			fill="white"
		/>
		<path
			d="M9.729 2.70728L9.86635 2.28418L10.0037 2.70728H9.729Z"
			fill="white"
		/>
		<path
			d="M9.82666 2.57715H10.2717L9.91185 2.83854L9.82666 2.57715Z"
			fill="white"
		/>
		<path
			d="M9.98205 2.62598L10.1194 3.04908L9.75952 2.78768L9.98205 2.62598Z"
			fill="white"
		/>
		<path
			d="M2.19869 3.53378L1.83911 3.79517L1.97645 3.37207L2.19869 3.53378Z"
			fill="white"
		/>
		<path
			d="M2.0464 3.58452L1.68652 3.32312H2.13129L2.0464 3.58452Z"
			fill="white"
		/>
		<path
			d="M1.94287 3.4563L2.0805 3.0332L2.21785 3.4563H1.94287Z"
			fill="white"
		/>
		<path
			d="M2.04663 3.32312H2.4914L2.13153 3.58452L2.04663 3.32312Z"
			fill="white"
		/>
		<path
			d="M2.20173 3.37207L2.33936 3.79517L1.97949 3.53378L2.20173 3.37207Z"
			fill="white"
		/>
		<path
			d="M3.93116 3.53378L3.57129 3.79517L3.70863 3.37207L3.93116 3.53378Z"
			fill="white"
		/>
		<path
			d="M3.77828 3.58452L3.4187 3.32312H3.86347L3.77828 3.58452Z"
			fill="white"
		/>
		<path
			d="M3.68115 3.45325L3.8185 3.03015L3.95584 3.45325H3.68115Z"
			fill="white"
		/>
		<path
			d="M3.77881 3.32312H4.22358L3.864 3.58452L3.77881 3.32312Z"
			fill="white"
		/>
		<path
			d="M3.9342 3.37207L4.07154 3.79517L3.71167 3.53378L3.9342 3.37207Z"
			fill="white"
		/>
		<path
			d="M5.65455 3.53597L5.29468 3.79737L5.43202 3.37427L5.65455 3.53597Z"
			fill="white"
		/>
		<path
			d="M5.50196 3.58671L5.14209 3.32532H5.58686L5.50196 3.58671Z"
			fill="white"
		/>
		<path
			d="M5.40454 3.45545L5.54188 3.03235L5.67952 3.45545H5.40454Z"
			fill="white"
		/>
		<path
			d="M5.5022 3.32532H5.94697L5.5871 3.58671L5.5022 3.32532Z"
			fill="white"
		/>
		<path
			d="M5.65759 3.37427L5.79493 3.79737L5.43506 3.53597L5.65759 3.37427Z"
			fill="white"
		/>
		<path
			d="M7.39137 3.53378L7.03149 3.79517L7.16913 3.37207L7.39137 3.53378Z"
			fill="white"
		/>
		<path
			d="M7.23853 3.58452L6.87866 3.32312H7.32343L7.23853 3.58452Z"
			fill="white"
		/>
		<path
			d="M7.14136 3.45325L7.2787 3.03015L7.41633 3.45325H7.14136Z"
			fill="white"
		/>
		<path
			d="M7.23901 3.32312H7.68378L7.32391 3.58452L7.23901 3.32312Z"
			fill="white"
		/>
		<path
			d="M7.39412 3.37207L7.53175 3.79517L7.17188 3.53378L7.39412 3.37207Z"
			fill="white"
		/>
		<path
			d="M9.113 3.53536L8.75342 3.79676L8.89076 3.37366L9.113 3.53536Z"
			fill="white"
		/>
		<path
			d="M8.9607 3.5861L8.60083 3.32471H9.0456L8.9607 3.5861Z"
			fill="white"
		/>
		<path
			d="M8.86328 3.45484L9.00091 3.03174L9.13826 3.45484H8.86328Z"
			fill="white"
		/>
		<path
			d="M8.96094 3.32471H9.40571L9.04584 3.5861L8.96094 3.32471Z"
			fill="white"
		/>
		<path
			d="M9.11604 3.37366L9.25367 3.79676L8.8938 3.53536L9.11604 3.37366Z"
			fill="white"
		/>
		<path
			d="M1.32984 4.27071L0.969971 4.53211L1.1076 4.10901L1.32984 4.27071Z"
			fill="white"
		/>
		<path
			d="M1.18336 4.31852L0.823486 4.05713H1.26826L1.18336 4.31852Z"
			fill="white"
		/>
		<path
			d="M1.07983 4.19031L1.21747 3.76721L1.35481 4.19031H1.07983Z"
			fill="white"
		/>
		<path
			d="M1.17749 4.06018H1.62226L1.26239 4.32158L1.17749 4.06018Z"
			fill="white"
		/>
		<path
			d="M1.3387 4.10596L1.47633 4.52906L1.11646 4.26766L1.3387 4.10596Z"
			fill="white"
		/>
		<path
			d="M3.07589 4.26766L2.71631 4.52906L2.85365 4.10596L3.07589 4.26766Z"
			fill="white"
		/>
		<path
			d="M2.92359 4.31852L2.56372 4.05713H3.00849L2.92359 4.31852Z"
			fill="white"
		/>
		<path
			d="M2.82617 4.18726L2.9638 3.76416L3.10115 4.18726H2.82617Z"
			fill="white"
		/>
		<path
			d="M2.92383 4.05713H3.3686L3.00873 4.31852L2.92383 4.05713Z"
			fill="white"
		/>
		<path
			d="M3.07893 4.10596L3.21656 4.52906L2.85669 4.26766L3.07893 4.10596Z"
			fill="white"
		/>
		<path
			d="M4.79176 4.27181L4.43188 4.53321L4.56923 4.11011L4.79176 4.27181Z"
			fill="white"
		/>
		<path
			d="M4.63888 4.32267L4.2793 4.06128H4.72407L4.63888 4.32267Z"
			fill="white"
		/>
		<path
			d="M4.54175 4.19141L4.67909 3.76831L4.81643 4.19141H4.54175Z"
			fill="white"
		/>
		<path
			d="M4.6394 4.06128H5.08417L4.72459 4.32267L4.6394 4.06128Z"
			fill="white"
		/>
		<path
			d="M4.7948 4.11011L4.93214 4.53321L4.57227 4.27181L4.7948 4.11011Z"
			fill="white"
		/>
		<path
			d="M6.53614 4.26766L6.17627 4.52906L6.31361 4.10596L6.53614 4.26766Z"
			fill="white"
		/>
		<path
			d="M6.38355 4.31852L6.02368 4.05713H6.46845L6.38355 4.31852Z"
			fill="white"
		/>
		<path
			d="M6.28003 4.19031L6.41737 3.76721L6.555 4.19031H6.28003Z"
			fill="white"
		/>
		<path
			d="M6.38379 4.05713H6.82856L6.46869 4.31852L6.38379 4.05713Z"
			fill="white"
		/>
		<path
			d="M6.53918 4.10596L6.67652 4.52906L6.31665 4.26766L6.53918 4.10596Z"
			fill="white"
		/>
		<path
			d="M8.24928 4.2723L7.8894 4.5337L8.02704 4.1106L8.24928 4.2723Z"
			fill="white"
		/>
		<path
			d="M8.09669 4.32316L7.73682 4.06177H8.18159L8.09669 4.32316Z"
			fill="white"
		/>
		<path
			d="M7.99927 4.1919L8.13661 3.7688L8.27424 4.1919H7.99927Z"
			fill="white"
		/>
		<path
			d="M8.09692 4.06177H8.54169L8.18182 4.32316L8.09692 4.06177Z"
			fill="white"
		/>
		<path
			d="M8.25203 4.1106L8.38966 4.5337L8.02979 4.2723L8.25203 4.1106Z"
			fill="white"
		/>
		<path
			d="M9.99825 4.26705L9.63867 4.52845L9.77601 4.10535L9.99825 4.26705Z"
			fill="white"
		/>
		<path
			d="M9.84596 4.31791L9.48608 4.05652H9.93085L9.84596 4.31791Z"
			fill="white"
		/>
		<path
			d="M9.74854 4.18665L9.88617 3.76355L10.0235 4.18665H9.74854Z"
			fill="white"
		/>
		<path
			d="M9.84619 4.05652H10.291L9.93109 4.31791L9.84619 4.05652Z"
			fill="white"
		/>
		<path
			d="M10.0013 4.10535L10.1389 4.52845L9.77905 4.26705L10.0013 4.10535Z"
			fill="white"
		/>
		<path
			d="M2.20167 4.99227L1.8418 5.25367L1.97943 4.83057L2.20167 4.99227Z"
			fill="white"
		/>
		<path
			d="M2.04908 5.04313L1.68921 4.78174H2.13398L2.04908 5.04313Z"
			fill="white"
		/>
		<path
			d="M1.94556 4.91492L2.0829 4.49182L2.22053 4.91492H1.94556Z"
			fill="white"
		/>
		<path
			d="M2.04932 4.78174H2.49409L2.13421 5.04313L2.04932 4.78174Z"
			fill="white"
		/>
		<path
			d="M2.20442 4.83057L2.34176 5.25367L1.98218 4.99227L2.20442 4.83057Z"
			fill="white"
		/>
		<path
			d="M3.94137 4.99227L3.58179 5.25367L3.71913 4.83057L3.94137 4.99227Z"
			fill="white"
		/>
		<path
			d="M3.78907 5.04313L3.4292 4.78174H3.87397L3.78907 5.04313Z"
			fill="white"
		/>
		<path
			d="M3.69165 4.91187L3.82928 4.48877L3.96663 4.91187H3.69165Z"
			fill="white"
		/>
		<path
			d="M3.78931 4.78174H4.23408L3.8742 5.04313L3.78931 4.78174Z"
			fill="white"
		/>
		<path
			d="M3.94441 4.83057L4.08204 5.25367L3.72217 4.99227L3.94441 4.83057Z"
			fill="white"
		/>
		<path
			d="M5.66944 4.99227L5.30957 5.25367L5.44691 4.83057L5.66944 4.99227Z"
			fill="white"
		/>
		<path
			d="M5.51686 5.04313L5.15698 4.78174H5.60175L5.51686 5.04313Z"
			fill="white"
		/>
		<path
			d="M5.41333 4.91492L5.55067 4.49182L5.68802 4.91492H5.41333Z"
			fill="white"
		/>
		<path
			d="M5.51709 4.78174H5.96186L5.60199 5.04313L5.51709 4.78174Z"
			fill="white"
		/>
		<path
			d="M5.67248 4.83057L5.80982 5.25367L5.44995 4.99227L5.67248 4.83057Z"
			fill="white"
		/>
		<path
			d="M7.40186 4.99227L7.04199 5.25367L7.17934 4.83057L7.40186 4.99227Z"
			fill="white"
		/>
		<path
			d="M7.24903 5.04313L6.88916 4.78174H7.33393L7.24903 5.04313Z"
			fill="white"
		/>
		<path
			d="M7.15186 4.91187L7.2892 4.48877L7.42654 4.91187H7.15186Z"
			fill="white"
		/>
		<path
			d="M7.24951 4.78174H7.69428L7.33441 5.04313L7.24951 4.78174Z"
			fill="white"
		/>
		<path
			d="M7.4049 4.83057L7.54225 5.25367L7.18237 4.99227L7.4049 4.83057Z"
			fill="white"
		/>
		<path
			d="M9.11427 4.99374L8.75439 5.25513L8.89203 4.83203L9.11427 4.99374Z"
			fill="white"
		/>
		<path
			d="M8.96168 5.0446L8.60181 4.7832H9.04658L8.96168 5.0446Z"
			fill="white"
		/>
		<path
			d="M8.86426 4.91321L9.0016 4.49011L9.13923 4.91321H8.86426Z"
			fill="white"
		/>
		<path
			d="M8.96191 4.7832H9.40668L9.04681 5.0446L8.96191 4.7832Z"
			fill="white"
		/>
		<path
			d="M9.11702 4.83203L9.25465 5.25513L8.89478 4.99374L9.11702 4.83203Z"
			fill="white"
		/>
		<path
			d="M1.35963 5.72599L0.999756 5.98739L1.13739 5.56458L1.35963 5.72599Z"
			fill="white"
		/>
		<path
			d="M1.20704 5.77714L0.847168 5.51575H1.29194L1.20704 5.77714Z"
			fill="white"
		/>
		<path
			d="M1.10352 5.64893L1.24086 5.22583L1.37849 5.64893H1.10352Z"
			fill="white"
		/>
		<path
			d="M1.20752 5.51575H1.65229L1.29242 5.77714L1.20752 5.51575Z"
			fill="white"
		/>
		<path
			d="M1.36238 5.56458L1.50001 5.98739L1.14014 5.72599L1.36238 5.56458Z"
			fill="white"
		/>
		<path
			d="M3.08297 5.73014L2.72339 5.99154L2.86073 5.56873L3.08297 5.73014Z"
			fill="white"
		/>
		<path
			d="M2.93067 5.78129L2.5708 5.5199H3.01557L2.93067 5.78129Z"
			fill="white"
		/>
		<path
			d="M2.83325 5.65003L2.97088 5.22693L3.10823 5.65003H2.83325Z"
			fill="white"
		/>
		<path
			d="M2.93091 5.5199H3.37568L3.01581 5.78129L2.93091 5.5199Z"
			fill="white"
		/>
		<path
			d="M3.08601 5.56873L3.22364 5.99154L2.86377 5.73014L3.08601 5.56873Z"
			fill="white"
		/>
		<path
			d="M4.81983 5.72599L4.45996 5.98739L4.5973 5.56458L4.81983 5.72599Z"
			fill="white"
		/>
		<path
			d="M4.66725 5.77714L4.30737 5.51575H4.75214L4.66725 5.77714Z"
			fill="white"
		/>
		<path
			d="M4.56372 5.64893L4.70106 5.22583L4.83841 5.64893H4.56372Z"
			fill="white"
		/>
		<path
			d="M4.66748 5.51575H5.11225L4.75238 5.77714L4.66748 5.51575Z"
			fill="white"
		/>
		<path
			d="M4.82287 5.56458L4.96021 5.98739L4.60034 5.72599L4.82287 5.56458Z"
			fill="white"
		/>
		<path
			d="M6.55201 5.72599L6.19214 5.98739L6.32948 5.56458L6.55201 5.72599Z"
			fill="white"
		/>
		<path
			d="M6.39942 5.77714L6.03955 5.51575H6.48432L6.39942 5.77714Z"
			fill="white"
		/>
		<path
			d="M6.302 5.64588L6.43934 5.22278L6.57669 5.64588H6.302Z"
			fill="white"
		/>
		<path
			d="M6.39966 5.51575H6.84443L6.48456 5.77714L6.39966 5.51575Z"
			fill="white"
		/>
		<path
			d="M6.55505 5.56458L6.69239 5.98739L6.33252 5.72599L6.55505 5.56458Z"
			fill="white"
		/>
		<path
			d="M8.27833 5.72514L7.91846 5.98653L8.05609 5.56372L8.27833 5.72514Z"
			fill="white"
		/>
		<path
			d="M8.12574 5.77629L7.76587 5.51489H8.21064L8.12574 5.77629Z"
			fill="white"
		/>
		<path
			d="M8.02222 5.64808L8.15956 5.22498L8.29719 5.64808H8.02222Z"
			fill="white"
		/>
		<path
			d="M8.12598 5.51489H8.57075L8.21087 5.77629L8.12598 5.51489Z"
			fill="white"
		/>
		<path
			d="M8.28108 5.56372L8.41842 5.98653L8.05884 5.72514L8.28108 5.56372Z"
			fill="white"
		/>
		<path
			d="M10.009 5.72514L9.64941 5.98653L9.78676 5.56372L10.009 5.72514Z"
			fill="white"
		/>
		<path
			d="M9.8567 5.77629L9.49683 5.51489H9.9416L9.8567 5.77629Z"
			fill="white"
		/>
		<path
			d="M9.75928 5.64502L9.89691 5.22192L10.0343 5.64502H9.75928Z"
			fill="white"
		/>
		<path
			d="M9.85693 5.51489H10.3017L9.94183 5.77629L9.85693 5.51489Z"
			fill="white"
		/>
		<path
			d="M10.012 5.56372L10.1497 5.98653L9.78979 5.72514L10.012 5.56372Z"
			fill="white"
		/>
		<path
			d="M2.19869 6.46513L1.83911 6.72652L1.97645 6.30371L2.19869 6.46513Z"
			fill="white"
		/>
		<path
			d="M2.0464 6.51657L1.68652 6.25488H2.13129L2.0464 6.51657Z"
			fill="white"
		/>
		<path
			d="M1.94287 6.38807L2.0805 5.96497L2.21785 6.38807H1.94287Z"
			fill="white"
		/>
		<path
			d="M2.04663 6.25488H2.4914L2.13153 6.51657L2.04663 6.25488Z"
			fill="white"
		/>
		<path
			d="M2.20173 6.30371L2.33936 6.72652L1.97949 6.46513L2.20173 6.30371Z"
			fill="white"
		/>
		<path
			d="M3.93116 6.46513L3.57129 6.72652L3.70863 6.30371L3.93116 6.46513Z"
			fill="white"
		/>
		<path
			d="M3.77828 6.51657L3.4187 6.25488H3.86347L3.77828 6.51657Z"
			fill="white"
		/>
		<path
			d="M3.68115 6.38501L3.8185 5.96191L3.95584 6.38501H3.68115Z"
			fill="white"
		/>
		<path
			d="M3.77881 6.25488H4.22358L3.864 6.51657L3.77881 6.25488Z"
			fill="white"
		/>
		<path
			d="M3.9342 6.30371L4.07154 6.72652L3.71167 6.46513L3.9342 6.30371Z"
			fill="white"
		/>
		<path
			d="M5.65455 6.46928L5.29468 6.73067L5.43231 6.30786L5.65455 6.46928Z"
			fill="white"
		/>
		<path
			d="M5.50196 6.52072L5.14209 6.25903H5.58686L5.50196 6.52072Z"
			fill="white"
		/>
		<path
			d="M5.40454 6.38916L5.54188 5.96606L5.67952 6.38916H5.40454Z"
			fill="white"
		/>
		<path
			d="M5.5022 6.25903H5.94697L5.5871 6.52072L5.5022 6.25903Z"
			fill="white"
		/>
		<path
			d="M5.6573 6.30786L5.79464 6.73067L5.43506 6.46928L5.6573 6.30786Z"
			fill="white"
		/>
		<path
			d="M7.39137 6.46513L7.03149 6.72652L7.16913 6.30371L7.39137 6.46513Z"
			fill="white"
		/>
		<path
			d="M7.23853 6.51657L6.87866 6.25488H7.32343L7.23853 6.51657Z"
			fill="white"
		/>
		<path
			d="M7.14136 6.38501L7.27899 5.96191L7.41633 6.38501H7.14136Z"
			fill="white"
		/>
		<path
			d="M7.23901 6.25488H7.68378L7.32391 6.51657L7.23901 6.25488Z"
			fill="white"
		/>
		<path
			d="M7.39412 6.30371L7.53175 6.72652L7.17188 6.46513L7.39412 6.30371Z"
			fill="white"
		/>
		<path
			d="M9.113 6.46989L8.75342 6.73128L8.89076 6.30847L9.113 6.46989Z"
			fill="white"
		/>
		<path
			d="M8.9607 6.52133L8.60083 6.25964H9.0456L8.9607 6.52133Z"
			fill="white"
		/>
		<path
			d="M8.86328 6.38977L9.00091 5.96667L9.13826 6.38977H8.86328Z"
			fill="white"
		/>
		<path
			d="M8.96094 6.25964H9.40571L9.04584 6.52133L8.96094 6.25964Z"
			fill="white"
		/>
		<path
			d="M9.11604 6.30847L9.25367 6.73128L8.8938 6.46989L9.11604 6.30847Z"
			fill="white"
		/>
		<path
			d="M1.33058 7.20951L0.970703 7.4712L1.10834 7.0481L1.33058 7.20951Z"
			fill="white"
		/>
		<path
			d="M1.17799 7.26095L0.818115 6.99927H1.26289L1.17799 7.26095Z"
			fill="white"
		/>
		<path
			d="M1.08057 7.1294L1.2182 6.7063L1.35554 7.1294H1.08057Z"
			fill="white"
		/>
		<path
			d="M1.17847 6.99927H1.62324L1.26336 7.26095L1.17847 6.99927Z"
			fill="white"
		/>
		<path
			d="M1.33332 7.0481L1.47096 7.4712L1.11108 7.20951L1.33332 7.0481Z"
			fill="white"
		/>
		<path
			d="M3.06271 7.20951L2.70312 7.4712L2.84047 7.0481L3.06271 7.20951Z"
			fill="white"
		/>
		<path
			d="M2.91041 7.26095L2.55054 6.99927H2.99531L2.91041 7.26095Z"
			fill="white"
		/>
		<path
			d="M2.81299 7.1294L2.95062 6.7063L3.08796 7.1294H2.81299Z"
			fill="white"
		/>
		<path
			d="M2.91064 6.99927H3.35541L2.99554 7.26095L2.91064 6.99927Z"
			fill="white"
		/>
		<path
			d="M3.06575 7.0481L3.20338 7.4712L2.84351 7.20951L3.06575 7.0481Z"
			fill="white"
		/>
		<path
			d="M4.79078 7.20951L4.43091 7.4712L4.56825 7.0481L4.79078 7.20951Z"
			fill="white"
		/>
		<path
			d="M4.6379 7.26095L4.27832 6.99927H4.72309L4.6379 7.26095Z"
			fill="white"
		/>
		<path
			d="M4.54077 7.1294L4.67811 6.7063L4.81546 7.1294H4.54077Z"
			fill="white"
		/>
		<path
			d="M4.63843 6.99927H5.0832L4.72362 7.26095L4.63843 6.99927Z"
			fill="white"
		/>
		<path
			d="M4.79382 7.0481L4.93116 7.4712L4.57129 7.20951L4.79382 7.0481Z"
			fill="white"
		/>
		<path
			d="M6.52296 7.20951L6.16309 7.4712L6.30072 7.0481L6.52296 7.20951Z"
			fill="white"
		/>
		<path
			d="M6.37037 7.26095L6.0105 6.99927H6.45527L6.37037 7.26095Z"
			fill="white"
		/>
		<path
			d="M6.27295 7.1294L6.41029 6.7063L6.54792 7.1294H6.27295Z"
			fill="white"
		/>
		<path
			d="M6.37061 6.99927H6.81538L6.4555 7.26095L6.37061 6.99927Z"
			fill="white"
		/>
		<path
			d="M6.52571 7.0481L6.66305 7.4712L6.30347 7.20951L6.52571 7.0481Z"
			fill="white"
		/>
		<path
			d="M8.25001 7.21037L7.89014 7.47205L8.02777 7.04895L8.25001 7.21037Z"
			fill="white"
		/>
		<path
			d="M8.09742 7.26181L7.73755 7.00012H8.18232L8.09742 7.26181Z"
			fill="white"
		/>
		<path
			d="M8 7.13025L8.13734 6.70715L8.27498 7.13025H8Z"
			fill="white"
		/>
		<path
			d="M8.09766 7.00012H8.54243L8.18255 7.26181L8.09766 7.00012Z"
			fill="white"
		/>
		<path
			d="M8.25276 7.04895L8.39039 7.47205L8.03052 7.21037L8.25276 7.04895Z"
			fill="white"
		/>
		<path
			d="M9.98068 7.21037L9.62109 7.47205L9.75844 7.04895L9.98068 7.21037Z"
			fill="white"
		/>
		<path
			d="M9.82838 7.26181L9.46851 7.00012H9.91328L9.82838 7.26181Z"
			fill="white"
		/>
		<path
			d="M9.73096 7.13025L9.86859 6.70715L10.0059 7.13025H9.73096Z"
			fill="white"
		/>
		<path
			d="M9.82861 7.00012H10.2734L9.91351 7.26181L9.82861 7.00012Z"
			fill="white"
		/>
		<path
			d="M9.98371 7.04895L10.1213 7.47205L9.76147 7.21037L9.98371 7.04895Z"
			fill="white"
		/>
	</>
)

export const australia = (
	<>
		<path
			fill="#00008b"
			d="M21 0H0v12.78h21V0z"
		/>
		<path
			fill="#fff"
			d="M5.65 9.2l.33 1.02 1-.38-.59.89.92.54-1.06.09.15 1.06-.73-.77-.73.77.15-1.06-1.06-.09.92-.54-.59-.89 1 .38.33-1.02zM16.61 9.73l.16.48.48-.18-.28.42.44.26-.51.04.07.5-.35-.37-.35.37.07-.5-.51-.04.44-.26-.28-.42.48.18.16-.48zM14.79 5.26l.16.48.48-.18-.28.42.44.26-.51.04.07.5-.35-.37-.35.37.07-.5-.51-.04.44-.26-.28-.42.48.18.16-.48zM16.61 2.2l.16.48.48-.18-.28.42.44.26-.51.04.07.5-.35-.37-.35.37.07-.5-.51-.04.44-.26-.28-.42.48.18.16-.48zM19.12 4.51l.16.48.48-.18-.28.42.44.26-.51.04.07.5-.35-.37-.35.37.07-.5-.51-.04.44-.26-.28-.42.48.18.16-.48zM17.74 6.77l.12.3.32.02-.25.21.08.32-.28-.17-.28.17.08-.32-.25-.21.32-.02.12-.3z"
		/>
		<path
			fill="#fff"
			d="M0 1.12L0 0 1.11 0 4.83 1.86 4.83 0 6.83 0 6.83 1.86 10.54 0 12.22 0 12.22 0.84 9.5 2.2 12.22 2.2 12.22 4.19 9.5 4.19 12.22 5.55 12.22 6.39 10.54 6.39 6.83 4.53 6.83 6.39 4.83 6.39 4.83 4.53 1.11 6.39 0 6.39 0 5.27 2.15 4.19 0 4.19 0 2.2 2.15 2.2 0 1.12z"
		/>
		<path
			fill="red"
			d="M0.33 0L0 0 0 0.28 0 0.73 2.94 2.2 3.83 2.2 4.72 2.2 0.33 0z"
		/>
		<path
			fill="red"
			d="M12.22 0L12.22 0 11.32 0 6.93 2.2 7.82 2.2 8.72 2.2 12.22 0.45 12.22 0 12.22 0z"
		/>
		<path
			fill="red"
			d="M3.83 4.19L2.94 4.19 0 5.66 0 6.11 0 6.39 0.33 6.39 4.72 4.19 3.83 4.19z"
		/>
		<path
			fill="red"
			d="M8.72 4.19L7.82 4.19 6.93 4.19 11.32 6.39 12.22 6.39 12.22 6.39 12.22 6.39 12.22 5.94 8.72 4.19z"
		/>
		<path
			fill="red"
			d="M8.7 2.6L7.92 2.6 7.03 2.6 6.43 2.6 6.43 2.45 6.43 2.06 6.43 0 5.83 0 5.23 0 5.23 2.06 5.23 2.45 5.23 2.6 4.63 2.6 3.74 2.6 2.95 2.6 0 2.6 0 3.2 0 3.79 2.95 3.79 3.74 3.79 4.63 3.79 5.23 3.79 5.23 3.94 5.23 4.33 5.23 6.39 5.83 6.39 6.43 6.39 6.43 4.33 6.43 3.94 6.43 3.79 7.03 3.79 7.92 3.79 8.7 3.79 12.22 3.79 12.22 3.2 12.22 2.6 8.7 2.6z"
		/>
	</>
)

export const canada = (
	<>
		<rect
			width="21"
			height="15"
			fill="white"
		/>
		<path
			d="M10.7059 11.6176H10.2941L10.3971 9.97059C10.3912 9.88385 10.3496 9.86105 10.1912 9.86765L8.54412 10.0735C8.54412 10.0735 8.85294 9.66176 8.85294 9.45588C8.85294 9.25 7 7.91176 7 7.91176C7 7.91176 7.41176 7.80882 7.51471 7.70588C7.61765 7.60294 7.10294 6.47059 7.10294 6.47059C7.10294 6.47059 8.14906 6.88235 8.23529 6.77941C8.32153 6.67647 8.44118 6.26471 8.44118 6.26471C8.44118 6.26471 9.26471 7.19118 9.47059 7.19118C9.67647 7.19118 9.05882 4.92647 9.05882 4.92647C9.05882 4.92647 9.57353 5.33824 9.77941 5.33824C9.98529 5.33824 10.5 4 10.5 4C10.5 4 11.0147 5.33824 11.1176 5.33824C11.2206 5.33824 11.9412 4.92647 11.9412 4.92647C11.9412 4.92647 11.4265 7.08824 11.5294 7.19118C11.6324 7.29412 12.5588 6.26471 12.5588 6.26471C12.5588 6.26471 12.6618 6.67647 12.7647 6.77941C12.8676 6.88235 13.8971 6.47059 13.8971 6.47059C13.8971 6.47059 13.3824 7.60294 13.4853 7.70588C13.5882 7.80882 14 7.91176 14 7.91176C14 7.91176 12.1471 9.25 12.1471 9.45588C12.1471 9.66176 12.3529 10.0735 12.3529 10.0735L10.8088 9.86765C10.6847 9.83206 10.6417 9.85907 10.6029 9.97059L10.7059 11.6176Z"
			fill="#F93939"
		/>
		<rect
			x="16"
			width="5"
			height="15"
			fill="#F93939"
		/>
		<rect
			width="5"
			height="15"
			fill="#F93939"
		/>
	</>
)

export const europe = (
	<>
		<path
			d="M25.5 0H-4.5V19.0476H25.5V0Z"
			fill="#0052B4"
		/>
		<path
			d="M10.5005 2.0238L10.3236 2.56936H9.75L10.2141 2.90682L10.0373 3.45237L10.5005 3.11492L10.9637 3.45237L10.7868 2.90682L11.25 2.56936H10.6773L10.5005 2.0238Z"
			fill="#FFCC00"
		/>
		<path
			d="M8.00047 2.73804L7.82361 3.23866H7.25L7.71413 3.5478L7.53634 4.04756L8.00047 3.73843L8.46366 4.04756L8.28681 3.5478L8.75 3.23866H8.17732L8.00047 2.73804Z"
			fill="#FFCC00"
		/>
		<path
			d="M6.12547 4.40479L5.94861 4.94998H5.375L5.83913 5.28722L5.66227 5.83336L6.12547 5.49612L6.58866 5.83336L6.41181 5.28722L6.875 4.94998H6.30232L6.12547 4.40479Z"
			fill="#FFCC00"
		/>
		<path
			d="M5.49953 7.87698L5.96366 8.21422L5.78681 7.66808L6.25 7.33178H5.67732L5.49953 6.78564L5.32268 7.33178H4.75L5.21319 7.66808L5.03634 8.21422L5.49953 7.87698Z"
			fill="#FFCC00"
		/>
		<path
			d="M6.30232 9.71218L6.12547 9.16663L5.94861 9.71218H5.375L5.83913 10.0496L5.66227 10.5952L6.12547 10.2577L6.58866 10.5952L6.41181 10.0496L6.875 9.71218H6.30232Z"
			fill="#FFCC00"
		/>
		<path
			d="M8.17732 11.4985L8.00047 10.9524L7.82361 11.4985H7.25L7.71413 11.8358L7.53634 12.381L8.00047 12.0437L8.46366 12.381L8.28681 11.8358L8.75 11.4985H8.17732Z"
			fill="#FFCC00"
		/>
		<path
			d="M10.9637 12.9762L10.5005 12.6387L10.0373 12.9762L10.2141 12.4306L9.75 12.0932H10.3236L10.5005 11.5476L10.6773 12.0932H11.25L10.7868 12.4306L10.9637 12.9762Z"
			fill="#FFCC00"
		/>
		<path
			d="M13.1764 11.4985L12.9995 10.9524L12.8227 11.4985H12.25L12.7132 11.8358L12.5363 12.381L12.9995 12.0437L13.4637 12.381L13.2859 11.8358L13.75 11.4985H13.1764Z"
			fill="#FFCC00"
		/>
		<path
			d="M15.0514 9.71218L14.8745 9.16663L14.6977 9.71218H14.125L14.5882 10.0496L14.4113 10.5952L14.8745 10.2577L15.3377 10.5952L15.1609 10.0496L15.625 9.71218H15.0514Z"
			fill="#FFCC00"
		/>
		<path
			d="M16.25 7.33178H15.6773L15.5005 6.78564L15.3227 7.33178H14.75L15.2132 7.66808L15.0363 8.21422L15.5005 7.87698L15.9637 8.21422L15.7868 7.66808L16.25 7.33178Z"
			fill="#FFCC00"
		/>
		<path
			d="M14.4113 5.83336L14.8745 5.49612L15.3377 5.83336L15.1609 5.28722L15.625 4.94998H15.0514L14.8745 4.40479L14.6977 4.94998H14.125L14.5882 5.28722L14.4113 5.83336Z"
			fill="#FFCC00"
		/>
		<path
			d="M12.9995 2.73804L12.8227 3.23866H12.25L12.7132 3.5478L12.5363 4.04756L12.9995 3.73843L13.4637 4.04756L13.2859 3.5478L13.75 3.23866H13.1764L12.9995 2.73804Z"
			fill="#FFCC00"
		/>
	</>
)

export const uk = (
	<>
		<rect
			width="21"
			height="15"
			fill="#1A47B8"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.23397 0H0V2.5L18.7539 15L21 15V12.5L2.23397 0Z"
			fill="white"
		/>
		<path
			d="M0.745098 0L21 13.5354V15H20.2724L0 1.45056V0H0.745098Z"
			fill="#F93939"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 0H21V2.5C21 2.5 8.0098 10.8281 2 15H0V12.5L19 0Z"
			fill="white"
		/>
		<path
			d="M21 0H20.3218L0 13.5471V15H0.745098L21 1.46151V0Z"
			fill="#F93939"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.63708 0H13.3804V4.62682H21V10.3701H13.3804V15H7.63708V10.3701H0V4.62682H7.63708V0Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.8421 0H12.1579V5.76923H21V9.23077H12.1579V15H8.8421V9.23077H0V5.76923H8.8421V0Z"
			fill="#F93939"
		/>
	</>
)

export const denmark = (
	<>
		<rect
			width="21"
			height="15"
			fill="white"
			transform="translate(0 0.5)"
		/>
		<rect
			width="21"
			height="15"
			transform="translate(0 0.5)"
			fill="#F93939"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 9.5H0V6.5H6V0.5H9V6.5H21V9.5H9V15.5H6V9.5Z"
			fill="white"
		/>
	</>
)

export const sweden = (
	<>
		<path
			fill="#3a99ff"
			d="M0 0H21V15H0z"
		/>
		<path
			fill="#ffda2c"
			fillRule="evenodd"
			d="M6 9H0V6h6V0h3v6h12v3H9v6H6V9z"
		/>
	</>
)

export const CountryMap: Record<RegionShort, Country> = {
	'': {
		flag: us,
		name: 'United States',
		region: '',
		currencyTitle: '$ USD',
	},
	ca: {
		flag: canada,
		name: 'Canada',
		region: 'ca',
		currencyTitle: '$ CAD',
	},
	uk: {
		flag: uk,
		name: 'United Kingdom',
		region: 'uk',
		currencyTitle: '£ GBP',
	},
	eu: {
		flag: europe,
		name: 'Europe',
		region: 'eu',
		currencyTitle: '€ EUR',
	},
	au: {
		flag: australia,
		name: 'Australia',
		region: 'au',
		currencyTitle: '$ AUD',
	},
	dk: {
		flag: denmark,
		name: 'Denmark',
		region: 'dk',
		currencyTitle: 'kr DKK',
	},
	se: {
		flag: sweden,
		name: 'Sweden',
		region: 'se',
		currencyTitle: 'kr SEK',
	},
}

export const getCountry = (region): Country => {
	return CountryMap[region]
}

export const getCountries = (region): Country[] => {
	const regions = Object.keys(CountryMap).filter((c) => c !== region)
	return regions.map((r) => CountryMap[r])
}
