import { FC } from 'react'
import styles from './Selection.module.scss'
import { SelectionProps } from './types'
import { SelectionModalButton } from 'components/Phantom/_shop/SelectionModalButton'
import { PricingInfo } from 'components/Phantom/_shop/PricingInfo'
import { CardSelection } from 'components/Phantom/_shop/CardSelection'
import { SelectionModalButtonProps } from 'components/Phantom/_shop/SelectionModalButton/types'
import { ModalKey } from '../Modals/modalMapping'
import { Icon } from 'components/Phantom/Icon'
import cx from 'classnames'
import { Type } from 'components/Type'

export const Selection: FC<SelectionProps> = (props) => {
	const { title, subtitle, displayPricing, modalButtons } = props
	return (
		<div
			className={cx(styles.container, 'shop-section')}
			id={`selection-${props.id}`}
		>
			<header className={styles.selection_header}>
				<Type.Headline6
					as={'h3'}
					className={styles.selection_title}
				>
					{title}
				</Type.Headline6>
				<Type.Body2 className={styles.selection_subtitle}>{subtitle}</Type.Body2>
				{displayPricing && <PricingInfo {...displayPricing} />}
			</header>
			<fieldset>
				<legend className={'vh'}>{title} selection</legend>
				<SelectionRendered {...props} />
			</fieldset>
			{props.error && (
				<div className={styles.error_box}>
					<Icon
						name={'ExclamationCircleLight'}
						color={'#df1c2f'}
					/>
					<p>{props.error}</p>
				</div>
			)}
			<ModalButtons
				modalButtons={modalButtons}
				modalClick={props.modalClick}
			/>
		</div>
	)
}

const SelectionRendered = (props: SelectionProps) => {
	switch (props.selectionType) {
		case 'card-selection':
			return (
				<CardSelection
					parentId={props.id}
					error={props.error}
					currentSelection={props.currentSelection}
					{...props.selectionProps}
				/>
			)
		default:
			return null
	}
}

export const ModalButtons = (props: { modalButtons: SelectionModalButtonProps[]; modalClick: (key: ModalKey) => void }) => {
	if (!props.modalButtons) return null
	if (!props.modalButtons.length) return null

	return (
		<ul className={styles.modal_buttons}>
			{props.modalButtons.map((modalButton) => {
				return (
					<li key={modalButton.modalKey}>
						<SelectionModalButton
							{...modalButton}
							onClick={() => props.modalClick(modalButton.modalKey)}
						/>
					</li>
				)
			})}
		</ul>
	)
}
