import * as React from 'react'

/**
 * @deprecated Remove ASAP
 */
export default class PageWrapper extends React.Component {
	render() {
		return (
			<div id="top">
				<div
					id="main"
					tabIndex={-1}
				>
					{this.props.children}
				</div>
			</div>
		)
	}
}
