import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.6016 4.07472C17.8604 3.75129 17.8079 3.27932 17.4845 3.02054C17.1611 2.76177 16.6891 2.81418 16.4303 3.13761L14.8196 5.1508H4.62354C4.33494 5.1508 4.07193 5.3164 3.9472 5.57666C3.82247 5.83692 3.85814 6.14567 4.03894 6.37063L11.25 15.3434V19.4923H6.984C6.56979 19.4923 6.234 19.8281 6.234 20.2423C6.234 20.6565 6.56979 20.9923 6.984 20.9923H17.016C17.4302 20.9923 17.766 20.6565 17.766 20.2423C17.766 19.8281 17.4302 19.4923 17.016 19.4923H12.75V15.3434L19.961 6.37063C20.1418 6.14567 20.1775 5.83692 20.0528 5.57666C19.928 5.3164 19.665 5.1508 19.3764 5.1508H16.7406L17.6016 4.07472ZM13.6195 6.6508L12.2995 8.30054C12.0408 8.62397 12.0932 9.09594 12.4166 9.35472C12.74 9.61349 13.212 9.56108 13.4708 9.23765L15.5405 6.6508H17.8115L12 13.8821L6.18848 6.6508H13.6195Z"
			fill="black"
		/>
	</svg>
)

export default icon
