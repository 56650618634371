import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getSummaryVisuals = (podName: string, selectionKeys: string[]): VisualProps => {
	const combinedUpsellNames = selectionKeys
		.filter((sel) => sel !== 'pod-model' && sel !== 'size' && sel !== 'auto-pilot')
		.map((n) => n.replace(/[-_]/g, ''))
		.sort()

	const combinedImageName = [podName].concat(combinedUpsellNames).join('_')
	const imageurl = 'https://eightsleep.imgix.net/shop_combined_' + combinedImageName + '.png'

	return {
		type: 'image',
		data: {
			src: imageurl,
			alt: 'All items selected',
		},
		associatedSelectorKeys: ['summary'],
	}
}
