import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5ZM12.223 7.69604C12.6372 7.69604 12.973 8.03183 12.973 8.44604V9.98191V10.2532L14.1783 8.96968C14.4618 8.66772 14.9215 8.66772 15.205 8.96968C15.4886 9.27163 15.4886 9.7612 15.205 10.0632L13.8958 11.4574H15.7547C16.1689 11.4574 16.5047 11.7932 16.5047 12.2074C16.5047 12.6216 16.1689 12.9574 15.7547 12.9574H13.9551L14.1857 13.2029L15.2055 14.289C15.489 14.5909 15.489 15.0805 15.2055 15.3824C14.9219 15.6844 14.4622 15.6844 14.1787 15.3824L13.1589 14.2964L12.973 14.0984V15.9686C12.973 16.3828 12.6372 16.7186 12.223 16.7186C11.8088 16.7186 11.473 16.3828 11.473 15.9686V14.0376L11.23 14.2964L10.2102 15.3824C9.92668 15.6844 9.46697 15.6844 9.18343 15.3824C8.89989 15.0805 8.89989 14.5909 9.18343 14.289L10.2032 13.2029L10.4338 12.9574H10.1331H8.69092C8.2767 12.9574 7.94092 12.6216 7.94092 12.2074C7.94092 11.7932 8.2767 11.4574 8.69092 11.4574H10.1331H10.4931L9.18387 10.0632C8.90033 9.7612 8.90033 9.27163 9.18387 8.96968C9.46741 8.66772 9.92712 8.66772 10.2107 8.96968L11.473 10.314V9.98191V8.44604C11.473 8.03183 11.8088 7.69604 12.223 7.69604Z"
			fill={color}
		/>
	</svg>
)

export default icon
