import { IconComponentType } from '../../types'

export const BarCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M16 20V13H20V20H16ZM10 20V4H14V20H10ZM4 20V9H8V20H4Z"
			fill={color}
		/>
	</svg>
)
