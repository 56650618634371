import { hiddenDiscountCodesMap } from 'constants/specialOffers'

export enum PaymentType {
	PAY_NOW = 'pay_now',
	PAY_AFFIRM = 'pay_affirm',
	PAY_CRYPTO = 'pay_crypto',
}

export const getPaymentOption = (): PaymentType => {
	const paymentOption = (localStorage.getItem('payment_option') as PaymentType) || PaymentType.PAY_NOW
	return paymentOption
}

export const setPaymentOption = (paymentOption: PaymentType) => {
	localStorage.setItem('payment_option', paymentOption)
}

export function mapDiscountCode(discountCode) {
	const code = discountCode === 'SLEEPCOOL' ? 'REFERRAL' : discountCode

	if (code in hiddenDiscountCodesMap) {
		return hiddenDiscountCodesMap[code]
	}

	return code
}
