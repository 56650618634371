import { FC } from 'react'

export const TruemedBlueLogo: FC<{ color?: string }> = (props) => {
	const { color = '#122334' } = props
	const key = Date.now().toString()
	const maskId = `${key}_mask0_10056_3627`
	const maskIdAlt = `${key}_mask1_10056_3627`
	const clipId = `${key}_clip0_10056_3627`
	return (
		<svg
			className="truemed-logo"
			width="59"
			height="18"
			viewBox="0 0 59 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				height: 'auto',
				verticalAlign: 'middle',
			}}
		>
			<g clipPath={`url(#${clipId})`}>
				<mask
					id={maskId}
					// style="mask-type:luminance"
					maskUnits="userSpaceOnUse"
					x="1"
					y="4"
					width="58"
					height="10"
				>
					<path
						d="M58.2726 4H1.00073V13.8523H58.2726V4Z"
						fill="white"
					/>
				</mask>
				<g mask={`url(#${maskId})`}>
					<mask
						id={maskIdAlt}
						// style="mask-type:luminance"
						maskUnits="userSpaceOnUse"
						x="1"
						y="4"
						width="58"
						height="10"
					>
						<path
							d="M1.00073 4H58.2726V13.8522H1.00073V4Z"
							fill="white"
						/>
					</mask>
					<g mask={`url(#${maskIdAlt})`}>
						<path
							d="M57.4938 12.4359H57.6349C57.6995 12.4429 57.7576 12.3962 57.7646 12.3316C57.7652 12.3264 57.7654 12.3212 57.7653 12.3161C57.7653 12.241 57.7126 12.1967 57.6349 12.1967H57.4938V12.4359ZM57.4131 12.7586V12.1202H57.6406C57.7462 12.112 57.8386 12.191 57.8468 12.2967C57.8473 12.3037 57.8476 12.3108 57.8473 12.3178C57.8494 12.4014 57.7964 12.4763 57.7171 12.5024L57.8621 12.7603H57.7692L57.6327 12.5154H57.4934V12.7603L57.4131 12.7586Z"
							fill={color}
						/>
						<path
							d="M57.6277 11.8844C57.3158 11.8844 57.0632 12.1371 57.0632 12.4489C57.0632 12.7607 57.3158 13.0134 57.6277 13.0134C57.9395 13.0134 58.1921 12.7607 58.1921 12.4489C58.1921 12.1371 57.9395 11.8844 57.6277 11.8844ZM57.6277 13.0951C57.2708 13.0951 56.9814 12.8058 56.9814 12.4489C56.9814 12.092 57.2708 11.8027 57.6277 11.8027C57.9845 11.8027 58.2739 12.092 58.2739 12.4489C58.2733 12.8056 57.9843 13.0946 57.6277 13.0951Z"
							fill={color}
						/>
						<path
							d="M15.028 13.0072V5.74873H12.3152V4.72217H18.8037V5.74873H16.0909V13.0072H15.028Z"
							fill={color}
						/>
						<path
							d="M17.8252 13.0072V6.97119H18.8274V7.83969C19.1939 7.28993 19.7806 6.97119 20.6726 6.97119H21.0634V7.99688H20.5749C19.3529 7.99688 18.8639 8.70558 18.8639 9.68308V13.0072H17.8252Z"
							fill={color}
						/>
						<path
							d="M21.5652 10.3678V6.9707H22.6039V10.3192C22.6039 11.4677 23.2514 12.1522 24.254 12.1522C25.2567 12.1522 25.9042 11.4677 25.9042 10.3192V6.9707H26.9429V10.3678C26.9429 12.0666 25.843 13.1297 24.2545 13.1297C22.666 13.1297 21.566 12.0666 21.566 10.3678"
							fill={color}
						/>
						<path
							d="M28.4462 9.45152H32.2832C32.1612 8.49617 31.4277 7.80138 30.3647 7.80138C29.3871 7.80138 28.6416 8.44884 28.4462 9.45152ZM27.3701 9.98913C27.3701 8.22954 28.6776 6.84863 30.3759 6.84863C32.1129 6.84863 33.3458 8.144 33.3458 9.90357C33.3495 10.0507 33.3413 10.1978 33.321 10.3435H28.4209C28.5552 11.4677 29.362 12.1764 30.4493 12.1764C31.2926 12.1764 31.9401 11.7487 32.2945 11.0908L33.15 11.5685C32.5759 12.5464 31.5867 13.1318 30.4493 13.1318C28.702 13.1318 27.3701 11.7509 27.3701 9.99129"
							fill={color}
						/>
						<path
							d="M33.8823 13.0076V6.97153H34.8845V7.6064C35.288 7.12872 35.9232 6.84863 36.6931 6.84863C37.5973 6.84863 38.3307 7.23946 38.7215 7.96075C39.1741 7.27641 39.9808 6.84863 40.9214 6.84863C42.388 6.84863 43.414 7.87524 43.414 9.51279V13.0076H42.3753V9.59786C42.3753 8.48618 41.7644 7.82613 40.7868 7.82613C39.8215 7.82613 39.174 8.52093 39.174 9.59786V13.0076H38.1353V9.59786C38.1353 8.48618 37.5238 7.82613 36.5463 7.82613C35.5811 7.82613 34.9211 8.52093 34.9211 9.59786V13.0076H33.8823Z"
							fill={color}
						/>
						<path
							d="M44.9171 9.45369H48.7541C48.632 8.49835 47.899 7.80354 46.8356 7.80354C45.858 7.80354 45.1125 8.45101 44.9171 9.45369ZM43.8418 9.98913C43.8418 8.22954 45.1494 6.84863 46.8477 6.84863C48.5847 6.84863 49.8175 8.144 49.8175 9.90357C49.8213 10.0507 49.8131 10.1978 49.7928 10.3435H44.8927C45.0269 11.4677 45.8338 12.1764 46.9211 12.1764C47.7644 12.1764 48.4119 11.7487 48.7666 11.0908L49.6217 11.5685C49.0477 12.5464 48.0585 13.1318 46.9211 13.1318C45.1736 13.1318 43.8418 11.7509 43.8418 9.99129"
							fill={color}
						/>
						<path
							d="M55.4999 9.9889C55.4999 8.72958 54.608 7.82591 53.3859 7.82591C52.1641 7.82591 51.2721 8.73001 51.2721 9.9889C51.2721 11.2478 52.1641 12.1519 53.3859 12.1519C54.608 12.1519 55.4995 11.2478 55.4995 9.9889M50.2334 9.9889C50.2334 8.22932 51.5409 6.84842 53.3126 6.84842C54.2172 6.84842 54.9993 7.22708 55.4878 7.85023V4.72363H56.5265V13.0074H55.5247V12.0907C55.047 12.7386 54.2414 13.1294 53.3126 13.1294C51.5409 13.1294 50.2334 11.7485 50.2334 9.9889Z"
							fill={color}
						/>
						<path
							d="M5.88815 5.81152C5.39601 5.81152 4.99707 6.21047 4.99707 6.7026C4.99707 7.19474 5.39601 7.59368 5.88815 7.59368C6.38029 7.59368 6.77923 7.19474 6.77923 6.7026C6.77875 6.21064 6.38007 5.81201 5.88815 5.81152Z"
							fill={color}
						/>
						<path
							d="M5.64317 9.18525L3.07067 11.8711C2.4549 11.2774 2.04215 10.5045 1.89125 9.66249C1.86246 9.50456 1.84275 9.34506 1.83219 9.18482L5.64317 9.18525ZM1.86172 8.36018C2.10812 6.60721 3.44942 5.20901 5.19067 4.8901C5.4365 4.84511 5.68594 4.82244 5.93585 4.82235C7.98003 4.82652 9.70909 6.33537 9.99 8.36018H1.86172ZM8.12664 12.3926C6.78081 13.2426 5.06531 13.2395 3.72248 12.3848L5.92065 10.0898L8.12664 12.3926ZM10.0221 9.18525C9.97575 9.92908 9.72728 10.6462 9.30346 11.2592C9.14881 11.4826 8.97269 11.6903 8.77758 11.8793L6.19727 9.18699L10.0221 9.18525ZM8.73067 4.87273C6.49239 3.32576 3.4238 3.88624 1.87688 6.12454C0.329908 8.36287 0.890394 11.4314 3.12868 12.9784C5.36586 14.5245 8.43265 13.9655 9.98044 11.7295C11.526 9.49036 10.9669 6.42256 8.73067 4.87273Z"
							fill={color}
						/>
					</g>
				</g>
			</g>
			<defs>
				<clipPath id={clipId}>
					<rect
						width="58"
						height="18"
						fill="white"
						transform="translate(0.666748)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export const TruemedLogo = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ height: '1em', verticalAlign: 'text-top' }}
	>
		<path
			d="M5.94936 2.20808C5.35005 2.20808 4.86423 2.69391 4.86423 3.29322C4.86423 3.89253 5.35005 4.37836 5.94936 4.37836C6.54868 4.37836 7.0345 3.89253 7.0345 3.29322C7.03391 2.69412 6.54841 2.20867 5.94936 2.20808Z"
			fill="#179895"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.06457 2.589C2.94838 -0.136746 6.68523 -0.819294 9.41097 1.06457C12.1342 2.95193 12.8151 6.68782 10.9329 9.41462C9.04804 12.1376 5.31337 12.8183 2.58899 10.9355C-0.136747 9.05163 -0.819293 5.31479 1.06457 2.589ZM2.51835 9.58702L5.65107 6.31627L1.01016 6.31573C1.02301 6.51087 1.04702 6.70512 1.08208 6.89744C1.26584 7.92277 1.76848 8.86401 2.51835 9.58702ZM5.10004 1.08572C2.97958 1.47409 1.34617 3.17678 1.04612 5.31152H10.9445C10.6025 2.84574 8.49685 1.00831 6.00749 1.00323C5.70316 1.00333 5.3994 1.03094 5.10004 1.08572ZM3.3121 10.2126C4.94737 11.2535 7.03647 11.2572 8.67539 10.2221L5.98899 7.4178L3.3121 10.2126ZM10.1085 8.84191C10.6246 8.09537 10.9272 7.22208 10.9837 6.31627L6.32585 6.31838L9.46809 9.59706C9.70568 9.36687 9.92017 9.11388 10.1085 8.84191Z"
			fill="#179895"
		/>
	</svg>
)
