import React, { FC } from 'react'
import styles from './PodUpsell.module.scss'
import { Img } from 'components/basic/Img/Img'
import { Button } from 'components/Phantom/Button/Button'

export const PodUpsell: FC = () => {
	return (
		<div className={styles.container}>
			<Img
				src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pod4+Pedestal+Sports.png"
				alt="Banner image"
				className={styles.image}
				objectFit="cover"
			/>

			<div className={styles.image_mask_1} />
			<div className={styles.image_mask_2} />

			<div className={styles.content_container}>
				<div className={styles.title}>Get the Pod.</div>
				<div className={styles.subtitle}>
					Elevate your sleep
					<br />
					experience
				</div>
				<Button.Blue
					id={'shop-card-cta'}
					href="/product/pod-cover"
				>
					Shop now
				</Button.Blue>
			</div>
		</div>
	)
}
