export type NarvarLocale = 'en_US' | 'en_GB' | 'en_BE' | 'en_AU' | 'en_CA' | 'en_US'
export type NarvarFulfillmentStatus = 'NOT_SHIPPED' | 'SHIPPED' | 'CANCELLED'

export interface NarvarNotification {
	version: string
	narvar_tracer_id: string
	notification_type: string
	notification_triggered_by_tracking_number?: string
	notification_locale?: string
	published_date?: string
	notification_date?: string
	order?: any
	customer?: any
	customer_email?: string
}

export interface NarvarLineItem {
	item_id: string | number
	sku: string
	name: string
	quantity: number
	description: string
	unit_price: string
	item_image: string
	item_url: string
	categories: any[]
	is_final_sale: boolean
	fulfillment_status: NarvarFulfillmentStatus
	is_gift: boolean
	attributes: { [key: string]: string }
	item_promise_date: Date
}

export interface NarvarOrder {
	order_info: {
		order_number: string | number
		order_date: string
		checkout_locale: NarvarLocale
		order_items: NarvarLineItem[]
		billing: {
			billed_to: {
				first_name: string
				last_name: string
				phone: string
				email: string
				address: {
					street_1: string
					street_2: string
					city: string
					state: string
					zip: string
					country: string
				}
			}
		}
		customer: {
			first_name: string
			last_name: string
			phone: string
			email: string
			address: {
				street_1: string
				street_2: string
				city: string
				state: string
				zip: string
				country: string
			}
			customer_id: string
		}
		currency_code: string
		attributes: { [key: string]: string }
	}
}

export function getImage(itemName: string) {
	const name = itemName.toLowerCase()
	if (name.includes('pod') && name.includes('cover')) {
		return 'https://eight-sleep.imgix.net/pc3_pf.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('pod 4') && name.includes('ultra')) {
		return 'https://eightsleep.imgix.net/shop_combined_pod4ultra.png?auto=format,compress&h=400&dpr=2'
	}
	if (name.includes('pod 4')) {
		return 'https://eightsleep.imgix.net/shop_combined_pod4.png?auto=format,compress&h=400&dpr=2'
	}
	if (name.includes('essential') && name.includes('bundle')) {
		return 'https://eight-sleep.imgix.net/bundle.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('air') && name.includes('pillow')) {
		return 'https://eightsleep.imgix.net/new-air-pillow-accessory.png?auto=format,compress&h=700&dpr=1'
	}
	if (name.includes('air') && name.includes('comforter')) {
		return 'https://eight-sleep.imgix.net/airlite_bright_4.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('sheet') && name.includes('set') && name.includes('dark')) {
		return 'https://eight-sleep.imgix.net/sheets-03.png?auto=format&h=300&dpr=1'
	}
	if (name.includes('sheet') && name.includes('set')) {
		return 'https://eight-sleep.imgix.net/sheets_bright_4.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('pod') && name.includes('protector')) {
		return 'https://eight-sleep.imgix.net/foundation_shop_thumb_1.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('foundation')) {
		return 'https://eight-sleep.imgix.net/foundation_features_bg_desktop.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('gravity')) {
		return 'https://eight-sleep.imgix.net/gb-nb-2.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('spray')) {
		return 'https://eight-sleep.imgix.net/edit-spray2.png?auto=format&h=300&dpr=1'
	}
	if (name.includes('melatonin')) {
		return 'https://eight-sleep.imgix.net/Eight-bed-sheets-resizehero_c08c3a4b-8d1a-4c55-909a-9215496f7891.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('humidifier')) {
		return 'https://eight-sleep.imgix.net/humidifier_1.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('venom') && name.includes('back')) {
		return 'https://eight-sleep.imgix.net/venom1_a5801fa2-2782-4260-a000-4ea8b89b726d.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('hypervolt')) {
		return 'https://eight-sleep.imgix.net/hypervolt_pro_1.jpg?auto=format&h=300&dpr=1'
	}
	if (name.includes('normatec')) {
		return 'https://eight-sleep.imgix.net/normatec1_a461ffaa-04a3-4dbf-bb8a-cd313e89075f.jpg?auto=format&h=300&dpr=1'
	}
	return ''
}

export function getItemUrl(itemName: string) {
	const name = itemName.toLowerCase()
	if (name.includes('pod') && name.includes('cover')) {
		return 'https://www.eightsleep.com/product/pod-cover/'
	}
	if (name.includes('pod 4')) {
		return 'https://www.eightsleep.com/product/pod-cover/'
	}
	if (name.includes('essential') && name.includes('bundle')) {
		return 'https://www.eightsleep.com/product/the-sleep-essentials-bundle/'
	}
	if (name.includes('air') && name.includes('pillow')) {
		return 'https://www.eightsleep.com/product/the-air-pillow/'
	}
	if (name.includes('air') && name.includes('comforter')) {
		return 'https://www.eightsleep.com/product/air-lite-comforter/'
	}
	if (name.includes('sheet') && name.includes('set') && name.includes('dark')) {
		return 'https://www.eightsleep.com/product/the-pod-sheet-set/'
	}
	if (name.includes('sheet') && name.includes('set')) {
		return 'https://www.eightsleep.com/product/the-pod-sheet-set/'
	}
	if (name.includes('pod') && name.includes('protector')) {
		return 'https://www.eightsleep.com/product/waterproof-protector/'
	}
	if (name.includes('foundation')) {
		return 'https://www.eightsleep.com/product/foundation/'
	}
	if (name.includes('gravity')) {
		return 'https://www.eightsleep.com/product/gravity-blanket/'
	}
	if (name.includes('spray')) {
		return 'https://www.eightsleep.com/product/lavender-sleep-spray/'
	}
	if (name.includes('melatonin')) {
		return 'https://www.eightsleep.com/product/melatonin-pro-bundle/'
	}
	if (name.includes('humidifier')) {
		return 'https://www.eightsleep.com/product/canopy-humidifier/'
	}
	if (name.includes('venom') && name.includes('back')) {
		return 'https://www.eightsleep.com/product/venom-back/'
	}
	if (name.includes('hypervolt')) {
		return 'https://www.eightsleep.com/product/hypervolt-2-pro/'
	}
	if (name.includes('normatec')) {
		return 'https://www.eightsleep.com/product/leg-pulse'
	}
	return 'https://www.eightsleep.com'
}

export function getItemPromiseDate(shippingTimeline2: string) {
	if (shippingTimeline2) {
		try {
			const json = JSON.parse(shippingTimeline2)
			const key = Object.keys(json)[0]
			const laterDate = json[key].split('-')[1].trim().replace('.', '')
			const today = new Date()
			let promisedDate = new Date(`${laterDate}, ${today.getFullYear()} 23:00:00 GMT-0400`)
			if (promisedDate.getMonth() < today.getMonth()) {
				promisedDate = new Date(`${laterDate}, ${today.getFullYear() + 1} 23:00:00 GMT-0400`)
			}
			return promisedDate
		} catch (e) {
			return undefined
		}
	}
	return undefined
}

export const getLocale = (countryCode: string): NarvarLocale => {
	switch (countryCode) {
		case 'US':
			return 'en_US'
		case 'GB':
			return 'en_GB'
		case 'ES':
		case 'IT':
		case 'DE':
		case 'BE':
		case 'FR':
		case 'NL':
		case 'SE':
		case 'DK':
		case 'AT':
			return 'en_BE'
		case 'AU':
			return 'en_AU'
		case 'CA':
			return 'en_CA'
		default:
			return 'en_US'
	}
}
