import styles from './Pod4ShopNews.module.scss'
import { Img } from 'components/basic/Img'
import { observer } from 'mobx-react'
import { SnoreCard } from 'components/Phantom/SnoreCard'
import { useRootStore } from 'components/_hooks/useRootStore'
import { FC, ReactNode } from 'react'
import { ImageProps } from 'components/_utils/utilityTypes'
import { Type } from 'components/Type'

export type Pod4ShopNewsProps = {
	metric: boolean
}

const ElevationIcon = () => (
	<svg
		width="64"
		height="65"
		viewBox="0 0 64 65"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="64"
			y="64.8203"
			width="63.9999"
			height="63.9999"
			rx="32"
			transform="rotate(-180 64 64.8203)"
			fill="white"
			fillOpacity="0.08"
		/>
		<rect
			x="63.5"
			y="64.3203"
			width="62.9999"
			height="62.9999"
			rx="31.5"
			transform="rotate(-180 63.5 64.3203)"
			stroke="white"
			strokeOpacity="0.52"
		/>
		<path
			d="M15 32.2734L29.0293 38.1535C30.0086 38.564 31.0598 38.7754 32.1217 38.7754H49.0002"
			stroke="white"
			strokeWidth="4"
			strokeLinecap="round"
		/>
		<path
			d="M19.0614 27.7128L25.6059 30.3841"
			stroke="white"
			strokeWidth="4"
			strokeLinecap="round"
		/>
	</svg>
)

interface NewsCardProps {
	imgProps: ImageProps
	eyebrow: string
	title: string
	content?: ReactNode
}

const NewsCard: FC<NewsCardProps> = (props) => {
	const { imgProps, eyebrow, title, content } = props

	return (
		<div className={styles.outer}>
			<Img
				{...imgProps}
				className={styles.bg_image}
				objectFit={'cover'}
			/>
			<div className={styles.content}>
				<Type.Eyebrow className={styles.eyebrow}>{eyebrow}</Type.Eyebrow>
				<Type.Headline3>{title}</Type.Headline3>
				{content}
			</div>
		</div>
	)
}

export const Pod4ShopNews = (props: Pod4ShopNewsProps) => {
	const { metric } = props
	return (
		<section className={`inner-paddings-y ${styles.root}`}>
			<div className={styles.header}>
				<Type.Headline1
					as={'h2'}
					animateOnScroll
				>
					What’s new in Pod 4 Ultra
				</Type.Headline1>
				<Type.Headchapter animateOnScroll>Pod 4 has all the signature features you love about the Pod, plus new, groundbreaking upgrades</Type.Headchapter>
			</div>

			<ul className={styles.full_grid}>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-bedroom.jpg', alt: '' }}
					eyebrow={'Elevation'}
					title={'The perfect unwinding and sleeping positions'}
					content={
						<div className={styles.elevationIcon}>
							<div>
								{ElevationIcon()}
								{ElevationIcon()}
								{ElevationIcon()}
							</div>
							<div>Reading position</div>
						</div>
					}
				/>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-rectangle.jpg', alt: '' }}
					eyebrow={'Comfort'}
					title={"Maintains your mattress' comfort"}
				/>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-snoring.jpg', alt: '' }}
					eyebrow={'Snoring'}
					title={'Detects if you snore, and stops it'}
					content={<SnoreCard fillColor="rgba(255, 255, 255, 0.15)" />}
				/>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-sensors.jpg', alt: '' }}
					eyebrow={'Sleep metrics'}
					title={'Clinical-grade sensors, now 99% accurate'}
				/>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-bed.jpg', alt: '' }}
					eyebrow={'Tap zones'}
					title={'Control with the tap of your hand'}
				/>
				<NewsCard
					imgProps={{ src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-shop-news-hub.png', alt: '' }}
					eyebrow={'Pod 4 Hub'}
					title={metric ? 'Cools as low as 12° and heats as high as 43°' : 'Cools as low as 55° and heats as high as 110°'}
				/>
			</ul>
		</section>
	)
}

export const ShopNewsConnected = observer(() => {
	const { settingsStore } = useRootStore()
	const metric = settingsStore.metricRegion

	return <Pod4ShopNews metric={metric} />
})
