import React from 'react'
import { FC } from 'react'
import { IconProps } from './Icon.types'

export const Icon: FC<IconProps> = (props) => {
	const color = props.color || 'white'
	const Icon = props.icon.generator
	const label = props.icon.label

	return (
		<span className={props.className}>
			<svg
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				aria-label={label}
			>
				<Icon color={color} />
			</svg>
		</span>
	)
}
