export function trackFriendbuyEcap(email: string) {
	try {
		window.friendbuyAPI.push([
			'track',
			'customer',
			{
				email: email,
				id: email,
				referral_modal: 'true',
			},
		])
	} catch (e) {
		console.log('cant track friendbuy ecap')
	}
}
