import { useEffect, useState } from 'react'

/**
 * Monitors height of header element and returns height on size change.
 * Please only use this observer at the lowest possible level of the component tree, as it triggers many re-renders.
 */
export const useHeaderHeightObserver = () => {
	const [headerHeight, setHeaderHeight] = useState(0)

	useEffect(() => {
		const headerElement = document.getElementById('header')

		const resizeObserver = new ResizeObserver((entries) => {
			if (entries[0]) {
				setHeaderHeight(entries[0].contentRect.height)
			}
		})

		if (headerElement) {
			resizeObserver.observe(headerElement)
		}

		return () => {
			if (headerElement) {
				resizeObserver.unobserve(headerElement)
			}
		}
	})

	return headerHeight
}
