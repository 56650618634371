import { action, computed, makeObservable, observable } from 'mobx'
import AssistedSaleModalEvent from 'timerevents/AssistedSaleModalEvent'
import ReferralWelcomeEvent from 'timerevents/ReferralWelcomeEvent'
import InactivityModalEvent from 'timerevents/InactivityModalEvent'
import { RootStore } from './index'

type Events = {
	assistedSaleModalEvent?: AssistedSaleModalEvent
	ReferralWelcomeEvent?: ReferralWelcomeEvent
	InactivityModalEvent?: InactivityModalEvent
}
export default class TimerStore {
	constructor(private readonly rootStore: RootStore) {
		makeObservable(this)
	}

	@observable events: Events = {}
	@observable pagesVisited = {}
	@observable eventPageViewInterval: any = null

	@computed get timerStoreDump() {
		return {
			events: this.events,
			pagesVisited: this.pagesVisited,
			eventPageViewInterval: this.eventPageViewInterval,
		}
	}

	// checks to see if interval still exists
	@computed get pageViewIntervalExists() {
		return !!this.eventPageViewInterval
	}

	@action init() {
		this.loadPagesVisited()
		this.registerEvents()
	}

	loadPagesVisited = () => {
		if (!sessionStorage.getItem('pv')) {
			return null
		}
		try {
			const sessionString = sessionStorage.getItem('pv')
			const json = JSON.parse(sessionString)
			if (typeof json === 'object' && json !== null) {
				this.pagesVisited = json
			}
		} catch (e) {
			console.log('invalid json saved in sessionStorage')
			console.log(e)
		}
	}

	@action clearEvents = () => {
		this.events = {}
	}

	@action stop = () => {
		clearInterval(this.eventPageViewInterval)
		clearTimeout(this.events.InactivityModalEvent?.timer)
	}

	registerEvents = () => {
		this.events['assistedSaleModalEvent'] = new AssistedSaleModalEvent()
		this.events['ReferralWelcomeEvent'] = new ReferralWelcomeEvent()
		this.events['InactivityModalEvent'] = new InactivityModalEvent()
	}
}
