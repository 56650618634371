import { useCallback, useEffect, useState } from 'react'
import { scrollLocked } from 'utils/jss'

export const useScrollThreshold = (threshold = 100) => {
	const [hitThreshold, setHitThreshold] = useState(false)

	const scrollHandler = useCallback(() => {
		const scrollAmount = window.scrollY
		if (scrollAmount > threshold) {
			setHitThreshold(true)
		} else if (!scrollLocked) {
			setHitThreshold(false)
		}
	}, [threshold])
	

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler, { passive: true })
		return () => {
			window.removeEventListener('scroll', scrollHandler)
		}
	}, [threshold, scrollHandler])

	return hitThreshold
}
