import { RootStore, RootStoreContext } from 'stores'
import { useContext } from 'react'

/**
 * Retrieves the RootStore from the RootStoreContext using the useContext hook.
 *
 * Ensure that you are also using mobx observer on the component that is ingesting
 * the root store, otherwise the component will not rerender on state change.
 *
 * @returns {RootStore} The RootStore instance.
 */
export const useRootStore = (): RootStore => {
	return useContext(RootStoreContext)
}
