import * as React from 'react'

interface MediaQueryWidth {
	min?: number
	max?: number
}

interface MediaQuery {
	width?: MediaQueryWidth
}

export const mediaQuery = (mediaQuery: MediaQuery) => {
	const query = []
	if (mediaQuery.width) {
		if (mediaQuery.width.min) {
			query.push(`(min-width: ${mediaQuery.width.min}px)`)
		}
		if (mediaQuery.width.max) {
			query.push(`(max-width: ${mediaQuery.width.max}px)`)
		}
	}
	return { css: `@media ` + query.join(' and '), media: query.join(' and ') }
}

const parseCssSize = (str) => {
	const is_px = /^(\d+\.?\d*px)$/g.exec(str)
	const is_em = /^(\d+\.?\d*em)$/g.exec(str)
	const is_rem = /^(\d+\.?\d*rem)$/g.exec(str)

	if (!(is_px || is_em || is_rem)) {
		// console.log(`Received invalid CSS value '${str}'. Requires [num][px|em|rem]`)
		throw new Error(`Received invalid CSS value '${str}'. Requires [num][px|em|rem]`)
	}
	/* let num = /^(\d+)\w+/g.exec(str)[1];

    if (is_px) return { num: num, unit: 'px'}
    else if (is_em) return { num: num, unit: 'em'}
    else if (is_rem) return { num: num, unit: 'rem'}
    else throw('Received invalid CSS value. Requires [num][px|em|rem]') */
}

export const minWidth = (minw) => {
	parseCssSize(minw)
	return `@media (min-width: ${minw})`
}

export const maxWidth = (maxw) => {
	parseCssSize(maxw)
	return `@media (max-width: ${maxw})`
}

export const betweenWidths = (minw, maxw) => {
	parseCssSize(minw)
	parseCssSize(maxw)
	return `@media (min-width: ${minw}) and (max-width: ${maxw})`
}

export const toggleClass = (reference: React.RefObject<any>, className: string): void => {
	if (reference.current) {
		if (reference.current.classList.contains(className)) {
			reference.current.classList.remove(className)
		} else {
			reference.current.classList.add(className)
		}
	}
}

let scrollPosition = 0
export let scrollLocked = false
let canAdjustScroll = false

export const setPageScroll = (scroll: boolean): void => {
	if (typeof window === 'undefined') return
	if (scroll) {
		// Remove the fixed positioning.
		document.body.style.position = ''
		document.body.style.top = ''
		document.body.style.width = ''

		if (canAdjustScroll) {
			// Restore the scroll position.
			window.scrollTo(0, scrollPosition)
			canAdjustScroll = false
		}
		//document.body.style.paddingRight = 0
	} else {
		// Store the current scroll position.
		scrollPosition = window.pageYOffset

		// Add fixed positioning, keeping the current scroll position in place.
		document.body.style.position = 'fixed'
		document.body.style.top = `-${scrollPosition}px`
		document.body.style.width = '100%'
		canAdjustScroll = true

		// calculation takes about 2ms at most
		//const scrollbarWidth = getScrollbarWidth()

		//document.body.style.paddingRight = `${scrollbarWidth}px`
	}

	scrollLocked = !scroll
}
