import { forwardRef } from 'react'
import styles from './Toast.module.scss'
import { ToastProps, ToastTheme } from './Toast.types'
import { Icon } from 'components/Phantom/Icon'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'

export const Toast = forwardRef<HTMLDivElement, ToastProps>((props, ref) => {
	const { eyebrow, text, handleClose, theme = 'bfcm' } = props

	return (
		<div
			className={styles.container}
			ref={ref}
		>
			<Type.Eyebrow className={styles.eyebrow}>{eyebrow}</Type.Eyebrow>
			<Type.SmallPrint className={styles.text}>{text}</Type.SmallPrint>

			<Button.Empty
				id={'toast-close'}
				className={styles.close_button}
				onClick={handleClose}
			>
				<Icon
					name={'CloseLight'}
					color={getTextColor(theme)}
					size={24}
				/>
			</Button.Empty>
		</div>
	)
})

const getTextColor = (theme: ToastTheme) => {
	switch (theme) {
		case 'bfcm':
			return '#fff'
		default:
			return '#fff'
	}
}
