import { ActiveSelections, SelectionField, ShopAction, ShopState } from 'components/WebEv/Shop/types'
import { Attribute, CartLineItem, LineItem, NewCartStore } from 'cart'
import { trackAddThermoCoverToCart, trackAddToCart, trackClickedButton } from 'events/index'
import { isPodBedProductId, isPodCoverProductId } from 'products/utils'
import { Dispatch } from 'react'
import { smoothScrollToElement } from 'components/_utils/boundingBoxHelpers'
import { getErrorMessage } from 'components/WebEv/Shop/utils/errorMessages'
import { PriceStore } from 'prices'
import { MattressProducts, MembershipProducts, PodCoverProducts } from 'products/allProducts'
import { VariantKey } from 'products/types'
import { Currency } from 'prices/types'

export const grabSelectionFromField = (selectionField: SelectionField, activeSelections: ActiveSelections) => {
	return Object.values(activeSelections).find((s) => s.field === selectionField)
}

export const sendTrackingEvents = (item: CartLineItem, buttonID: string) => {
	trackAddToCart(item)
	trackClickedButton('Add to cart', buttonID)
	const coverProduct = item.productId && isPodCoverProductId(item.productId)
	if (coverProduct) {
		trackAddThermoCoverToCart()
	}
}

export const checkRequiredSteps = (state: ShopState, dispatch: Dispatch<ShopAction>) => {
	const requiredSelectionIDs = state.data.selections.filter((s) => !s.optional).map((s) => s.id)
	const selectionsMade = Object.keys(state.activeSelections)
	const missingSelections = requiredSelectionIDs.filter((id) => !selectionsMade.includes(id))

	if (missingSelections.length !== 0) {
		const missingSelection = missingSelections[0]
		smoothScrollToElement(document.getElementById(`selection-${missingSelection}`), window.innerHeight / 2)
		dispatch({
			type: 'setError',
			data: {
				selectionID: missingSelection,
				error: `Please select a ${getErrorMessage(missingSelection)}`,
			},
		})
		return false
	}

	return true
}

export const generateProductLineItem = (state: ShopState, priceStore: PriceStore) => {
	const productSelection = grabSelectionFromField('product', state.activeSelections)
	const variantSelection = grabSelectionFromField('variant', state.activeSelections)

	const baseProduct = Object.values(PodCoverProducts).find((p) => p.handle === productSelection.value)
	const variant = variantSelection.value as VariantKey
	const productToBeAdded = baseProduct

	const variantObject = priceStore.withPrices(productToBeAdded).variants[variant]

	const isValidPod = isPodBedProductId(productToBeAdded.id)
	const isValidCover = isPodCoverProductId(productToBeAdded.id)

	const attributes: Attribute[] = [{ key: 'size', value: variant }]
	if (isValidPod || isValidCover) {
		const sessionCode = sessionStorage.getItem('appliedDiscountCode')
		if (sessionCode.length) {
			attributes.push({ key: 'vanitycode', value: sessionCode.toUpperCase() })
		}
	}

	const item: LineItem = {
		variantId: variantObject.id,
		quantity: 1,
		attributes: attributes,
	}

	return { productToBeAdded, variantObject, item }
}

export const getMattressUpsell = (state: ShopState, currency: Currency) => {
	const mattressSelection = grabSelectionFromField('mattress-upsell', state.activeSelections)

	if (mattressSelection && mattressSelection.value === 'true') {
		switch (currency) {
			case 'CAD':
				return MattressProducts.ThreeLayer
			case 'USD':
				return MattressProducts.FiveLayerDiscounted
		}
	}

	return null
}

export const generateMembershipLineItem = (state: ShopState) => {
	const membershipSelection = grabSelectionFromField('membership', state.activeSelections)

	const plusPlan = MembershipProducts.AutopilotEnhanced
	const proPlan = MembershipProducts.AutopilotStandard

	const membershipProduct = membershipSelection.value === 'plus' ? plusPlan : proPlan

	const membershipLineItem: LineItem = {
		variantId: membershipProduct.variants.standard.id,
		sellingPlanId: membershipProduct.sellingPlanId,
		quantity: 1,
		attributes: membershipProduct.id === MembershipProducts.AutopilotEnhanced.id ? [{ key: 'warranty', value: 'extended warranty' }] : [],
	}

	return membershipLineItem
}

export const handleCheckout = (cartStoreNew: NewCartStore, itemsToAdd: LineItem[], itemsToRemove: LineItem[], addedMembership: boolean, isValidPodOrCoverAndNotUpgrade: boolean) => {
	cartStoreNew.removeItems(itemsToRemove).then(() => {
		cartStoreNew.addItems(itemsToAdd).then(() => {
			if (isValidPodOrCoverAndNotUpgrade && cartStoreNew.hasMembership && cartStoreNew.numCovers < 2 && cartStoreNew.numPods < 2) {
				cartStoreNew.redirectToCheckout()
			} else {
				cartStoreNew.toggleCartOpen()
			}
		})
	})
}
