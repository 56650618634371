import React, { FC } from 'react'
import { injectRegionIntoPath, RegionShort } from 'utils/internationalization'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { PREFETCH_DISABLE_LIST } from 'config/preload'

export const LinkWithLocale: FC<React.PropsWithChildren<LinkProps>> = (props) => {
	const router = useRouter()
	// const path = injectRegionIntoPath(props.href, extractRegionFromPath(getFullPath(router.asPath)) as RegionShort)
	const path = injectRegionIntoPath(props.href, router.query.locale as RegionShort)
	const href = path ? path.toString() : '/'

	return router.asPath.startsWith('/blog') && !href.startsWith('/blog') ? (
		<a href={href}>{props.children}</a>
	) : (
		<Link
			{...props}
			href={path}
			locale={false}
			passHref
			prefetch={PREFETCH_DISABLE_LIST.includes(href) ? false : undefined}
			legacyBehavior
		>
			{props.children}
		</Link>
	)
}

export default LinkWithLocale
