import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.773 14.6491H7.32277C6.58707 14.6491 5.86337 14.4627 5.21921 14.1073C1.73034 12.1824 2.4452 6.97068 6.32352 6.0563L6.56246 5.99997L7.32622 5.83659L7.63255 5.24844C9.46536 1.72944 14.5098 1.75631 16.305 5.29464C16.5671 5.81112 16.735 6.37016 16.8009 6.94554L16.8887 7.71159L17.8776 7.74155C21.2924 7.84503 22.501 12.3133 19.6039 14.124C19.0548 14.4671 18.4204 14.6491 17.773 14.6491ZM17.1922 17.2475C17.4986 17.5262 17.521 18.0005 17.2423 18.3069L16.4015 19.2311C16.1228 19.5375 15.6485 19.56 15.3421 19.2812C15.0357 19.0025 15.0132 18.5282 15.292 18.2218L16.1327 17.2976C16.4115 16.9912 16.8858 16.9687 17.1922 17.2475ZM14.2423 21.3069C14.521 21.0005 14.4986 20.5262 14.1922 20.2475C13.8858 19.9687 13.4115 19.9912 13.1327 20.2976L12.292 21.2218C12.0132 21.5282 12.0357 22.0025 12.3421 22.2812C12.6485 22.56 13.1228 22.5375 13.4015 22.2311L14.2423 21.3069ZM8.84355 16.5094C9.14128 16.7974 9.1492 17.2722 8.86123 17.5699L7.48418 18.9937C7.19621 19.2914 6.7214 19.2993 6.42366 19.0113C6.12593 18.7234 6.11802 18.2485 6.40599 17.9508L7.78304 16.5271C8.07101 16.2293 8.54582 16.2214 8.84355 16.5094ZM13.3687 17.587C13.6661 17.2987 13.6735 16.8239 13.3853 16.5265C13.097 16.2291 12.6221 16.2217 12.3247 16.51L9.17718 19.5608C8.87975 19.8491 8.87235 20.3239 9.16063 20.6213C9.44892 20.9188 9.92374 20.9262 10.2212 20.6379L13.3687 17.587Z"
			fill={color}
		/>
	</svg>
)

export default icon
