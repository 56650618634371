import React, { FC, useCallback, useState } from 'react'
import cx from 'classnames'
import { Waypoint } from 'react-waypoint'

/*
	Will animate a component with a pre-made animation from animate.css
	Activates when the component scroll into view with Waypoint
	See https://daneden.github.io/animate.css/ for full list of animations
*/

export interface AnimateOnScrollProps {
	children?: React.ReactNode
	enterClass: string // name from animate.css
	exitClass?: string // name from animate.css
	once?: boolean // if you want animation to only run once
	delay?: string
	topOffset?: string
	bottomOffset?: string
	className?: string
	noMinHeight?: boolean
}

export interface AnimateOnRevealProps {
	children?: React.ReactNode
	enterClass?: string // name from animate.css
	exitClass?: string // name from animate.css
	once?: boolean // if you want animation to only run once
	delay?: string
	topOffset?: string
	bottomOffset?: string
	className?: string
	noMinHeight?: boolean
}

export const AnimateOnScroll: FC<AnimateOnScrollProps> = (props) => {
	const [active, setActive] = useState(false)
	const [className, setClassName] = useState('')

	function toggle() {
		setActive(!active)
	}

	function handleEnter() {
		if (props.once && active) {
			return
		}
		toggle()
		setClassName(`animate__animated animate__${props.enterClass}`)
	}

	function handleLeave() {
		if (props.once && active) {
			return
		}
		toggle()
		setClassName(`animate__animated animate__${props.exitClass}`)
	}

	return (
		<Waypoint
			onEnter={handleEnter}
			onLeave={handleLeave}
			topOffset={props.topOffset}
			bottomOffset={props.bottomOffset}
			scrollableAncestor={'window'}
		>
			<div
				className={cx(className, props.className)}
				style={{ animationDelay: props.delay }}
			>
				{props.children}
			</div>
		</Waypoint>
	)
}

AnimateOnScroll.defaultProps = {
	topOffset: '100px',
}

export const ClassOnScroll: FC<AnimateOnScrollProps> = (props) => {
	const [active, setActive] = useState(false)
	const [className, setClassName] = useState('')

	function toggle() {
		setActive(!active)
	}

	function handleEnter() {
		if (props.once && active) {
			return
		}
		toggle()
		setClassName(props.enterClass)
	}

	function handleLeave() {
		if (props.once && active) {
			return
		}
		toggle()
		setClassName(props.exitClass || '')
	}

	return (
		<Waypoint
			onEnter={handleEnter}
			onLeave={handleLeave}
			topOffset={props.topOffset}
			bottomOffset={props.bottomOffset}
			scrollableAncestor={'window'}
		>
			<div
				className={cx(className, props.className)}
				style={{ animationDelay: props.delay }}
			>
				{props.children}
			</div>
		</Waypoint>
	)
}

export const AnimateOnReveal: FC<AnimateOnRevealProps> = (props) => {
	const [className, setClassName] = useState('')

	const onEnter = useCallback(() => {
		if (!className) {
			setClassName(props.enterClass ? `animate__${props.enterClass}` : 'specialFadeIn')
		}
	}, [props.enterClass, className])

	const onLeave = useCallback(() => {
		if (className) {
			setClassName(props.exitClass ? `animate__${props.exitClass}` : '')
		}
	}, [props.exitClass, className])

	return (
		<div className={props.className}>
			<Waypoint
				onEnter={onEnter}
				// onLeave={onLeave}
				topOffset={props.topOffset}
				bottomOffset={props.bottomOffset}
				scrollableAncestor={'window'}
			/>
			<div
				className={cx('animate__animated', className)}
				style={{ animationDelay: props.delay ?? '150ms', minHeight: props.noMinHeight ? '0' : '150px' }}
			>
				{props.children}
			</div>
		</div>
	)
}
