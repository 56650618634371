import { FC } from 'react'
import styles from './ModelModal.module.scss'
import { ModelModalProps } from './types'
import { Pod4ComparisonTable } from 'components/Pod4Comparison'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

export const ModelModalConnected = observer(() => {
	const { settingsStore } = useRootStore()
	const metric = settingsStore.metricRegion
	return (
		<ModelModal
			metric={metric}
			showPod3={settingsStore.settings.promoCode !== 'members'}
		/>
	)
})

export const ModelModal: FC<ModelModalProps> = (props) => {
	const { metric, showPod3 = true } = props
	return (
		<div className={styles.container}>
			<Pod4ComparisonTable
				metric={metric}
				showPod3={showPod3}
			/>
		</div>
	)
}
