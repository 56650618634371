import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getLegKitVisuals = (metric = false): VisualProps => {
	return {
		type: 'image',
		associatedSelectorKeys: ['leg-kit'],
		data: {
			src: metric
				? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Leg+Kit+Non-US+%E2%80%93+Wide.png'
				: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Leg+Kit+%E2%80%93+Wide.png',
			mobileSrc: metric
				? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Leg+Kit+Non-US+-+Mobile-2.png'
				: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Leg+Kit+Mobile-2.png',
			alt: 'Leg Ket display',
		},
	}
}
