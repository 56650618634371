type IconMapType = Record<string, string>

export const IconMap: IconMapType = {
	price_tag: 'https://eightsleep.imgix.net/icons_price_tag.svg?v=1650776727',
	bell: 'https://eightsleep.imgix.net/icons_bell.svg?v=1650776726',
	clock: 'https://eightsleep.imgix.net/icons_clock.svg?v=1650776727',
	eight_box: 'https://eightsleep.imgix.net/icons_8_box.svg?v=1650776726',
	phone: 'https://eightsleep.imgix.net/icons_phone.svg?v=1650776727',
	mattress: 'https://eightsleep.imgix.net/icons_matress.svg?v=1650776727',
	vibrate: 'https://eightsleep.imgix.net/icons_vibrate.svg?v=1650776727',
	bed: 'https://eightsleep.imgix.net/icons_bed.svg?v=1650776726',
	cart: 'https://eightsleep.imgix.net/icons_cart.svg?v=1650776726',
	delivery: 'https://eightsleep.imgix.net/icons_delivery.svg?v=1650776727',
	moon: 'https://eightsleep.imgix.net/icons_moon.svg?v=1650776727',
	wake: 'https://eightsleep.imgix.net/icons_wake.svg?v=1650776727',
	sound: 'https://eightsleep.imgix.net/icons_sound.svg?v=1650776727',
	bar_chart: 'https://eightsleep.imgix.net/icons_bar_chart.svg?v=1650776726',
	minus_circle: 'https://eightsleep.imgix.net/icons_minus_circle.svg?v=1650776727',
	to_do: 'https://eightsleep.imgix.net/icons_to_do.svg?v=1650776727',
	menu: 'https://eightsleep.imgix.net/icons_menu.svg?v=1650776727',
	chill: 'https://eightsleep.imgix.net/icons_chill.svg?v=1650776727',
	prime: 'https://eightsleep.imgix.net/icons_prime.svg?v=1650776727',
	heart: 'https://eightsleep.imgix.net/icons_heart.svg?v=1650776727',
	heart_report: 'https://eightsleep.imgix.net/icons_health_report.svg?v=1650776727',
	warranty: 'https://eightsleep.imgix.net/icons_warranty.svg?v=1650776727',
	check2: 'https://eightsleep.imgix.net/icons_check2.svg?v=1650776727',
	check: 'https://eightsleep.imgix.net/icons_check.svg?v=1650776727',
	chat: 'https://eightsleep.imgix.net/icons_chat.svg?v=1650776726',
	chat_round: 'https://eightsleep.imgix.net/icons_chat_round.svg?v=1650776726',
	chat2: 'https://eightsleep.imgix.net/icons_chat2.svg?v=1650776727',
	customer_care: 'https://eightsleep.imgix.net/icons_customer_care.svg?v=1650776727',
	dual_temp: 'https://eightsleep.imgix.net/icons_dual_temp.svg?v=1650776727',
	cog: 'https://eightsleep.imgix.net/icons_cog.svg?v=1650776727',
	calendar: 'https://eightsleep.imgix.net/icons_calendar.svg?v=1650776726',
	mail: 'https://eightsleep.imgix.net/icons_mail.svg?v=1650776727',
	search: 'https://eightsleep.imgix.net/icons_search.svg?v=1650776727',
	wifi: 'https://eightsleep.imgix.net/icons_wifi.svg?v=1650776727',
	eye: 'https://eightsleep.imgix.net/icons_eye.svg?v=1650776727',
	warm: 'https://eightsleep.imgix.net/icons_warm.svg?v=1650776727',
	cool: 'https://eightsleep.imgix.net/icons_cool.svg?v=1650776727',
	home: 'https://eightsleep.imgix.net/icons_home.svg?v=1650776727',
	adjustments: 'https://eightsleep.imgix.net/icons_adjustments.svg?v=1650776726',
	square: 'https://eightsleep.imgix.net/icons_square.svg?v=1650776727',
	srr: 'https://eightsleep.imgix.net/icons_SRR.svg?v=1650776727',
	gift: 'https://eightsleep.imgix.net/icons_gift.svg?v=1650776727',
	x: 'https://eightsleep.imgix.net/icons_x.svg?v=1650776727',
	add: 'https://eightsleep.imgix.net/icons.svg?v=1650776726',
	off: 'https://eightsleep.imgix.net/icons_off.svg?v=1650776727',
	play: 'https://eightsleep.imgix.net/icons_play.svg?v=1650776727',
	pause: 'https://eightsleep.imgix.net/icons_pause.svg?v=1650776727',
	exercise: 'https://eightsleep.imgix.net/icons_exercise.svg?v=1650776727',
	lightning_bolt: 'https://eightsleep.imgix.net/icons_lightning_bolt.svg?v=1650776727',
	trending_up: 'https://eightsleep.imgix.net/icons_trending_up.svg?v=1650776727',
	watch: 'https://eightsleep.imgix.net/icons_watch.svg?v=1650776727',
	alarm: 'https://eightsleep.imgix.net/icons_alarm.svg?v=1650776726',
	link: 'https://eightsleep.imgix.net/icons_link.svg?v=1650776727',
	exclamation_circle: 'https://eightsleep.imgix.net/icons_exclamation_circle.svg?v=1650776727',
	exclamation: 'https://eightsleep.imgix.net/icons_exclamation.svg?v=1650776727',
	shield_check: 'https://eightsleep.imgix.net/icons_shield_check.svg?v=1650776727',
	shopping_bag: 'https://eightsleep.imgix.net/icons_shopping_bag.svg?v=1650776727',
	credit_card: 'https://eightsleep.imgix.net/icons_credit_card.svg?v=1650776727',
	location: 'https://eightsleep.imgix.net/icons_location.svg?v=1650776727',
	lock_closed: 'https://eightsleep.imgix.net/icons_lock_closed.svg?v=1650776727',
	lock_open: 'https://eightsleep.imgix.net/icons_lock_open.svg?v=1650776727',
	cloud: 'https://eightsleep.imgix.net/icons_cloud.svg?v=1650776727',
	music_note: 'https://eightsleep.imgix.net/icons_music_note.svg?v=1650776727',
	cocktail: 'https://eightsleep.imgix.net/icons_cocktail.svg?v=1650776727',
	meal: 'https://eightsleep.imgix.net/icons_meal.svg?v=1650776727',
	coffee: 'https://eightsleep.imgix.net/icons_coffee.svg?v=1650776727',
	temperature: 'https://eightsleep.imgix.net/icons_temperature.svg?v=1650776727',
	temperature2: 'https://eightsleep.imgix.net/icons_temperature2.svg?v=1650776727',
	temperature_schedule: 'https://eightsleep.imgix.net/icons_temperature_schedule.svg?v=1650776727',
	sparkles: 'https://eightsleep.imgix.net/icons_sparkles.svg?v=1650776727',
	arrow_up: 'https://eightsleep.imgix.net/icons_arrow_up.svg?v=1650776726',
	arrow_right: 'https://eightsleep.imgix.net/icons_arrow_right.svg?v=1650776726',
	arrow_left: 'https://eightsleep.imgix.net/icons_arrow_left.svg?v=1650776726',
	chevron_down: 'https://eightsleep.imgix.net/icons_chevron_down.svg?v=1650776727',
	chevron_up: 'https://eightsleep.imgix.net/icons_chevron_up.svg?v=1650776727',
	chevron_right: 'https://eightsleep.imgix.net/icons_chevron_right.svg?v=1650776727',
	chevron_left: 'https://eightsleep.imgix.net/icons_chevron_left.svg?v=1650776727',
	thumb_up: 'https://eightsleep.imgix.net/icons_thumb_up.svg?v=1650776727',
	thumb_down: 'https://eightsleep.imgix.net/icons_thumb_down.svg?v=1650776727',
	dots_circle: 'https://eightsleep.imgix.net/icons_dots_circle.svg?v=1650776728',
	dots: 'https://eightsleep.imgix.net/icons_dots.svg?v=1650776727',
	upload: 'https://eightsleep.imgix.net/icons_upload.svg?v=1650776727',
	loader: 'https://eightsleep.imgix.net/icons_loader.svg?v=1650776727',
	light_bulb: 'https://eightsleep.imgix.net/icons_light-bulb.svg?v=1650776727',
	arrow_down: 'https://eightsleep.imgix.net/icons_arrow_down.svg?v=1650776726',
	phone_hrv: 'https://eightsleep.imgix.net/icons_phone_hrv.svg?v=1650776727',
	phone_8: 'https://eightsleep.imgix.net/icons_phone_8.svg?v=1650776727',
	disturbances: 'https://eightsleep.imgix.net/disturb.svg?v=1654789998',
	bed_down: 'https://eightsleep.imgix.net/bed_down.svg?v=1667932231',
	close: 'https://eightsleep.imgix.net/close_d716c4c1-e156-409d-93c2-92907327e991.svg?v=1679385254',
	trash: 'https://eightsleep.imgix.net/trash.svg?v=1679577440',
	angled_circle_checkmark: 'https://eightsleep.imgix.net/angled_circle_checkmark.svg?v=1687435692',
	gray_checkmark: 'https://d115sb6i1ixllw.cloudfront.net/assets/gray_checkmark_2024.svg',
	blue_checkmark: 'https://d115sb6i1ixllw.cloudfront.net/assets/blue_checkmark_2024.svg',
	two_users: 'https://d115sb6i1ixllw.cloudfront.net/assets/two-users-icon.svg',
	minus_sign: 'https://d115sb6i1ixllw.cloudfront.net/assets/minus-sign-icon.svg',
	snoring_detection: 'https://d115sb6i1ixllw.cloudfront.net/assets/snoring-detection-icon.svg',
	snoring_mitigation: 'https://d115sb6i1ixllw.cloudfront.net/assets/snore-mitigation-icon.svg',
	autopilot_bed: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-time-bed.svg',
	autopilot_rem_sleep: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-rem-sleep.svg',
	autopilot_lung: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-lung.svg',
	autopilot_light_sleep: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-light-sleep.svg',
	autopilot_heart_report: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-heart-report.svg',
	autopilot_heart: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-heart.svg',
	autopilot_fell_asleep: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-fell-asleep.svg',
	autopilot_deep_asleep: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-deep-sleep.svg',
	autopilot_clock: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-clock.svg',
	autopilot_alarm: 'https://d115sb6i1ixllw.cloudfront.net/assets/autopilot-2024/autopilot-icon-alarm.svg',
}
