export const formatURL = (url: string) => {
	let formattedUrl: string = url.split(/[?#]/)[0]
	if (formattedUrl.substr(-1) !== '/') {
		formattedUrl += '/'
	}
	return formattedUrl
}

export const formatLink = (href: string) => {
	if (!href) return ''
	if (href.includes('http') || href === '/' || href.includes('#') || href.includes('?')) {
		return href
	}
	const is_prod = process.env.NODE_ENV === 'production'
	if (is_prod) {
		return href.slice(-1) === '/' ? href : `${href}/`
	}
	return href.slice(-1) === '/' ? href.slice(0, -1) : href
}

export const toCSS = (obj) => {
	return Object.keys(obj)
		.map((p) => {
			const name = p
				.split(/(?=[A-Z])/)
				.join('-')
				.toLowerCase()
			const value = obj[p]
			return `${name}: ${value};`
		})
		.join('\n')
}

export const withSlash = (str) => {
	if (!str.length) {
		return ''
	}
	if (str === '/') {
		return str
	}
	if (str.charAt(str.length - 1) === '/') {
		return str
	}
	return str + '/'
}

export const capitalize = (str: string) => {
	return str
		.toLowerCase()
		.split(' ')
		.map((word) => word[0].toUpperCase() + word.substr(1))
		.join(' ')
}

export const utcStringToUnix = (str) => {
	if (!str) {
		return null
	}
	if (str.charAt(str.length - 1) !== 'Z') {
		throw new Error('Time stamp must have Z at the end. (utc dates only)')
	}
	if (str.length !== 20) {
		throw new Error('Time stamp must 20 chars long. (utc dates only)')
	}
	try {
		const dateString = str.split('T')[0]
		let timeString = str.split('T')[1]
		const year = parseInt(dateString.split('-')[0], 10)
		const month = parseInt(dateString.split('-')[1], 10) - 1 // subtract 1 bc months are 0-11
		const day = parseInt(dateString.split('-')[2], 10)
		timeString = timeString.substring(0, 8)
		const hour = parseInt(timeString.split(':')[0], 10)
		const minute = parseInt(timeString.split(':')[1], 10)
		const seconds = parseInt(timeString.split(':')[2], 10)
		return Date.UTC(year, month, day, hour, minute, seconds)
	} catch (e) {
		throw new Error('invalid UTC time stamp')
	}
}

export const utcStringToMonthAndDayOneDayBefore = (str: string): string => {
	try {
		const time: any = new Date(str)
		time.setDate(time.getDate() - 1)
		const month = time.toLocaleString('default', { month: 'long' })
		const date = time.getDate()
		return month + ' ' + date
	} catch (error) {
		console.log('utcStringToMonthAndDayOneDayBefore', error)
	}
	return 'undefined'
}
