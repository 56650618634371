import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.07705 7.88722V6.75C5.07841 6.2863 5.2612 5.84198 5.58549 5.51409C5.90977 5.1862 6.34921 5.00138 6.80781 5H17.1924C17.651 5.00138 18.0905 5.1862 18.4148 5.51409C18.739 5.84198 18.9218 6.2863 18.9232 6.75V7.88746C18.5311 7.49801 18.0172 7.25206 17.4471 7.25H17.4444H15.5L13.5556 7.25L13.5529 7.25C12.9448 7.25219 12.4006 7.53191 12 7.96727C11.5994 7.53191 11.0552 7.25219 10.4471 7.25V7.25H10.4444H6.55556V7.25L6.55286 7.25C5.98292 7.25206 5.4691 7.49791 5.07705 7.88722ZM19.4587 11.5934C19.7257 11.7275 19.9719 11.9054 20.1866 12.1225C20.7055 12.6472 20.998 13.3581 21.0001 14.1V19H3.00012V14.1C3.00229 13.3581 3.29474 12.6472 3.81361 12.1225C4.02828 11.9055 4.2745 11.7276 4.54138 11.5935C4.66817 11.6916 4.82726 11.75 5 11.75H11.3444H12H19C19.1728 11.75 19.3319 11.6916 19.4587 11.5934ZM18.25 10.25H12.75V10.001C12.751 9.61712 12.8714 9.27606 13.0489 9.04788C13.2227 8.82441 13.4125 8.75086 13.5572 8.75H15.5H17.4428C17.5875 8.75086 17.7773 8.82441 17.9511 9.04788C18.1285 9.27602 18.249 9.61701 18.25 10.0008V10.25ZM10.9511 9.04788C11.1285 9.27603 11.249 9.61701 11.25 10.0008V10.25H5.75V10.0009C5.75099 9.61703 5.87145 9.27604 6.0489 9.04788C6.22271 8.82441 6.41247 8.75086 6.55723 8.75H10.4428C10.5875 8.75086 10.7773 8.82441 10.9511 9.04788Z"
			fill={color}
		/>
	</svg>
)

export default icon
