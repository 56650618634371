import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.4622 5.28787H7.48588C6.52372 5.28787 5.62824 5.77944 5.11168 6.59118L1.71486 11.929C1.42752 12.3806 1.2749 12.9047 1.2749 13.4399V15.8886C1.2749 17.4429 2.53484 18.7028 4.08906 18.7028H19.4356C20.9898 18.7028 22.2498 17.4429 22.2498 15.8886V13.3753C22.2498 12.8961 22.1274 12.4249 21.8943 12.0063L18.9581 6.73298C18.4614 5.84091 17.5204 5.28787 16.4994 5.28787H13.5321V6.82287H16.4994C16.9635 6.82287 17.3912 7.07425 17.617 7.47974L20.0715 11.8878H3.56053L6.4067 7.41528C6.6415 7.04631 7.04853 6.82287 7.48588 6.82287H10.4622V5.28787ZM2.8099 13.4399L2.81001 13.4228H20.7148V15.8886C20.7148 16.5951 20.1421 17.1678 19.4356 17.1678H4.08906C3.3826 17.1678 2.8099 16.5951 2.8099 15.8886V13.4399Z"
			fill="black"
		/>
		<path
			d="M9.74731 8.01613L11.9973 10.4953M11.9973 10.4953L14.2473 8.01613M11.9973 10.4953L11.9973 4.1203"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
