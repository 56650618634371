import React, { cloneElement, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { Button as NewButton } from 'components/WebEv/Buttons'
import styles from 'pagestyles/shop.module.scss'
import styles2 from './ShopMainB.module.scss'
import { ModelSelectProps, ProductModelSelect } from 'components/ProductModelSelect'
import { PriceManager } from 'prices/priceManager'
import shippingTimelines, { getShopPageShippingText, ShippingStatus, shipsToAU, shipsToCanada, shipsToEU, shipsToUK } from 'config/shipping_timelines'
import { isMemberUpgradeProductId, isPodBedProductId, isPodCoverProductId } from 'products/utils'
import { MultiVariantItem, Product, ProductFeaturesData, ProductImageType, ProductSelectFields, ShippingInfo, ShippingRegion, ShopSizeSelectOptions } from 'products/types'
import { RadioButtonGroup } from 'components/basic/RadioButton'
import NotShippingAlert from 'sections/PodAtc/NotShippingAlert'
import SettingsStore from 'settings/store'
import { amClickedButton, amCloseMembershipModal, amHideStickyATC, amOpenMembershipModal, amShowStickyATC } from 'events/amplitude'
import dynamic from 'next/dynamic'
import { StickyATC } from 'components/StickyATC/StickyATC'
import { Waypoint } from 'react-waypoint'
import { Currency, FormattedPrices } from 'prices/types'
import { RegionShort } from 'utils/internationalization'
import { PodCoverProducts } from 'products/allProducts'
import { LinkWithArrow } from 'components/basic/LinkWithArrow'
import { Img } from 'components/basic/Img'
import { ProductVariantSelect } from 'components/ProductVariantSelect'
import { trackAffirmLearnMoreClick, trackClickFinancingModalAffirm } from 'events/index'
import { AffirmLocations } from 'constants/events'
import { CoverModelExplanationModal, ModalTriggerNew } from 'components/CoverModelExplanationModal'
import { AccessoriesSizeExplanationModal, PodFamilySizeExplanationModal } from 'components/PodFamilySizeExplanationModal'
import { MattressModelExplanationModal } from 'components/MattressModelExplanationModal'
import { ClientOnly } from 'components/ClientOnly'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { IconMap } from 'components/_const_assets/icons'
import { MattressLayers } from 'components/Pod 3 LP/Sections/MattressLayers'
import { SlickSlider, SlideItem } from 'components/SlickSlider'
import { ShopSticky } from 'components/WebEv/ShopSticky/ShopSticky'
import { useRouter } from 'next/router'
import { BenefitsContainer } from 'components/WebEv/Shop/HelperComponents/BenefitsContainer/BenefitsContainer'
import { ShopCarousel } from 'components/WebEv/Shop/HelperComponents/ShopCarousel/ShopCarousel'
import { ContentWithSteps } from 'components/WebEv/Shop/types'
import { Selection } from 'components/Phantom/_shop/Selection'
import { ShopSelection } from 'shop/shopManager'
import { ModalKey } from 'components/Phantom/_shop/Modals/modalMapping'
import { Type } from 'components/Type'
import { Badge } from 'components/Badge'

/*
	TODOS:
	track switching products in dropdown + radio buttons
	trackSwitchProductSize(SheetProducts.SheetSet.name)
	trackSwitchProductSize(PillowProducts.CarbonAirPillow.name)
*/

declare global {
	interface Window {
		affirm?: any
	}
}

export interface ShopProps {
	noSizeError: boolean
	selectedSize: string | undefined
	products: Product[]
	images: ProductImageType[]
	features: ProductFeaturesData
	selectedProduct: number
	selectedVariant: string
	modelSelectComponentType?: 'cards' | 'radio_buttons' | 'color_picker'
	modelSelectProps: ModelSelectProps | null
	variantSelectComponentType?: 'dropdown' | 'cards' | 'multi_variant_radio_buttons'
	variantSelectLabel: string
	dropdownItems: ShopSizeSelectOptions[]
	multiVariantItems?: MultiVariantItem[]
	shopSavingsTag?: string
	saleShopSavingsTag?: string
	onSelectVariant: (val: string | number | undefined) => void
	onAtcClick: (p: boolean | undefined, buttonID: string, addMattress: boolean) => void
	showCannotShipAlert: boolean
	currentDropdownOptions: ShopSizeSelectOptions | null
	onChangeDropdownOptions: (val: string) => void
	ipLocations: Pick<SettingsStore, 'isCanada' | 'isUK' | 'isEULegacy' | 'isAU'>
	shipping?: ShippingInfo
	currency: Currency
	region: RegionShort
	showMembershipCheck: boolean
	membershipPrices: FormattedPrices

	displayPrice?: string
	note?: string

	onSelectModel: (index: number) => void
	onSelectVariantIndex: (index: number) => void

	cartLoading: boolean

	stickyATCTest: boolean

	currentProduct: Product
	saleEndDate: any
	numPodsAndCovers: number
	whatsInTheBoxSrc?: string
	showSubscriptionPlans: boolean

	selectMembership: (index: number) => void
	selectedMembership: number

	showMattressUpsell: boolean

	setAddMattress: (val: boolean) => void
	addMattress: boolean

	mattressUpsellPrice: number
	mattressUpsellStrikePrice: number

	showSticky: boolean
	strikePriceNumber: number
	priceNumber: number

	outOfStock?: boolean
	legKitSelectData: ShopSelection
	selectModal: (modalKey: ModalKey) => void
	showLegKitUpsell?: boolean
	legKitSelection?: string
	legKitSelect?: (legKit: string) => void
}

interface CarouselAreaProps {
	trialLength: number
	productImages: any[]
	carouselPlaceholder: string
	productHandle: any
	children?: ReactNode
}

const ShopSliderLazy = dynamic(() => import('components/Sliders/ShopSlider/ShopSlider').then((module) => module.ShopSlider), {
	ssr: false,
})

const CarouselSubArea = () => (
	<div className={styles.custom_feature}>
		<strong>Not interested in the accessories? Get the Pod 3 Upgrade only</strong>
		<LinkWithArrow href="/product/eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208">Explore Pod 3 upgrade</LinkWithArrow>
	</div>
)

const CarouselArea: FC<CarouselAreaProps> = (props) => {
	const [placeholder, setPlaceholder] = useState(props.carouselPlaceholder)

	return (
		<div className={cx(styles.carousel, styles.sticky)}>
			<Img
				className={`${styles.loading_placeholder_new} ${!placeholder ? styles.remove : ''}`}
				src={props.carouselPlaceholder}
				alt="Shop carousel placeholder"
			/>
			<ShopSliderLazy
				products={props.productImages}
				id="shop-image-slider"
				placeholderImg={props.carouselPlaceholder}
				onInit={() => {
					setPlaceholder('')
				}}
			/>
			{props.children}
		</div>
	)
}

const canShipOutsideUS = (variantName: string, region: ShippingRegion, shipping?: ShippingInfo): boolean => {
	return shipping ? shipping[variantName]?.indexOf(region) !== -1 : false
}

const getNoShipCountry = (variantId: number, variant: string, ipLocations: Pick<SettingsStore, 'isCanada' | 'isUK' | 'isEULegacy' | 'isAU'>, shipping?: ShippingInfo): ShippingRegion | null => {
	const variantName = variant.toLowerCase()
	const variantData = shippingTimelines[variantId]
	if (ipLocations.isCanada && !shipsToCanada(variantData) && !canShipOutsideUS(variantName, 'Canada', shipping)) {
		return 'Canada'
	} else if (ipLocations.isUK && !shipsToUK(variantData) && !canShipOutsideUS(variantName, 'UK', shipping)) {
		return 'UK'
	}
	if (ipLocations.isEULegacy && !shipsToEU(variantData) && !canShipOutsideUS(variantName, 'EU', shipping)) {
		return 'EU'
	}
	if (ipLocations.isAU && !shipsToAU(variantData) && !canShipOutsideUS(variantName, 'AU', shipping)) {
		return 'AU'
	}
	return null
}

const renderVariantSelector_Cards = (props: ShopProps, dropdownItems: any[], product: Product) => {
	if (!dropdownItems || !dropdownItems.length) {
		return null
	}
	const selectedVariantIndex: number = dropdownItems.findIndex((it) => it.value === props.selectedVariant)
	const labels: ProductSelectFields[] = dropdownItems.map((it) => ({
		id: it.value,
		price: product.variants[it.value]?.prices.priceString,
		comparePrice: product.variants[it.value]?.prices.comparePriceString,
		title: it.label,
		description: '',
		onCheck: () => {
			// empty body
		},
	}))
	return (
		<div id={'size_select'}>
			<div className={styles.variant_selector_header}>
				<Type.Headline6>{props.variantSelectLabel}</Type.Headline6>
			</div>

			<ProductModelSelect
				legendLabel={props.variantSelectLabel}
				labels={labels}
				onCheck={(index) => props.onSelectVariant(dropdownItems[index]?.value)}
				selectedModel={selectedVariantIndex}
			/>

			{props.variantSelectLabel === 'Size' ? (
				<AccessoriesSizeExplanationModal
					title="Size"
					region={props.region}
					id={props.currentProduct.id}
				/>
			) : null}
		</div>
	)
}

const renderVariantSelector_CardsNew = (props: ShopProps, dropdownItems: any[], product: Product, variantName: string) => {
	if (!dropdownItems || !dropdownItems.length) {
		return null
	}
	const selectedVariantIndex: number = dropdownItems.findIndex((it) => it.value === props.selectedSize)
	const labels: ProductSelectFields[] = dropdownItems.map((it) => ({
		id: it.value,
		price: product.variants[it.value]?.prices.priceString,
		comparePrice: product.variants[it.value]?.prices.comparePriceString,
		title: it.label,
		description: '',
		onCheck: () => {
			// empty body
		},
	}))

	const onCheck = (index: number) => {
		if (props.showMattressUpsell) {
			const el = document.getElementById('membership-selector')
			if (el && window.innerWidth < 1024) {
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				})
			}
		}
		props.onSelectVariant(dropdownItems[index]?.value)
	}

	return (
		<div
			className={`${props.noSizeError ? styles2.size_error : ''}`}
			id="variant_select"
		>
			<span className={styles2.error_message}>Choose a size</span>
			<span className={cx(styles.product_type_select)}>Size</span>
			<ProductVariantSelect
				legendLabel={props.variantSelectLabel}
				labels={labels}
				onCheck={onCheck}
				selectedModel={selectedVariantIndex}
			/>
			<PodFamilySizeExplanationModal
				title={variantName}
				region={props.region}
				id={product.id}
			/>
		</div>
	)
}

const MultiVariantSelectors = (props: ShopProps) => {
	const variants = props.multiVariantItems!
	const [selectedVariants, setSelectedVariants] = useState(variants.map((it) => it.defaultVariantSelected))

	const onVariantSelect = (value: string, groupIndex: number) => {
		setSelectedVariants((previousVariants) => {
			const variantElements = [...previousVariants]
			variantElements[groupIndex] = value
			const _oldVariants = previousVariants.join('-')
			const _newVariants = variantElements.join('-')
			if (_oldVariants !== _newVariants) {
				props.onSelectVariant(_newVariants)
			}
			return variantElements
		})
	}

	return (
		<div>
			{variants.map((variantGroup, i) => {
				return (
					<div
						className={cx(styles.pillow_size_select, 'legend-visible', styles[variantGroup.name])}
						key={`multi_variant_${variantGroup.label}`}
					>
						<RadioButtonGroup
							options={variantGroup.items.map((it) => ({ groupName: variantGroup.name, ...it }))}
							legend={variantGroup.name === 'colors' ? `${variantGroup.label} - ${variantGroup.items.find((v) => v.value === selectedVariants[i])?.label}` : variantGroup.label}
							default={variantGroup.items.find((it) => it.value === variantGroup.defaultVariantSelected)?.value || variantGroup.items[0].value}
							onSelect={(value) => onVariantSelect(value, i)}
						/>
					</div>
				)
			})}
		</div>
	)
}

const renderModelSelector_Cards = (props: ShopProps, productId: number) => {
	const getExplanation = () => {
		if (isPodCoverProductId(productId) || isMemberUpgradeProductId(productId)) {
			return (
				<CoverModelExplanationModal
					region={props.region}
					selectedIndex={props.selectedProduct}
					prices={props.products.map((it) => it.variants[props.selectedVariant]?.prices.priceString)}
				/>
			)
		}

		if (isPodBedProductId(productId)) {
			return <MattressModelExplanationModal region={props.region} />
		}
		return null
	}

	const onCheck = (index: number) => {
		if (props.showMattressUpsell) {
			const el = document.getElementById('variant_select')
			if (el && window.innerWidth < 1024) {
				el.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}
		props.modelSelectProps.onCheck(index)
	}

	return (
		<div>
			<span className={cx(styles.product_type_select)}>Model</span>
			<ProductModelSelect
				{...props.modelSelectProps!}
				onCheck={onCheck}
				selectedModel={props.selectedProduct}
			/>
			{getExplanation()}
		</div>
	)
}

const renderMembershipSelector = (region: RegionShort, selectedMembership, selectMembership, isshowMattressUpsell) => {
	const labels = [
		{
			title: 'Pro',
			subtitle: `${PriceManager.getRegionSubscriptionPrice('8-pro-membership', region)}/mo`,
			subtitle2: `(${PriceManager.getRegionSubscriptionPriceYearly('8-pro-membership', region)} billed annually)`,
			description: `Automatic temperature, vibration & thermal alarm, sleep & health reports`,
			titleBadge: '',
			id: 'pro_membership',
		},
		{
			title: 'Plus',
			subtitle: `${PriceManager.getRegionSubscriptionPrice('8-plus-membership', region)}/mo`,
			subtitle2: `(${PriceManager.getRegionSubscriptionPriceYearly('8-plus-membership', region)} billed annually)`,
			description: `Automatic temperature, vibration & thermal alarm, sleep & health reports, extended warranty`,
			titleBadge: 'Most Popular',
			id: 'premium_membership',
		},
	]

	const onCheck = (index: number) => {
		if (isshowMattressUpsell) {
			const el = document.getElementById('mattress-upsell')
			if (el && window.innerWidth < 1024) {
				el.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}
		selectMembership(index)
	}

	return (
		<div id={'membership-selector'}>
			<span className={cx(styles.product_type_select)}>Membership</span>
			<p className={styles.membership_num_accounts}>Includes 2 individual accounts per Pod</p>
			<ProductModelSelect
				labels={labels}
				legendLabel={'Select membership type'}
				selectedModel={selectedMembership}
				onCheck={onCheck}
				className={styles.membership_fieldset}
			/>
		</div>
	)
}

const renderModelSelector_RadioButtons = (props: ShopProps) => {
	const labels: ProductSelectFields[] = props.modelSelectProps.labels.map((it) => ({
		id: it.id,
		title: it.title,
		description: '',
		onCheck: () => {
			// empty body
		},
	}))
	return (
		<div>
			<div className={styles.variant_selector_header}>
				<Type.Headline6>{props.modelSelectProps.legendLabel}</Type.Headline6>
			</div>

			<ProductModelSelect
				legendLabel={props.modelSelectProps.legendLabel}
				labels={labels}
				onCheck={(index) => props.modelSelectProps.onCheck(index)}
				selectedModel={props.selectedProduct}
			/>

			<AccessoriesSizeExplanationModal
				title={props.modelSelectProps.legendLabel}
				region={props.region}
				id={props.currentProduct.id}
			/>
		</div>
	)
}

const renderModelSelector_ColorPicker = (props: ShopProps) => {
	return (
		<div className={styles.color_picker_container}>
			<Type.Headline6>Color - {props.modelSelectProps.labels[props.selectedProduct].title}</Type.Headline6>
			<div
				className={styles.color_picker_items}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.modelSelectProps!.onCheck(Number(event.target.value))}
			>
				{props.modelSelectProps!.labels.map((label, i) => {
					const isSelected = i === props.selectedProduct
					return (
						<div
							key={label.id}
							className={isSelected ? cx(styles.color_picker_item, styles.selected) : styles.color_picker_item}
							style={{
								backgroundColor: label.color,
							}}
						>
							<input
								type="radio"
								value={i}
								checked={isSelected}
							/>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const mattressUpsellImages: SlideItem[] = [
	{
		src: 'https://eightsleep.imgix.net/Mattress_Upsell_2.jpg?v=1686849106',
		alt: '',
		component: (
			<Img
				src={'https://eightsleep.imgix.net/Mattress_Upsell_2.jpg?v=1686849106'}
				alt={''}
				simple={true}
				lazy={false}
				key={`mattress_upsell_2`}
				objectFit={'cover'}
			/>
		),
	},
	{
		src: 'https://eightsleep.imgix.net/Mattress_Upsell_1.jpg?v=1686849106',
		alt: '',
		component: (
			<Img
				src={'https://eightsleep.imgix.net/Mattress_Upsell_1.jpg?v=1686849106'}
				alt={''}
				simple={true}
				lazy={false}
				key={`mattress_upsell_1`}
				objectFit={'cover'}
			/>
		),
	},
	{
		src: 'https://eightsleep.imgix.net/mattress_4.jpg?v=1687374445',
		alt: '',
		component: (
			<Img
				src={'https://eightsleep.imgix.net/mattress_4.jpg?v=1687374445'}
				alt={''}
				simple={true}
				lazy={false}
				key={`mattress_upsell_3`}
				objectFit={'cover'}
			/>
		),
	},
]

interface MattressUpsellProps {
	setMattressUpsell: (flag: boolean) => void
	price: string
	strikePrice: string
	discount?: string
	label: string
}

const MattressUpsell: FC<MattressUpsellProps> = (props) => {
	const [checked, setChecked] = useState(false)

	useEffect(() => {
		props.setMattressUpsell(checked)
	}, [checked])

	const id = 'open-pod-size-explanation-modal'

	return (
		<div
			className={styles.mattress_upsell}
			id={'mattress-upsell'}
		>
			<span className={cx(styles.product_type_select, styles.product_type_select_alt)}>Add a mattress and save more</span>
			<p>With our {props.label} mattress, you can achieve the perfect mix of temperature control and comfort.</p>
			<SlickSlider
				items={mattressUpsellImages}
				settings={{ dots: true }}
				className={styles.mattress_upsell_slider}
			/>
			{/*<Img*/}
			{/*	src={'https://eightsleep.imgix.net/pod_mattress.jpg?v=1684257641'}*/}
			{/*	alt={'The stand-alone Pod mattress'}*/}
			{/*	className={styles.mattress_image}*/}
			{/*/>*/}
			<div className={styles.mattress_upsell_check}>
				<label>
					<input
						checked={checked}
						onChange={(e) => {
							setChecked(true)
						}}
						type={'radio'}
						id={'add-mattress'}
						className={'vh'}
					/>
					<p className={styles.mattress_upsell_radio_box}>
						<strong>Add {props.label} mattress</strong>
						<span>
							<span aria-label="Current price">{props.price}</span>
							{props.price !== props.strikePrice && <s aria-label="Old price">{props.strikePrice}</s>}
							{props.price !== props.strikePrice && props.discount && (
								<span
									className={styles.sa_vings_tag}
									style={{ fontSize: '12px', marginLeft: '7px' }}
								>
									{props.discount} off
								</span>
							)}
						</span>
					</p>
				</label>
				<label>
					<input
						checked={!checked}
						onChange={(e) => {
							setChecked(false)
						}}
						type={'radio'}
						className={'vh'}
					/>
					<p className={styles.mattress_upsell_radio_box}>
						<strong>No {props.label} mattress</strong>
					</p>
				</label>
			</div>
			<Modal
				rootElementId="__next"
				triggerElement={
					<ModalTriggerNew
						topText={'Need help deciding?'}
						bottomText={'Explore upgrading to the ultimate sleep experience'}
						id={id}
						onClick={() => amClickedButton('Compare sizes link', id)}
					/>
				}
				onOpen={amOpenMembershipModal}
				onClose={amCloseMembershipModal}
			/>
		</div>
	)
}

interface ModalProps {
	rootElementId: string
	triggerElement: ReactElement
	onOpen?: () => void
	onClose?: () => void
}

const Modal: FC<ModalProps> = (props) => {
	const [modalVisible, setModalVisible] = useState(false)
	const pageWrapper = useRef(null)

	function showModal() {
		setModalVisible(true)
		if (props.onOpen) {
			props.onOpen()
		}
	}
	function hideModal() {
		setModalVisible(false)
		props.onClose?.()
	}

	useClickOutsideModal(pageWrapper, hideModal, false)

	return (
		<div className={cx('modal')}>
			{cloneElement(props.triggerElement, { onClick: showModal })}
			{modalVisible && (
				<>
					<FocusTrapWrapper active={true}>
						<div className={'modal_container'}>
							<div
								className={styles.modal_content_container}
								ref={pageWrapper}
							>
								<button
									role="button"
									aria-label="Close modal button"
									className={styles.close_button}
									onClick={hideModal}
								>
									<img
										src={IconMap.close}
										alt="close icon"
									/>
								</button>
								<div className={styles.mattress_modal}>
									<MattressLayers />
								</div>
							</div>
						</div>
					</FocusTrapWrapper>
				</>
			)}
		</div>
	)
}

export const ShopMain: FC<ShopProps> = (props) => {
	const [internationalShipToUS, setInternationalShipToUS] = useState(false)

	const [showStickyATC, setShowStickyATC] = useState(false)
	const [kustomerOpen, setKustomerOpen] = useState(false)

	const router = useRouter()

	const carouselSlides: ContentWithSteps[] = props.images
		.filter((item) => item.type === 'image' || item.type === 'video')
		.map((item) => {
			switch (item.type) {
				case 'image':
					return {
						content: {
							type: 'image',
							data: {
								src: item.src,
								alt: item.alt,
							},
						},
						associatedSteps: [],
					}
				case 'video':
					return {
						content: {
							type: 'video-gif',
							data: {
								src: item.src,
								poster: item.thumb,
							},
						},
						associatedSteps: [],
					}
			}
		})

	useEffect(() => {
		if (typeof window !== 'undefined' && window.Kustomer && window.Kustomer.addListener) {
			window.Kustomer.addListener('onOpen', () => setKustomerOpen(true))
			window.Kustomer.addListener('onClose', () => setKustomerOpen(false))
		}
	}, [])

	useEffect(() => {
		if (window.affirm?.ui?.refresh) {
			window.affirm.ui.refresh()
		}
	}, [props.selectedVariant])

	const clickAffirmTrigger = (event: React.MouseEvent) => {
		event.preventDefault()
		trackClickFinancingModalAffirm(AffirmLocations.POD_SHOP)
		sessionStorage.setItem('pause_exit_intent', '1')
		try {
			;(document.querySelector('.affirm-trigger-container .affirm-modal-trigger') as any).click()
		} catch (e) {
			console.log(e)
		}
	}

	const product = props.currentProduct
	const singleOptionKey = Object.keys(product.variants)[0]
	const variant = product.variants[props.selectedVariant] ?? product.variants[singleOptionKey]

	const labels = props.currentDropdownOptions?.items.map((i) => {
		const _variant = product.variants[i.value]
		return { ...i, oldPrice: _variant.prices.comparePriceString, newPrice: _variant.prices.priceString! }
	})

	const isPodFamily = false

	const shippingObj = shippingTimelines[variant.id.toString()]

	let shippingStatus: ShippingStatus = { text: '' }
	if (shippingObj) {
		shippingStatus = getShopPageShippingText(
			shippingTimelines[variant.id],
			props.ipLocations.isCanada && !internationalShipToUS,
			props.ipLocations.isUK,
			props.ipLocations.isEULegacy,
			props.ipLocations.isAU
		)
	}

	const isMembershipProduct = false
	const shippingTimeline = isMembershipProduct
		? `${PriceManager.formatPriceToCurrencyNoDecimal(variant.prices.price / 100, props.currency)}/mo membership (billed monthly, cancel anytime) for monthly reports plus weekly insights`
		: shippingStatus?.text

	const showOneClick = isPodCoverProductId(product.id) || isPodBedProductId(product.id)

	let atcLabel = isMembershipProduct ? 'Subscribe now' : 'Add to cart'
	if (props.showMembershipCheck && props.numPodsAndCovers < 1) {
		atcLabel = showOneClick ? 'Buy now' : 'Add to cart and checkout'
	}
	if (props.outOfStock) {
		atcLabel = 'Out of Stock'
	}

	let noShipCountry = getNoShipCountry(variant.id, variant.name, props.ipLocations, props.shipping)

	// refactor this into productManager
	let carouselPlaceholder = isPodFamily ? 'https://eightsleep.imgix.net/mattress_shop_blur.png?v=1675462913' : ''
	if (isPodCoverProductId(product.id)) {
		noShipCountry = null
		carouselPlaceholder = 'https://eightsleep.imgix.net/cover_shop_blur.png?v=1675462913'
	}

	const savingsAmount = (variant.prices.comparePrice - variant.prices.price) / 100
	const upsellSavingsAmount = props.mattressUpsellStrikePrice / 100 - props.mattressUpsellPrice / 100

	const renderShippingAlert = () => (
		<>
			<NotShippingAlert
				onShippingToUSClick={() => {
					setInternationalShipToUS(true)
				}}
				productName={product.name}
				country={noShipCountry!}
				allowShipToUS={props.region === 'ca' || props.region === ''}
			/>
		</>
	)

	const renderProductUI = () => (
		<>
			{props.modelSelectComponentType === 'cards' && props.products.length > 1 && props.modelSelectProps && renderModelSelector_Cards(props, product.id)}
			{props.modelSelectComponentType === 'radio_buttons' && props.modelSelectProps && renderModelSelector_RadioButtons(props)}
			{props.modelSelectComponentType === 'color_picker' && props.modelSelectProps && renderModelSelector_ColorPicker(props)}
			{props.variantSelectComponentType === 'dropdown' &&
				labels &&
				renderVariantSelector_CardsNew(props, labels, product, product.id === PodCoverProducts.Pod3CoverPerfect.id ? 'Pod 3 Cover with PerfectFit' : product.name)}
			{props.variantSelectComponentType === 'cards' && labels && renderVariantSelector_Cards(props, labels, product)}
			{props.variantSelectComponentType === 'multi_variant_radio_buttons' && props.multiVariantItems && MultiVariantSelectors(props)}
			{props.showLegKitUpsell && (
				<Selection
					{...props.legKitSelectData}
					selectionType={'card-selection'}
					selectionProps={{
						options: props.legKitSelectData.options,
						onChange: (selectionKey: string, optionKey: string) => {
							props.legKitSelect(optionKey)
						},
					}}
					modalClick={props.selectModal}
					currentSelection={props.legKitSelection}
				/>
			)}
			{props.showMattressUpsell && (
				<MattressUpsell
					setMattressUpsell={props.setAddMattress}
					price={PriceManager.formatPriceToRegionNoDecimal(props.mattressUpsellPrice / 100, props.region)}
					strikePrice={PriceManager.formatPriceToRegionNoDecimal(props.mattressUpsellStrikePrice / 100, props.region)}
					discount={PriceManager.formatPriceToCurrencyNoDecimal(props.mattressUpsellStrikePrice / 100 - props.mattressUpsellPrice / 100, props.currency)}
					label={props.currency === 'CAD' ? '3-layer' : '5-layer'}
				/>
			)}

			{PriceManager.regionHasAffirm(props.region) && isPodFamily && (
				<div className={styles2.financing}>
					<p
						data-page-type="cart"
						data-amount={variant.prices.price}
						onClick={() => trackAffirmLearnMoreClick(AffirmLocations.POD_SHOP)}
						className={cx('affirm-as-low-as', 'none', 'affirm-trigger-container')}
					/>
					Financing starting from 0% APR with $0 down with
					<svg
						viewBox="0 0 420 167"
						className={styles.affirm_logo}
					>
						<g
							fillRule="evenodd"
							clipRule="evenodd"
						>
							<path
								fill="#060809"
								d="M34.3 94.1c-11.3 0-24.4 5.4-31.5 11l6.5 13.6c5.7-5.2 14.9-9.6 23.2-9.6 7.9 0 12.2 2.6 12.2 7.9 0 3.6-2.9 5.4-8.3 6.1C16 125.7 0 131.3 0 147c0 12.4 8.9 20 22.7 20 9.9 0 18.7-5.5 22.9-12.7V165H64v-44.9c0-18.5-12.9-26-29.7-26zm-6.9 58.5c-5.2 0-7.8-2.5-7.8-6.7 0-7.8 8.7-10.4 24.6-12.1.1 10.4-7 18.8-16.8 18.8zm210.1-44.9V96.1h-18.4V165h19.7v-33.2c0-15.8 9.5-20.4 16.2-20.4 2.6 0 6.1.8 8.4 2.5l3.6-18.2c-3-1.3-6.2-1.6-8.8-1.6-10.1 0-16.5 4.5-20.7 13.6zm120.9-13.6c-10.4 0-18.2 6.2-22.3 12.1-3.8-7.7-11.7-12.1-21.3-12.1-10.4 0-17.6 5.8-21 12.4V96.1h-19V165h19.7v-35.5c0-12.7 6.7-18.8 12.9-18.8 5.6 0 10.8 3.6 10.8 13V165H338v-35.5c0-12.9 6.5-18.8 13-18.8 5.2 0 10.7 3.8 10.7 12.9V165h19.7v-47.6c0-15.5-10.4-23.3-23-23.3zM133.6 91v5.1h-29.9v-7c0-9.1 5.2-11.7 9.7-11.7 2.6 0 6 .6 8.8 2.2l6.1-13.9c-3.6-2.1-9.5-4-17.4-4-12.6 0-26.9 7.1-26.9 29.4v5.1H72.6v15.2H84V165h19.7v-53.7h29.9V165h19.7v-53.7h17.9V96.1h-17.9v-7c0-9.1 5.2-11.7 9.7-11.7 5 0 8.8 2.2 8.8 2.2l6.1-13.9s-6.2-4-17.4-4c-12.5-.1-26.9 7-26.9 29.3zm49.3 5.1h19.7V165h-19.7z"
							/>
							<path
								fill="#0fa0ea"
								d="M297.7 0c-53.2 0-100.6 36.9-114 84.4H203C214.2 49 252.3 18 297.7 18c55.2 0 102.8 42 102.8 107.4 0 14.7-1.9 27.9-5.5 39.6h18.7l.2-.6c3.1-12.1 4.6-25.2 4.6-39C418.5 52.5 365.4 0 297.7 0"
							/>
						</g>
					</svg>
					<a
						href="#"
						onClick={clickAffirmTrigger}
						className={styles2.trigger_p}
						aria-label={'Open Financing Modal'}
					>
						Pre-qualify now
					</a>
					{/* <div className={styles2.AffirmLineBF}>
						<AffirmLineBF
							newFinancing={true}
							id={'cart-affirm-trigger-container'}
							monthlyPrice={PriceManager.formatPriceToCurrencyNoDecimal(monthlyPrice, props.currency)}
							currentPrice={variant.prices.price / 100}
						/>
					</div> */}
				</div>
			)}

			{props.note && <div className={styles.note}>{props.note}</div>}

			<Waypoint
				onPositionChange={(position: any) => {
					if (props.stickyATCTest) {
						if (position.currentPosition === 'above') {
							amShowStickyATC()
							setShowStickyATC(true)
						} else {
							amHideStickyATC()
							setShowStickyATC(false)
						}
					}
				}}
				topOffset={100}
			>
				<div className={cx(styles.atc_container, { [styles.atc_container_sticky_shown]: props.stickyATCTest })}>
					<NewButton.Primary
						onClick={() => props.onAtcClick(props.showMembershipCheck ? true : undefined, 'add-to-cart-button', props.addMattress)}
						className={cx(styles.atc_button)}
						id="add-to-cart-button"
						size={'large'}
						loading={props.cartLoading}
						disabled={props.outOfStock ? true : props.cartLoading}
					>
						{atcLabel}
					</NewButton.Primary>
				</div>
			</Waypoint>
			{shippingTimeline && (
				<div className={styles.timeline}>
					<ClientOnly>
						<BenefitsContainer
							warrantyAmount={0}
							shippingTimeline={shippingTimeline}
						/>
					</ClientOnly>
				</div>
			)}
		</>
	)

	const atcSection = renderProductUI()

	const trialLength = 30

	const stickyATC = props.stickyATCTest
	let showStickyATCTestCheck = false

	if (stickyATC && !kustomerOpen) {
		showStickyATCTestCheck = stickyATC || showStickyATC
	}

	if (!kustomerOpen && typeof document !== 'undefined' && stickyATC) {
		const kustomer = document.getElementById('kustomer-ui-sdk-iframe')
		if (kustomer) {
			setTimeout(() => {
				kustomer.style.visibility = 'hidden'
				kustomer.style.display = 'none'
			}, 250)
		}
	}

	return (
		<>
			{props.showSticky && (
				<ShopSticky
					stickyName={props.currentProduct.name}
					promoText={isPodCoverProductId(product.id) ? '' : `${PriceManager.formatPriceToRegionNoDecimal((props.strikePriceNumber - props.priceNumber) / 100, props.region)} off`}
					sizeText={props.currentProduct.variants[props.selectedVariant].name + ' Size'}
					price={PriceManager.formatPriceToRegionNoDecimal(props.priceNumber / 100, props.region)}
					strikePrice={PriceManager.formatPriceToRegionNoDecimal(props.strikePriceNumber / 100, props.region)}
					ctaClick={() => props.onAtcClick(props.showMembershipCheck ? true : undefined, 'top-stick-atc-button', props.addMattress)}
					ctaCopy={atcLabel}
					loading={props.cartLoading}
					disabled={props.cartLoading}
				/>
			)}
			<div className={cx(styles.shop)}>
				<ShopCarousel
					items={carouselSlides}
					className={styles.shop_carousel}
				/>

				<div className={styles.atc}>
					<div className={styles.right_side_normal}>
						<div className={styles.product_info}>
							<Type.Headline3
								as={'h1'}
								className={styles.title}
							>
								{' '}
								{product.name} {props.saleShopSavingsTag && props.showMattressUpsell && upsellSavingsAmount > 0 ? <span className={styles.sa_vings_tag3}>{props.saleShopSavingsTag}</span> : null}
							</Type.Headline3>

							<Type.Headline5
								as={'p'}
								className={styles.money_amount_tags}
							>
								{variant.prices.comparePriceString !== variant.prices.priceString && (
									<del className={cx(styles.price_tag_compare, { [styles.google_compare_tag]: router.asPath.includes('shop-pod-cover') })}>{variant.prices.comparePriceString}</del>
								)}
								<span className={cx(styles.price_tag_price, { [styles.google_price_tag]: router.asPath.includes('shop-pod-cover') })}>{variant.prices.priceString}</span>

								{savingsAmount > 0 && <Badge.BFCMLight type={'discount'}>{PriceManager.formatPriceToCurrencyNoDecimal(savingsAmount, props.currency)} off</Badge.BFCMLight>}
							</Type.Headline5>

							{isPodFamily && isPodBedProductId(product.id) && <p className={styles.subtitle}>The Pod is an intelligent sleep system that improves your sleep.</p>}
							{isPodFamily && !isPodBedProductId(product.id) && <p className={styles.subtitle}>The Pod is an intelligent sleep system that can be installed onto any bed to improve your sleep.</p>}

							<Type.Body2>{product.description}</Type.Body2>
						</div>

						{atcSection}
					</div>
				</div>

				{stickyATC ? (
					<div className={cx(styles.sticky_atc_hidden, { [styles.sticky_atc_show]: showStickyATCTestCheck })}>
						<StickyATC
							price={variant.prices.price + (props.addMattress ? props.mattressUpsellPrice : 0)}
							comparePrice={variant.prices.comparePrice + (props.addMattress ? props.mattressUpsellStrikePrice : 0)}
							currency={props.currency}
							atcClick={() => props.onAtcClick(props.showMembershipCheck ? true : undefined, 'add-to-cart-button', props.addMattress)}
							cartLoading={props.cartLoading}
							buyNowLoading={props.buyNowLoading}
							showFinancing={!props.ipLocations.isUK && !props.ipLocations.isEULegacy && !props.ipLocations.isAU && props.displayPrice == null}
							size={variant.name}
							selectedSize={props.selectedSize}
							outOfStock={props.outOfStock}
						/>
					</div>
				) : null}
			</div>
		</>
	)
}
