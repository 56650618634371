import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M9.33333 19V6L20 3V16M9.33333 19C9.33333 20.105 8.13956 21 6.66667 21C5.19378 21 4 20.105 4 19C4 17.895 5.19378 17 6.66667 17C8.13956 17 9.33333 17.895 9.33333 19ZM20 16C20 17.105 18.8062 18 17.3333 18C15.8604 18 14.6667 17.105 14.6667 16C14.6667 14.895 15.8604 14 17.3333 14C18.8062 14 20 14.895 20 16ZM9.33333 10L20 7"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
