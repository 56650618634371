import { BadgeComponent, BadgeComponentProps, BadgeSubComponent } from './Badge.types'
import styles from './Badge.module.scss'
import { CSSProperties } from 'react'
import cx from 'classnames'
import { Type } from 'components/Type'

/**
 * A tag/badge component for things like Price badges, section tags, just delightful chips of information
 *
 * TODO: replace p with Text component once we have it
 */
export const Badge: BadgeComponent = (props) => {
	const { children, backdropBlur = true, textColor = 'black', backgroundColor = 'none', borderColor, className, type = 'eyebrow', animateOnScroll = false, style, ...rest } = props

	const Component = type === 'discount' ? Type.Eyebrow2 : Type.Eyebrow
	return (
		<Component
			as={'span'}
			className={cx(styles.badge_base, className)}
			animateOnScroll={animateOnScroll}
			style={
				{
					'--text-color': textColor,
					'--background-color': backgroundColor || 'transparent',
					'--border-color': borderColor || 'transparent',
					'--backdrop-blur-amount': backdropBlur ? '4px' : 0,
					...style,
				} as CSSProperties
			}
			{...rest}
		>
			{children}
		</Component>
	)
}

export const Outline: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Outline}
			{...props}
		/>
	)
}

export const Orange: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Orange}
			{...props}
		/>
	)
}

export const BFCMLight: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.BFCMLight}
			{...props}
		/>
	)
}

export const BFCMLight2: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.BFCMLight2}
			{...props}
		/>
	)
}

export const BFCMDark: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.BFCMDark}
			{...props}
		/>
	)
}

export const Important: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Important}
			{...props}
		/>
	)
}

export const Blue: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Blue}
			{...props}
		/>
	)
}

export const Glass: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Glass}
			{...props}
		/>
	)
}

export const Grey: BadgeSubComponent = (props) => {
	return (
		<Badge
			{...BadgeComponentProps.Grey}
			{...props}
		/>
	)
}

Badge.Outline = Outline
Badge.Orange = Orange
Badge.BFCMLight = BFCMLight
Badge.BFCMLight2 = BFCMLight2
Badge.BFCMDark = BFCMDark
Badge.Important = Important
Badge.Blue = Blue
Badge.Glass = Glass
Badge.Grey = Grey
