import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M18 11.1C18 12.7709 17.3679 14.3733 16.2426 15.5548C15.1174 16.7363 13.5913 17.4 12 17.4M12 17.4C10.4087 17.4 8.88258 16.7363 7.75736 15.5548C6.63214 14.3733 6 12.7709 6 11.1M12 17.4V21M12 21H8.57143M12 21H15.4286M12 13.8C11.318 13.8 10.664 13.5155 10.1817 13.0092C9.69949 12.5028 9.42857 11.8161 9.42857 11.1V5.7C9.42857 4.98392 9.69949 4.29716 10.1817 3.79081C10.664 3.28446 11.318 3 12 3C12.682 3 13.336 3.28446 13.8183 3.79081C14.3005 4.29716 14.5714 4.98392 14.5714 5.7V11.1C14.5714 11.8161 14.3005 12.5028 13.8183 13.0092C13.336 13.5155 12.682 13.8 12 13.8Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
