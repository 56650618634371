import { inject, observer } from 'mobx-react'
import Script from 'next/script'
import { RootStore } from 'stores'
import styles from './TruemedInfo.module.scss'
import { TruemedBlueLogo } from 'components/_const_assets/truemedLogo'
import { ClientOnly } from 'components/ClientOnly'

export interface TruemedInfoProps {
	rootStore?: RootStore
	className?: string
	textOnly?: boolean
}

export const TruemedInfo = inject('rootStore')(
	observer((props: TruemedInfoProps) => {
		const { currency } = props.rootStore.priceStore

		if (currency !== 'USD') return null

		return (
			<div
				className={`${props.className} ${styles.container}`}
				style={{ height: '16px' }}
			>
				{props.textOnly ? (
					<ClientOnly>
						HSA/FSA-eligible with
						<TruemedBlueLogo key={Date.now().toString()} />
					</ClientOnly>
				) : (
					<ClientOnly>
						<p
							id="truemed-instructions"
							icon-height="11"
							style={{ margin: '0', lineHeight: '1' }}
						></p>
						<Script
							src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/eight-sleep-pdp-widget.min.js"
							strategy="afterInteractive"
						></Script>
					</ClientOnly>
				)}
			</div>
		)
	})
)
