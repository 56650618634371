import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_6399)">
			<path
				d="M20.1818 4.79999H3.81812L10.3636 12.368V17.6L13.6363 19.2V12.368L20.1818 4.79999Z"
				stroke={color}
				strokeWidth="1.5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_6399">
				<rect
					width="18"
					height="16"
					fill={color}
					transform="translate(3 4)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
