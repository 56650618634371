import React, { FC } from 'react'
import cx from 'classnames'
import styles from './AccessoryCTA.module.scss'
import { RootStore } from 'stores/index'
import { BundleProducts, PillowProducts, PodCoverProducts, ProtectorProducts, SheetProducts } from 'products/allProducts'
import { AccessoryCard } from 'components/AccessoryCard/AccessoryCard'
import { PriceManager } from 'prices/priceManager'

interface Props {
	rootStore: RootStore
	handle: string
}

const getItems = (handle: string) => {
	if (handle === PillowProducts.CarbonAirPillow.handle || handle === PillowProducts.CarbonAirPillowKing.handle) {
		return [BundleProducts.SleepEssentialBundle.handle, SheetProducts.SheetSet.handle, ProtectorProducts.PodProtector.handle]
	} else if (handle === ProtectorProducts.PodProtector.handle) {
		return [BundleProducts.SleepEssentialBundle.handle, PillowProducts.CarbonAirPillow.handle, SheetProducts.SheetSet.handle]
	} else if (handle === BundleProducts.SleepEssentialBundle.handle) {
		return [SheetProducts.SheetSet.handle, PillowProducts.CarbonAirPillow.handle, ProtectorProducts.PodProtector.handle]
	}
	return [PillowProducts.CarbonAirPillow.handle, ProtectorProducts.PodProtector.handle, BundleProducts.SleepEssentialBundle.handle]
}

const AccessoryCTA: FC<Props> = (props) => {
	const { rootStore } = props
	const data = rootStore.productStore.getAccessoriesProducts('accessories')
	const itemHandles = [PodCoverProducts.Pod3CoverPerfect.handle, ...getItems(props.handle)]
	const items = data.flatMap((section) => section.items).filter((item) => itemHandles.includes(item.handle))

	const allPrices = rootStore.priceStore.allPrices

	return (
		<section className="inner-paddings-x inner-paddings-bottom bg-white">
			<div className={styles.headline}>Explore more premium accessories</div>
			<div className={cx('max-1440', styles.items, items.length > 3 ? styles.items2 : '')}>
				{items.map((item, index) => {
					const prices = PriceManager.formatPrices(Object.keys(allPrices).length ? allPrices[item.variantId].prices : PriceManager.emptyPrice, 'USD')
					return (
						<AccessoryCard
							key={index}
							{...item}
							prices={prices}
						/>
					)
				})}
			</div>
		</section>
	)
}

export default AccessoryCTA
