import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3.20845 8.95815L6.60705 3.6175C6.85179 3.2329 7.27606 3 7.73193 3H16.7163C17.2001 3 17.6459 3.26203 17.8812 3.68469L20.8316 8.98325C20.942 9.18161 21 9.40487 21 9.6319V12.6667C21 13.403 20.403 14 19.6667 14H4.33333C3.59695 14 3 13.403 3 12.6667V9.67398C3 9.4204 3.07231 9.17208 3.20845 8.95815Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M21 9.11111H3"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<circle
			cx="4.42105"
			cy="19.7895"
			r="1.42105"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<circle
			cx="11.9999"
			cy="19.7895"
			r="1.42105"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M4.42114 18.3684L6.31588 16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M19.1052 18.3684L17.2105 16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M12 18.3684V16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<circle
			cx="19.579"
			cy="19.7895"
			r="1.42105"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
