import { withSlash } from 'utils/index'
import { POD_LANDING_URL_STRIPPED } from 'constants/index'
import * as eventNames from './eventNames'

const BREAKPOINT = 1024

export default class ModalEvent {
	hasBeenTriggered: boolean
	canBeTriggered: boolean

	constructor() {
		this.hasBeenTriggered = false
		this.canBeTriggered = false
	}

	run = (pagesVisited: any) => {
		if (this.hasBeenTriggered) {
			this.canBeTriggered = false
			return
		}
		this.updateCanTrigger(pagesVisited)
		if (this.canBeTriggered) {
			this.action()
		}
	}

	updateCanTrigger = (pagesVisited): boolean => {
		const done = this.getHasBeenTriggered() || sessionStorage.getItem(eventNames.referralWelcomeTriggerDone) || localStorage.getItem('email')
		if (done) {
			return false
		}
		const pages = ['/' + POD_LANDING_URL_STRIPPED, '/podlanding']
		let totalTime = 0

		// const _pages = pages // loop through only pages specified
		const _pages = Object.keys(pagesVisited) // loop through ALL pages

		const excludedPages = ['/checkout/contact', '/checkout/payment', '/checkout/contact/', '/checkout/payment/', '/product']

		const currentPage = window.location.pathname
		const viewingExcludedPage = excludedPages.includes(currentPage)

		const hasVisitedMemberAccessories = _pages.some((page) => page.includes('member-accessories'))
		const hasVisitedPodProUpgradeDiscounted = _pages.some(
			(page) => page.includes('eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208') || page.includes('dce234937b8d54e2r417b318707ea9713e6087b402b40670319c4c1e166e9101')
		)

		if (hasVisitedMemberAccessories || hasVisitedPodProUpgradeDiscounted || viewingExcludedPage) {
			return false
		}

		_pages.map((path) => {
			// make sure to have trailing slash
			const _path = withSlash(path)
			if (pagesVisited.hasOwnProperty(_path)) {
				totalTime = totalTime + pagesVisited[_path]
			}
		})

		let passed = false

		passed = totalTime > 1 // 1 second

		// if (passed && !this.hasBeenTriggered) {
		if (sessionStorage.getItem('referral') && passed && !this.hasBeenTriggered) {
			this.canBeTriggered = true
		}

		return passed
	}

	action = () => {
		const emailAvailable = !!localStorage.getItem('email')
		if (!this.hasBeenTriggered && !emailAvailable) {
			window.dispatchEvent(new Event(eventNames.referralWelcomeTrigger))
			this.setTriggered()
		}
	}

	setTriggered = () => {
		this.hasBeenTriggered = true
		this.canBeTriggered = false

		try {
			sessionStorage.setItem(eventNames.referralWelcomeTriggerDone, JSON.stringify(Date.now()))
			sessionStorage.setItem(eventNames.lastEventTime, JSON.stringify(Date.now()))
		} catch (e) {
			console.log(e)
		}
	}

	getHasBeenTriggered = () => {
		const sessionItem = sessionStorage.getItem(eventNames.referralWelcomeTriggerDone)
		const alreadyTriggered = (sessionItem && sessionItem.length) || this.hasBeenTriggered
		return alreadyTriggered
	}
}
