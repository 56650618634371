import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M18.7656 9H4.98438L4 21H19.75L18.7656 9Z"
			fill={color}
		/>
		<path
			d="M15.8125 11V7C15.8125 5.93913 15.3977 4.92172 14.6592 4.17157C13.9208 3.42143 12.9193 3 11.875 3C10.8307 3 9.82919 3.42143 9.09077 4.17157C8.35234 4.92172 7.9375 5.93913 7.9375 7V11"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
