import React from 'react'
import Head from 'next/head'
import { metadata } from 'constants/headMetadata'

export const stripTrailingSlash = (path) => {
	if (path.length > 1) {
		const lastChar = path[path.length - 1]
		if (lastChar === '/') {
			return path.substring(0, path.length - 1)
		}
	}
	return path
}
export const getPathMeta = (_path) => {
	let path = _path
	path = path.split('?')[0]
	path = stripTrailingSlash(path)

	// Get metadata
	return path in metadata
		? {
				title: metadata[path].title,
				meta: metadata[path].meta,
		  }
		: metadata['/']
}

export const AppMetadata = (props) => {
	const pathMeta = getPathMeta(props.path)

	return (
		<Head>
			<title>{pathMeta.title}</title>
			{pathMeta.meta.map((meta, i) => {
				if (meta.name) {
					return (
						<meta
							key={i}
							name={meta.name}
							content={meta.content}
						/>
					)
				}

				if (meta.itemprop) {
					return (
						<meta
							key={i}
							itemProp={meta.itemprop}
							content={meta.content}
						/>
					)
				}

				if (meta.charset) {
					return (
						<meta
							key={i}
							charset={meta.charset}
						/>
					)
				}

				if (meta.property) {
					return (
						<meta
							key={i}
							property={meta.property}
							content={meta.content}
						/>
					)
				}
			})}
		</Head>
	)
}
