import { accessoryDetailsIcons, podProShopIcons } from 'constants/icons'
import { ProductFeaturesType } from './types'
import { IconMap } from 'components/_const_assets/icons'

export const podFeaturesWithMembershipCheck: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const podProMaxFeaturesWithMembershipCheck: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: IconMap.bed_down,
		name: 'Premium comfort with Invisible Sensing',
		text: 'Exclusive to Pod 3, new Invisible Sensing design makes the Pod even more comfortable than ever before',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.wifi,
		name: 'Expanded Wi-Fi connectivity',
		text: '2.4GHz and 5GHz Wi-Fi support for better Wi-Fi stability',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const podProFeaturesWithMembershipCheck: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: IconMap.bed_down,
		name: 'Premium comfort with Invisible Sensing',
		text: 'Exclusive to Pod 3, new Invisible Sensing design makes the Pod even more comfortable than ever before',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.wifi,
		name: 'Expanded Wi-Fi connectivity',
		text: '2.4GHz and 5GHz Wi-Fi support for better Wi-Fi stability',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const pillowFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.bed,
		name: 'Queen size',
		text: '16 inches wide x 29 inches long x 6 inches loft.',
	},
	{
		icon: 'https://eightsleep.imgix.net/pillows.png?v=1591879319',
		name: 'Carbon-infused foam',
		text: 'Supportive foam infused with graphite for cooling properties',
	},
	{
		icon: 'https://eightsleep.imgix.net/airflow.png?v=1591879273',
		name: 'Air ventilation system',
		text: ' Perforated design for increased airflow and breathability.',
	},
	{
		icon: 'https://eightsleep.imgix.net/pillow_00f93ebc-d8e5-481b-9ab4-dc7145669531.png?v=1591879273',
		name: 'TENCEL cover',
		text: 'Premium breathable cover, removable for easy wash and care.',
	},
	{
		icon: 'https://eightsleep.imgix.net/warrenty.png?v=1591879452',
		name: '5 Year Warranty',
		text: 'Guarantees high-quality construction that lasts.',
	},
]

export const kingPillowFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.bed,
		name: 'King size',
		text: '16 inches wide x 35 inches long x 6 inches loft.',
	},
	{
		icon: 'https://eightsleep.imgix.net/pillows.png?v=1591879319',
		name: 'Carbon-infused foam',
		text: 'Supportive foam infused with graphite for cooling properties',
	},
	{
		icon: 'https://eightsleep.imgix.net/airflow.png?v=1591879273',
		name: 'Air ventilation system',
		text: ' Perforated design for increased airflow and breathability.',
	},
	{
		icon: 'https://eightsleep.imgix.net/pillow_00f93ebc-d8e5-481b-9ab4-dc7145669531.png?v=1591879273',
		name: 'TENCEL cover',
		text: 'Premium breathable cover, removable for easy wash and care.',
	},
	{
		icon: 'https://eightsleep.imgix.net/warrenty.png?v=1591879452',
		name: '5 Year Warranty',
		text: 'Guarantees high-quality construction that lasts.',
	},
]

export const cover2StandardFeatures: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: podProShopIcons.bed,
		name: 'Fits seamlessly onto your #depth mattress',
		text: `Installs in minutes onto any #depth mattress, staying snugly in place night after night`,
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const cover3Features: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: podProShopIcons.bed,
		name: 'Fits seamlessly onto your #depth mattress',
		text: `Installs in minutes onto any #depth mattress, staying snugly in place night after night`,
	},
	{
		icon: IconMap.bed_down,
		name: 'Premium comfort with Invisible Sensing',
		text: 'Exclusive to Pod 3, new Invisible Sensing design makes the Pod even more comfortable than ever before',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.wifi,
		name: 'Expanded Wi-Fi connectivity',
		text: '2.4GHz and 5GHz Wi-Fi support for better Wi-Fi stability',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const cover3PerfectFeatures: ProductFeaturesType[] = [
	{
		icon: podProShopIcons.dualzone,
		name: 'Individualized cooling & heating',
		text: `Set each side of the bed between #temp. Schedule personalized temperatures for each phase of the night`,
	},
	{
		icon: podProShopIcons.heartrate,
		name: 'Sleep and health tracking',
		text: 'Individual sleep and health tracking on each side of the bed including sleep stages, sleep time, heart rate, HRV, and more',
	},
	{
		icon: podProShopIcons.bed,
		name: 'Fits seamlessly onto any bed',
		text: `Installs in minutes onto any bed, staying snugly in place night after night`,
	},
	{
		icon: IconMap.bed_down,
		name: 'Premium comfort with Invisible Sensing',
		text: 'Exclusive to Pod 3, new Invisible Sensing design makes the Pod even more comfortable than ever before',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.gentlerise,
		name: 'GentleRise vibration alarm',
		text: 'Silently wakes up each individual with chest-level vibration and gradual thermal change',
	},
	{
		icon: podProShopIcons.wifi,
		name: 'Expanded Wi-Fi connectivity',
		text: '2.4GHz and 5GHz Wi-Fi support for better Wi-Fi stability',
		badgeText: 'NEW',
	},
	{
		icon: podProShopIcons.phone,
		name: 'iOS & Android App',
		text: 'Each sleeper can control all Pod features and review data from their own phone',
	},
	{
		icon: podProShopIcons.temptherm,
		name: 'Temperature Autopilot',
		text: 'Learns your ideal thermal environment and automatically adjusts to optimize your sleep',
	},
	{
		icon: podProShopIcons.sunrise,
		name: 'Digital Coaching',
		text: 'Get actionable feedback to build better habits for your sleep and health',
	},
	{
		icon: podProShopIcons.lines,
		name: 'Data Insights',
		text: 'Learn about factors impacting your sleep & health; get notified when anything is abnormal',
	},
	{
		icon: podProShopIcons.lines2,
		name: 'Sleep Content',
		text: 'Growing library of meditation & sleep sounds designed by experts to improve sleep',
	},
]

export const sheetsFeatures: ProductFeaturesType[] = [
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'A full set',
		text: 'Includes one fitted sheet, one flat sheet, and two pillow cases.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'Designed to the Pod dimensions',
		text: 'Made exclusively to the dimensions of the Eight Sleep Pod, making it a seamless sleep solution.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'Percale weave',
		text: 'Made with percale weave for smooth feel and high breathability.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: '300-thread count',
		text: 'Soft, moisture-wicking, and durable.',
	},
]

export const sheetsGrayFeatures: ProductFeaturesType[] = [
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'A full set',
		text: 'Includes one fitted sheet, one flat sheet, and two pillow cases.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'Designed to the Pod dimensions',
		text: 'Made exclusively to the dimensions of the Eight Sleep Pod, making it a seamless sleep solution.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: 'Eucalyptus fibers',
		text: 'Made of 100% Eucalyptus fibers for maximum breathability.',
	},
	{
		icon: 'https://eightsleep.imgix.net/check-24_f22066cf-af7f-4ab5-a217-cccadf79e6ca.png?v=1594409146',
		name: '300-thread count',
		text: 'Soft, moisture-wicking, and durable.',
	},
]

export const sleepCoachingMontlyFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: `Integration of data from your favorite devices`,
		text: 'Eight Sleep Pod, Whoop, Oura.',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Analysis of weekly metrics & trends',
		text: 'Key performance indicators in the context of your goals.',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Covers the most important areas of your health',
		text: 'Sleep, stress, cardiovascular, metabolism.',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Improve towards your goals',
		text: 'Personalized targets for each metrics related to your objectives.',
	},
]

export const canopyHumidifierFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: `Hydrates rooms up to 500 square feet`,
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Dishwasher safe',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: '2.5 liter tank, up to 36 hours of running time',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Sensors maintain optimal moisture day and night',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'USB-C power cord included',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: '3lbs (1.5kg), 10in x 7in x 8.5in',
		text: '',
	},
]

export const duvetCoverFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: `Hypoallergenic`,
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Thermoregulating',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Eco-friendly',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Buttery soft',
		text: '',
	},
]

export const hypervoltProFeatures: ProductFeaturesType[] = [
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: `Quiet Yet Powerful`,
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Intelligent Responsiveness',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: '5 Head Attachments to target muscle groups',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Sleep Pro Pick',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: '5 speed settings and a new digital speed dial',
		text: '',
	},
	{
		icon: accessoryDetailsIcons.circle_checkmark,
		name: 'Bluetooth connected to Hyperice App',
		text: '',
	},
]

export interface FeaturesStats {
	minMattressDepth: string
	maxMattressDepth: string
	minPodTemp: string
	maxPodTemp: string
}

export const coverPerfectImperial = {
	minMattressDepth: '10"',
	maxMattressDepth: '16"',
	minPodTemp: '55°F',
	maxPodTemp: '110°F',
}

export const coverImperial = {
	minMattressDepth: '10"',
	maxMattressDepth: '11"',
	minPodTemp: '55°F',
	maxPodTemp: '110°F',
}

export const coverPerfectMetric = {
	minMattressDepth: '25cm',
	maxMattressDepth: '40cm',
	minPodTemp: '12°C',
	maxPodTemp: '43°C',
}

export const coverMetric = {
	minMattressDepth: '25cm',
	maxMattressDepth: '28cm',
	minPodTemp: '12°C',
	maxPodTemp: '43°C',
}

export const getFeaturesWithDimensions = (dimens: FeaturesStats, features: ProductFeaturesType[]) => {
	const newFeatures = JSON.parse(JSON.stringify(features))

	newFeatures.forEach((element: ProductFeaturesType) => {
		element.text = element.text.replace('#depth', `${dimens.minMattressDepth} - ${dimens.maxMattressDepth}`).replace('#temp', `${dimens.minPodTemp} - ${dimens.maxPodTemp}`)
		if (element.name) element.name = element.name.replace('#depth', `${dimens.minMattressDepth} - ${dimens.maxMattressDepth}`).replace('#temp', `${dimens.minPodTemp} - ${dimens.maxPodTemp}`)
	})
	return newFeatures || []
}
