import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getEssentialBundleVisuals = (): VisualProps => {
	return {
		type: 'image',
		associatedSelectorKeys: ['essentials-bundle'],
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/essentials-sheets-new_pillow_update.png',
			alt: 'The Pod Cover with the Sleep Essentials Bundle',
		},
	}
}
