import React, { ReactNode, FC, MouseEventHandler } from 'react'
import { LoadingCircle, LoadingIconProps } from 'components/basic/LoadingCircle'
import styles from './Button.module.scss'
import cx from 'classnames'
import { amClickedButton } from 'events/amplitude'

export interface ButtonProps {
	content: string | ReactNode
	onClick?: MouseEventHandler
	type?: 'submit' | 'reset' | 'button'
	className?: string
	disabled?: boolean
	id?: string
	loadingIconProps?: LoadingIconProps
	usesLoading?: boolean
	ariaLabel?: string
	target?: string
	unstyled?: boolean
}

export const Button: FC<ButtonProps> = (props) => {
	const handleOnClick = (e: React.MouseEvent) => {
		if (typeof props.content === 'string') {
			amClickedButton(props.content, props.id)
		} else {
			amClickedButton('[children]', props.id)
		}

		if (props.onClick) {
			props.onClick(e)
		}
	}

	return (
		<button
			className={cx({ [styles.button]: !props.unstyled }, { [styles.button_reset]: props.unstyled }, props.className)}
			onClick={handleOnClick}
			disabled={props.disabled}
			id={props.id}
			type={props.type}
			aria-label={props.ariaLabel}
		>
			{props.usesLoading && props.loadingIconProps !== undefined ? <LoadingCircle {...props.loadingIconProps} /> : props.content}
		</button>
	)
}

Button.defaultProps = {
	type: 'button',
	usesLoading: false,
}
