import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M15.4104 15.629C17.2039 15.9952 19.0656 15.8265 20.7639 15.1438C20.0687 16.8732 18.8716 18.3551 17.3268 19.3987C15.782 20.4424 13.9601 21.0001 12.0956 21C9.92734 20.9977 7.82743 20.2414 6.15585 18.861C4.48426 17.4806 3.34511 15.5619 2.93366 13.4339C2.52222 11.3058 2.86412 9.10101 3.90074 7.19736C4.93737 5.29371 6.60416 3.80979 8.61538 3C7.93242 4.6977 7.76365 6.55862 8.13006 8.35141C8.49647 10.1442 9.3819 11.7898 10.6763 13.0837C11.9707 14.3776 13.6169 15.2627 15.4104 15.629Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
