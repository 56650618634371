import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M5.15888 4.0217C5.53702 3.52339 6.12654 3.23071 6.75208 3.23071H16.8322C17.4692 3.23071 18.0681 3.53414 18.4448 4.04775L20.2622 6.52515C20.5138 6.86823 20.6495 7.28263 20.6495 7.70811V19.0001C20.6495 20.1047 19.7541 21.0001 18.6495 21.0001H4.8584C3.75383 21.0001 2.8584 20.1047 2.8584 19.0001L2.8584 7.72615C2.8584 7.28955 3.00127 6.86494 3.2652 6.51714L5.15888 4.0217Z"
			stroke={color}
			strokeWidth="1.5"
		/>
		<path
			d="M3.33154 7.3847L20.8584 7.38471"
			stroke={color}
			strokeWidth="1.5"
		/>
		<line
			x1="11.5815"
			y1="7.3847"
			x2="11.5815"
			y2="3.00004"
			stroke={color}
			strokeWidth="1.5"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.1066 16.9452C13.1066 17.2219 12.8675 17.4462 12.5726 17.4462H11.0836C10.7888 17.4462 10.5498 17.2219 10.5498 16.9452V16.0155C10.5498 15.7389 10.7888 15.5146 11.0836 15.5146H12.5726C12.8675 15.5146 13.1066 15.7389 13.1066 16.0155V16.9452ZM10.5498 12.3057C10.5498 12.0289 10.7888 11.8048 11.0836 11.8048H12.5726C12.8675 11.8048 13.1066 12.0289 13.1066 12.3057V12.9784C13.1066 13.2552 12.8675 13.4794 12.5726 13.4794H11.0836C10.7888 13.4794 10.5498 13.2552 10.5498 12.9784V12.3057ZM14.6527 13.0334V11.9596C14.6527 11.1931 13.9913 10.5726 13.1743 10.5726H10.4786C9.66159 10.5726 9 11.1931 9 11.9596V13.0334C9 13.5981 9.35731 14.0791 9.86987 14.2964V14.4949C9.35731 14.7122 9 15.1962 9 15.7579V17.294C9 18.0605 9.66159 18.6812 10.4786 18.6812H13.1743C13.9913 18.6812 14.6527 18.0605 14.6527 17.294V15.7579C14.6527 15.1962 14.2956 14.7122 13.7828 14.4949V14.2964C14.2956 14.0791 14.6527 13.5951 14.6527 13.0334Z"
			fill={color}
		/>
	</svg>
)

export default icon
