import React, { FC, PropsWithChildren, useRef, useState } from 'react'
import styles from 'components/WebEv/Shop/Modals/Modals.module.scss'
import { useOnClickOutside } from 'components/_hooks/useOnClickOutside'
import { ScreenBreakPoint } from 'constants/index'
import useIsMobile from 'hooks/useIsMobile'
import { Icon } from 'components/Icon'
import { close } from 'components/Icon'

interface ModalWrapperProps extends PropsWithChildren {
	onClose: () => void
	active: boolean
}

export const ModalWrapper: FC<ModalWrapperProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null)
	const [isDragging, setIsDragging] = useState(false)
	const startDragPos = useRef(0)
	const isMobile = useIsMobile(ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL)

	const handleStartDrag = (e: React.PointerEvent<HTMLDivElement>) => {
		setIsDragging(true)
		if (isMobile) {
			startDragPos.current = e.clientY
		} else {
			startDragPos.current = e.clientX
		}
	}

	const handleEndDrag = (e: React.PointerEvent<HTMLDivElement>) => {
		// If the drag is more than 50px, close the modal
		const ePosition = isMobile ? e.clientY : e.clientX

		if (isDragging && ePosition - startDragPos.current > 50) {
			handleClose()
		} else {
			updateModalPosition(0, 0)
		}

		setIsDragging(false)
	}

	const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
		// If the drag is more than 50px, close the modal
		const touch = e.changedTouches[0]
		if (!touch) {
			updateModalPosition(0, 0)
			return
		}

		const touchPos = isMobile ? touch.clientY : touch.clientX

		if (isDragging && touchPos - startDragPos.current > 50) {
			handleClose()
		} else {
			updateModalPosition(0, 0)
		}

		setIsDragging(false)
	}

	const handleClose = () => {
		props.onClose()
		startDragPos.current = 0
		if (isMobile) {
			ref.current.style.transform = 'translate3d(0, 100%, 0)'
		} else {
			ref.current.style.transform = 'translate3d(100%, 0, 0)'
		}
	}

	useOnClickOutside(ref, () => {
		handleClose()
	})

	const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
		if (isDragging) {
			if (isMobile) {
				const y = e.clientY - startDragPos.current
				updateModalPosition(0, y)
			} else {
				const x = e.clientX - startDragPos.current
				updateModalPosition(x, 0)
			}
		}
	}

	const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
		if (isDragging) {
			if (isMobile) {
				const y = e.touches[0].clientY - startDragPos.current
				updateModalPosition(0, y)
			} else {
				const x = e.touches[0].clientX - startDragPos.current
				updateModalPosition(x, 0)
			}
		}
	}

	const updateModalPosition = (x: number, y: number) => {
		if (props.active) {
			ref.current.style.transform = `translate3d(${Math.max(0, x)}px, ${Math.max(0, y)}px, 0)`
		}
	}

	let transform = props.active && props.children ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'
	if (isMobile) {
		transform = props.active && props.children ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)'
	}

	return (
		<div
			className={styles.modal_outer}
			style={{
				backgroundColor: props.active ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
				pointerEvents: props.active ? 'all' : 'none',
				cursor: isDragging ? 'grabbing' : 'default',
			}}
			onPointerUp={handleEndDrag}
			onPointerMove={handlePointerMove}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			<div
				className={styles.modal_wrapper}
				ref={ref}
				style={{
					transform: transform,
					opacity: props.active ? 1 : 0,
					boxShadow: props.active ? 'rgba(0, 0, 0, 0.5) 0 0 5rem 0' : 'none',
					transition: isDragging
						? 'none'
						: props.active
						? 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out'
						: 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, opacity 0.5s cubic-bezier(0.7, 0, 0.84, 0)',
					userSelect: isDragging ? 'none' : 'auto',
				}}
			>
				<button
					onClick={handleClose}
					className={styles.close_button}
				>
					<Icon
						icon={close}
						color={'black'}
					/>
				</button>
				<div
					className={styles.handle_outer}
					style={{
						cursor: isDragging ? 'grabbing' : 'grab',
					}}
					onPointerDown={handleStartDrag}
				>
					<div />
				</div>
				{props.children}
			</div>
		</div>
	)
}
