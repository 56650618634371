import React from 'react'
import styles from 'components/WebEv/Shop/Modals/Modals.module.scss'
import { bed, dual_temp, heart_report, Icon, trending_up, vibration } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'

export const ModelModal = () => {
	return (
		<div className={styles.modal_inner}>
			<h2 className={styles.title}>Which model is right for you?</h2>
			<ul className={styles.models}>
				{items.map((item) => (
					<li
						key={item.title}
						className={styles.model_info}
					>
						{item.badge && <em>{item.badge}</em>}
						<h3>{item.title}</h3>
						<ul className={styles.features_list}>
							{item.items.map((i, index) => (
								<li
									key={index}
									className={styles.feature}
								>
									<Icon
										icon={i.icon}
										color={Colors.Neutral.black.hex}
									/>
									<p>{i.description}</p>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</div>
	)
}

const items = [
	{
		title: 'Pod 3 Cover with PerfectFit',
		badge: 'Popular',
		items: [
			{
				icon: bed,
				description: 'Fits on all beds',
			},
			{
				icon: dual_temp,
				description: 'Individualized cooling & heating',
			},
			{
				icon: heart_report,
				description: 'Sleep & health tracking',
			},
			{
				icon: vibration,
				description: 'Vibration & thermal alarm',
			},
			{
				icon: trending_up,
				description: 'Improved comfort',
			},
		],
	},

	{
		title: 'Pod 3 Cover',
		items: [
			{
				icon: bed,
				description: 'Fits on 10"-11"',
			},
			{
				icon: dual_temp,
				description: 'Individualized cooling & heating',
			},
			{
				icon: heart_report,
				description: 'Sleep & health tracking',
			},
			{
				icon: vibration,
				description: 'Vibration & thermal alarm',
			},
			{
				icon: trending_up,
				description: 'Improved comfort',
			},
		],
	},
	{
		title: 'Pod 2 Cover',
		items: [
			{
				icon: bed,
				description: 'Fits on 10"-11"',
			},
			{
				icon: dual_temp,
				description: 'Individualized cooling & heating',
			},
			{
				icon: heart_report,
				description: 'Sleep & health tracking',
			},
			{
				icon: vibration,
				description: 'Vibration & thermal alarm',
			},
		],
	},
]
