/* eslint-disable @next/next/no-img-element */
import React, { FC, useState } from 'react'
import cx from 'classnames'
import styles from './MattressLayers.module.scss'
import { HP } from 'components/basic/HP/HP'
import { ClassOnScroll } from 'components/AnimateOnScroll/AnimateOnScroll'
import { Waypoint } from 'react-waypoint'
import { mattressLayersFeatureIcons } from 'constants/icons'
import { Img, Source } from 'components/basic/Img'
import { IconMap } from 'components/_const_assets/icons'

const PremiumMattressImageSet: Source[] = [
	{ src: 'https://eightsleep.imgix.net/modal_desktop.png?v=1687285173', mediaQuery: '(min-width: 1024px)', dprHeight: 600 },
	{ src: 'https://eightsleep.imgix.net/modal_mobile.png?v=1687285445', mediaQuery: '(max-width: 1023px)', dprHeight: 320 },
]

const PodProMaxContent = () => (
	<ClassOnScroll
		className={styles.layers_overlay}
		enterClass={styles.layer_reveal}
		delay={'1s'}
	>
		<Img
			alt="Mattress Layers"
			lazy={false}
			sources={PremiumMattressImageSet}
			src={PremiumMattressImageSet[0].src}
			className={styles.layers_ab}
			dprHeight={600}
		/>
	</ClassOnScroll>
)

export const MattressLayers: FC = () => {
	const [active, setActive] = useState(false)
	return (
		<div className={styles.container}>
			<HP
				title={'A supportive mattress to compliment cooler sleep'}
				type={'h2'}
				titleClass={styles.h2_ab}
				paragraphs={[
					'Our 5-layer mattress is designed to work with your Pod’s technology, elevating your comfort. The medium-firmness supports you, while the advanced cooling gel layer works with your Pod to keep you even cooler all night long.',
				]}
				paragraphClass={styles.description_ab}
			/>
			<div className={styles.features_ab}>
				<div>
					<img
						src={mattressLayersFeatureIcons.spinal_support}
						alt="Spinal support icon"
					/>
					<span>Medium firmness with spinal support</span>
				</div>
				<div>
					<img
						src={IconMap.mattress}
						alt={'Mattress'}
					/>
					<span>11" height</span>
				</div>
				<div>
					<img
						src={mattressLayersFeatureIcons.pressure}
						alt="Spinal support icon"
					/>
					<span>Pressure relieving materials</span>
				</div>
			</div>
			<Waypoint onEnter={() => setActive(true)} />
			<div className={cx(styles.wrapper, active ? styles.is_active : '')}>
				<PodProMaxContent />
			</div>
		</div>
	)
}
