import { POD_LANDING_URL_STRIPPED } from 'constants/index'

export enum PageViewedType {
	POD_LANDING_PAGE = 'POD_LANDING_PAGE',
	REVIEWS = 'REVIEWS',
}

export interface PageViewedPage {
	type: PageViewedType
	timeMs: number
	pages: string[]
	eventName: string
}

export const pageViewedPages: PageViewedPage[] = [
	{
		type: PageViewedType.POD_LANDING_PAGE,
		timeMs: 20000,
		pages: [
			'/' + POD_LANDING_URL_STRIPPED,
			'/podlanding',
			'/how-it-works',
			'/reviews',
			'/financing',
			// '/eight-plus-membership',
			'/sleep-science',
		],
		eventName: '20sProductPageView',
	},
	{
		type: PageViewedType.REVIEWS,
		timeMs: 10000,
		pages: ['/reviews'],
		eventName: '10sReviews',
	},
]

export enum AffirmLocations {
	POD_LANDING = 'Pod Landing',
	POD_SHOP = 'Pod Shop',
	CART = 'Cart',
	CHECKOUT_PAYMENT = 'Checkout Payment',
	OTHER = 'Other',
}

export enum PressRowInteractionSurface {
	ARTICLE = 'article',
	QUOTE = 'quote',
}

export enum HomePageHeroCTAType {
	VIDEO = 'video',
	POD_LANDING = 'pod_landing',
	POD_LANDING_SALE = 'pod_landing_sale',
}

export enum BundleInteractionType {
	SELECT = 'Select',
	DESELECT = 'Deselect',
}

export enum ProactiveInteractionType {
	VIEWED = 'Viewed',
	CLICKED = 'Clicked',
}

// key is query param and key is dimension# in GA admin
export const customQueryParamsToTrackInGA = {
	variation: 'dimension6',
	type: 'dimension5',
	targetaudience: 'dimension7',
}
