import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.7185 14.8726C19.0231 15.5541 17.1647 15.7225 15.3744 15.3569C13.584 14.9912 11.9406 14.1077 10.6485 12.8161C9.3564 11.5245 8.47252 9.88171 8.10675 8.09205C7.74098 6.30239 7.90945 4.44473 8.59123 2.75C6.58352 3.55838 4.91965 5.03969 3.88484 6.94001C2.85003 8.84033 2.50873 11.0413 2.91945 13.1656C3.33017 15.2899 4.46733 17.2052 6.13599 18.5833C7.80466 19.9613 9.90089 20.7162 12.0653 20.7185C13.9266 20.7186 15.7453 20.1619 17.2874 19.12C18.8295 18.0782 20.0245 16.5989 20.7185 14.8726ZM13.6929 5.95715C14.3024 5.34766 15.129 5.00525 15.991 5.00525C16.8529 5.00525 17.6796 5.34766 18.2891 5.95715C18.8986 6.56665 19.241 7.3933 19.241 8.25525C19.241 8.68205 19.1569 9.10466 18.9936 9.49897C18.8303 9.89328 18.5909 10.2516 18.2891 10.5533C17.9873 10.8551 17.629 11.0945 17.2347 11.2579C16.8404 11.4212 16.4178 11.5052 15.991 11.5052C15.5642 11.5052 15.1416 11.4212 14.7473 11.2579C14.353 11.0945 13.9947 10.8551 13.6929 10.5533C13.3911 10.2516 13.1517 9.89328 12.9884 9.49897C12.825 9.10466 12.741 8.68205 12.741 8.25525C12.741 7.3933 13.0834 6.56665 13.6929 5.95715ZM15.991 3.50525C14.7312 3.50525 13.523 4.0057 12.6322 4.89649C11.7414 5.78729 11.241 6.99547 11.241 8.25525C11.241 8.87903 11.3638 9.4967 11.6026 10.073C11.8413 10.6493 12.1911 11.1729 12.6322 11.614C13.0733 12.0551 13.5969 12.405 14.1732 12.6437C14.7495 12.8824 15.3672 13.0052 15.991 13.0052C16.6148 13.0052 17.2324 12.8824 17.8087 12.6437C18.385 12.405 18.9087 12.0551 19.3497 11.614C19.7908 11.1729 20.1407 10.6493 20.3794 10.073C20.6181 9.4967 20.741 8.87903 20.741 8.25525C20.741 6.99547 20.2405 5.78729 19.3497 4.89649C18.4589 4.0057 17.2508 3.50525 15.991 3.50525ZM16.741 6.47747C16.741 6.06326 16.4052 5.72747 15.991 5.72747C15.5768 5.72747 15.241 6.06326 15.241 6.47747V8.25525C15.241 8.45416 15.32 8.64493 15.4607 8.78558L16.794 10.1189C17.0869 10.4118 17.5618 10.4118 17.8546 10.1189C18.1475 9.82602 18.1475 9.35115 17.8546 9.05825L16.741 7.94459V6.47747Z"
			fill={color}
		/>
	</svg>
)

export default icon
