import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from './index'

export default class NavStore {
	constructor(private readonly rootStore: RootStore) {
		makeObservable(this)
	}

	@observable stickyAtcVisible = false
	@observable route = '/'
	@observable collapsed = false

	@computed get navStoreDump() {
		return {
			stickyAtcVisible: this.stickyAtcVisible,
			route: this.route,
			collapsed: this.collapsed,
		}
	}

	@action setStickyAtcVisible = (show: boolean) => {
		this.stickyAtcVisible = show
	}
	@action setRoute = () => {
		this.route = window.location.pathname
	}
	@action toggle = (b: boolean | undefined = undefined) => {
		this.collapsed = b ?? !this.collapsed
	}
}
