import React, { FC } from 'react'
import styles from './ProductVariantSelect.module.scss'
import { ProductSelectFields } from 'products/types'

interface ProductPropsCalculated extends ProductSelectFields {
	isChecked: boolean
	index: number
	onCheck: (index: number) => void
}

export interface ModelSelectProps {
	labels: ProductSelectFields[]
	legendLabel: string
	selectedModel: number
	onCheck: (index: number) => void
}

export const ProductVariantSelect: FC<ModelSelectProps> = (props) => {
	return (
		<form>
			<fieldset
				className={`${styles.fieldset}`}
				tabIndex={0}
			>
				<legend className="vh">{props.legendLabel}</legend>
				{props.labels.map((product, index) => (
					<ModelInput
						{...product}
						isChecked={props.selectedModel === index}
						index={index}
						key={index}
						onCheck={props.onCheck}
					/>
				))}
			</fieldset>
		</form>
	)
}

const ModelInput: FC<ProductPropsCalculated> = (props) => {
	function handleChange() {
		props.onCheck(props.index)
	}
	return (
		<>
			<input
				className="vh"
				type="radio"
				id={props.id}
				name="productModelInput"
				checked={props.isChecked}
				onChange={handleChange}
			/>
			<label
				htmlFor={props.id}
				className={styles.label}
			>
				<span
					className={styles.title}
					dangerouslySetInnerHTML={{ __html: props.title }}
				></span>
			</label>
		</>
	)
}
