import { mediaQuery, minWidth, maxWidth, betweenWidths } from '../utils/jss'

export const SITE_DOMAIN = 'https://www.eightsleep.com'
export const SHOPIFY_SITE_DOMAIN = 'site.eightsleep.com'

export const SHOPIFY_CHECKOUT_DOMAIN = 'eight-sleep.myshopify.com'
export const SHOPIFY_STOREFRONT_TOKEN = '077f9d45a36a1f011449bd7ec1e5af41'
export const SHOPIFY_GRAPHQL_URL = 'https://site.eightsleep.com/api/2021-10/graphql.json'
export const RECHARGE_API_CREATE_URL = 'https://mqs408dwkc.execute-api.us-east-1.amazonaws.com/dev/checkout/create'
export const RECHARGE_API_UPDATE_URL = 'https://ca20lcgb78.execute-api.us-east-1.amazonaws.com/default/recharge_checkout_update'

export const RECHARGE_CHECKOUT_DOMAIN = 'pay.eightsleep.com'
// export const AWS_PRODUCT_PRICES_URL = 'https://eight-eightsleep.s3.amazonaws.com/products.json'
export const AWS_PRODUCT_PRICES_URL = 'https://shopify-prices-bucket.s3.amazonaws.com/products-2022.json'

export const MEDIA_QUERY_EXTRA_SMALL_ONLY = 'forXsOnly'
export const MEDIA_QUERY_EXTRA_SMALL_UP = 'forXsUp'
export const MEDIA_QUERY_SMALL_ONLY = 'forSmOnly'
export const MEDIA_QUERY_SMALL_UP = 'forSmUp'
export const MEDIA_QUERY_MEDIUM_ONLY = 'forMdOnly'
export const MEDIA_QUERY_MEDIUM_UP = 'forMdUp'
export const MEDIA_QUERY_LARGE_ONLY = 'forLgOnly'
export const MEDIA_QUERY_LARGE_UP = 'forLgUp'
export const MEDIA_QUERY_EXTRA_LARGE_ONLY = 'forXlOnly'
export const MEDIA_QUERY_EXTRA_LARGE_UP = 'forXlUp'
export const MEDIA_QUERY_SMALL_DOWN = 'forSmDown'
export const MEDIA_EXTRA_SMALL_ONLY = 'XsOnly'
export const MEDIA_EXTRA_SMALL_UP = 'XsUp'
export const MEDIA_SMALL_ONLY = 'SmOnly'
export const MEDIA_SMALL_UP = 'SmUp'
export const MEDIA_MEDIUM_ONLY = 'MdOnly'
export const MEDIA_MEDIUM_UP = 'MdUp'
export const MEDIA_LARGE_ONLY = 'LgOnly'
export const MEDIA_LARGE_UP = 'LgUp'
export const MEDIA_EXTRA_LARGE_ONLY = 'XlOnly'
export const MEDIA_EXTRA_LARGE_UP = 'XlUp'
export const MEDIA_SMALL_DOWN = 'SmDown'

export interface MediaQueryType {
	[MEDIA_QUERY_EXTRA_SMALL_ONLY]: string
	[MEDIA_QUERY_EXTRA_SMALL_UP]: string
	[MEDIA_QUERY_SMALL_ONLY]: string
	[MEDIA_QUERY_SMALL_UP]: string

	[MEDIA_QUERY_MEDIUM_ONLY]: string
	[MEDIA_QUERY_MEDIUM_UP]: string
	[MEDIA_QUERY_LARGE_ONLY]: string
	[MEDIA_QUERY_LARGE_UP]: string
	[MEDIA_QUERY_EXTRA_LARGE_ONLY]: string
	[MEDIA_QUERY_EXTRA_LARGE_UP]: string
	[MEDIA_QUERY_SMALL_DOWN]: string

	[MEDIA_EXTRA_SMALL_ONLY]: string
	[MEDIA_EXTRA_SMALL_UP]: string
	[MEDIA_SMALL_ONLY]: string
	[MEDIA_SMALL_UP]: string
	[MEDIA_MEDIUM_ONLY]: string
	[MEDIA_MEDIUM_UP]: string
	[MEDIA_LARGE_ONLY]: string
	[MEDIA_LARGE_UP]: string
	[MEDIA_EXTRA_LARGE_ONLY]: string
	[MEDIA_EXTRA_LARGE_UP]: string
	[MEDIA_SMALL_DOWN]: string

	min: (arg0: string) => string
	max: (arg0: string) => string
	minmax: (arg0: string, arg1: string) => string
}

type ScreenBreakPointType = {
	SCREEN_BREAKPOINT_SMALL: number
	SCREEN_BREAKPOINT_MEDIUM: number
	SCREEN_BREAKPOINT_LARGE: number
	SCREEN_BREAKPOINT_EXTRA_LARGE: number
	sm: number
	md: number
	lg: number
	xl: number
}

export const ScreenBreakPoint: ScreenBreakPointType = {
	SCREEN_BREAKPOINT_SMALL: 600,
	SCREEN_BREAKPOINT_MEDIUM: 1024,
	SCREEN_BREAKPOINT_LARGE: 1440,
	SCREEN_BREAKPOINT_EXTRA_LARGE: 1920,
	sm: 600,
	md: 1024,
	lg: 1440,
	xl: 1920,
}

export const MediaQuery: MediaQueryType = {
	[MEDIA_QUERY_EXTRA_SMALL_ONLY]: mediaQuery({
		width: { max: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL - 1 },
	}).css,
	[MEDIA_QUERY_EXTRA_SMALL_UP]: mediaQuery({ width: { min: 0 } }).css,
	[MEDIA_QUERY_SMALL_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL, max: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM - 1 },
	}).css,
	[MEDIA_QUERY_SMALL_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL },
	}).css,
	[MEDIA_QUERY_MEDIUM_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM, max: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE - 1 },
	}).css,
	[MEDIA_QUERY_MEDIUM_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM },
	}).css,
	[MEDIA_QUERY_LARGE_ONLY]: mediaQuery({
		width: {
			min: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE,
			max: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE - 1,
		},
	}).css,
	[MEDIA_QUERY_LARGE_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE },
	}).css,
	[MEDIA_QUERY_EXTRA_LARGE_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE },
	}).css,
	[MEDIA_QUERY_EXTRA_LARGE_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE },
	}).css,
	[MEDIA_QUERY_SMALL_DOWN]: mediaQuery({
		width: { max: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM - 1 },
	}).css,
	[MEDIA_EXTRA_SMALL_ONLY]: mediaQuery({
		width: { max: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL - 1 },
	}).media,
	[MEDIA_EXTRA_SMALL_UP]: mediaQuery({ width: { min: 0 } }).media,
	[MEDIA_SMALL_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL, max: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM - 1 },
	}).media,
	[MEDIA_SMALL_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_SMALL },
	}).media,
	[MEDIA_MEDIUM_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM, max: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE - 1 },
	}).media,
	[MEDIA_MEDIUM_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM },
	}).media,
	[MEDIA_LARGE_ONLY]: mediaQuery({
		width: {
			min: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE,
			max: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE - 1,
		},
	}).media,
	[MEDIA_LARGE_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_LARGE },
	}).media,
	[MEDIA_EXTRA_LARGE_ONLY]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE },
	}).media,
	[MEDIA_EXTRA_LARGE_UP]: mediaQuery({
		width: { min: ScreenBreakPoint.SCREEN_BREAKPOINT_EXTRA_LARGE },
	}).media,
	[MEDIA_SMALL_DOWN]: mediaQuery({
		width: { max: ScreenBreakPoint.SCREEN_BREAKPOINT_MEDIUM - 1 },
	}).media,
	min: minWidth,
	max: maxWidth,
	minmax: betweenWidths,
}

export enum ZIndexType {
	HEADER = 300,
	KUSTOMER = 900,
	SITE_OVERLAY = 1000,
	CART = 1100,
	LOADING = 1200,
	MODAL = 1300,
}

export const Palette = {
	Primary: {
		Sky: '#000',
		Luna: '#ffffff',
		MilkyWay: '#1700c3',
		SpaceDust: '#E5E5EC',
	},
	Secondary: {
		Invigoration: '#41c880',
		Tahoe: '#05b1fe',
		Charge: '#ffc828',
		OJ: '#ff8142',
		Vigor: '#ff3e94',
		LiquidDream: '#64ffee',
	},
	Extended: {
		Neutrals: {
			N01: '#000000',
			N02: '#454361',
			N03: '#75748b',
			N04: '#a9a7be',
			N05: '#bfbecc',
			N06: '#e5e5ec',
			N07: '#faf7f7',
			N08: '#ffffff',
		},
		Purples: {
			P01: '#07051f',
			P02: '#140044',
			P03: '#1b005d',
			P04: '#180194',
			P05: '#1700c3',
			P06: '#543ff0',
			P07: '#7a77cf',
			P08: '#b6b4e6',
		},
		Blues: {
			B01: '#004868',
			B02: '#00618d',
			B03: '#0078ae',
			B04: '#0094d7',
			B05: '#05b1fe',
			B06: '#05B1FE',
			B07: '#69ddff',
			B08: '#a2e2ff',
		},
		Teals: {
			T01: '#004740',
			T02: '#108074',
			T03: '#22a698',
			T04: '#3ddecc',
			T05: '#64ffee',
			T06: '#a9fff6',
			T07: '#e5fffc',
			T08: '#effaff',
		},
		Greens: {
			G01: '#104c2c',
			G02: '#2b744d',
			G03: '#319560',
			G04: '#10a957',
			G05: '#41c880',
			G06: '#6adc9f',
			G07: '#8aeeb9',
			G08: '#d0f8e3',
		},
		Yellows: {
			Y01: '#493d1a',
			Y02: '#927930',
			Y03: '#b99735',
			Y04: '#ddb02d',
			Y05: '#ffc828',
			Y06: '#ffe535',
			Y07: '#fff08b',
			Y08: '#fff6bc',
		},
		Oranges: {
			O01: '#4f1a00',
			O02: '#993300',
			O03: '#c14a0f',
			O04: '#e56d30',
			O05: '#ff8142',
			O06: '#ffa77b',
			O07: '#ffc3a4',
			O08: '#ffe6da',
		},
		Reds: {
			R01: '#5a0028',
			R02: '#860941',
			R03: '#b6195f',
			R04: '#dd2e7c',
			R05: '#ff3e94',
			R06: '#ff76b3',
			R07: '#ff9cc8',
			R08: '#ffcae2',
		},
	},
}

export const Palette2019 = {
	Primary: {
		DarkGray: '#24272A',
		White: '#FFFFFF',
		Blue: '#5289FE',
		BluePrimary: '#002173',
	},
	Secondary: {
		Black: '#000',
		MediumGray: '#474951',
		LightGray: '#CDCDD3',
		LighterGray: '#F6f6F6',
		IconGray: '#78797F',
	},
}

export const GUTTER = 30
export const PAGE_MARGIN_NUM = 76
export const PAGE_MARGIN = `${PAGE_MARGIN_NUM}px`

export const MEDIUM_MARGIN_NUM = PAGE_MARGIN_NUM / 2
export const MEDIUM_MARGIN = `${PAGE_MARGIN_NUM / 2}px`

export const MOBILE_MARGIN_NUM = 24
export const MOBILE_MARGIN = `${MOBILE_MARGIN_NUM}px`

export const HEADER_HEIGHT = 55
export const OFFER_BAR_HEIGHT = 46

export const GOOGLE_ANALYTICS_ID = 'UA-56341084-1'
export const GOOGLE_MEASUREMENT_ID = 'G-3NHGHSDTKZ'

export const HIDE_OFFER_PAGES: string[] = [
	'', // Index page
	'eight-pod-sleep-cool',
	'pod-mattress',
	'coming-soon',
	'coming-soon-sf',
	'coming-soon-ny',
	'about',
	'alexa',
	'exit-intent-demo',
	'interactive-image',
	'new-sections-added',
	'privacy-policy',
	'product',
	'return-policy',
	'section-guide',
	'warranty',
]

export const UTM_COOKIE_TIME = 30 * 24 * 60 * 60 // time in seconds

export const POD_LANDING_URL_STRIPPED = 'pod-mattress'

export const SKIP_MEMBERSHIP_CHECKOUT_KEY = 'allowMembershipSkipFromFAQ'
