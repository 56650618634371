import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_4858)">
			<path
				d="M12.4142 20.0586H20.887"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.6506 4.52509C17.432 3.74371 18.6935 3.74371 19.4749 4.52509C20.2563 5.30647 20.2563 6.56798 19.4749 7.34936L7.7071 19.1171L3.94141 20.0586L4.88283 16.2929L16.6506 4.52509Z"
				fill={color}
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_4858">
				<rect
					width="18.8285"
					height="18"
					fill={color}
					transform="translate(3 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
