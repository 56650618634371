import React, { FC, ReactNode } from 'react'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import { NextLinkHref } from 'constants/types'
import { formatLink } from 'utils'
import { Url } from 'url'
import { amClickedButton } from 'events/amplitude'
import { PREFETCH_DISABLE_LIST } from 'config/preload'

type Rel = 'alternate' | 'author' | 'bookmark' | 'external' | 'help' | 'license' | 'next' | 'nofollow' | 'noreferrer' | 'noopener' | 'prev' | 'search' | 'tag'

export interface LinkProps {
	href: string | Url | NextLinkHref
	children: ReactNode
	target?: string
	className?: string
	rel?: Rel
	onClick?: () => void
	id: string
	role?: string
	download?: boolean
	ariaLabel?: string
}

export const A: FC<LinkProps> = (props) => {
	const href = typeof props.href === 'string' ? formatLink(props.href) : props.href

	const aClickHandler = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		const analyticsName = href.toString()

		if (typeof props.onClick === 'function') {
			if (analyticsName === '#') event.preventDefault()
			props.onClick()
		}

		const analyticsID = props.id || 'unknown_id'
		amClickedButton(analyticsName, analyticsID)
	}

	if (href.toString().startsWith('#')) {
		return (
			<a
				href={href.toString()}
				id={props.id}
				className={props.className}
				target={props.target}
				rel={props.target === '_blank' ? 'noopener' : props.rel}
				onClick={aClickHandler}
				role={props.role}
				download={props.download || false}
				aria-label={props.ariaLabel}
			>
				{props.children}
			</a>
		)
	}

	return (
		<Link
			href={href}
			passHref
			prefetch={PREFETCH_DISABLE_LIST.includes(href.toString()) ? false : undefined}
		>
			<a
				id={props.id}
				className={props.className}
				target={props.target}
				rel={props.target === '_blank' ? 'noopener' : props.rel}
				onClick={aClickHandler}
				role={props.role}
				download={props.download || false}
				aria-label={props.ariaLabel}
			>
				{props.children}
			</a>
		</Link>
	)
}
