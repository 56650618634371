export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const addWeekdaysFromNow = (days) => {
	try {
		const result = new Date()
		for (let i = 0; i < days; i++) {
			result.setDate(result.getDate() + 1) // add 1 day to the current date
			// skip weekends
			if (result.getDay() === 6) {
				// saturday
				result.setDate(result.getDate() + 2)
			}
			if (result.getDay() === 0) {
				// sunday
				result.setDate(result.getDate() + 1)
			}
		}
		sessionStorage.setItem('deliverby', result.toISOString().split('T')[0])
		return `${months[result.getMonth()]} ${result.getDate()}`
	} catch (error) {
		console.log('addWeekdaysFromNow', error)
	}
	return 'undefined'
}

export const isCustomerSupportOnline = () => {
	// our CS hours are M-F 12PM  to 12AM (EST) Sa/Sun: 2pm-12am (EST)
	const d = new Date()
	const hour = d.getUTCHours()
	const day = d.getUTCDay() // sunday is 0
	let businessOpen = false
	if ((day === 6 && hour >= 18) || (day === 0 && hour < 4)) {
		// saturday 2pm (18:00 utc) to rest of UTC day (up to 8pm EST) || sunday 8pm to 12am EST (UTC midnight to 4am)
		businessOpen = true
	}
	if ((day === 0 && hour >= 18) || (day === 1 && hour < 4)) {
		// sunday 2pm to 8pm EST (UTC midnight) || sunday 8pm to 12am EST (UTC midnight to monday 4am)
		businessOpen = true
	}
	if ((day === 1 && hour >= 16) || (day === 2 && hour < 4)) {
		// monday at 12pm - 12am
		businessOpen = true
	}
	if ((day === 2 && hour >= 16) || (day === 3 && hour < 4)) {
		// tuesday at 12pm - 12am
		businessOpen = true
	}
	if ((day === 3 && hour >= 16) || (day === 4 && hour < 4)) {
		// wednesday at 12pm - 12am
		businessOpen = true
	}
	if ((day === 4 && hour >= 16) || (day === 5 && hour < 4)) {
		// thursday at 12pm - 12am
		businessOpen = true
	}
	if ((day === 5 && hour >= 16) || (day === 6 && hour < 4)) {
		// friday at 12pm - 12am
		businessOpen = true
	}
	return businessOpen
}
