import { Cart, LineItem, LineItemByExistingId, CartLineItem, CountryCode } from './types'
import { CartManager } from './cartManager'
import { amAppCheckoutError } from 'events/amplitude'
import { getProductByVariantId, isMembershipVariantId } from 'products/utils'

export interface StoreFront {
	getCart: (countryCode: CountryCode) => Cart
	cartCreate: (lineItems: LineItem[], countryCode: CountryCode) => Cart
	cartAddLineItems: (cartId: string, lineItems: LineItem[], countryCode: CountryCode) => Cart
	cartDecreaseLineItems: (cart: Cart, lineItems: LineItem[], countryCode: CountryCode) => Cart
	cartRemoveLineItems: (lineItems: CartLineItem[]) => Cart
	cartUpdateLineItems: (lineItemIds: LineItemByExistingId[], countryCode: CountryCode) => Cart
	cartUpdateDiscount: (cartId: string, discountCode: string) => Cart
	setLocalCart: (cart: Cart) => void
}

export class MainStoreFront implements StoreFront {
	constructor() {}

	public cartCreate(lineItems: LineItem[], countryCode: CountryCode = 'US') {
		try {
			const newCart: Cart = {
				id: this.getCartId(countryCode),
				lineItems: lineItems,
				createdAt: new Date().toISOString(),
			}

			this.setLocalCart(newCart)

			return newCart
		} catch (e) {
			console.log(e)
			amAppCheckoutError(`Error creating cart: ${e}`)
			return CartManager.ZeroCart
		}
	}

	public getCartId(countryCode: CountryCode = 'US') {
		return countryCode === 'US' ? 'cart' : 'cart' + countryCode
	}

	public getLocalCart(countryCode: CountryCode = 'US') {
		const cartID = this.getCartId(countryCode)
		const localCart = window.localStorage.getItem(cartID)

		if (localCart) {
			const cart = JSON.parse(localCart) as Cart

			if (cart.lineItems.length === 0) return CartManager.ZeroCart

			const missingItem = cart.lineItems.find((it) => it.variantId === undefined || getProductByVariantId(it.variantId as number) == null)
			if (missingItem) return CartManager.ZeroCart
			
			const membershipItems = cart.lineItems.filter((it) => isMembershipVariantId(it.variantId.toString()))
			if (membershipItems.length > 0 && membershipItems.length === cart.lineItems.length) return CartManager.ZeroCart
			
			return cart
		}

		return CartManager.ZeroCart
	}

	public setLocalCart(cart: Cart) {
		window.localStorage.setItem(cart.id, JSON.stringify(cart))
	}

	public getCart(countryCode: CountryCode = 'US') {
		return this.getLocalCart(countryCode)
	}

	public cartAddLineItems(cartId: string, lineItems: LineItem[], countryCode: CountryCode = 'US') {
		try {
			const localCart = this.getLocalCart(countryCode)
			localCart.lineItems = localCart.lineItems.concat(lineItems)
			this.setLocalCart(localCart)
			return localCart
		} catch (e) {
			amAppCheckoutError(`Error adding to cart: ${e}`)
			return CartManager.ZeroCart
		}
	}

	public cartRemoveLineItems(lineItems: CartLineItem[], countryCode: CountryCode = 'US') {
		try {
			const lineItemIds = lineItems.map((i) => i.id)
			const localCart = this.getLocalCart(countryCode)
			localCart.lineItems = localCart.lineItems.filter((li) => !lineItemIds.includes(li.id))
			this.setLocalCart(localCart)
			return localCart
		} catch (e) {
			return CartManager.ZeroCart
		}
	}

	public cartDecreaseLineItems(cart: Cart, lineItems: LineItem[], countryCode: CountryCode = 'US') {
		try {
			const localCart = this.getLocalCart(countryCode)
			for (const item of lineItems) {
				const quantityToRemove = item.quantity
				const itemInCart = localCart.lineItems.find((cartItem) => cartItem.variantId === item.variantId)
				if (itemInCart) {
					let quantity = itemInCart.quantity - quantityToRemove
					if (quantity < 0) {
						quantity = 0
					}
					itemInCart.quantity = quantity
				}
			}

			// remove items with quantity 0
			localCart.lineItems = localCart.lineItems.filter((li) => li.quantity > 0)

			this.setLocalCart(localCart)
			return localCart
		} catch (e) {
			return CartManager.ZeroCart
		}
	}

	public cartUpdateLineItems(lineItems: LineItemByExistingId[], countryCode: CountryCode = 'US') {
		const localCart = this.getLocalCart(countryCode)
		for (const item of lineItems) {
			const itemInCart = localCart.lineItems.find((cartItem) => cartItem.id === item.id || cartItem.variantId.toString() === item.id)
			if (itemInCart) {
				itemInCart.quantity = item.quantity
				itemInCart.attributes = item.attributes
				itemInCart.id = item.id
			}
		}

		// remove items with quantity 0
		localCart.lineItems = localCart.lineItems.filter((li) => li.quantity > 0)

		return localCart
	}

	public cartUpdateDiscount(cartId: string, discount: string) {
		// TODO: this assumes that cartId is correct, check if that's true

		// Also this function is practically useless once the migration
		// is complete, so it's not worth spending time on

		const localCart = window.localStorage.getItem(cartId)
		return JSON.parse(localCart) as Cart
	}
}
