import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M19.506 12.5909C19.506 11.175 19.066 9.79084 18.2416 8.61353C17.4172 7.43622 16.2454 6.51863 14.8745 5.97677C13.5035 5.43492 11.995 5.29314 10.5396 5.56938C9.08419 5.84561 7.74733 6.52745 6.69805 7.52867C5.64878 8.52988 4.93421 9.80551 4.64472 11.1942C4.35522 12.583 4.5038 14.0224 5.07167 15.3306C5.63953 16.6387 6.60117 17.7568 7.83499 18.5435C9.06881 19.3301 10.5194 19.75 12.0033 19.75C13.9931 19.75 15.9015 18.9957 17.3085 17.6532C18.7155 16.3106 19.506 14.4896 19.506 12.5909ZM6.65993 4.50158C6.26622 4.18011 5.76573 4.00237 5.24754 4L5.11672 4.00358C3.91862 4.07249 2.99673 5.07386 3.00001 6.28196C3.00001 6.87303 3.21853 7.14866 3.51019 7.50035C3.52831 7.52295 3.55119 7.54167 3.57736 7.55531C3.60353 7.56895 3.63241 7.57721 3.66212 7.57955H3.70339C3.72662 7.57915 3.74945 7.57366 3.77011 7.56349C3.79076 7.55333 3.80869 7.53876 3.8225 7.52093L6.67213 4.85014C6.69646 4.82666 6.71547 4.79866 6.72797 4.76786C6.74047 4.73707 6.74621 4.70416 6.74481 4.67116C6.74363 4.63885 6.73548 4.60712 6.72088 4.57794C6.70628 4.54876 6.68553 4.52277 6.65993 4.50158V4.50158ZM17.3401 4.50158C17.7338 4.18011 18.2343 4.00237 18.7525 4L18.8833 4.00358C20.0814 4.07249 21.0033 5.07386 21 6.28196C21 6.87303 20.7815 7.14866 20.4898 7.50035C20.4716 7.52286 20.4487 7.5415 20.4226 7.55507C20.3964 7.56863 20.3676 7.57682 20.3379 7.5791H20.2966C20.2734 7.5787 20.2505 7.57321 20.2299 7.56304C20.2092 7.55288 20.1913 7.53832 20.1775 7.52048L17.3279 4.85014C17.3035 4.82666 17.2845 4.79866 17.272 4.76786C17.2595 4.73707 17.2538 4.70416 17.2552 4.67116C17.2564 4.63885 17.2645 4.60712 17.2791 4.57794C17.2937 4.54876 17.3145 4.52277 17.3401 4.50158V4.50158Z"
			stroke={color}
			strokeWidth="1.5"
			strokeMiterlimit="10"
		/>
		<path
			d="M19.5062 19.7501L17.6305 17.9603M4.50073 19.7501L6.37641 17.9603"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0033 7.57959V12.591H8.25195"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
