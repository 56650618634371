import { PricingInfoProps } from 'components/Phantom/_shop/PricingInfo/types'
import { Discount } from 'components/_utils/utilityTypes'
import { ProductCore, ProductVariant, VariantKey } from 'products/types'
import { SelectionModalButtonProps } from 'components/Phantom/_shop/SelectionModalButton/types'

interface ShopManagerType {
	// product: Product,
	productTitle: string
	// price: 100,
	// strikePrice: 120,
	discount: Discount
	// getProductData: () => any
}

interface ShopCoreProduct extends ProductCore {
	images: string[]
}

export interface ShopCoreProductWithPrices extends ProductCore {
	images: ProductVisual[]
	variants: {
		[key in VariantKey]?: ProductVariant
	}
}

export interface ShopProductBase {
	product: ShopCoreProduct
	selections: ShopSelection[]
}

export interface ShopProduct {
	product: ShopCoreProductWithPrices
	selections: ShopSelection[]
}

export type ProductVisual =
	| {
			type: 'image'
			data: {
				src: string
				alt: string
				className?: string
			}
	  }
	| {
			type: 'video'
			data: {
				src: string
				poster: string
				className?: string
			}
	  }
	| {
			type: 'carousel'
			data: {
				src: string
				alt: string
				className?: string
			}[]
	  }

export interface ShopSelection {
	title: string
	id: string
	images: ProductVisual[]
	subtitle?: string
	required?: boolean
	options: {
		id: string
		type: string
		data: {
			product?: ProductCore
			[key: string]: any
		}
	}[]
	displayPricing?: PricingInfoProps
	modalButtons?: SelectionModalButtonProps[]
	className?: string
}

export interface ShopError {
	errorText: string
	associatedSelectionId: string
}

export type PodModel = 'pod_3_cover_perfect' | 'pod_4' | 'pod_4_ultra'

export class ShopManager implements ShopManagerType {
	product: ShopProductBase | undefined = undefined

	constructor() {
		// this._cartId = CartManager.loadCartId()
	}

	get productTitle() {
		return 'test product'
	}

	get discount(): Discount {
		return {
			type: 'amount',
			value: 100,
		}
	}
}
