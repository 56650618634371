import React, { FC, useEffect, useState } from 'react'
import styles from './Countdown.module.scss'
import cx from 'classnames'
import { getDHMS } from 'utils'
import { ClientOnly } from 'components/ClientOnly'

export interface CountdownProps {
	date: Date | string
}

export const Countdown: FC<CountdownProps> = (props) => {
	const [time, setTime] = useState(Date.now())

	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000)
		return () => {
			clearInterval(interval)
		}
	}, [])

	// if (validateSaleEndDate(props.date, props.minDaysLeftToShow) !== SaleDate.VALID) return null
	if (props.date == null) return null
	const { days, hours, minutes, seconds } = getDHMS(new Date(props.date).getTime() - time)

	return (
		<div className={cx(styles.container)}>
			<div className={styles.right_side}>
				<div className={styles.upper_container}>
					<div className={styles.dates}>
						<div className={styles.label}>ends in:</div>
						<div className={styles.date_unit_container}>
							<ClientOnly>
								<p className={styles.number}>{(hours + days * 24).toString().padStart(2, '0')}</p>
							</ClientOnly>
							<p className={styles.unit}>Hrs</p>
						</div>
						<div className={styles.date_unit_container}>
							<ClientOnly>
								<p className={styles.number}>{minutes.toString().padStart(2, '0')}</p>
							</ClientOnly>
							<p className={styles.unit}>Min</p>
						</div>
						<div className={styles.date_unit_container}>
							<ClientOnly>
								<p className={styles.number}>{seconds.toString().padStart(2, '0')}</p>
							</ClientOnly>
							<p className={styles.unit}>Sec</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
