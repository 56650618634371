import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.9735 10H14.1462V5C14.1462 4.46957 13.9327 3.96086 13.5526 3.58579C13.1726 3.21071 12.6571 3 12.1196 3H12.0233C11.5167 3 11.1063 3.405 11.1063 3.905C11.1063 4.619 10.8925 5.317 10.4902 5.911L8.25 9.22793V20.2952L10.8641 20.94C11.0252 20.98 11.1904 21 11.3556 21H15.426C15.8026 21.0002 16.1718 20.8968 16.4921 20.7014C16.8125 20.5061 17.0714 20.2265 17.2398 19.894L20.7863 12.894C20.9407 12.5891 21.0135 12.2503 20.9979 11.9098C20.9824 11.5692 20.8788 11.2383 20.6972 10.9484C20.5156 10.6584 20.2619 10.4191 19.9602 10.2531C19.6585 10.0871 19.3189 10 18.9735 10ZM5 10C3.89543 10 3 10.8954 3 12V18C3 19.1046 3.89543 20 5 20H6C6.55228 20 7 19.5523 7 19V11C7 10.4477 6.55228 10 6 10H5Z"
			fill={color}
		/>
	</svg>
)

export default icon
