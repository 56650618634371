import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 8.72222C4 8.22126 4.19901 7.74081 4.55324 7.38658C4.90748 7.03234 5.38792 6.83333 5.88889 6.83333H6.76722C7.07812 6.83338 7.38422 6.75669 7.65837 6.61006C7.93251 6.46343 8.16623 6.2514 8.33878 5.99278L9.10567 4.84056C9.27821 4.58194 9.51193 4.36991 9.78608 4.22328C10.0602 4.07665 10.3663 3.99995 10.6772 4H14.3228C14.6337 3.99995 14.9398 4.07665 15.2139 4.22328C15.4881 4.36991 15.7218 4.58194 15.8943 4.84056L16.6612 5.99278C16.8338 6.2514 17.0675 6.46343 17.3416 6.61006C17.6158 6.75669 17.9219 6.83338 18.2328 6.83333H19.1111C19.6121 6.83333 20.0925 7.03234 20.4468 7.38658C20.801 7.74081 21 8.22126 21 8.72222V17.2222C21 17.7232 20.801 18.2036 20.4468 18.5579C20.0925 18.9121 19.6121 19.1111 19.1111 19.1111H5.88889C5.38792 19.1111 4.90748 18.9121 4.55324 18.5579C4.19901 18.2036 4 17.7232 4 17.2222V8.72222ZM11.0269 11.0269C11.4176 10.6362 11.9475 10.4167 12.5 10.4167C13.0526 10.4167 13.5825 10.6362 13.9732 11.0269C14.3639 11.4176 14.5834 11.9475 14.5834 12.5C14.5834 13.0526 14.3639 13.5825 13.9732 13.9732C13.5825 14.3639 13.0526 14.5834 12.5 14.5834C11.9475 14.5834 11.4176 14.3639 11.0269 13.9732C10.6362 13.5825 10.4167 13.0526 10.4167 12.5C10.4167 11.9475 10.6362 11.4176 11.0269 11.0269ZM12.5 8.91669C11.5497 8.91669 10.6382 9.29422 9.96622 9.96622C9.29422 10.6382 8.91669 11.5497 8.91669 12.5C8.91669 13.4504 9.29422 14.3618 9.96622 15.0338C10.6382 15.7058 11.5497 16.0834 12.5 16.0834C13.4504 16.0834 14.3618 15.7058 15.0338 15.0338C15.7058 14.3618 16.0834 13.4504 16.0834 12.5C16.0834 11.5497 15.7058 10.6382 15.0338 9.96622C14.3618 9.29422 13.4504 8.91669 12.5 8.91669Z"
			fill={color}
		/>
	</svg>
)

export default icon
