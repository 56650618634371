import { QA } from './FAQ'
import React from 'react'
import { A } from 'components/basic/A'
import { SKIP_MEMBERSHIP_CHECKOUT_KEY } from 'constants/index'
import { RegionShort, regionIsMetric } from 'utils/internationalization'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const MEMBERSHIP_FAQ_ITEM = {
	question: 'I already own a Pod?',
	answer: (
		<>
			If you already have an existing Pod you do not need to pay for another subscription. Simply use the same email address when setting up your new Pod and your subscription will automatically carry
			over.
			<br />
			<A
				href="#"
				id="click-here-avoid-membership"
				onClick={() => {
					sessionStorage.setItem(SKIP_MEMBERSHIP_CHECKOUT_KEY, '')
					document.getElementById('add-to-cart-button').click()
				}}
			>
				Click here
			</A>
			<span>&nbsp;</span>to proceed without selecting an additional subscription.
		</>
	),
}

export const memberUpgradeQAs: QA[] = [
	{
		question: 'What happens to all my Eight Sleep data and temperature preferences?',
		answer: 'All of your existing Eight Sleep data and preferences will be saved with your current Eight Sleep account.',
	},
	{
		question: 'Can I purchase more than one discounted upgrade bundle? ',
		answer: 'Yes - you can purchase more than one upgrade bundle, but they will not include the mattress or encasement. Contact our Sleep Pros to purchase.',
	},
	{
		question: 'Does my current warranty extend when I purchase an upgrade?',
		answer: 'With your purchase of a Pod 3 you will receive a new warranty that kicks off with this new product purchase. The warranty for Pod 3 is of 2 years (Active Grid and Hub only).',
	},
	{
		question: 'What happens if I am still paying a financing loan for my current Pod?',
		answer:
			'If you are currently financing a 1st or 2nd generation Pod, your financing payments will continue separately of your upgrade. If you choose to finance your Pod 3 Upgrade, then you will need to take out a new loan separately.',
	},
	{
		question: 'Do I need another membership?',
		answer: 'No, you do not need another membership if you own more than one Pod. You can simply add your new Pod to your same Eight Sleep app account with Multi-Pod support.',
	},
	{
		question: 'I want to buy a Pod that is a different size from my current Pod, how do I upgrade?',
		answer: 'Simply select the size you are looking for and you will be able to install on your current size mattress.',
	},
	{
		question: 'Can I install my current Pod in another room?',
		answer: 'Yes, you can install your current Pod in another room and control it with Multi-Pod support from your same Eight Sleep app account.',
	},
]

export const memberAnniversaryUpgradeQAs: QA[] = [
	{
		question: 'What happens to all my Eight Sleep data and temperature preferences?',
		answer: 'All of your existing Eight Sleep data and preferences will be saved with your current Eight Sleep account.',
	},
	{
		question: 'Can I purchase more than one discounted upgrade bundle? ',
		answer: 'Yes - you can purchase more than one upgrade bundle, but they will not include the mattress or encasement. Contact our Sleep Pros to purchase.',
	},
	{
		question: 'Does my current warranty extend when I purchase an upgrade?',
		answer: 'With your purchase of a Pod 3 you will receive a new warranty that kicks off with this new product purchase. The warranty for Pod 3 is of 2 years (Active Grid and Hub only).',
	},
	{
		question: 'What happens if I am still paying a financing loan for my current Pod?',
		answer:
			'If you are currently financing a 1st or 2nd generation Pod, your financing payments will continue separately of your upgrade. If you choose to finance your Pod 3 Upgrade, then you will need to take out a new loan separately.',
	},
	{
		question: 'I want to buy a Pod that is a different size from my current Pod, how do I upgrade? ',
		answer:
			'You can buy an upgrade bundle with the specific size you would like to upgrade to, and then purchase an encasement and/or mattress for your new bed size separately. For example, if you have a queen-sized Pod and would like to upgrade to a king-size, select the king–sized member upgrade and purchase a king-sized mattress as well. Contact our Sleep Pros to purchase.',
	},
	{
		question: 'Can I install my current Pod in another room?',
		answer: 'Yes, you can install your Active Grid on a bed in another room, but you will need to purchase an additional Encasement for it. Contact our Sleep Pros to purchase.',
	},
]

const FAQs = {
	financing: {
		question: 'Can I finance my purchase?',
		answer: (
			<>
				Financing is available in the U.S. through Affirm, with plans starting at 0% APR. Click{' '}
				<a
					className={'affirm-site-modal'}
					data-provider="Affirm"
					data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
					tabIndex={0}
					aria-label="Pre qualify button for affirm"
				>
					here
				</a>{' '}
				to pre-qualify, or choose Affirm at checkout as your payment method.
			</>
		),
	},
	payOverTime: {
		question: 'Can I pay over time?',
		answer: <>Klarna is available in Europe. Shop now and pay over time with Klarna today by choosing Klarna at checkout as your payment method.</>,
	},
	whyMembership: {
		question: 'Why does the Pod need a membership?',
		answer: (
			<>
				The Pod is an intelligent sleep system that helps you sleep better and better each night by continuously learning from your sleep history, personal preferences and external factors.
				<br />
				<br />
				Your sleep journey is unique and the Pod’s features work in harmony to give you the best sleep experience possible, every night. It learns about your preferences, your health and sleep habits
				and makes automatic temperature adjustments to improve your sleep over time. It also delivers on-going insights about your sleep to show how you’ve been progressing as well as tips to continue
				improving your sleep fitness.
				<br />
				<br />A membership is required for the first 12 months (cancel any time), and covers two separate users per Pod.
			</>
		),
	},
	whyMembershipAB: {
		question: 'Why do I need Autopilot?',
		answer: (
			<>
				Autopilot is an intelligent sleep system that helps you sleep better and better each night by continuously learning from your sleep history, personal preferences and external factors.
				<br />
				<br />
				Your sleep journey is unique and the Autopilot’s features work in harmony to give you the best sleep experience possible, every night. It learns about your preferences, your health and sleep
				habits and makes automatic temperature adjustments to improve your sleep over time. It also delivers on-going insights about your sleep to show how you’ve been progressing as well as tips to
				continue improving your sleep fitness.
				<br />
				<br />
				Autopilot is required for the first 12 months (cancel any time), and covers two separate users per Pod.
			</>
		),
	},
	noMembership: {
		question: 'Can I buy the Pod without a membership?',
		answer: (
			<>
				A membership is required for the first 12 months, and the cost covers two separate users per Pod.
				<br />
				<br />
				The membership is what turns your Pod into an intelligent sleep system with features such as <strong>automatic temperature adjustments.</strong> This personalizes the temperature of each side
				of the bed based on each individual’s sleep schedule, sleep phases, comfort preferences, and environmental conditions.
				<br />
				<br />
				The membership also includes a <strong>vibration & thermal alarm, sleep & health reports</strong> and <strong>continuous software updates</strong>.
			</>
		),
	},
	noMembershipAB: {
		question: 'Can I buy the Pod without Autopilot?',
		answer: (
			<>
				Autopilot is required for the first 12 months, and the cost covers two separate users per Pod.
				<br />
				<br />
				Through Autopilot, the Pod continuously learns based on your sleep history, personal preferences and dozens of external factors, making automatic adjustments so that you stay asleep longer and
				sleep deeper night after night.
				<br />
				<br />
				When the lights go off, your Pod goes to work to deliver you and your partner a unique sleep experience each night. In the morning, you receive a personalized sleep and health report with
				in-depth analysis of how you slept, what adjustments the Pod made for you, and insights about what you can do to improve your sleep even further.
			</>
		),
	},
	availablePartners: {
		question: 'Which partners are available through member perks?',
		answer: (
			<>
				The following partners offer exclusive discounts as a member perk: Hyperice, Remedy Place, Equinox Spa, Plunge, Levels, Beam, Inside Tracker, Rootine, Prenuvo and Hydrow.
				<br /> The program is currently only available for US residents.
			</>
		),
	},
	whatsIncludedB: {
		question: "What's included in the membership?",
		answer: (
			<>
				Depending on what plan you select, you and your partner will get access to some or all of the following features:
				<br />
				<br />
				<ul>
					<li>
						<strong>Automatic temperature adjustments </strong>
						<br />
						Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 55°F - 110°F.
					</li>
					<li>
						<strong>Vibration & thermal alarm</strong>
						<br />
						Silently wakes you up with chest-level vibration and gradual thermal change.
					</li>
					<li>
						<strong>Sleep and health reports</strong>
						<br />
						Sleep and health reports for each side of the bed including sleep stages, sleep time, heart rate, and HRV.
					</li>
					<li>
						<strong>Automatic software updates</strong>
						<br />
						Continuous software updates that add new features and enhance existing ones.
					</li>
					<li>
						<strong>Extended warranty</strong>
						<br />
						If you have any issues, we will replace your Pod for up to 5 years as long as you purchased through this site and have an active Plus plan (
						<A
							href={'/warranty'}
							id={'faq-warranty-link'}
						>
							terms apply
						</A>
						)
					</li>
				</ul>
			</>
		),
	},
	whatsIncludedBMetric: {
		question: "What's included in the membership?",
		answer: (
			<>
				Depending on what plan you select, you and your partner will get access to some or all of the following features:
				<br />
				<br />
				<ul>
					<li>
						<strong>Automatic temperature adjustments </strong>
						<br />
						Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 12°C - 43°C.
					</li>
					<li>
						<strong>Vibration & thermal alarm</strong>
						<br />
						Silently wakes you up with chest-level vibration and gradual thermal change.
					</li>
					<li>
						<strong>Sleep and health reports</strong>
						<br />
						Sleep and health reports for each side of the bed including sleep stages, sleep time, heart rate, and HRV.
					</li>
					<li>
						<strong>Automatic software updates</strong>
						<br />
						Continuous software updates that add new features and enhance existing ones.
					</li>
					<li>
						<strong>Extended warranty</strong>
						<br />
						If you have any issues, we will replace your Pod for up to 5 years as long as you purchased through this site and have an active Plus plan (
						<A
							href={'/warranty'}
							id={'faq-warranty-link'}
						>
							terms apply
						</A>
						)
					</li>
				</ul>
			</>
		),
	},
	whatsIncludedBRename: {
		question: "What's included in the membership?",
		answer: (
			<>
				Depending on what plan you select, you and your partner will get access to some or all of the following features:
				<br />
				<br />
				<ul>
					<li>
						<strong>Automatic temperature adjustments </strong>
						<br />
						Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 55°F - 110°F.
					</li>
					<li>
						<strong>Vibration & thermal alarm</strong>
						<br />
						Silently wakes you up with chest-level vibration and gradual thermal change.
					</li>
					<li>
						<strong>Sleep and health reports</strong>
						<br />
						Sleep and health reports for each side of the bed including sleep stages, sleep time, heart rate, and HRV.
					</li>
					<li>
						<strong>Automatic software updates</strong>
						<br />
						Continuous software updates that add new features and enhance existing ones.
					</li>
					<li>
						<strong>Extended warranty</strong>
						<br />
						If you have any issues, we will replace your Pod for up to 5 years as long as you purchased through this site and have an active Enhanced plan (
						<A
							href={'/warranty'}
							id={'faq-warranty-link'}
						>
							terms apply
						</A>
						)
					</li>
				</ul>
			</>
		),
	},
	whatsIncludedBMetricRename: {
		question: "What's included in the membership?",
		answer: (
			<>
				Depending on what plan you select, you and your partner will get access to some or all of the following features:
				<br />
				<br />
				<ul>
					<li>
						<strong>Automatic temperature adjustments </strong>
						<br />
						Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 12°C - 43°C.
					</li>
					<li>
						<strong>Vibration & thermal alarm</strong>
						<br />
						Silently wakes you up with chest-level vibration and gradual thermal change.
					</li>
					<li>
						<strong>Sleep and health reports</strong>
						<br />
						Sleep and health reports for each side of the bed including sleep stages, sleep time, heart rate, and HRV.
					</li>
					<li>
						<strong>Automatic software updates</strong>
						<br />
						Continuous software updates that add new features and enhance existing ones.
					</li>
					<li>
						<strong>Extended warranty</strong>
						<br />
						If you have any issues, we will replace your Pod for up to 5 years as long as you purchased through this site and have an active Enhanced plan (
						<A
							href={'/warranty'}
							id={'faq-warranty-link'}
						>
							terms apply
						</A>
						)
					</li>
				</ul>
			</>
		),
	},
	canICancel: {
		question: 'Can I cancel my membership?',
		answer: (
			<>
				You can cancel your membership, but you will still be charged for the 12 months and have access to the features until the end of your first billing cycle.
				<br />
				<br />
				After you cancel your membership, you will lose access to the intelligent features of the Pod, such as automatic temperature adjustments, sleep and health insights, and the vibration and
				thermal alarm.
			</>
		),
	},
	canICancelAB: {
		question: 'Can I cancel my Autopilot?',
		answer: (
			<>
				You can cancel your Autopilot, but you will still be charged for the 12 months and have access to the features until the end of your first billing cycle.
				<br />
				<br />
				After you cancel your Autopilot, you will lose access to the intelligent features of the Pod, such as automatic temperature adjustments, sleep and health insights, and the vibration and
				thermal alarm.
			</>
		),
	},
	returnPolicyCover: {
		question: 'What is the return policy and what gets refunded?',
		answer: <>If for any reason you are not happy with the Pod, you can return it within 30 days of delivery and we will fully refund both your Pod and membership purchases.</>,
	},
	returnPolicyMattress: {
		question: 'What is the return policy and what gets refunded?',
		answer: <>If for any reason you are not happy with the Pod, you can return it within 30 days of delivery and we will fully refund both your Pod and membership purchases.</>,
	},
	warrantyB: {
		question: 'What is the warranty of the product?',
		answer: (
			<>
				<strong>Standard warranty</strong>
				<br />
				The Pod is a product designed to be slept on every night – and designed to last. We stand behind our product with a 2-year limited warranty, meaning if you have any issues or problems, our
				team will replace your Pod device as long as you purchased directly through Eight Sleep and have an active membership. (
				<A
					href={'/warranty'}
					id={'faq-warranty-link'}
				>
					terms apply
				</A>
				)
				<br />
				<br />
				<strong>Extended warranty</strong>
				<br />
				For up to 5 years following your initial purchase date, we will replace your Pod device as long as you purchased directly through Eight Sleep and are an active Plus member (
				<AnchorLink
					offset={150}
					href="#membership-selector"
					onClick={(e) => {
						e.preventDefault()
					}}
				>
					see membership plans
				</AnchorLink>
				) if you have any issues or problems. (
				<A
					href={'/warranty'}
					id={'faq-warranty-link'}
				>
					terms apply
				</A>
				)
			</>
		),
	},
	warrantyC: {
		question: 'What is the warranty of the product?',
		answer: (
			<>
				<strong>Standard warranty</strong>
				<br />
				The Pod is a product designed to be slept on every night – and designed to last. We stand behind our product with a 2-year limited warranty, meaning if you have any issues or problems, our
				team will replace your Pod device as long as you purchased directly through Eight Sleep and have an active membership. (
				<A
					href={'/warranty'}
					id={'faq-warranty-link'}
				>
					terms apply
				</A>
				)
				<br />
				<br />
				<strong>Extended warranty</strong>
				<br />
				For up to 5 years following your initial purchase date, we will replace your Pod device as long as you purchased directly through Eight Sleep and are an active Enhanced member (
				<AnchorLink
					offset={150}
					href="#membership-selector"
					onClick={(e) => {
						e.preventDefault()
					}}
				>
					see membership plans
				</AnchorLink>
				) if you have any issues or problems. (
				<A
					href={'/warranty'}
					id={'faq-warranty-link'}
				>
					terms apply
				</A>
				)
			</>
		),
	},
	doINeedTwoMemberships: {
		question: 'I already own a Pod?',
		answer: (
			<>
				If you already have an existing Pod you do not need to pay for another subscription. You can purchase a new Pod through the member shop in the Eight Sleep app or contact us at{' '}
				<a
					href="mailto:support@eightsleep.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					{' '}
					<span>support@eightsleep.com</span>{' '}
				</a>
				.
			</>
		),
	},
	whatHappensIfICancel: {
		question: 'What will the Pod be like if I cancel my membership?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a membership is required (either our Pro or Plus plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 55°F and as high as 110°F, so you and your
				partner can both sleep at the right temperature.
			</>
		),
	},
	whatHappensIfICancelAB: {
		question: 'What will the Pod be like if I cancel my Autopilot?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a Autopilot is required (either our Pro or Plus plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 55°F and as high as 110°F, so you and your
				partner can both sleep at the right temperature.
			</>
		),
	},
	whatHappensIfICancelABRename: {
		question: 'What will the Pod be like if I cancel my Autopilot?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a Autopilot is required (either our Standard or Enhanced plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 55°F and as high as 110°F, so you and your
				partner can both sleep at the right temperature.
			</>
		),
	},
	whatHappensIfICancelMetric: {
		question: 'What will the Pod be like if I cancel my membership?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a membership is required (either our Pro or Plus plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 12°C and as high as 43°C, so you and your partner
				can both sleep at the right temperature.
			</>
		),
	},
	whatHappensIfICancelMetricAB: {
		question: 'What will the Pod be like if I cancel my Autopilot?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a Autopilot is required (either our Pro or Plus plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 12°C and as high as 43°C, so you and your partner
				can both sleep at the right temperature.
			</>
		),
	},
	whatHappensIfICancelMetricABRename: {
		question: 'What will the Pod be like if I cancel my Autopilot?',
		answer: (
			<>
				For the first 12 months of Pod ownership, a Autopilot is required (either our Standard or Enhanced plan). After 12 months, you can unsubscribe.
				<br />
				<br />
				If you choose to unsubscribe, you will still get access to manual temperature control. The Pod can cool or warm each side of the bed as low as 12°C and as high as 43°C, so you and your partner
				can both sleep at the right temperature.
			</>
		),
	},
	partner: {
		question: 'Does the Pod work with a partner?',
		answer: <>Yes, the Pod has dual-zone cooling and heating, sleep and health tracking, and wake up alarms if you sleep with a partner. Simply add them to your Pod via the Eight Sleep app.</>,
	},
	currentMattress: {
		question: 'Can I use the Pod if I want to keep my current mattress?',
		answer: <>Yes, the Pod Cover fits over your existing mattress similar to a fitted sheet.</>,
	},
	baseExisting: {
		question: 'Does the the Base of Pod 4 Ultra fits into my existing bed setup?',
		answer: <>Designed to fit between your current bed frame and your mattress, the Base does not replace your current setup. It is compatible with slatted and platform bed frames and foundations.</>,
	},
	needLegKit: {
		question: 'Do I need the leg kit for Pod 4 Ultra?',
		answer: (
			<>
				If you need extra support for your bed frame, you can add the optional leg kit to the Base for a more supportive setup. You can also use the optional leg kit to use the Base in place of a bed
				frame.
			</>
		),
	},
	whatIsThePod: {
		question: 'What is the Pod?',
		answer: (
			<>
				The Pod is a sleep system that improves your quality of sleep through temperature regulation (Pod 4, Pod 4 Ultra) and elevation (Pod 4 Ultra only). The system is composed of a temperature
				regulating Cover that fits onto your mattress, a Hub that sits next to your bed and controls automatic adjustments, as well as a Base (Pod 4 Ultra only), which provides elevation, slotting in
				your existing bed frame and below your mattress.
			</>
		),
	},
	howDoesItImproveMySleep: {
		question: 'How does the Pod improve my sleep?',
		answer: (
			<>
				One of the primary reasons people wake up during the night is temperature-related discomfort. The body needs different temperatures throughout the night to stay asleep, especially during REM
				cycles. Therefore, the Pod’s ability to dynamically cool and heat based on current body temperatures allows you to sleep deeper and for longer. For those who snore, the elevation feature of
				the Pod 4 Ultra automatically raise your head to mitigate snoring and the sleep interruptions that result from it.{' '}
			</>
		),
	},
	doesItFitMyBed: {
		question: 'Does the Pod fit into my existing bed setup?',
		answer: (
			<>
				Yes, the Pod 4 and Pod 4 Ultra will seamlessly integrate with your current mattress and bed frame. Both products come with a Cover that fits on any mattress and a Hub that can fit next to your
				bed. Pod 4 Ultra comes with a Base that slots into your existing bed frame and beneath your mattress.
			</>
		),
	},
	isItComfortable: {
		question: 'Is the Pod comfortable?',
		answer: (
			<>
				The comfort of your current mattress is maintained when you use the Pod. To install the Pod, you will add a thin cover to your existing mattress, like a fitted sheet. This Cover is responsible
				for the dynamic heating and cooling of your body temperature, keeping you asleep longer through the night.
			</>
		),
	},
	howDoesItRegulateTemperature: {
		question: 'How does the Pod regulate temperature?',
		answer: (
			<>
				The Pod adjusts the temperature of your bed surface through the Cover that fits over your mattress, which heats and cools your body through intelligent temperature adjustments. You can select
				the baseline temperature that works best for you, and the Pod will make micro adjustments to help you sleep longer and more deeply. It can cool as low as 55º F (12.8º C) and heat as high as
				110º F (43.3º C).
			</>
		),
	},
	howDoesItReduceSnoring: {
		question: 'How does the Pod reduce snoring?',
		answer: (
			<>
				The Base of the Pod 4 Ultra gently elevates the bed when snoring is detected – in real time and without disruption. Pod 4 Ultra makes adjustments instantly once snoring is detected, reducing
				risk of waking up for both the person snoring and their partner. In a clinical study, participants who slept on the Pod 4 Ultra, decreased their snoring by 45%{' '}
				<A
					id={'how-reduce-snoring-study'}
					href={'https://www.eightsleep.com/blog/snoring-detection-mitigation/?srsltid=AfmBOorECWq5Qi7-UtiL_hUhJa3tFXsFaUuvHWj8D4s32DKv_yFPYJ2X'}
				>
					(link to study)
				</A>
				.
			</>
		),
	},
}

export const getFAQs = (product: 'mattress' | 'cover' | 'sleepos' | 'prospecting', region: RegionShort, isMember?: boolean) => {
	const faqs = []

	if (isMember) return memberUpgradeQAs

	if (product === 'prospecting') {
		return [FAQs.whatIsThePod, FAQs.howDoesItImproveMySleep, FAQs.doesItFitMyBed, FAQs.isItComfortable, FAQs.howDoesItRegulateTemperature, FAQs.howDoesItReduceSnoring]
	}

	if (region === '' && product !== 'sleepos') {
		faqs.push(FAQs.financing)
	}

	if (region === 'eu') {
		faqs.push(FAQs.payOverTime)
	}

	FAQs.whatsIncludedBMetric.question = "What's included in the Autopilot?"
	FAQs.whatsIncludedB.question = "What's included in the Autopilot?"

	faqs.push(FAQs.whyMembershipAB)

	if (product === 'sleepos') {
		if (regionIsMetric(region)) {
			faqs.push(FAQs.whatsIncludedBMetricRename)
		} else {
			faqs.push(FAQs.whatsIncludedBRename)
		}

		faqs.push(FAQs.noMembershipAB)
		faqs.push(FAQs.canICancelAB)

		if (regionIsMetric(region)) {
			faqs.push(FAQs.whatHappensIfICancelMetricABRename)
		} else {
			faqs.push(FAQs.whatHappensIfICancelABRename)
		}
	} else {
		faqs.push(FAQs.noMembershipAB)
	}

	if (product === 'mattress') {
		faqs.push(FAQs.returnPolicyMattress)
	} else if (product === 'cover') {
		faqs.push(FAQs.returnPolicyCover)
	}

	if (product === 'mattress' || product === 'cover') {
		faqs.push(FAQs.warrantyC)
	}

	faqs.push(FAQs.doINeedTwoMemberships)

	if (product === 'sleepos') {
		faqs.push(FAQs.availablePartners)
	}

	if (product === 'cover') {
		faqs.push(FAQs.baseExisting)
		faqs.push(FAQs.needLegKit)
	}

	return faqs
}

export const hubermanQAs: QA[] = [
	{
		question: 'Who is eligible to win?',
		answer: 'Eligible participants must be over 18 years-old.',
	},
	{
		question: 'What is included in the prize?',
		answer: 'Two VIP tickets that include premium seats, early venue access, and two autographed merchandise items.',
	},
	{
		question: 'How many winners will be awarded?',
		answer: 'Two winners will be awarded two tickets each.',
	},
	{
		question: 'When do applications close?',
		answer: <>Applications will close on September 20th, at 11:59 PM EST.</>,
	},
	{
		question: 'When will winners be notified?',
		answer: <>Winners will be notified by September 21st, at 11:00 AM EST.</>,
	},
]

export const pod4LPQAs: QA[] = [
	{
		question: 'What should I do with my current Pod?',
		answer: 'You can keep it in a second home or guest room, gift it to family or friends or discard it at an e-waste near you.',
	},
	{
		question: 'Will all my data be transferred to my new Pod?',
		answer: 'Yes, if you set up your new Pod with your same Eight Sleep account, your data will transfer.',
	},
	{
		question: 'Will I need a new Autopilot plan?',
		answer: 'If you upgrade to Pod 4 Ultra and want access to snoring mitigation, you must upgrade to Autopilot Enhanced.',
	},
	{
		question: 'Can I buy the Base separately?',
		answer: 'No, the Base must be purchased with Pod 4. The Base does not work without Pod 4.',
	},
	{
		question: 'Do all Pod generations work with the Base?',
		answer: 'No, only Pod 4 is compatible with the Base. Any previous generation of the Pod will not work with the Base.',
	},
	{
		question: 'Will my Pod 4 work with any brand adjustable base?',
		answer:
			'Our recommendation is to use Pod 4 with our Base as they have been designed to fit perfectly and work together for the best experience. However, if you already own an adjustable base, you can use Pod 4 with it. In this case, you will not have access to snoring mitigation, or custom modes and cannot control the Base from your app.',
	},
]

export const hooxQAs: QA[] = [
	{
		question: 'Can I use the Pod on my current bed?',
		answer: 'Yes! The Pod Cover fits seamlessly onto any bed.',
	},
	{
		question: 'What do I need to setup the Pod?',
		answer: 'Everything you need to add the Pod to your bed is included in your purchase. During first setup, you’ll need to add distilled water and hydrogen peroxide to your hub.',
	},
	{
		question: 'What sizes & dimensions does the Pod come in?',
		answer: (
			<ul style={{ listStyleType: 'none' }}>
				<li style={{ marginBottom: 30 }}>- Full Size is 53 x 75 x 12 inches </li>
				<li style={{ marginBottom: 30 }}>- Queen Size is 58.5 x 79.5 x 12 inches</li>
				<li style={{ marginBottom: 30 }}>- King Size is 74.5 x 79.5 x 12 inches</li>
				<li style={{ marginBottom: 0 }}>- Cali King Size is 70.5 x 83.5 x 12 inches</li>
			</ul>
		),
	},
	{
		question: 'How cool or warm does the Pod get?',
		answer: 'Set each side of the bed between 55°F - 110°F. Schedule personalized temperatures for each phase of the night.',
	},
	{
		question: 'Can I finance my purchase?',
		answer: 'Yes! We offer financing on the Pod Cover with options as low as 0% APR. Choose Affirm at checkout to learn more.',
	},
	{
		question: 'What’s the trial and warranty policy?',
		answer: 'The Pod Cover comes with a 30 night free-trial or full refund, 2 year warranty, and is currently available in continental US (excluding Hawaii, Alaska and Puerto Rico).',
	},
]

export const truemedQA: QA[] = [
	{
		question: 'What is an HSA/FSA account?',
		answer: (
			<div>
				<p>
					Health Savings Accounts (HSA) and Flexible Spending Accounts (FSA) are tax-free accounts that can be used to pay for qualified health expenses. These accounts are usually set up and managed
					by an HSA or FSA administrator, and you should have access to said HSA/FSA administrator through your employer (ask your HR department!).
				</p>
				<p>
					HSAs are typically associated with a high-deductible health plan, and funds do not expire. FSAs are independent of your health plan, and funds elections occur in October-November each year
					for the following calendar year. FSA funds expire every calendar year.
				</p>
				<p>Unfortunately, HSAs and FSAs are not available outside of the US, and self-employed individuals (who do not have an HSA from a previous employment) do not qualify for HSAs or FSAs.</p>
			</div>
		),
	},
	{
		question: 'What is Truemed?',
		answer: (
			<div>
				<p>
					Truemed partners with merchants and brands to enable qualified customers to use HSA/FSA funds on qualified products and services that are used to treat, mitigate, or prevent a diagnosed
					medical condition. Truemed partners with a network of individual practitioners who evaluate customers’ eligibility and issue Letters of Medical Necessity to qualifying customers, thereby
					saving customers money on legitimate medical expenditures.
				</p>
				<p>Truemed is backed by best-in-class investors, including functional medicine pioneer Mark Hyman and founders from Thrive Market, Eight Sleep and Levels.</p>
			</div>
		),
	},
	{
		question: 'How does using my HSA/FSA account save me money?',
		answer: (
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
				<p>
					HSA/FSA accounts were created so individuals could use pre-tax money to pay for expenses used to treat, mitigate, or prevent a diagnosed medical condition. Because HSA/FSAs use pre-tax
					money, you’re getting more purchasing power for your dollars. Rather than pay taxes on income and then spend it on health items, qualified customers can use pre-tax funds to invest in their
					health.
				</p>
				<p>
					An individual can contribute up to $3,850 pretax to their HSA per year, or $7,750 for a family (plus an additional $1,000 if you are at least 55 years old Individuals can contribute up to
					$3,050 pretax to their FSA per year (with an additional $500 in employer contributions allowed). Almost every qualified individual will save between $1,000 and $2,000, depending on their
					state and tax rate.{' '}
				</p>
			</div>
		),
	},
	{
		question: 'What is a Letter of Medical Necessity?',
		answer: (
			<p>
				In order to determine whether certain products or services are legitimate expenses for treating, mitigating, or preventing a diagnosed medical condition, HSA/FSA plan administrators often
				require a letter from a licensed practitioner. This letter is called a “Letter of Medical Necessity.”
			</p>
		),
	},
	{
		question: `I don't live in the US. Can I still get reimbursed with Truemed?`,
		answer: <p>Unfortunately, Truemed is currently only available in the United States.</p>,
	},
	{
		question: `How long does it take for me to receive my Letter of Medical Necessity?`,
		answer: <p>Generally it takes 24-48 hours. If you aren't seeing your letter in your inbox, check spam, then reach out to us at support@truemed.com for help!</p>,
	},
	{
		question: `If I am not approved, what happens?`,
		answer: <p>If you do not qualify, you are able to reapply for HSA/FSA spending at a later date. </p>,
	},
	{
		question: `I don't have enough funds in my HSA/FSA account to cover the entire purchase. Can I still use Truemed?`,
		answer: (
			<p>
				You can occasionally request a split invoice from the merchant that you are making a purchase from, but like your normal bank account, you generally need to have the funds to cover the
				purchase to avoid a rejection.
			</p>
		),
	},
	{
		question: `What if my HSA/FSA payment is rejected by my administrator?`,
		answer: (
			<p>
				Please contact us at team@truemed.com so we can help you troubleshoot the issue. HSA/FSA plan administrators often have detailed requirements, so it may be as simple as re-issuing your Letter
				of Medical Necessity using the administrator's form.
			</p>
		),
	},
]
