import { CheckboxSelectionItemProps } from 'components/Phantom/_shop/CheckboxSelection'
import { PricingData } from 'shop/Shop.types'

export const getEssentialsCheckboxProps = (pricingData: PricingData): CheckboxSelectionItemProps => {
	return {
		selectionKey: 'essentials-bundle',
		trueValue: 'white',
		falseValue: 'none',
		title: 'Sleep Essentials Bundle',
		description: 'Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Air Pillows.',
		pricingData,
	}
}
