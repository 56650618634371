import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { CarouselProps } from 'components/Phantom/_shop/Carousel/types'
import { Img } from 'components/basic/Img'
import { SelectionModalButtonProps } from 'components/Phantom/_shop/SelectionModalButton/types'

/**
 * This file houses default props for testing the Shop page
 */

export const CardSelectionItemDefaults: CardSelectionItemProps = {
	data: { title: 'Add leg kit', subtitleMedium: 'Replace your current bed frame with the Base' },
	type: 'standard',
	selected: false,
	key: '',
}

export const SelectionModalButtonDefaults: SelectionModalButtonProps = {
	modalKey: 'example',
	text: 'Need help choosing',
}

export const CarouselDefaults: CarouselProps = {
	items: [
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/1b01c4945936d42d2ce1bf3d66398c2e.png'}
			alt={''}
		/>,
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/63c4941b705ae2986a1833fb8dcbe4c4.png'}
			alt={''}
		/>,
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/d3509499fe3ae2e8b314c42361398c6b.png'}
			alt={''}
		/>,
	],
}
