// const generateTokenUrl = 'https://w24caxxk6j.execute-api.us-east-1.amazonaws.com/staging'
const generateTokenUrl = 'https://cdjhag50rl.execute-api.us-east-1.amazonaws.com/production'

export async function generateCode() {
	try {
		const response = await fetch(generateTokenUrl, { method: 'POST' })
		const json = await response.json()
		return json
	} catch (e) {
		throw new Error('eight_sentry: Error creating token ' + e)
	}
}

export async function getSubscriptionCode() {
	try {
		const newObj = await generateCode()
		return newObj.code as string
	} catch (e) {
		const newObj = await generateCode()
		return newObj.code as string
	}
}
