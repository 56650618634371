import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M5.0625 12.0625H5.07313M12.5 12.0625H12.5106M19.9375 12.0625H19.9481M6.125 12.0625C6.125 12.3443 6.01306 12.6145 5.8138 12.8138C5.61454 13.0131 5.34429 13.125 5.0625 13.125C4.78071 13.125 4.51046 13.0131 4.3112 12.8138C4.11194 12.6145 4 12.3443 4 12.0625C4 11.7807 4.11194 11.5105 4.3112 11.3112C4.51046 11.1119 4.78071 11 5.0625 11C5.34429 11 5.61454 11.1119 5.8138 11.3112C6.01306 11.5105 6.125 11.7807 6.125 12.0625ZM13.5625 12.0625C13.5625 12.3443 13.4506 12.6145 13.2513 12.8138C13.052 13.0131 12.7818 13.125 12.5 13.125C12.2182 13.125 11.948 13.0131 11.7487 12.8138C11.5494 12.6145 11.4375 12.3443 11.4375 12.0625C11.4375 11.7807 11.5494 11.5105 11.7487 11.3112C11.948 11.1119 12.2182 11 12.5 11C12.7818 11 13.052 11.1119 13.2513 11.3112C13.4506 11.5105 13.5625 11.7807 13.5625 12.0625ZM21 12.0625C21 12.3443 20.8881 12.6145 20.6888 12.8138C20.4895 13.0131 20.2193 13.125 19.9375 13.125C19.6557 13.125 19.3855 13.0131 19.1862 12.8138C18.9869 12.6145 18.875 12.3443 18.875 12.0625C18.875 11.7807 18.9869 11.5105 19.1862 11.3112C19.3855 11.1119 19.6557 11 19.9375 11C20.2193 11 20.4895 11.1119 20.6888 11.3112C20.8881 11.5105 21 11.7807 21 12.0625Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
