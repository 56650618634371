import React, { FC, useEffect, useState } from 'react'
import { RadioButton, RadioButtonValues } from './RadioButton'

export interface RadioButtonGroupProps {
	options: RadioButtonValues[]
	legend: string
	default: string
	onSelect: (value: string) => void
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = (props) => {
	function defaultIsValid(): boolean {
		return props.default !== undefined && props.options.filter((option) => option.value === props.default).length > 0
	}

	const _default = defaultIsValid() ? props.default : props.options[0].value
	const [selected, setSelected] = useState<string | undefined>(undefined)

	function onSelect(value: string): void {
		setSelected(value)
		props.onSelect(value)
	}

	useEffect(
		function setInitialInParent() {
			props.onSelect(_default)
		},
		[_default]
	)

	const renderOption: FC<RadioButtonValues> = (option: RadioButtonValues, index: number) => {
		const isChecked = selected === option.value || (selected === undefined && option.value === props.default)

		return (
			<RadioButton
				key={option.value}
				label={option.label}
				value={option.value}
				groupName={option.groupName}
				color={option.color}
				onSelect={onSelect}
				checked={isChecked}
			/>
		)
	}

	return (
		<fieldset>
			<legend>{props.legend}</legend>
			{props.options.map((option, i) => renderOption(option, i))}
		</fieldset>
	)
}
