import React, { FC } from 'react'
import { P, ParagraphProps } from 'components/basic/P'
import { Heading, HeadingProps } from 'components/basic/Heading'
import cx from 'classnames'

export interface Props extends ParagraphProps, HeadingProps {
	variant?: string
}

export const HP: FC<Props> = (props) => {
	return (
		<>
			<Heading
				title={props.title}
				type={props.type}
				titleClass={cx({ 'h3 tac': props.variant === 'primary' }, props.titleClass)}
				headingLink={props.headingLink}
			/>
			<P
				paragraphClass={cx({ 'large tac': props.variant === 'primary' }, props.paragraphClass)}
				paragraphs={props.paragraphs}
			/>
		</>
	)
}
