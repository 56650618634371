import { IconProps } from './Icon.types'
import { icons } from './mapping'
import { FC } from 'react'

export const Icon: FC<IconProps> = ({ name, size = 24, color = 'white', className = '', accentColor, ariaLabel }) => {
	const IconSVG = icons[name]

	if (!IconSVG) {
		console.warn(`Icon with name ${name} not found.`)
		return icons['CloseCircleDark']({ size, color, className })
	}

	return (
		<IconSVG
			size={size}
			color={color}
			accentColor={accentColor}
			className={className}
			ariaLabel={ariaLabel || name}
		/>
	)
}
