import { IABTest, isABTestActiveVariant } from 'ab_testing'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

export default function useABTestVariant(abTest: IABTest, variantId: number, abTestIdsCookie?: string) {
	const router = useRouter()
	const [isTestVariantActive, setIsTestVariantActive] = useState<boolean>(isABTestActiveVariant(router.asPath, abTest, variantId, abTestIdsCookie))

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsTestVariantActive(isABTestActiveVariant(router.asPath, abTest, variantId))
		}
	}, [router.asPath, setIsTestVariantActive, abTest, variantId])

	return isTestVariantActive
}

// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
