import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 12.75V15.6667C3 16.403 3.59695 17 4.33333 17H19.6667C20.403 17 21 16.403 21 15.6667V12.75H20.9999H3ZM20.4233 11.25L17.8812 6.68469C17.6459 6.26203 17.2001 6 16.7163 6H7.73193C7.27606 6 6.85179 6.2329 6.60705 6.6175L3.65909 11.25H20.4233Z"
			fill={color}
		/>
	</svg>
)

export default icon
