import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M15.6465 15.629C17.44 15.9952 19.3016 15.8265 21 15.1438C20.3048 16.8732 19.1077 18.3551 17.5629 19.3987C16.0181 20.4424 14.1962 21.0001 12.3317 21C10.1634 20.9977 8.06352 20.2414 6.39193 18.861C4.72034 17.4806 3.58119 15.5619 3.16975 13.4339C2.75831 11.3058 3.1002 9.10101 4.13683 7.19736C5.17345 5.29371 6.84025 3.80979 8.85147 3C8.1685 4.6977 7.99973 6.55862 8.36614 8.35141C8.73255 10.1442 9.61799 11.7898 10.9124 13.0837C12.2068 14.3776 13.853 15.2627 15.6465 15.629Z"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.8572 6L18 11.1429M12.8572 11.1429L18 6L12.8572 11.1429Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
