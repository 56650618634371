import * as React from 'react'
import { FC, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './ShopCarousel.module.scss'
import { Colors } from 'components/WebEv/Colors/colors'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { WhatsIncluded } from 'components/WebEv/Shop/HelperComponents/WhatsIncluded/WhatsIncluded'
import { amClickedButton } from 'events/amplitude'
import { arrow_left, arrow_right, Icon } from 'components/Icon'
import { ContentWithSteps, Image, VariableImage, Video } from '../../types'
import { mod } from 'components/_utils/mathUtils'
import { useSwipe } from 'components/_hooks/useSwipe'
import { useShop } from 'components/WebEv/Shop/context/ShopProvider'

export interface ShopCarouselProps {
	items: ContentWithSteps[]
	highlightedSection?: string
	startingSlide?: number
	style?: React.CSSProperties
	className?: string
}

export const ShopCarousel: FC<ShopCarouselProps> = (props) => {
	const container = useRef<HTMLDivElement>(null)
	const [currentSlide, setCurrentSlide] = React.useState(props.startingSlide ?? 0)
	const videoGifRefs = useRef<HTMLVideoElement[]>([])

	useEffect(() => {
		const index = props.items.findIndex((item) => item.associatedSteps.includes(props.highlightedSection))
		if (index === -1) return
		setCurrentSlide(index)
	}, [props.highlightedSection])

	useEffect(() => {
		if (props.items[currentSlide]?.whiteDots) {
			container.current.style.setProperty('--dot-color', Colors.Neutral.white.hex)
		} else {
			container.current.style.setProperty('--dot-color', Colors.Neutral.black.hex)
		}

		// Play the video gif if it exists
		const videoGifRef = videoGifRefs.current[currentSlide]
		if (videoGifRef) {
			videoGifRef.play()
		}

		// Pause all other video gifs
		videoGifRefs.current.forEach((videoGifRef, index) => {
			if (index !== currentSlide && videoGifRef) {
				videoGifRef.pause()
				videoGifRef.currentTime = 0
			}
		})
	}, [currentSlide])

	useSwipe(
		container,
		(direction) => {
			if (direction === 'right') {
				setCurrentSlide((prev) => mod(prev - 1, props.items.length))
			} else if (direction === 'left') {
				setCurrentSlide((prev) => mod(prev + 1, props.items.length))
			}
		},
		50,
		300
	)

	const items = []

	props.items.forEach((contentWithSteps, index) => {
		const item = contentWithSteps.content
		switch (item.type) {
			case 'image': {
				const image = item.data as Image
				items.push(
					<Img
						src={image.src}
						alt={image.alt}
						objectFit={'cover'}
						className={styles.carousel_photo}
					/>
				)
				break
			}
			case 'variable-image': {
				const image = item.data as {
					images: VariableImage[]
					defaultImage: Image
				}
				items.push(
					<VariableImg
						{...image}
						associatedSection={contentWithSteps.associatedSteps[0]}
						className={styles.carousel_photo}
					/>
				)
				break
			}
			case 'video-gif': {
				const video = item.data as Video
				items.push(
					<VideoGif
						src={video.src}
						poster={video.poster}
						autoPlay={false}
						ref={(ref) => {
							videoGifRefs.current[index] = ref as HTMLVideoElement
						}}
						className={styles.carousel_video}
					/>
				)
				break
			}
			case 'video': {
				const video = item.data as Video
				items.push(
					<ShopCarouselVideo
						src={video.src}
						poster={video.poster}
						forcePause={currentSlide !== index}
					/>
				)
				break
			}

			case 'whats-included': {
				items.push(<WhatsIncluded />)
				break
			}
		}
	})

	return (
		<div
			ref={container}
			className={cx(styles.slider_outer, props.className)}
			style={props.style}
		>
			<ul className={styles.slider_inner}>
				{items.map((item, index) => (
					<li
						key={index}
						className={styles.item}
						style={{
							opacity: currentSlide === index ? 1 : 0,
							pointerEvents: currentSlide === index ? 'all' : 'none',
						}}
					>
						{item}
					</li>
				))}
			</ul>
			<div>
				<LeftArrow
					onClick={() => {
						setCurrentSlide((prev) => mod(prev - 1, props.items.length))
						amClickedButton('Previous Arrow', 'prev-arrow')
					}}
				/>
				<RightArrow
					onClick={() => {
						setCurrentSlide((prev) => mod(prev + 1, props.items.length))
						amClickedButton('Next Arrow', 'next-arrow')
					}}
				/>
			</div>
			<ul className={styles.carousel_dots}>
				{props.items.map((item, index) => (
					<li
						key={index}
						className={styles.carousel_dot}
						style={{
							opacity: currentSlide === index ? 1 : 0.2,
						}}
					>
						<button
							onClick={() => {
								setCurrentSlide(index)
								amClickedButton('Carousel Dot', 'carousel-dot')
							}}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

const LeftArrow = (props: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) => (
	<button
		className={styles.arrow}
		aria-label="Previous slide"
		onClick={(e) => {
			amClickedButton('Previous Arrow', 'prev-arrow')
			props.onClick && props.onClick(e)
		}}
	>
		<Icon
			icon={arrow_left}
			color={Colors.Neutral.black.hex}
		/>
	</button>
)

const RightArrow = (props: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) => (
	<button
		className={styles.arrow}
		aria-label="Next slide"
		onClick={(e) => {
			amClickedButton('Next Arrow', 'next-arrow')
			props.onClick && props.onClick(e)
		}}
	>
		<Icon
			icon={arrow_right}
			color={Colors.Neutral.black.hex}
		/>
	</button>
)

interface VideoProps {
	src: string
	poster?: string
	autoPlay?: boolean
	forcePause?: boolean
}

export const ShopCarouselVideo: FC<VideoProps> = ({ autoPlay = false, ...props }) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const [playing, setPlaying] = useState(autoPlay)

	useEffect(() => {
		if (props.forcePause && videoRef.current) {
			videoRef.current.pause()
			videoRef.current.currentTime = 0
			setPlaying(false)
		}
	}, [props.forcePause])

	return (
		<figure className={styles.video_wrapper}>
			{!playing && (
				<button
					onClick={() => {
						if (videoRef.current) {
							videoRef.current.play()
							setPlaying(true)
						}
					}}
				>
					<img
						src="https://eightsleep.imgix.net/play_button.svg?v=1623412594"
						alt="play"
					/>
				</button>
			)}
			<video
				playsInline
				disablePictureInPicture
				disableRemotePlayback
				preload="none"
				loop={false}
				muted={true}
				autoPlay={autoPlay}
				controls={playing}
				ref={videoRef}
				src={props.src}
				poster={props.poster}
				onPause={() => {
					setPlaying(false)
				}}
				onPlay={() => {
					if (props.forcePause) return
					setPlaying(true)
				}}
			/>
		</figure>
	)
}

interface VariableImgProps {
	images: VariableImage[]
	defaultImage: Image
	className?: string
	associatedSection: string
}

const VariableImg: FC<VariableImgProps> = (props) => {
	const { state } = useShop()

	const activeSelections = state.activeSelections
	const activeSelection = activeSelections[props.associatedSection]
	const activeSelectionValue = activeSelection?.value

	const matchingImage = props.images.find((image) => image.selectionValues.includes(activeSelectionValue))

	const defaultImageNeeded = !matchingImage || !activeSelectionValue

	return (
		<div className={styles.image_stack}>
			{defaultImageNeeded && (
				<Img
					src={props.defaultImage.src}
					alt={props.defaultImage.alt}
					objectFit={'cover'}
					className={styles.carousel_photo}
				/>
			)}
			{props.images.map((image, index) => (
				<div
					key={index}
					style={{
						opacity: image.selectionValues.includes(activeSelectionValue) ? 1 : 0,
					}}
				>
					<Img
						src={image.src}
						alt={image.alt}
						objectFit={'cover'}
						className={styles.carousel_photo}
					/>
				</div>
			))}
		</div>
	)
}
