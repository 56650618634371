export const getErrorMessage = (selection: string) => {
	switch (selection) {
		case 'size':
			return 'Please select a size'
		case 'color':
			return 'Please select a color'
		case 'model':
			return 'Please select a model'
		default:
			return 'Please select an option'
	}
}
