import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M6.25863 12.5128C6.00531 12.3223 5.52327 12.0043 5.0678 11.9367C4.53134 11.8565 3.60938 12.2106 3.76812 13.4187C3.87631 14.2424 7.62181 17.9908 9.29857 19.6955C9.76311 20.1678 10.2037 20.6664 10.7835 21.006C11.8176 21.6117 12.9035 21.8554 14.1105 21.7083C15.3266 21.5605 16.393 21.1138 17.2599 20.2722C17.9564 19.5963 18.6555 18.9144 19.2749 18.1576C19.9066 17.3858 20.2111 16.497 20.2409 15.5037C20.2668 14.6392 20.239 13.7747 20.1366 12.9181C20.0504 12.2008 19.8586 11.4979 19.7388 10.7864C19.5813 9.8477 19.2853 8.95232 19.0527 8.03659C18.9108 7.47821 18.4216 7.10311 17.9175 7.05844C17.2392 6.99866 16.7351 7.44602 16.5641 7.87499C16.4773 8.09308 16.4708 8.32563 16.4591 8.56146C16.4319 9.09093 16.579 10.6905 16.5693 11.0078C16.5693 11.0078 15.6778 9.96266 15.462 9.74325C14.3923 8.65606 13.311 7.58135 12.2381 6.49744C11.8124 6.06716 10.7317 4.86238 10.0527 4.57137C9.37372 4.28036 7.57386 4.86238 8.46148 6.62882C8.95194 7.27325 9.54606 7.81981 10.1071 8.39723C10.5341 8.83736 10.9669 9.27093 11.3978 9.70777C11.9355 10.2537 12.4674 10.8061 13.0162 11.3402L8.46084 6.62882L7.52138 5.78206C7.06462 5.44638 6.55537 5.36689 6.04224 5.6198C5.49087 5.89111 5.22135 6.36803 5.2369 6.99012C5.24403 7.28508 5.36389 7.55179 5.55696 7.77514C5.73513 7.98141 6.74002 9.00619 7.00955 9.28144C8.31959 10.6156 9.63353 11.9459 10.9455 13.2781L6.41931 8.67576C6.16598 8.48526 5.74874 8.16731 5.29327 8.09965C4.75681 8.01951 3.83485 8.37358 3.99358 9.58165C4.10178 10.4054 8.96296 15.3493 8.96296 15.3493"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.1704 3.06244L13.5795 4.61507"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.9397 3.91446L17.1898 5.06948"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.6253 2.41806L15.4567 4.64779"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
