import { FC } from 'react'
import styles from './WhatsIncluded.module.scss'
import { Img } from 'components/basic/Img'
import { ShopState, WhatsIncludedItem } from 'components/WebEv/Shop/types'
import { useShop } from 'components/WebEv/Shop/context/ShopProvider'

const getItems = (state: ShopState): WhatsIncludedItem[] => {
	// TODO we can eventually make this more complicated, but for now,
	// let's just assume that we only have 2 layouts, and that the only determining
	// factor is whether or not the user has added the mattress upsell.

	if (state.activeSelections['mattress'].value === 'true') return ['mattress', 'cover', 'hub', 'power-cord']
	return ['cover', 'hub', 'power-cord']
}

export const WhatsIncluded: FC = () => {
	const { state } = useShop()

	const items = getItems(state)

	const layoutImage = getLayoutImage(items)

	return (
		<Img
			src={layoutImage}
			alt={''}
			dprHeight={1200}
			className={styles.layout_image}
		/>
	)
}

const knownLayouts: WhatsIncludedItem[][] = [
	['mattress', 'cover', 'hub', 'power-cord'],
	['cover', 'hub', 'power-cord'],
]

const getLayoutImage = (items: WhatsIncludedItem[]): string => {
	const copiedItems = [...items]
	const layout = knownLayouts.find((layout) => layout.sort().join('') === copiedItems.sort().join(''))
	if (layout.includes('mattress')) {
		return 'https://eightsleep.imgix.net/witbmattress.jpg?v=1694701345'
	}

	return 'https://eightsleep.imgix.net/witbnewcover.jpg?v=1694640681'
}
