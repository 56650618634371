import { FC } from 'react'
import styles from './CardSelectionItem.module.scss'
import { CardSelectionItemProps, StandardCardSelectionItemProps } from './types'
import { Icon } from 'components/Phantom/Icon'
import cx from 'classnames'
import { Badge } from 'components/Badge'
import { Type } from 'components/Type'

export const CardSelectionItem: FC<CardSelectionItemProps> = (props) => {
	const { highlightColor = '#0038FF', selected, error } = props

	const boxShadowColor = error ? '#df1c2f' : selected ? highlightColor : '#d3d3d3'
	const boxShadowWidth = selected ? '2px' : '1px'

	return (
		<div
			className={cx(styles.container, { [styles.container_selected]: selected })}
			style={{
				boxShadow: `${boxShadowColor} 0 0 0 ${boxShadowWidth} inset`,
			}}
		>
			<RenderedContent {...props} />
		</div>
	)
}

const RenderedContent = (props: CardSelectionItemProps) => {
	switch (props.type) {
		case 'standard':
			return <StandardCardSelectionItem {...props.data} />
		default:
			return null
	}
}

export const StandardCardSelectionItem: FC<StandardCardSelectionItemProps> = (props) => {
	const { title, description, subtitle, badge, checklistTitle, checklist } = props
	const isEnhancedPlanCustomization = title === 'Enhanced'

	return (
		<div className={cx(styles.standard_card, { [styles.v_align]: !checklist })}>
			<Badge.Important
				type={'discount'}
				className={styles.badge}
			>
				{badge}
			</Badge.Important>
			<div className={styles.standard_titles}>
				<Type.Headline6
					as={'p'}
					className={styles.title}
				>
					{title}
				</Type.Headline6>
				<Type.Headline6
					as={'p'}
					className={styles.subtitle}
				>
					{subtitle}
				</Type.Headline6>
				<Type.Body3 className={styles.description}>{description}</Type.Body3>
			</div>

			<Checklist {...props} />

			{isEnhancedPlanCustomization && (
				<div className={styles.enhanced_plan_wrapper}>
					<Type.Body3 className={styles.enhanced_plan_title}>Everything in Standard, plus:</Type.Body3>
					<div className={styles.enhanced_plan_banner}>
						<Icon
							name={'ShieldCheckLight'}
							color={'#1862FF'}
							size={24}
						/>
						<Type.Body3>Peace of mind with 5-year warranty</Type.Body3>
					</div>
				</div>
			)}
		</div>
	)
}

const Checklist: FC<StandardCardSelectionItemProps> = (props) => {
	const { checklist, checklistTitle, title } = props
	if (!checklist && !checklistTitle) return null

	const isEnhancedPlanCustomization = title === 'Enhanced'

	return (
		<div className={styles.checklist_wrapper}>
			<Type.Body3 className={styles.checklist_title}>{checklistTitle}</Type.Body3>
			{checklist?.length > 0 && (
				<ul className={styles.checklist}>
					{checklist.map((item, index) => (
						<li key={index}>
							<Icon
								name={'CheckDark'}
								color={'#999999'}
								size={16}
								className={isEnhancedPlanCustomization ? 'hide_xs' : ''}
							/>
							<Type.Body3>{item}</Type.Body3>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
