import { FC } from 'react'
import styles from './Selectors.module.scss'
import { SelectorProps, SelectorsProps, StandardCardData, StandardCheckboxData } from './Selectors.types'
import { observer } from 'mobx-react'
import { CardSelection } from 'components/Phantom/_shop/CardSelection'
import { useRootStore } from 'components/_hooks/useRootStore'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import { ModalButtons } from 'components/Phantom/_shop/Selection'
import { CheckboxSelection } from 'components/Phantom/_shop/CheckboxSelection'
import { SelectionSummary } from 'components/Phantom/_shop/SelectionSummary'
import { Visuals } from 'components/Phantom/_shop/Visuals'
import { PricingInfo } from 'components/Phantom/_shop/PricingInfo'
import { Type } from 'components/Type'

export const SelectorsConnected = observer(() => {
	const { shopStore } = useRootStore()

	const selectors = shopStore.currentSelectors
	const selectorsProps = selectors.map((selectorKey) => shopStore.getSelectorProps(selectorKey)).filter((x) => !!x)

	const currentVisuals = shopStore.getCurrentVisuals()

	return (
		<Selectors
			selectors={selectorsProps}
			currentSelections={shopStore.getCurrentSelections()}
			currentVisuals={currentVisuals}
			handleSelectionChange={shopStore.handleSelectionChange}
			modalClick={shopStore.selectModal}
			error={shopStore.error}
		/>
	)
})

export const Selectors: FC<SelectorsProps> = (props) => {
	const { selectors, currentSelections, currentVisuals, error } = props
	if (!selectors) return null

	const { handleSelectionChange, modalClick } = props

	return (
		<ul className={cx(styles.container)}>
			{selectors.map((selector, index) => (
				<li
					key={index}
					id={`selector-${selector.selectorKey}`}
				>
					{/* We render the first visual on top of the product title separately */}
					{index !== 0 && (
						<div className={styles.mobile_visual}>
							<Visuals
								visuals={currentVisuals}
								currentSelectorKey={selector.selectorKey}
								renderSingle={true}
								currentSelections={currentSelections}
							/>
						</div>
					)}
					<SelectorRendered
						{...selector}
						onChange={(selectionKey, optionKey) => handleSelectionChange(selector.selectorKey, selectionKey, optionKey)}
						currentSelections={currentSelections}
						modalClick={modalClick}
						error={error}
					/>
				</li>
			))}
		</ul>
	)
}

export const SelectorRendered: FC<SelectorProps> = (props) => {
	const { type, data, currentSelections, modalButtons, error, selectorKey, title, description, smallDescription, displayPricing } = props

	const { onChange, modalClick } = props

	const getSelectionComponent = () => {
		switch (type) {
			case 'standard-cards':
				return (
					<CardSelection
						options={data.options}
						onChange={onChange}
						selectionKey={data.selectionKey}
						currentSelections={currentSelections}
					/>
				)
			case 'standard-checkboxes':
				return (
					<CheckboxSelection
						options={data.options}
						onChange={onChange}
						currentSelections={currentSelections}
						onModalTrigger={modalClick}
					/>
				)
			case 'summary':
				return null
		}
	}

	const getSelectorError = () => {
		if (!error) return ''
		switch (type) {
			case 'standard-cards': {
				const _data = data as StandardCardData
				if (_data.selectionKey === error.associatedSelectionId) {
					return error.errorText
				}
				break
			}
			case 'standard-checkboxes': {
				const _data = data as StandardCheckboxData
				if (_data.options.find((o) => o.selectionKey === error.associatedSelectionId)) {
					return error.errorText
				}
				break
			}
			case 'summary':
				if (error.associatedSelectionId === 'summary') {
					return error.errorText
				}
				break
		}
		return ''
	}

	if (type === 'summary') {
		return <SelectionSummary {...data} />
	}

	const selectorError = getSelectorError()

	return (
		<section className={cx(styles.selector_container, 'shop-section')}>
			<header className={styles.selection_header}>
				<Type.Headline5
					as={'h3'}
					className={styles.selection_title}
				>
					{title}
				</Type.Headline5>
				{/*TODO: if we _do_ re-enable this, we want to make sure it's unique to the top selector*/}
				{/*<a*/}
				{/*	className={'affirm-site-modal'}*/}
				{/*	data-provider="Affirm"*/}
				{/*	data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"*/}
				{/*	role="button"*/}
				{/*	tabIndex={0}*/}
				{/*	aria-label="Pre qualify button for affirm"*/}
				{/*	id={'affirm-info-cta-button'}*/}
				{/*	style={{ visibility: 'hidden', position: 'absolute', pointerEvents: 'none' }}*/}
				{/*>*/}
				{/*	Learn more.*/}
				{/*</a>*/}
				{displayPricing && <PricingInfo {...displayPricing} />}
				<Type.Body2 className={styles.selection_subtitle}>{description}</Type.Body2>
				<Type.SmallPrint className={styles.selection_subtitle}>{smallDescription}</Type.SmallPrint>
			</header>
			<fieldset>
				<legend className={'vh'}>{title} selection</legend>
				{getSelectionComponent()}
			</fieldset>
			{selectorError && (
				<div className={styles.error_box}>
					<Icon
						name={'ExclamationCircleLight'}
						color={'#df1c2f'}
					/>
					<p>{selectorError}</p>
				</div>
			)}
			<ModalButtons
				modalButtons={modalButtons}
				modalClick={modalClick}
			/>
		</section>
	)
}
