import { FC, HTMLAttributes, PropsWithChildren } from 'react'

export interface BadgeProps extends PropsWithChildren, HTMLAttributes<HTMLSpanElement> {
	className?: string
	type?: 'eyebrow' | 'discount'
	animateOnScroll?: boolean
}

export interface BadgeInteriorProps extends Partial<BadgeProps> {
	textColor?: string
	backgroundColor?: string
	borderColor?: string
	backdropBlur?: boolean
}

export type BadgeSubComponent = FC<BadgeProps>

interface BadgeSubComponents {
	Outline: BadgeSubComponent
	Orange: BadgeSubComponent
	BFCMLight: BadgeSubComponent
	BFCMLight2: BadgeSubComponent
	BFCMDark: BadgeSubComponent
	Important: BadgeSubComponent
	Blue: BadgeSubComponent
	Glass: BadgeSubComponent
	Grey: BadgeSubComponent
}

export type BadgeComponent = FC<BadgeInteriorProps> & BadgeSubComponents

export const BadgeComponentProps: Record<keyof BadgeSubComponents, BadgeInteriorProps> = {
	Outline: {
		borderColor: '#a6a6a6',
		textColor: '#a6a6a6',
	},
	Orange: {
		textColor: '#ff5b00',
		backgroundColor: '#ff5b0019',
	},
	BFCMLight: {
		textColor: '#9C6D26',
		backgroundColor: '#D09E5B66',
	},
	BFCMLight2: {
		textColor: '#fff',
		backgroundColor: '#D09E5B66',
	},
	BFCMDark: {
		textColor: '#f6c58d',
		backgroundColor: '#000',
	},
	Important: {
		backgroundColor: '#0038ff',
		textColor: '#ffffff',
	},
	Blue: {
		textColor: '#5289fe',
		backgroundColor: '#5289fe4c',
	},
	Glass: {
		textColor: '#ffffff',
		backdropBlur: true,
		backgroundColor: '#ffffff3f',
	},
	Grey: {
		textColor: '#000000',
		backgroundColor: '#f1f2f4',
	},
}
