export enum ACCESSORIES_FILTER {
	MadeForPod = 'Made for the Pod',
	WindDown = 'Wind down',
	BoostYourRecovery = 'Boost your recovery',
	NewPodTechnology = 'New Pod Technology',
	TheBase = 'The Base (compatible only with Pod 4)',
}

export const AccessoriesPageConfig = {
	filters: [ACCESSORIES_FILTER.MadeForPod, ACCESSORIES_FILTER.WindDown, ACCESSORIES_FILTER.BoostYourRecovery],
	defaultFilterSelection: ACCESSORIES_FILTER.MadeForPod,
}

export const MemberAccessoriesPageConfig = {
	filters: [ACCESSORIES_FILTER.NewPodTechnology, ACCESSORIES_FILTER.MadeForPod, ACCESSORIES_FILTER.WindDown, ACCESSORIES_FILTER.BoostYourRecovery],
	defaultFilterSelection: ACCESSORIES_FILTER.NewPodTechnology,
}

export const MemberAccessoriesPageConfigInternational = {
	filters: [ACCESSORIES_FILTER.NewPodTechnology],
	defaultFilterSelection: ACCESSORIES_FILTER.NewPodTechnology,
}

export const queryToFilterMap = {
	all: 'View All',
	best: 'Best Sellers',
	bundles: 'Bundle and Save',
	furniture: 'Furniture',
	bedding: 'Bedding',
	'sleep-fitness': 'Sleep Fitness',
}
