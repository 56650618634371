import React from 'react'

export const AffirmLogo = ({ className = 'affirm', textColor = '#060809' }) => (
	<svg
		viewBox="0 0 420 167"
		className={className}
	>
		<g
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<path
				fill={textColor}
				d="M34.3 94.1c-11.3 0-24.4 5.4-31.5 11l6.5 13.6c5.7-5.2 14.9-9.6 23.2-9.6 7.9 0 12.2 2.6 12.2 7.9 0 3.6-2.9 5.4-8.3 6.1C16 125.7 0 131.3 0 147c0 12.4 8.9 20 22.7 20 9.9 0 18.7-5.5 22.9-12.7V165H64v-44.9c0-18.5-12.9-26-29.7-26zm-6.9 58.5c-5.2 0-7.8-2.5-7.8-6.7 0-7.8 8.7-10.4 24.6-12.1.1 10.4-7 18.8-16.8 18.8zm210.1-44.9V96.1h-18.4V165h19.7v-33.2c0-15.8 9.5-20.4 16.2-20.4 2.6 0 6.1.8 8.4 2.5l3.6-18.2c-3-1.3-6.2-1.6-8.8-1.6-10.1 0-16.5 4.5-20.7 13.6zm120.9-13.6c-10.4 0-18.2 6.2-22.3 12.1-3.8-7.7-11.7-12.1-21.3-12.1-10.4 0-17.6 5.8-21 12.4V96.1h-19V165h19.7v-35.5c0-12.7 6.7-18.8 12.9-18.8 5.6 0 10.8 3.6 10.8 13V165H338v-35.5c0-12.9 6.5-18.8 13-18.8 5.2 0 10.7 3.8 10.7 12.9V165h19.7v-47.6c0-15.5-10.4-23.3-23-23.3zM133.6 91v5.1h-29.9v-7c0-9.1 5.2-11.7 9.7-11.7 2.6 0 6 .6 8.8 2.2l6.1-13.9c-3.6-2.1-9.5-4-17.4-4-12.6 0-26.9 7.1-26.9 29.4v5.1H72.6v15.2H84V165h19.7v-53.7h29.9V165h19.7v-53.7h17.9V96.1h-17.9v-7c0-9.1 5.2-11.7 9.7-11.7 5 0 8.8 2.2 8.8 2.2l6.1-13.9s-6.2-4-17.4-4c-12.5-.1-26.9 7-26.9 29.3zm49.3 5.1h19.7V165h-19.7z"
			/>
			<path
				fill={'#0fa0ea'}
				d="M297.7 0c-53.2 0-100.6 36.9-114 84.4H203C214.2 49 252.3 18 297.7 18c55.2 0 102.8 42 102.8 107.4 0 14.7-1.9 27.9-5.5 39.6h18.7l.2-.6c3.1-12.1 4.6-25.2 4.6-39C418.5 52.5 365.4 0 297.7 0"
			/>
		</g>
	</svg>
)

export const AffirmLogoWhite = (props: { className?: string }) => (
	<svg
		viewBox="0 0 420 167"
		className={props.className}
	>
		<g
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<path
				fill="white"
				d="M34.3 94.1c-11.3 0-24.4 5.4-31.5 11l6.5 13.6c5.7-5.2 14.9-9.6 23.2-9.6 7.9 0 12.2 2.6 12.2 7.9 0 3.6-2.9 5.4-8.3 6.1C16 125.7 0 131.3 0 147c0 12.4 8.9 20 22.7 20 9.9 0 18.7-5.5 22.9-12.7V165H64v-44.9c0-18.5-12.9-26-29.7-26zm-6.9 58.5c-5.2 0-7.8-2.5-7.8-6.7 0-7.8 8.7-10.4 24.6-12.1.1 10.4-7 18.8-16.8 18.8zm210.1-44.9V96.1h-18.4V165h19.7v-33.2c0-15.8 9.5-20.4 16.2-20.4 2.6 0 6.1.8 8.4 2.5l3.6-18.2c-3-1.3-6.2-1.6-8.8-1.6-10.1 0-16.5 4.5-20.7 13.6zm120.9-13.6c-10.4 0-18.2 6.2-22.3 12.1-3.8-7.7-11.7-12.1-21.3-12.1-10.4 0-17.6 5.8-21 12.4V96.1h-19V165h19.7v-35.5c0-12.7 6.7-18.8 12.9-18.8 5.6 0 10.8 3.6 10.8 13V165H338v-35.5c0-12.9 6.5-18.8 13-18.8 5.2 0 10.7 3.8 10.7 12.9V165h19.7v-47.6c0-15.5-10.4-23.3-23-23.3zM133.6 91v5.1h-29.9v-7c0-9.1 5.2-11.7 9.7-11.7 2.6 0 6 .6 8.8 2.2l6.1-13.9c-3.6-2.1-9.5-4-17.4-4-12.6 0-26.9 7.1-26.9 29.4v5.1H72.6v15.2H84V165h19.7v-53.7h29.9V165h19.7v-53.7h17.9V96.1h-17.9v-7c0-9.1 5.2-11.7 9.7-11.7 5 0 8.8 2.2 8.8 2.2l6.1-13.9s-6.2-4-17.4-4c-12.5-.1-26.9 7-26.9 29.3zm49.3 5.1h19.7V165h-19.7z"
			/>
			<path
				fill="white"
				d="M297.7 0c-53.2 0-100.6 36.9-114 84.4H203C214.2 49 252.3 18 297.7 18c55.2 0 102.8 42 102.8 107.4 0 14.7-1.9 27.9-5.5 39.6h18.7l.2-.6c3.1-12.1 4.6-25.2 4.6-39C418.5 52.5 365.4 0 297.7 0"
			/>
		</g>
	</svg>
)

export const AffirmLogoPurple = () => (
	<svg
		width="78"
		height="30"
		viewBox="0 0 78 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_184_4388)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.37 16.907C4.27143 16.907 1.83857 17.877 0.52 18.883L1.72714 21.3261C2.78571 20.392 4.49429 19.6016 6.03571 19.6016C7.50286 19.6016 8.30143 20.0686 8.30143 21.0207C8.30143 21.6674 7.76286 21.9908 6.76 22.1166C2.97143 22.5836 0 23.5896 0 26.41C0 28.6375 1.65286 30.0028 4.21571 30.0028C6.05429 30.0028 7.68857 29.0148 8.46857 27.7213V29.6435H11.8857V21.5776C11.8857 18.2543 9.49 16.907 6.37 16.907ZM5.08857 27.416C4.12286 27.416 3.64 26.9669 3.64 26.2124C3.64 24.8112 5.25571 24.3441 8.20857 24.0387C8.22714 25.907 6.90857 27.416 5.08857 27.416ZM44.1071 19.3501V17.2663H40.69V29.6435H44.3486V23.6794C44.3486 20.8411 46.1129 20.0148 47.3571 20.0148C47.84 20.0148 48.49 20.1585 48.9171 20.4639L49.5857 17.1944C49.0286 16.9609 48.4343 16.907 47.9514 16.907C46.0757 16.907 44.8871 17.7154 44.1071 19.3501ZM66.56 16.907C64.6286 16.907 63.18 18.0207 62.4186 19.0806C61.7129 17.6974 60.2457 16.907 58.4629 16.907C56.5314 16.907 55.1943 17.9489 54.5629 19.1345V17.2663H51.0343V29.6435H54.6929V23.2663C54.6929 20.9848 55.9371 19.889 57.0886 19.889C58.1286 19.889 59.0943 20.5357 59.0943 22.2243V29.6435H62.7714V23.2663C62.7714 20.9489 63.9786 19.889 65.1857 19.889C66.1514 19.889 67.1729 20.5716 67.1729 22.2064V29.6435H70.8314V21.0926C70.8314 18.3082 68.9 16.907 66.56 16.907ZM24.8114 16.3501V17.2663H19.2586V16.0088C19.2586 14.374 20.2243 13.907 21.06 13.907C21.5429 13.907 22.1743 14.0148 22.6943 14.3022L23.8271 11.8052C23.1586 11.4279 22.0629 11.0866 20.5957 11.0866C18.2557 11.0866 15.6 12.3621 15.6 16.368V17.2842H13.4829V20.0148H15.6V29.6435H19.2586V19.9968H24.8114V29.6435H28.47V19.9968H31.7943V17.2663H28.47V16.0088C28.47 14.374 29.4357 13.907 30.2714 13.907C31.2 13.907 31.9057 14.3022 31.9057 14.3022L33.0386 11.8052C33.0386 11.8052 31.8871 11.0866 29.8071 11.0866C27.4857 11.0686 24.8114 12.3441 24.8114 16.3501ZM33.9671 17.2663H37.6257V29.6435H33.9671V17.2663Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M55.2872 0.000732422C45.4072 0.000732422 36.6043 6.62948 34.1157 15.1624H37.7C39.78 8.80313 46.8557 3.23427 55.2872 3.23427C65.5386 3.23427 74.3786 10.7792 74.3786 22.5277C74.3786 25.1684 74.0257 27.5397 73.3572 29.6415H76.83L76.8671 29.5337C77.4429 27.36 77.7214 25.0067 77.7214 22.5277C77.7214 9.43187 67.86 0.000732422 55.2872 0.000732422Z"
				fill="#4A4AFE"
			/>
		</g>
		<defs>
			<clipPath id="clip0_184_4388">
				<rect
					width="78"
					height="30"
					fill="white"
				/>
			</clipPath>
		</defs>
	</svg>
)
export const AffirmLogoPurpleBlackText = () => (
	<svg
		width="62"
		height="24"
		viewBox="0 0 62 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_184_4416)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.48167 13.5246C3.84048 13.5246 1.93786 14.3006 0.906667 15.1054L1.85071 17.0599C2.67857 16.3126 4.01476 15.6803 5.22024 15.6803C6.36762 15.6803 6.99214 16.0539 6.99214 16.8156C6.99214 17.333 6.57095 17.5917 5.78667 17.6923C2.82381 18.0659 0.5 18.8707 0.5 21.127C0.5 22.909 1.79262 24.0012 3.7969 24.0012C5.23476 24.0012 6.51286 23.2108 7.12286 22.1761V23.7138H9.79524V17.2611C9.79524 14.6024 7.92167 13.5246 5.48167 13.5246ZM4.47952 21.9318C3.72429 21.9318 3.34667 21.5725 3.34667 20.9689C3.34667 19.848 4.61024 19.4743 6.91952 19.23C6.93405 20.7246 5.90286 21.9318 4.47952 21.9318ZM34.994 15.4791V13.812H32.3217V23.7138H35.1829V18.9426C35.1829 16.6719 36.5626 16.0108 37.5357 16.0108C37.9133 16.0108 38.4217 16.1258 38.7557 16.3701L39.2786 13.7545C38.8429 13.5677 38.3781 13.5246 38.0005 13.5246C36.5336 13.5246 35.604 14.1713 34.994 15.4791ZM52.5533 13.5246C51.0429 13.5246 49.91 14.4156 49.3145 15.2635C48.7626 14.1569 47.6152 13.5246 46.2209 13.5246C44.7105 13.5246 43.6648 14.3581 43.1709 15.3066V13.812H40.4114V23.7138H43.2726V18.612C43.2726 16.7869 44.2457 15.9102 45.1462 15.9102C45.9595 15.9102 46.7148 16.4276 46.7148 17.7785V23.7138H49.5905V18.612C49.5905 16.7581 50.5345 15.9102 51.4786 15.9102C52.2338 15.9102 53.0326 16.4563 53.0326 17.7641V23.7138H55.8938V16.8731C55.8938 14.6456 54.3833 13.5246 52.5533 13.5246ZM19.9038 13.0791V13.812H15.5612V12.806C15.5612 11.4983 16.3164 11.1246 16.97 11.1246C17.3476 11.1246 17.8414 11.2108 18.2481 11.4408L19.134 9.44316C18.6112 9.14137 17.7543 8.86831 16.6069 8.86831C14.7769 8.86831 12.7 9.88867 12.7 13.0935V13.8264H11.0443V16.0108H12.7V23.7138H15.5612V15.9965H19.9038V23.7138H22.765V15.9965H25.3648V13.812H22.765V12.806C22.765 11.4983 23.5202 11.1246 24.1738 11.1246C24.9 11.1246 25.4519 11.4408 25.4519 11.4408L26.3379 9.44316C26.3379 9.44316 25.4374 8.86831 23.8107 8.86831C21.9952 8.85394 19.9038 9.8743 19.9038 13.0791ZM27.064 13.812H29.9252V23.7138H27.064V13.812Z"
				fill="#060809"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M43.7378 0C36.0111 0 29.1269 5.30299 27.1807 12.1293H29.9838C31.6104 7.04192 37.144 2.58683 43.7378 2.58683C51.755 2.58683 58.6683 8.62276 58.6683 18.0216C58.6683 20.1341 58.3923 22.0311 57.8695 23.7126H60.5854L60.6145 23.6263C61.0647 21.8874 61.2826 20.0048 61.2826 18.0216C61.2826 7.54491 53.5704 0 43.7378 0Z"
				fill="#4A4AFE"
			/>
		</g>
		<defs>
			<clipPath id="clip0_184_4416">
				<rect
					width="61"
					height="24"
					fill="white"
					transform="translate(0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export const AffirmBoxedLogo = () => (
	<svg
		viewBox="0 0 34 23"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect
			x="0.5"
			y="0.5"
			width="33"
			height="21.3748"
			rx="3.5"
			fill="white"
		/>
		<rect
			x="0.5"
			y="0.5"
			width="33"
			height="21.3748"
			rx="3.5"
			stroke="#F9F7F6"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.6344 11.9691H13.5255V11.534C13.5255 10.968 13.8489 10.806 14.1278 10.806C14.4356 10.806 14.6754 10.9426 14.6754 10.9426L15.0529 10.0798C15.0529 10.0798 14.6702 9.82964 13.9745 9.82964C13.1924 9.82964 12.3024 10.2706 12.3024 11.6544V11.9691H10.446V11.534C10.446 10.968 10.7693 10.806 11.0477 10.806C11.2062 10.806 11.4194 10.8429 11.5958 10.9426L11.9733 10.0798C11.748 9.94722 11.386 9.82964 10.895 9.82964C10.1128 9.82964 9.2229 10.2706 9.2229 11.6544V11.9691H8.51281V12.9132H9.2229V16.2487H10.446V12.9132H12.3024V16.2487H13.5255V12.9132H14.6344V11.9691ZM5.7049 15.4808C5.38329 15.4808 5.22306 15.3229 5.22306 15.0618C5.22306 14.5788 5.76369 14.4134 6.75044 14.3091C6.75044 14.9552 6.31355 15.4808 5.7049 15.4808ZM6.13026 11.8433C5.42536 11.8433 4.61383 12.1759 4.17349 12.5269L4.57579 13.3736C4.92911 13.0503 5.50029 12.7742 6.01557 12.7742C6.50491 12.7742 6.77522 12.9379 6.77522 13.267C6.77522 13.4895 6.59597 13.6013 6.25707 13.6451C4.99251 13.8099 4 14.1586 4 15.1338C4 15.9068 4.55043 16.3748 5.41038 16.3748C6.02364 16.3748 6.57004 16.0336 6.82998 15.5834V16.2485H7.9735V13.4618C7.9735 12.3108 7.1735 11.8433 6.13026 11.8433ZM17.6139 11.9691V16.2487H18.8381V14.187C18.8381 13.2066 19.4312 12.919 19.8451 12.919C20.0064 12.919 20.2243 12.9657 20.3684 13.0734L20.5915 11.942C20.4024 11.8613 20.2047 11.8435 20.0427 11.8435C19.4134 11.8435 19.018 12.1224 18.7569 12.6884V11.9691H17.6139ZM24.8832 12.5952C25.1351 12.2263 25.6187 11.8436 26.2659 11.8436C27.0481 11.8436 27.6953 12.3289 27.6953 13.2908V16.2488H26.4723V13.6776C26.4723 13.111 26.1311 12.8758 25.8077 12.8758C25.4031 12.8758 24.9985 13.2459 24.9985 14.0459V16.2488H23.7754V13.6862C23.7754 13.1024 23.4538 12.8758 23.104 12.8758C22.7172 12.8758 22.3034 13.2545 22.3034 14.0459V16.2488H21.0786V11.9692H22.2584V12.6165C22.4654 12.2032 22.9132 11.8436 23.5605 11.8436C24.1535 11.8436 24.6492 12.1185 24.8832 12.5952ZM16.5866 16.2488H15.3647V11.9693H16.5866V16.2488Z"
			fill="#060809"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M22.4933 6C19.1896 6 16.2449 8.29338 15.4092 11.2421H16.6069C17.3049 9.04612 19.6743 7.11873 22.4933 7.11873C25.9199 7.11873 28.8813 9.72739 28.8813 13.7885C28.8813 14.7003 28.7631 15.5222 28.5395 16.2484H29.7014L29.713 16.2087C29.9032 15.4594 30 14.6456 30 13.7885C30 9.25937 26.6997 6 22.4933 6Z"
			fill="#0FA0EA"
		/>
	</svg>
)
