import React, { FC } from 'react'
import styles from './ShopSticky.module.scss'
import { useHeaderHeightObserver } from 'components/_hooks/useHeaderHeightObserver'
import { Button } from 'components/WebEv/Buttons'

export interface StickyProps {
	stickyName: string
	forceHide?: boolean
	promoText?: string
	sizeText?: string
	price: string
	strikePrice: string

	ctaCopy: string
	ctaClick: () => void
	loading: boolean
	disabled: boolean
}

export const ShopSticky: FC<StickyProps> = (props) => {
	const headerHeight = useHeaderHeightObserver()

	return (
		<div
			className={styles.container}
			id={'sticky-bar'}
			style={{ top: `${headerHeight || 0}px` }}
		>
			<h2>{props.stickyName}</h2>
			<strong className={styles.promo_text}>{props.promoText}</strong>
			<p className={styles.size_text}>{props.sizeText}</p>
			<div className={styles.divider} />
			<p className={styles.price}>{props.price}</p>
			<s className={styles.strike_price}>{props.strikePrice}</s>
			<Button.Secondary
				id={'top-sticky-cta'}
				onClick={props.ctaClick}
				size={'compact'}
				className={styles.cta}
				loading={props.loading}
				disabled={props.disabled}
			>
				{props.ctaCopy}
			</Button.Secondary>
		</div>
	)
}
