import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12.1704 3.06244L13.5795 4.61507"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.9397 3.91446L17.1898 5.06948"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.6253 2.41806L15.4567 4.64779"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.13204 9.89885L9.33634 14.63C9.54067 14.8158 9.55573 15.132 9.36997 15.3363C9.18422 15.5407 8.868 15.5557 8.66367 15.37L4.87601 11.9266C4.33918 11.9461 3.62708 12.3453 3.76812 13.4187C3.86906 14.1872 7.13597 17.5014 8.93517 19.3266C9.06445 19.4577 9.18616 19.5812 9.29857 19.6955C9.36556 19.7636 9.43206 19.8323 9.49854 19.9009C9.89308 20.3083 10.2873 20.7154 10.7835 21.006C11.8176 21.6117 12.9035 21.8554 14.1105 21.7083C15.3266 21.5605 16.393 21.1138 17.2599 20.2722C17.9564 19.5963 18.6555 18.9144 19.2749 18.1576C19.9066 17.3858 20.2111 16.497 20.2409 15.5037C20.2668 14.6392 20.239 13.7747 20.1366 12.9181C20.0853 12.4907 19.9964 12.0685 19.9077 11.6465C19.8474 11.3602 19.7872 11.074 19.7388 10.7864C19.6309 10.143 19.4578 9.51988 19.2847 8.89664C19.2053 8.61065 19.1258 8.32462 19.0527 8.03659C18.9108 7.47821 18.4216 7.10311 17.9175 7.05844C17.2392 6.99867 16.7351 7.44602 16.5641 7.87499C16.4823 8.08035 16.4718 8.29852 16.4611 8.52019C16.4605 8.53393 16.4598 8.54769 16.4591 8.56146C16.4431 8.87262 16.4873 9.55334 16.5245 10.1262C16.5506 10.5281 16.5733 10.877 16.5693 11.0078C16.5693 11.0078 15.6778 9.96266 15.462 9.74325C14.8436 9.11473 14.2213 8.49039 13.599 7.86604C13.1449 7.41039 12.6907 6.95473 12.2381 6.49744C12.1721 6.43072 12.0903 6.34539 11.9968 6.24772C11.4869 5.71552 10.6264 4.81726 10.0527 4.57137C9.54157 4.3523 8.39522 4.62798 8.23837 5.53126L13.8536 11.1464C14.0488 11.3417 14.0488 11.6583 13.8536 11.8536C13.6583 12.0488 13.3417 12.0488 13.1465 11.8536L6.76949 5.47659C6.53149 5.45405 6.2873 5.49902 6.04224 5.61981C5.49087 5.89111 5.22135 6.36803 5.2369 6.99013C5.23748 7.01418 5.23881 7.03804 5.24087 7.06171C5.27515 7.08058 5.30774 7.10381 5.33787 7.13142L11.3379 12.6314C11.5414 12.818 11.5552 13.1343 11.3686 13.3379C11.182 13.5414 10.8657 13.5552 10.6621 13.3686L4.92466 8.10922C4.42222 8.20208 3.86762 8.62296 3.99358 9.58165C4.00335 9.65602 4.05186 9.76398 4.13204 9.89885Z"
			fill={color}
		/>
	</svg>
)

export default icon
