import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_4824)">
			<path
				d="M11.809 12V20.1"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.36 17.751C21.538 16.563 22.348 13.827 21.16 11.649C20.368 10.2 18.856 9.3 17.209 9.3H16.075C15.076 5.448 11.152 3.135 7.29997 4.134C3.44797 5.133 1.13497 9.057 2.13397 12.9C2.42197 13.998 2.96197 15.015 3.70897 15.87"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.409 15.6L11.809 12L8.20898 15.6"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_4824">
				<rect
					width="21.6"
					height="18"
					fill={color}
					transform="translate(1 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
