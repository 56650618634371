import { regionIsMetric, RegionShort } from 'utils/internationalization'

export type IRegionUnit = {
	[key in 'uk' | 'eu' | 'au']: string
}

export interface IUnits {
	[key: string]: IRegionUnit
}

export const units: IUnits = {
	'10"-16"': {
		// done
		uk: '25-40 cm',
		eu: '25-40 cm',
		au: '25-40 cm',
	},
	'10" to 16"': {
		// done
		uk: '25 to 40 cm',
		eu: '25 to 40 cm',
		au: '25 to 40 cm',
	},
	'10"-11"': {
		// done
		uk: '25-28 cm',
		eu: '25-28 cm',
		au: '25-28 cm',
	},
	'10” - 11”': {
		// done
		uk: '25 - 28 cm',
		eu: '25 - 28 cm',
		au: '25 - 28 cm',
	},
	'10" to 11"': {
		uk: '25 to 28 cm',
		eu: '25 to 28 cm',
		au: '25 to 28 cm',
	},
	'55°-110°F': {
		uk: '12°-43°C',
		eu: '12°-43°C',
		au: '12°-43°C',
	},
	'55°F-110°F': {
		uk: '12°-43°C',
		eu: '12°-43°C',
		au: '12°-43°C',
	},
	'55° and 110°F': {
		// done
		uk: '12°C and 43°C',
		eu: '12°C and 43°C',
		au: '12°C and 43°C',
	},
	'55 to 110°F': {
		uk: '12° to 43°C',
		eu: '12° to 43°C',
		au: '12° to 43°C',
	},
	'55 to 110 degrees Fahrenheit': {
		// done
		uk: '12 to 43 degrees Celsius',
		eu: '12 to 43 degrees Celsius',
		au: '12 to 43 degrees Celsius',
	},
	'12"': {
		uk: '30 cm',
		eu: '30 cm',
		au: '30 cm',
	},
	'11"': {
		uk: '27.5 cm',
		eu: '27.5 cm',
		au: '27.5 cm',
	},
	'11-16"': {
		uk: '27 - 40 cm',
		eu: '27 - 40 cm',
		au: '27 - 40 cm',
	},
	'0.01 inches': {
		// done
		uk: '0.25 millimeters',
		eu: '0.25 millimeters',
		au: '0.25 millimeters',
	},
	'7 square feet': {
		uk: '0.65 m²',
		eu: '0.65 m²',
		au: '0.65 m²',
	},
	'55° F': {
		//done
		uk: '12° C',
		eu: '12° C',
		au: '12° C',
	},
	'55°': {
		uk: '12°',
		eu: '12°',
		au: '12°',
	},
	'1"': {
		uk: '2.5 cm',
		eu: '2.5 cm',
		au: '2.5 cm',
	},
	'2"': {
		uk: '5 cm',
		eu: '5 cm',
		au: '5 cm',
	},
	'3"': {
		uk: '7.5 cm',
		eu: '7.5 cm',
		au: '7.5 cm',
	},
	'4"': {
		uk: '10 cm',
		eu: '10 cm',
		au: '10 cm',
	},
	'11 inches': {
		uk: '27.5 cm',
		eu: '27.5 cm',
		au: '27.5 cm',
	},
	'12 inches': {
		uk: '30 cm',
		eu: '30 cm',
		au: '30 cm',
	},
	'58.5 x 79.5 x 12 inches': {
		uk: '150 x 200 x 30 cm',
		eu: '150 x 200 x 30 cm',
		au: '150 x 200 x 30 cm',
	},
	'52.5 x 74.5 x 12 inches': {
		uk: '133 x 190 x 30 cm',
		eu: '133 x 190 x 30 cm',
		au: '133 x 190 x 30 cm',
	},
	'74.5 x 79.5 x 12 inches': {
		uk: '190 x 200 x 30 cm',
		eu: '190 x 200 x 30 cm',
		au: '190 x 200 x 30 cm',
	},
	'70.5 x 83.5 x 12 inches': {
		uk: '180 x 212 x 30 cm',
		eu: '180 x 212 x 30 cm',
		au: '180 x 212 x 30 cm',
	},
	// assets
	'https://eight-eightsleep.s3.amazonaws.com/dz1.mp4': {
		uk: 'https://eightsleep.imgix.net/desktop_dual_zone_active_temp_uk.mp4?v=1648042043',
		eu: 'https://eightsleep.imgix.net/desktop_dual_zone_active_temp_uk.mp4?v=1648042043',
		au: 'https://eightsleep.imgix.net/desktop_dual_zone_active_temp_uk.mp4?v=1648042043',
	},
	'https://eightsleep.imgix.net/photo_2_ea6dc694-62c8-4c66-8bf4-d319132bf130.jpg?v=1633524777': {
		uk: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
		eu: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
		au: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
	},
	'https://eightsleep.imgix.net/yourbed.svg?v=1626387755': {
		uk: 'https://eightsleep.imgix.net/Inside_Graph-2.svg?v=1648047616',
		eu: 'https://eightsleep.imgix.net/Inside_Graph-2.svg?v=1648047616',
		au: 'https://eightsleep.imgix.net/Inside_Graph-2.svg?v=1648047616',
	},
	'https://eightsleep.imgix.net/your_rythm_graph.svg?v=1626175763': {
		uk: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
		eu: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
		au: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
	},
	'https://eightsleep.imgix.net/cover_graph.svg?v=1626175816': {
		uk: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
		eu: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
		au: 'https://eightsleep.imgix.net/Inside_Graph-1.svg?v=1648047615',
	},
	'https://eightsleep.imgix.net/lifestyle_image1.jpg?v=1635847157': {
		uk: 'https://eightsleep.imgix.net/couple_compare.png?v=1648046897',
		eu: 'https://eightsleep.imgix.net/couple_compare.png?v=1648046897',
		au: 'https://eightsleep.imgix.net/couple_compare.png?v=1648046897',
	},
	'https://eightsleep.imgix.net/temp_section_3_cbd71aa9-905f-4c37-a579-ea74f6afdc7a.jpg?v=1633525094': {
		uk: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
		eu: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
		au: 'https://eightsleep.imgix.net/couple_dual_temp_metric.jpg?v=6565',
	},
	'https://eightsleep.imgix.net/blue-mattres.jpg?v=1612865871': {
		uk: 'https://eightsleep.imgix.net/temp_cool.png?v=1648046897',
		eu: 'https://eightsleep.imgix.net/temp_cool.png?v=1648046897',
		au: 'https://eightsleep.imgix.net/temp_cool.png?v=1648046897',
	},
	'https://eightsleep.imgix.net/red-mattress.jpg?v=1612865896': {
		uk: 'https://eightsleep.imgix.net/temp_warm.png?v=1648046897',
		eu: 'https://eightsleep.imgix.net/temp_warm.png?v=1648046897',
		au: 'https://eightsleep.imgix.net/temp_warm.png?v=1648046897',
	},
	'https://eightsleep.imgix.net/couple-bed-fah.png?v=1658418693': {
		uk: 'https://eightsleep.imgix.net/couple-bed-cel.png?v=1658418693',
		eu: 'https://eightsleep.imgix.net/couple-bed-cel.png?v=1658418693',
		au: 'https://eightsleep.imgix.net/couple-bed-cel.png?v=1658418693',
	},
}

export const getUnitForRegion = (unit: string, region: string): string => {
	if (!regionIsMetric(region as RegionShort)) {
		return unit
	}

	const rr = 'eu'
	return units[unit] && units[unit][rr] ? units[unit][rr] : unit
}

export const convertUnitsInString = (text: string, current_units: string[], region: string): string => {
	if (!regionIsMetric(region as RegionShort)) {
		return text
	}

	const rr = region

	current_units.forEach((unit) => {
		if (units[unit] && units[unit][rr] && text && text.replaceAll) {
			text = text.replaceAll(unit, units[unit][rr])
		}
	})

	return text
}

export const convertUnitsInArray = (array: any[], fields: string[], current_units: string[], region: string): any[] => {
	if (!regionIsMetric(region as RegionShort)) {
		return array
	}

	const rr = region
	const result = [...array]

	current_units.forEach((unit) => {
		if (units[unit] && units[unit][rr]) {
			result.forEach((it) => {
				fields.forEach((f) => {
					it[f] = it[f] && it[f].replaceAll ? it[f].replaceAll(unit, units[unit][rr]) : it[f]
				})
			})
		}
	})

	return result
}
