export const getTemperature = (fahrenheit: number, useCelsius = false, showUnit = true) => {
	const unit = getTemperatureUnit(useCelsius)
	const value = getTemperatureNumber(fahrenheit, useCelsius).toFixed(0)

	if (!showUnit) {
		return `${value}`
	}

	return `${value}${unit}`
}

export const getTemperatureNumber = (fahrenheit: number, useCelsius = false) => {
	return useCelsius ? fahrenheitToCelsius(fahrenheit) : fahrenheit
}

export const getTemperatureUnit = (useCelsius = false) => {
	return useCelsius ? '°C' : '°F'
}

export const fahrenheitToCelsius = (fahrenheit: number) => {
	return ((fahrenheit - 32) * 5) / 9
}
