import { forwardRef, VideoHTMLAttributes } from 'react'
import { srcToImgix } from 'components/basic/Img'

interface SourceProps {
	src: string
	type: string
}

interface VideoGifProps extends VideoHTMLAttributes<HTMLVideoElement> {
	sources?: SourceProps[]
}

const processVideoPoster = (videoProps: VideoGifProps) => {
	if (!videoProps) return undefined
	if (!videoProps.poster) return undefined

	return srcToImgix(videoProps.poster)
}

export const VideoGif = forwardRef<HTMLVideoElement, VideoGifProps>((props, ref) => {
	const { sources, ...videoProps } = props

	const poster = processVideoPoster(videoProps)

	return (
		<video
			playsInline
			disablePictureInPicture
			disableRemotePlayback
			autoPlay
			loop
			muted
			ref={ref}
			{...videoProps}
			poster={poster}
			key={props.src}
		>
			{sources?.map((source, index) => (
				<source
					key={index}
					src={source.src}
					type={source.type}
				/>
			))}
		</video>
	)
})

VideoGif.displayName = 'VideoGif'
