/* eslint-disable @next/next/no-img-element */
import React, { cloneElement, FC, ReactElement, useRef, useState } from 'react'
import styles from './MattressModelExplanationModal.module.scss'
import cx from 'classnames'
import { amClickedButton, amCloseMembershipModal, amOpenMembershipModal } from 'events/amplitude'
import { HP } from 'components/basic/HP'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { Img } from 'components/basic/Img'
import { accessoryDetailsIcons } from 'constants/icons'
import { RegionShort } from 'utils/internationalization'
import useIsMobile from 'hooks/useIsMobile'
import { ModalTriggerNew } from 'components/CoverModelExplanationModal'

interface MattressModelExplanationModalProps {
	rootElementId: string
	triggerElement: ReactElement
	region: RegionShort
	onOpen?: () => void
	onClose?: () => void
}

interface IMattressModelExplanation {
	region: RegionShort
}

export const MattressModelExplanationModal: FC<IMattressModelExplanation> = (props) => {
	const id = 'mattress-model-explanation-modal'

	return (
		<Modal
			rootElementId="__next"
			triggerElement={
				<ModalTriggerNew
					onClick={() => amClickedButton('Compare models link', id)}
					topText={'Need help choosing a model?'}
					bottomText={'Explore the differences in features'}
					id={id}
				/>
			}
			onOpen={amOpenMembershipModal}
			onClose={amCloseMembershipModal}
			region={props.region}
		/>
	)
}

const CheckmarkIcon = () => (
	<Img
		src={accessoryDetailsIcons.circle_checkmark}
		alt="checkmark icon"
	/>
)

const DynamicTable: FC<IMattressModelExplanation> = (props) => {
	const [selectedIndex, setSelectedIndex] = useState(3)
	const isMobile = useIsMobile()

	const isCA = props.region === 'ca'

	const CheckMarkCellCanada = () =>
		isCA ? (
			<></>
		) : (
			<td>
				<CheckmarkIcon />
			</td>
		)

	return (
		<>
			<div className={`${styles.table} ${isMobile ? styles[`show_column_${selectedIndex}`] : ''}`}>
				<table>
					<thead>
						{isMobile ? (
							<tr className={styles.tablike}>
								{isCA ? (
									<></>
								) : (
									<th
										className={selectedIndex === 3 && styles.active}
										onClick={() => setSelectedIndex(3)}
									>
										Pod 3 Max
									</th>
								)}
								<th
									className={selectedIndex === 2 && styles.active}
									onClick={() => setSelectedIndex(2)}
								>
									Pod 3
								</th>
								<th
									className={selectedIndex === 1 && styles.active}
									onClick={() => setSelectedIndex(1)}
								>
									Pod 2
								</th>
							</tr>
						) : (
							<tr>
								<th className={styles.t_left}></th>
								<th>Pod 2</th>
								<th>Pod 3</th>
								{isCA ? <></> : <th>Pod 3 Max</th>}
							</tr>
						)}
					</thead>
					<tbody>
						<tr>
							<td className={styles.t_left}>Individualized cooling & heating</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Sleep tracking</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Health tracking</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>HRV tracking</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Personalized insights</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Gentle Rise™ alarm</td>
							<td>
								<CheckmarkIcon />
							</td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Premium comfort with Invisible Sensing</td>
							<td></td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Expanded Wi-Fi connectivity</td>
							<td></td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						<tr>
							<td className={styles.t_left}>Hub with quad-core CPU</td>
							<td></td>
							<td>
								<CheckmarkIcon />
							</td>
							<CheckMarkCellCanada />
						</tr>
						{isCA ? (
							<></>
						) : (
							<tr>
								<td className={styles.t_left}>MaxChill™ layer</td>
								<td></td>
								<td></td>
								<CheckMarkCellCanada />
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</>
	)
}

const Modal: FC<MattressModelExplanationModalProps> = (props) => {
	const [modalVisible, setModalVisible] = useState(false)
	const pageWrapper = useRef(null)

	function showModal() {
		setModalVisible(true)
		if (props.onOpen) {
			props.onOpen()
		}
	}
	function hideModal() {
		setModalVisible(false)
		props.onClose?.()
	}

	useClickOutsideModal(pageWrapper, hideModal, false)

	return (
		<div className={cx('modal')}>
			{cloneElement(props.triggerElement, { onClick: showModal })}
			{modalVisible && (
				<>
					<FocusTrapWrapper active={true}>
						<div className={'modal_container'}>
							<div
								className={styles.modal_content_container}
								ref={pageWrapper}
							>
								<button
									role="button"
									aria-label="Close modal button"
									className={styles.close_button}
									onClick={hideModal}
								>
									<img
										src="https://eightsleep.imgix.net/Close_Icon_1.png?v=1583943271"
										alt="close icon"
									/>
								</button>
								<div className={cx(styles.content)}>
									<HP
										title={'Which model is right for you?'}
										type="h2"
										titleClass={cx('h5', styles.modal_h)}
										paragraphs={[]}
									/>
									<DynamicTable region={props.region} />
								</div>
							</div>
						</div>
					</FocusTrapWrapper>
				</>
			)}
		</div>
	)
}
