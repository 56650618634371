export const autopilotAB_StringMap = {
	membership_label: {
		'0': 'Membership',
		'1': 'Autopilot',
	},
	personalized: {
		'0': 'Automatically cools or warms to keep you asleep',
		'1': 'Autopilot automatically cools or warms your Pod to keep you asleep',
	},
	intelligent_heating: {
		'0': 'The Pod is designed to dynamically heat and cool throughout the night to help you sleep better.',
		'1': 'Autopilot dynamically heats and cools your Pod throughout the night to help you sleep better.',
	},
	personalize_pod: {
		'0': 'Tell the Pod a little bit about your preferences, so it can personalize your sleep experience.',
		'1': 'Tell Autopilot a little bit about your preferences, so it can personalize your sleep experience.',
	},
	cover_pod_every_night: {
		'0': 'Every night, the Pod automatically adjusts to your perfect temperature, ensuring you get into your bed ready for sleep.',
		'1': 'Every night, Autopilot automatically adjusts your Pod to your perfect temperature, ensuring you get into your bed ready for sleep.',
	},
	cover_pod_every_night_title: {
		'0': 'The Pod gets your bed ready, every night',
		'1': 'Autopilot gets your bed ready, every night',
	},
	deeper_sleep: {
		'0': 'The Pod tracks your sleep and health metrics, and adjusts your temperature based on you.',
		'1': 'Autopilot tracks your sleep and health metrics, and adjusts your Pod temperature based on your needs.',
	},
	deeper_sleep_temp: {
		'0': 'The Pod knows if you prefer sleeping warmer or cooler and will adjust accordingly.',
		'1': 'Autopilot knows if you prefer sleeping warmer or cooler and will adjust accordingly.',
	},
	membership_description: {
		'0': 'Unlock the full features of the Pod with Eight Sleep Membership. Includes 2 individual accounts per Pod.',
		'1': 'Unlock the full features of the Pod with Autopilot. One plan for you and your partner.',
	},
	membership_description_alt: {
		'0': 'Gift one year of Eight Sleep Membership to unlock the full features of the Pod. Includes 2 individual accounts per Pod.',
		'1': 'Gift one year of Autopilot to unlock the full features of the Pod. One plan for you and your partner.',
	},
	why_membership: {
		'0': 'Why does the Pod need a membership?',
		'1': 'Why do I need Autopilot?',
	},
	what_membership_gives: {
		'0': 'What membership gives you',
		'1': 'What Autopilot gives you',
	},
	track_sleep: {
		'0': 'The Pod uses sensors to track your sleep, health and bedroom conditions',
		'1': 'Autopilot uses sensors to track your sleep, health and bedroom conditions',
	},
	adjust_temperature: {
		'0': 'Based on your local weather, sleep stages and more, the Pod adjusts your bed temperature during the night',
		'1': 'Based on your local weather, sleep stages and more, Autopilot adjusts your bed temperature during the night',
	},
	improve_sleep: {
		'0': 'The Pod’s adjustments help you fall asleep faster, stay asleep longer, and wake up energized',
		'1': 'Autopilot’s adjustments help you fall asleep faster, stay asleep longer, and wake up energized',
	},
	one_membership: {
		'0': 'One membership for you and your partner',
		'1': 'One plan for you and your partner',
	},
	share_membership: {
		'0': 'Share your membership with your partner for free. One Eight Sleep membership provides the full benefits for both you and your partner so you can both sleep better every night.',
		'1': 'Share Autopilot with your partner for free. One plan provides the full benefits for both you and your partner so you can both sleep better every night.',
	},
	scheduled_temperature: {
		'0': 'Set your bedtime, deep, and REM temperatures. The Pod will automatically be ready at your bedtime temperature until you fall asleep. Once it detects you fell asleep, it will start transitioning through your other two temperatures.',
		'1': 'Set your bedtime, deep, and REM temperatures. Autopilot will automatically be ready at your bedtime temperature until you fall asleep. Once it detects you fell asleep, it will start transitioning through your other two temperatures.',
	},
	personalize_pod_prospecting: {
		'0': 'As the Pod learns from your preferences and environment, it makes automatic temperature adjustments to personalize your sleep experience.',
		'1': 'As Autopilot learns from your preferences and environment, it makes automatic temperature adjustments to personalize your sleep experience.',
	},
	ready_every_night_subheading: {
		'0': 'Every night, the Pod automatically adjusts to your perfect temperature, ensuring you get into bed ready for sleep.',
		'1': 'Every night, Autopilot automatically adjusts to your perfect temperature, ensuring you get into bed ready for sleep.',
	},
	editorial_subheading: {
		'0': 'The Pod adds personalized temperature control to each side of the bed so you sleep better every night.',
		'1': 'Autopilot adds personalized temperature control to each side of the bed so you sleep better every night.',
	},
	bedroom_temperature: {
		'0': 'Depending on your room temperature, the Pod will automatically adjusts settings to be warmer or cooler',
		'1': 'Depending on your room temperature, Autopilot will automatically adjusts settings to be warmer or cooler',
	},
	real_time_weather: {
		'0': 'During different seasons, the Pod will adjust your temperature to make you more comfortable.',
		'1': 'During different seasons, Autopilot will adjust your temperature to make you more comfortable.',
	},
}

export const getAutopilotABStringMap = (key: string) => {
	return autopilotAB_StringMap[key]?.['1'] ?? key
}
