import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3 3.00212V8.62645H3.65467M3.65467 8.62645C4.40142 6.77957 5.73982 5.23263 7.46013 4.22807C9.18045 3.22351 11.1854 2.8181 13.1609 3.07539C15.1364 3.33268 16.9706 4.23811 18.3762 5.6498C19.7818 7.06149 20.6794 8.89964 20.9281 10.8762M3.65467 8.62645H8.62434M20.9979 21V15.3757H20.3443M20.3443 15.3757C19.5965 17.2215 18.2577 18.7672 16.5376 19.7708C14.8175 20.7745 12.8131 21.1794 10.8382 20.9222C8.86333 20.6651 7.0295 19.7603 5.62379 18.3495C4.21809 16.9388 3.31986 15.1017 3.06974 13.1259M20.3443 15.3757H15.3735"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
