import React, { FC, useState } from 'react'
import styles from './Pod4Comparison.module.scss'
import { Pod4ComparisonProps } from './types'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import cx from 'classnames'
import { IconName } from 'components/Phantom/Icon/mapping'
import { Icon } from 'components/Phantom/Icon'
import SlideDown from 'components/SlideDown'
import { inject, observer } from 'mobx-react'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { getPod4FeatureMatrix } from 'constants/pod4-data'
import { Badge } from 'components/Badge'
import { Type } from 'components/Type'

interface PodData {
	eyebrow: string
	name: string
	subheader: string
	subheaderClass?: string
	priceOld: string
	priceNew: string
	discount: string
	includes: string
	shopLink: string
	img: string
}

const PodInfo = ({ eyebrow, name, subheader, subheaderClass, priceOld, priceNew, discount, includes, shopLink, img }: PodData) => (
	<div className={styles.infoBox}>
		<Img
			alt={name}
			className={styles.podImage}
			src={img}
		/>
		<div className={styles.podHeader}>
			<Type.Eyebrow className={styles.podEyebrow}>{eyebrow}</Type.Eyebrow>
			<div className={styles.podTitle}>{name}</div>
			<div className={cx(styles.podSubheader, subheaderClass)}>{subheader}</div>
		</div>
		<div className={styles.podDescription}>
			<div className={styles.podInfoStarting}>Starting at</div>
			<div className={styles.podInfoPrice}>
				<Type.Body1 className={styles.strikethrough}>{priceOld}</Type.Body1>
				<Type.Body1 className={styles.podNewPrice}>{priceNew}</Type.Body1>
				<Badge.BFCMLight type={'discount'}>{discount} off</Badge.BFCMLight>
			</div>
			<div className={styles.podInfoIncludes}>{includes}</div>
		</div>
		<div className={styles.podInfoCta}>
			<Button.Blue
				href={shopLink} //where?
				className={styles.ctaButton}
				id={'compare-pod4-cta-button'}
			>
				{`Shop now`}
			</Button.Blue>
		</div>
	</div>
)
interface FeatureCompareTableProps {
	featureMatrix: { title: string; description: string; values: number[] }[]
	showPod3?: boolean
}
const FeatureCompareTable = (props: FeatureCompareTableProps) => {
	return (
		<table className={styles.compareTable}>
			<thead>
				<tr>
					<th></th>
					{props.showPod3 && (
						<th>
							<Img
								alt={''}
								className={styles.thImage}
								simple={true}
								src={'https://eightsleep.imgix.net/assets/pod3_compare_crop_3004.png'}
							/>
						</th>
					)}
					<th className={styles.popularHeader}>
						<Img
							alt={''}
							className={styles.thImage}
							simple={true}
							src={'https://eightsleep.imgix.net/assets/pod3_plus_compare.png'}
						/>
					</th>
					<th className={styles.popularHeader}>
						<Img
							alt={''}
							className={styles.thImage}
							simple={true}
							src={'https://eightsleep.imgix.net/assets/pod3_ultra_compare.png'}
						/>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td></td>
					{props.showPod3 && (
						<td>
							<div className={styles.thTitle}>Pod 3</div>
						</td>
					)}
					<td>
						<div className={styles.thTitle}>Pod 4</div>
					</td>
					<td>
						<div className={styles.thTitle}>Pod 4 Ultra</div>
					</td>
				</tr>
				{props.featureMatrix.map((feature, i) => (
					<tr key={`feature-matrix-f-${i}`}>
						<td>
							<SlideDown
								top={<div className={styles.sliderFeature}>{feature.title}</div>}
								key={i}
								className={styles.sliderFeatureContainer}
								inverted={true}
							>
								<span className={styles.sliderDescription}>{feature.description}</span>
							</SlideDown>
						</td>
						{feature.values.map((value, j) => {
							if (j === 0 && !props.showPod3) return null
							return (
								<td key={`feature-matrix-check-${i}-${j}`}>
									{value ? (
										<Icon
											name={'CheckDark'}
											color={'black'}
										/>
									) : (
										<Icon
											color={'#E6E6E6'}
											name={'CloseLight'}
										/>
									)}
								</td>
							)
						})}
					</tr>
				))}
			</tbody>
			<tfoot>
				<tr>
					<td></td>
					{props.showPod3 && <td></td>}
					<td></td>
					<td></td>
				</tr>
			</tfoot>
		</table>
	)
}

const FeatureCompareTableMob = (props: FeatureCompareTableProps) => {
	return (
		<div className={styles.featureCompareTableMob}>
			<div className={styles.featureCompareTableMobTitles}>
				{props.showPod3 && (
					<div>
						<div>Pod 3</div>
					</div>
				)}
				<div className={styles.popularTableHeader}>
					<div>Pod 4 </div>
				</div>
				<div className={styles.popularTableHeader}>
					<div className={styles.divTitle}>Pod 4 Ultra</div>
				</div>
			</div>
			{props.featureMatrix.map((feature) => (
				<React.Fragment key={feature.title}>
					<div className={styles.featureInfo}>
						<div className={styles.featureInfoTitle}>{feature.title}</div>
						<div className={styles.featureInfoDescription}>{feature.description}</div>
					</div>
					<div className={styles.featureInfoCheckmarks}>
						{feature.values.map((value, i) => {
							if (i === 0 && !props.showPod3) return null
							return value ? (
								<Icon
									key={`feature-check-icon-${i}`}
									name={'CheckDark'}
									color={i > 0 ? '#0038FF' : 'black'}
								/>
							) : (
								<Icon
									key={`feature-check-icon-${i}`}
									color={'#E6E6E6'}
									name={'CloseLight'}
								/>
							)
						})}
					</div>
					<hr />
				</React.Fragment>
			))}
		</div>
	)
}

interface FeatureListProps {
	featureList: Array<{ icon: IconName; iconColor?: string; text: string }>
}
const FeatureList = ({ featureList }: FeatureListProps) => {
	return (
		<div className={styles.featureList}>
			{featureList.map((feature) => (
				<div
					className={styles.feature}
					key={feature.text}
				>
					<div className={styles.icon}>
						<Icon
							color={feature.iconColor || 'black'}
							name={feature.icon}
							size={'32px'}
						/>
					</div>
					<div>{feature.text}</div>
				</div>
			))}
		</div>
	)
}

export const Pod4ComparisonTable = ({ metric = false, showPod3 = true }) => (
	<>
		<div className={styles.popUpHeader}>Which Pod is right for you?</div>
		<FeatureCompareTable
			featureMatrix={getPod4FeatureMatrix(metric)}
			showPod3={showPod3}
		/>
		<FeatureCompareTableMob
			featureMatrix={getPod4FeatureMatrix(metric)}
			showPod3={showPod3}
		/>
	</>
)

export const Pod4Comparison: FC<Pod4ComparisonProps> = inject('rootStore')(
	observer((props) => {
		const { priceStore, settingsStore } = props.rootStore

		const [compareIsVisible, setCompareIsVisible] = useState(false)
		const oldFeaturesList: Array<{ icon: IconName; iconColor?: string; text: string }> = [
			{ icon: 'TemperatureLight', text: 'Automatic temperature' },
			{ icon: 'CoolDark', text: 'Dual zone cooling and heating' },
			{ icon: 'DualTempLight', iconColor: 'white', text: 'Vibration and thermal alarm' },
			{ icon: 'HeartReportLight', text: 'Sleep and health reports' },
		]
		const podFeatures = [
			{
				featureList: [
					{ icon: 'TapDark', text: 'Control without phone' },
					{ icon: 'PrimeDark', text: '99% accurate, clinical grade sensors' },
					{ icon: 'CoolLight', text: '2x more cooling power' },
					{ icon: 'DisturbanceLight', iconColor: 'white', text: '40% quieter vs Pod 3' },
					{ icon: 'SurfaceLight', text: 'Maintains your mattress’ comfort' },
					{ icon: 'BedLight', text: 'Fits on any bed' },
					{ icon: 'SnoringLight', text: 'Snoring detection' },
				] as Array<{ icon: IconName; text: string }>,
				oldFeaturesList,
			},
			{
				featureList: [
					{ icon: 'TapDark', text: 'Control without phone' },
					{ icon: 'PrimeDark', text: '99% accurate, clinical grade sensors' },
					{ icon: 'CoolLight', text: '2x more cooling power' },
					{ icon: 'DisturbanceLight', iconColor: 'white', text: '40% quieter vs Pod 3' },
					{ icon: 'SurfaceLight', text: 'Maintains your mattress’ comfort' },
					{ icon: 'BedLight', text: 'Fits on any bed' },
					{ icon: 'SnoringLight', text: 'Snoring detection' },
					{ icon: 'SnoreMitigationCustom', text: 'Snoring mitigation' },
					{ icon: 'ElevationDark', text: 'Includes adjustable base' },
					{ icon: 'SleepingDark', text: 'Sleeping mode' },
					{ icon: 'ReadingDark', text: 'Reading mode' },
					{ icon: 'RelaxingDark', text: 'Relaxing mode' },
				] as Array<{ icon: IconName; iconColor?: string; text: string }>,
				oldFeaturesList,
			},
		]
		const pods = [
			{
				eyebrow: '',
				name: 'Pod 4',
				subheader: 'Fits on your existing mattress',
				subheaderClass: styles.firstSubheader,
				priceOld: '$2549',
				priceNew: '$2049',
				discount: '$500',
				includes: 'Includes Cover and Hub',
				shopLink: '/product/pod-cover',
				img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod_compare_left_v2.png',
			},
			{
				eyebrow: '',
				name: 'Pod 4 Ultra',
				subheader: 'Fits on your existing mattress and bed frame',
				priceOld: '$4049',
				priceNew: '$3349',
				discount: '$500',
				includes: 'Includes Cover, Hub, and Base',
				shopLink: '/product/pod-cover',
				img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod_compare_right_v2.png',
			},
		]

		pods[0].priceNew = priceStore.pod4PriceFormatted
		pods[0].priceOld = priceStore.pod4ComparePriceFormatted
		pods[0].discount = priceStore.pod4DiscountFormatted
		pods[1].priceNew = priceStore.pod4UltraPriceFormatted
		pods[1].priceOld = priceStore.pod4UltraComparePriceFormatted
		pods[1].discount = priceStore.pod4UltraDiscountFormatted

		useGSAP(() => {
			gsap.registerPlugin(ScrollTrigger)

			gsap.from('#pod4-comparison-title', {
				scrollTrigger: {
					trigger: '#pod4-comparison-title',
					start: 'top 70%',
					end: 'bottom center',
					toggleActions: 'restart none none reverse',
				},
				opacity: 0,
				y: 50,
				duration: 0.5,
			})
		}, [])

		return (
			<div className={styles.container}>
				<div
					className={styles.title}
					id={'pod4-comparison-title'}
				>
					Available in two models.
					<br />
					With or without the Base.
				</div>

				<div className={styles.podInfo}>
					<PodInfo {...pods[0]} />
					<div className={styles.mobFeatures}>
						<div className={styles.featureSection}>
							<div className={styles.featureSectionHeader}>What's new</div>
							<hr />
							<FeatureList featureList={podFeatures[0].featureList} />
						</div>
					</div>
					<PodInfo {...pods[1]} />
					<div className={styles.mobFeatures}>
						<div className={styles.featureSection}>
							<div className={styles.featureSectionHeader}>What's new</div>
							<hr />
							<FeatureList featureList={podFeatures[1].featureList} />
						</div>
					</div>
				</div>
				<div className={styles.features}>
					<div className={styles.featureSection}>
						<div className={styles.featureSectionHeader}>What's new</div>

						<hr />
						<div className={styles.sideBySide}>
							<FeatureList featureList={podFeatures[0].featureList} />
							<FeatureList featureList={podFeatures[1].featureList} />
						</div>
					</div>
					<div className={styles.featureSection}>
						<div className={styles.featureSectionHeader}>Plus all the features you love </div>

						<hr />
						<div className={styles.sideBySide}>
							<FeatureList featureList={podFeatures[0].oldFeaturesList} />
							<FeatureList featureList={podFeatures[1].oldFeaturesList} />
						</div>
					</div>
				</div>

				<Button.Plus
					onClick={() => setCompareIsVisible(true)}
					id="compare-pods"
					className={styles.compareButton}
				>
					Compare to previous generations
				</Button.Plus>
				<FullScreenModal
					closeAction={() => {
						setCompareIsVisible(false)
					}}
					visible={compareIsVisible}
				>
					<div className={styles.fullScreenContent}>
						<Pod4ComparisonTable
							metric={settingsStore.metricRegion}
							showPod3={settingsStore.settings.promoCode !== 'members'}
						/>
					</div>
				</FullScreenModal>
			</div>
		)
	})
)
