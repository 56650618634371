import * as React from 'react'
import { useRouter } from 'next/router'

import styles from './styles/NotShippingAlert.module.scss'
import { Heading } from 'components/basic/Heading'
import { Button } from 'components/basic/Button'

interface IProps {
	productName: string
	onShippingToUSClick: () => void
	country: 'Canada' | 'UK' | 'EU' | 'AU'
	allowShipToUS?: boolean
}

const NotShippingAlert = (props: IProps) => {
	const router = useRouter()

	const onShopCoverClick = (e) => {
		e.preventDefault()
		router.push({
			pathname: props.country === 'Canada' ? '/' : `/${props.country.toLowerCase()}/product/pod-cover`,
		})
	}

	const isCanada = props.country === 'Canada'

	const textCanada = `Unfortunately ${props.productName} does not ship to Canada`
	const textUK = `Unfortunately ${props.productName} does not ship to the United Kingdom`
	const textEU = `Unfortunately ${props.productName} does not ship to Europe`
	const textAU = `Unfortunately ${props.productName} does not ship to Australia`
	const checkout = 'Check out the Pod 3 and the Pod 3 Cover instead.'
	const checkout2 = 'Check out the Pod 3 Cover instead.'

	let ctaText = 'Shop Pod Products'
	if (props.country === 'UK' || props.country === 'EU') {
		ctaText = 'Shop Pod 3 Cover'
	}

	let title = textCanada
	if (props.country === 'UK') {
		title = textUK
	} else if (props.country === 'EU') {
		title = textEU
	} else if (props.country === 'AU') {
		title = textAU
	}

	return (
		<div className={styles.main_wrapper}>
			<img
				src="https://eightsleep.imgix.net/icon_alert.png?v=1617885033"
				alt="Notice"
			/>
			<Heading
				titleClass={styles.title}
				type="h5"
				title={title}
			/>
			<p className={styles.description}>{isCanada ? checkout : checkout2}</p>
			<Button
				className={styles.shop_cover_cta}
				onClick={onShopCoverClick}
				content={ctaText}
				usesLoading={false}
			/>
			{props.allowShipToUS && (
				<a
					className={styles.ship_to_us}
					href="#"
					onClick={props.onShippingToUSClick}
				>
					Click here if you’re shipping to the US
				</a>
			)}
		</div>
	)
}

export default NotShippingAlert
