import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 9.86108V12.6667C3 13.403 3.59695 14 4.33333 14H19.6667C20.403 14 21 13.403 21 12.6667V9.86108H3ZM20.4851 8.36108L17.8812 3.68469C17.6459 3.26203 17.2001 3 16.7163 3H7.73193C7.27606 3 6.85179 3.2329 6.60705 3.6175L3.5884 8.36108H20.4851Z"
			fill={color}
		/>
		<circle
			cx="4.42105"
			cy="19.7895"
			r="1.42105"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<circle
			cx="11.9999"
			cy="19.7895"
			r="1.42105"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M4.42114 18.3684L6.31588 16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M19.1052 18.3684L17.2105 16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M12 18.3684V16"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<circle
			cx="19.579"
			cy="19.7895"
			r="1.42105"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
