// Icons based on this url
// https://www.figma.com/file/WULrsqs3sN4jsO7Ip5jMJ8/Production?node-id=778%3A16966

export const generalIcons = {
	// Tag needs to be updated on components.scss, Cart.module.scss and PriceBlock.module.scss as well
	tag: 'https://eightsleep.imgix.net/tag-outline-16.svg?v=1623412593',
	plus: 'https://eightsleep.imgix.net/Type_tool-tip-plus_State_Default_3ac57676-4943-4ad5-8c91-4ea2fc7c5e1a.svg?v=1623756724',
	plusHover: 'https://eightsleep.imgix.net/Type_tool-tip-plus_State_Hover_5d9ffff9-ca22-49d7-81a0-a20238c8cbf8.svg?v=1623756724',
	plusNavy: 'https://eightsleep.imgix.net/plus-button-blue.svg?v=1641034865',
	minus: 'https://eightsleep.imgix.net/minus_default.svg?v=1623768977',
	minusNavy: 'https://eightsleep.imgix.net/minus_default_navy.svg?v=1636642190',
	minusHover: 'https://eightsleep.imgix.net/minus_hover_8804a957-bdf6-4a52-bca5-2405c58e661e.svg?v=1623769141',
	thinArrowRightBlue: 'https://eightsleep.imgix.net/arrow-right.png?v=1599157157',
	thinArrowRightWhite: 'https://eightsleep.imgix.net/bfcm-right-arrow-white.svg?v=1636567734',
	circleCheckmarkNavy: 'https://eightsleep.imgix.net/check-filled-16_7d249446-29cb-44f6-beca-f32700d283f5.svg?v=1641032592',
	playButtonNavy: 'https://eightsleep.imgix.net/play_button.svg?v=1623412594',
	playButtonNavy2: 'https://eightsleep.imgix.net/play_button_14d1bc3f-6383-418f-b687-1fc0363fc16e.svg?v=1667417370',
	chevronDownWhite: 'https://eightsleep.imgix.net/chevron-down-48.png?v=1601656369',
	chevron: 'https://eightsleep.imgix.net/chevron-arrow.svg?v=1634822222',
	tooltip: 'https://eightsleep.imgix.net/tooltip_ef9c77cf-cb4b-496f-80f3-a38ba48f28f1.svg?v=1645819235',
}
export const homePageIcons = {
	moon: 'https://eightsleep.imgix.net/moon.svg?v=1623404025',
	clock: 'https://eightsleep.imgix.net/clock.svg?v=1623404024',
	temperature: 'https://eightsleep.imgix.net/temp.svg?v=1623404024',
	heart: 'https://eightsleep.imgix.net/heart.svg?v=1623404024',

	chat: 'https://eightsleep.imgix.net/chat.svg?v=1623404369',
	call: 'https://eightsleep.imgix.net/phone_5430f858-82b4-4bfd-b24e-310f4e3575a9.svg?v=1623404369',
	phone: 'https://eightsleep.imgix.net/phone-1.svg?v=1623404369',

	play: 'https://eightsleep.imgix.net/play_button.svg?v=1623412594',
	checkmark: 'https://eightsleep.imgix.net/check-filled-16.svg?v=1623412593',
	stars: 'https://eightsleep.imgix.net/Stars.svg?v=1623485248',
	check: 'https://eightsleep.imgix.net/check-line-32.svg?v=1623412593',
}

export const appIcons = {
	starBig: 'https://eightsleep.imgix.net/Vector.svg?v=1623681804',
}

export const technologyIcons = {
	play: 'https://eightsleep.imgix.net/play_button.svg?v=1623412594',
}

export const comfortIcons = {
	// ChevronDown needs to be updated on ComfortLayers.module.scss as well
	chevronDown: 'https://eightsleep.imgix.net/Polygon_4.svg?v=1623486026',
}

export const reviewIcons = {
	// stars are located on library, while colors on yotpo.scss
	// .jdgm-histogram .jdgm-histogram__bar-content, :not(.jdgm-prev-badge__stars)>.jdgm-star
	starBig: null,

	play: 'https://eightsleep.imgix.net/play_button.svg?v=1623412594',
}

export const financingIcons = {
	bullet: 'https://eightsleep.imgix.net/Bullet.svg?v=1623419355',
}

export const compareIcons = {
	moon: 'https://eightsleep.imgix.net/moon_af166597-325a-4391-989c-646a253c7e39.svg?v=1623419594',
	shipping: 'https://eightsleep.imgix.net/8_box.svg?v=1623419594',
	financing: 'https://eightsleep.imgix.net/price_tag.svg?v=1623419594',
	support: 'https://eightsleep.imgix.net/customer_care.svg?v=1623419594',
	checkmark: 'https://eightsleep.imgix.net/check-filled-16_5116d6b7-6e51-4632-97a2-9877cf8e51b5.svg?v=1623419594',
}

export const podProLandingIcons = {
	temperature: 'https://eightsleep.imgix.net/temp_8b3b61c9-d7ac-4cb1-93c3-9d28dcd42094.svg?v=1623489673',
	dualZone: 'https://eightsleep.imgix.net/dual_temp_af6c78c1-736a-4e22-9dfe-76e752433743.svg?v=1623489674',
	sleepTracking: 'https://eightsleep.imgix.net/phone_a40195a6-dd4f-46d9-aec8-384051ebcdd9.svg?v=1623489673',
	heart: 'https://eightsleep.imgix.net/heart_538239bd-900a-4b30-af36-98c037d9d2ce.svg?v=1623489674',
	bell: 'https://eightsleep.imgix.net/bell_03fea3ba-c296-4b00-af56-facbaceff277.svg?v=1623489673',
	comfort: null,
	check: 'https://eightsleep.imgix.net/check-line-32_9a96ec47-0b04-473f-b687-18cbf5b9529b.svg?v=1623489674',
}

export const podProLandingIconsDarkBlue = {
	temperature: 'https://eightsleep.imgix.net/temp_9d389357-9819-48d2-9027-9665972d48b9.png?v=1636658072',
	dualZone: 'https://eightsleep.imgix.net/dual_temp.png?v=1636658072',
	sleepTracking: 'https://eightsleep.imgix.net/phone_1d36d939-4922-46d0-9603-f8de548823b0.png?v=1636658072',
	heart: 'https://eightsleep.imgix.net/heart_9024a712-f070-4d8a-98bc-c3351228f366.png?v=1636658072',
	bell: 'https://eightsleep.imgix.net/bell.png?v=1636658072',
	comfort: null,
	check: 'https://eightsleep.imgix.net/premium.png?v=1636658072',
}

export const shopIcons = {
	camera: 'https://eightsleep.imgix.net/Frame_55_8eefa821-02fd-4d23-af0e-c1f573ccbec6.svg?v=1623490270',
	chat: 'https://eightsleep.imgix.net/chat_c37de30a-3532-414a-85a8-b6916f22a5a4.svg?v=1623490269',
}

export const podProShopIcons = {
	phone: 'https://eightsleep.imgix.net/phone_0d260f2a-2e3e-4b59-a235-8889ec94c3e4.svg?v=1654884813',
	maxchill: 'https://eightsleep.imgix.net/maxchill_4b99ba0b-bc47-4059-b3d8-5a45bd8beff8.svg?v=1654884813',
	heartrate: 'https://eightsleep.imgix.net/heartrate.svg?v=1654884813',
	gentlerise: 'https://eightsleep.imgix.net/gentlerise.svg?v=1654884813',
	trend: 'https://eightsleep.imgix.net/trend.svg?v=1654884813',
	wifi: 'https://eightsleep.imgix.net/wifi.svg?v=1654884813',
	bed: 'https://eightsleep.imgix.net/bed_48a10768-ce20-436d-afec-d83142743305.svg?v=1654884813',
	healthreport: 'https://eightsleep.imgix.net/sleepmetrics.svg?v=1654884813',
	dualzone: 'https://eightsleep.imgix.net/dzch.svg?v=1657897021',
	lines: 'https://eightsleep.imgix.net/lines.svg?v=1654884813',
	lines2: 'https://eightsleep.imgix.net/lines2.svg?v=1654884813',
	sunrise: 'https://eightsleep.imgix.net/sunrise.svg?v=1654884813',
	temptherm: 'https://eightsleep.imgix.net/thermometer.svg?v=1654884813',
}

export const podProCoverShopIcons = {
	comfort: 'https://eightsleep.imgix.net/bed_48738ef1-5b71-48f8-9e78-4aae0b81de71.svg?v=1642801015',
	dualZone: 'https://eightsleep.imgix.net/dual-temp.svg?v=1642801015',
	sleepTracking: 'https://eightsleep.imgix.net/phone_d15b7620-5213-4ec7-9673-5aad3839ba1a.svg?v=1642801015',
	app: 'https://eightsleep.imgix.net/app.svg?v=1642801015',
	checkmark: 'https://eightsleep.imgix.net/check-filled-16_cd0a3e8c-a68d-47ef-9af1-4d62cacea312.svg?v=1623491556',
	star: 'https://eightsleep.imgix.net/star-filled-24_2.svg?v=1623696835',
	check: 'https://eightsleep.imgix.net/check-line-32_a7be2451-b922-41ff-b958-72296811c958.svg?v=1623491556',
	sun: 'https://eightsleep.imgix.net/sun.svg?v=1642801813',
	report: 'https://eightsleep.imgix.net/report.svg?v=1642801813',
	tools: 'https://eightsleep.imgix.net/sleep-tools.svg?v=1642801813',
}

export const accessoryDetailsIcons = {
	bed: 'https://eightsleep.imgix.net/bed_5236eb37-d595-443c-984a-00522de60c8c.svg?v=1623495014',
	delivery: 'https://eightsleep.imgix.net/Frame_45_7c63c79d-c9ae-4d88-9858-d86a767bc3cb.svg?v=1623496293',
	moon: 'https://eightsleep.imgix.net/moon_2af52bce-6303-428a-8ab1-fff557dff2bb.svg?v=1623495014',
	warranty: 'https://eightsleep.imgix.net/Frame_57_3ba4c20d-5fd5-48a7-a6fd-0b3f18485e4e.svg?v=1623496293',
	textUs: 'https://eightsleep.imgix.net/phone_e744b913-9225-49df-a202-23147011e985.svg?v=1623495014',
	plus: 'https://eightsleep.imgix.net/tool_tip_button_05ce9f84-9961-45c2-aa61-395a94613ad8.svg?v=1623495014',
	circle_checkmark: 'https://eightsleep.imgix.net/circle-checkmark-black.svg?v=1649941158',
}

export const productFeatureIcons = {
	night_trial: 'https://eightsleep.imgix.net/Icons_28pt_2.svg?v=1654853210',
	free_returns: 'https://eightsleep.imgix.net/Icons_28pt.svg?v=1654853210',
	warranty: 'https://eightsleep.imgix.net/Icons_28pt_1.svg?v=1654853210',
}

export const autopilotFeatureIcons = {
	sleep: 'https://eightsleep.imgix.net/sleep.svg?v=1654860934',
	feedback: 'https://eightsleep.imgix.net/feedback.svg?v=1654860934',
	temperature: 'https://eightsleep.imgix.net/temperature.svg?v=1654860934',
	weather: 'https://eightsleep.imgix.net/weather.svg?v=1654860934',
	coaching: 'https://eightsleep.imgix.net/coaching_7fef7f4e-1024-4c28-b01b-571a55a257ba.svg?v=1657215118',
	insights: 'https://eightsleep.imgix.net/insights.svg?v=1657215118',
}

export const mattressLayersFeatureIcons = {
	spinal_support: 'https://eightsleep.imgix.net/Icons_40pt_3.svg?v=1654867342',
	chill_layer: 'https://eightsleep.imgix.net/Icons_40pt_2.svg?v=1654867342',
	dualzone: 'https://eightsleep.imgix.net/dzch.svg?v=1657897021',
	pressure: 'https://eightsleep.imgix.net/Icons_40pt.svg?v=1654867342',
}
