import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore, RootStoreInitializationData } from 'stores'
import { PodModel, ShopError, ShopManager, ShopSelection } from './shopManager'
import { Product, ProductCore, ProductVariant, VariantCore, VariantKey } from 'products/types'
import { PriceManager } from 'prices'
import { BundleProducts, MattressProducts, MembershipProducts, Pod4Accessories, PodCoverProducts, TheBaseProducts } from 'products/allProducts'
import { createCartLineItem, getVariantDimensions, isMembershipProductId, isPodBedProductId, isPodCoverProductId, OFFERED_MEMBERSHIPS } from 'products/utils'
import { getErrorMessage } from 'components/WebEv/Shop/utils/errorMessages'
import { sendTrackingEvents } from 'components/WebEv/Shop/context/checkoutUtils'
import { Attribute, LineItem } from 'cart'
import { ModalKey } from 'components/Phantom/_shop/Modals/modalMapping'
import { getShippingText } from 'config/shipping_timelines'
import { determineSubscriptionType, getSubscriptionFeatures, getSubscriptionYearlyCost, SubscriptionResponse, SubscriptionType } from 'components/_utils/subscriptionUtils'
import { jwtDecode } from 'jwt-decode'
import { getDefaultProductId, shouldShowShopBanner } from 'pageCores/product'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { getModelDescription, getModelOptions } from 'shop/selectorData/modelProps'
import { SelectorKey, SelectorProps, StandardCardProps, StandardCheckboxProps, SummaryProps } from 'components/Phantom/_shop/Selectors'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { ItemizedSelection, MembershipPricing, PaymentOption, PlanData, PricingData, SelectionEntry, SelectionKey, Selections, ShopTotal } from 'shop/Shop.types'
import { getEnhancedPlan, getMembershipTooltip, getStandardPlan } from 'shop/selectorData/autopilotProps'
import { getMattressCheckboxProps } from 'shop/selectorData/mattressProps'
import { VisualProps } from 'components/Phantom/_shop/Visuals'
import { getAccessoriesProps } from 'shop/selectorData/accessoriesProps'
import { isSelectionAllowed, isSelectionKeyUpsell } from 'shop/utils/selectionFiltering'
import { currencyDefaultSizes } from 'shop/utils/currencyDefaultSizes'
import { trackAccessoryShopPageVisit, trackCoverShopPageVisit } from 'events/index'
import { getAccessoriesVisuals, getAutopilotVisuals, getEssentialBundleVisuals, getLegKitVisuals, getMattressVisuals, getModelVisuals, getSizeVisuals, getSummaryVisuals } from 'shop/visualData'
import { PricingInfoProps } from 'components/Phantom/_shop/PricingInfo/types'
import { getTheBaseVisuals } from './visualData/theBaseVisual'
import { amTrackBaseAddedToCheckout, amTrackBaseSelected } from 'events/amplitude'

export class ShopStore {
	constructor(private readonly rootStore: RootStore, private readonly shopManager: ShopManager, private readonly initializationData?: RootStoreInitializationData) {
		makeObservable(this)

		const defaultProductId = getDefaultProductId(initializationData?.query?.index as string, initializationData?.asPath as string)
		if (defaultProductId) {
			this.selectedSelections['pod-model'] = defaultProductId
		}

		this.showShopBanner = shouldShowShopBanner(initializationData?.query?.index as string, initializationData?.asPath as string)

		this.setSubscriptionInformation()
	}

	@observable paymentOption: PaymentOption = 'cash'
	@action setPaymentOption = (paymentOption: PaymentOption) => {
		this.paymentOption = paymentOption
	}

	@observable podData: Product = undefined
	@observable selectedSelections: Selections = {
		'pod-model': 'pod_4_ultra',
		'leg-kit': 'none',
		'auto-pilot': 'autopilot_enhanced',
		'essentials-bundle': undefined,
		'eight-mattress': undefined,
		'the-base': undefined,
		size: undefined,
	}

	@observable error: ShopError = null
	@observable activeModal: ModalKey = ''

	@observable highlightedSelector: SelectorKey | undefined
	@observable subscriptionInformation: SubscriptionResponse | undefined
	@observable goingToCheckout = false
	@observable offerBaseUpsell = false

	@action setOfferBaseUpsell(state: boolean) {
		this.offerBaseUpsell = state
	}

	@observable showShopBanner = false
	@action setShowShopBanner = (showShopBanner: boolean) => {
		this.showShopBanner = showShopBanner
	}

	@computed get showBanner() {
		return this.showShopBanner
	}

	@computed
	get shopStoreDump() {
		return {
			podData: this.podData,
			selectedSelections: this.selectedSelections,
			error: this.error,
			activeModal: this.activeModal,
			highlightedSelector: this.highlightedSelector,
			subscriptionInformation: this.subscriptionInformation,
			goingToCheckout: this.goingToCheckout,
		}
	}

	public getPodProducts(podModel: PodModel) {
		switch (podModel) {
			case 'pod_3_cover_perfect':
				return this.addProductPrices(PodCoverProducts.Pod3CoverPerfect)

			case 'pod_4':
				return this.addProductPrices(PodCoverProducts.Pod4)

			case 'pod_4_ultra':
				return this.addProductPrices(PodCoverProducts.Pod4Ultra)

			default:
				return this.addProductPrices(PodCoverProducts.Pod4Ultra)
		}
	}
	@action setPodShopData() {
		const product = this.getPodProducts(this.selectedSelections['pod-model'] as PodModel)
		const productVariants = product.variants
		const selectedVariant = this.selectedVariant
		if (!productVariants[selectedVariant]) {
			this.selectedSelections['size'] = undefined
		}

		this.podData = product
		this.selectedSelections = { ...this.selectedSelections, 'pod-model': this.podData.id }
	}

	public trackViewShopPage() {
		trackCoverShopPageVisit({
			code: this.rootStore.settingsStore.globalPromoCode,
			amount: this.rootStore.priceStore.totalProductDiscountNumber,
			region: this.rootStore.settingsStore.currentRegion,
			size: this.getCurrentSelections()['size'] || this.selectedVariant,
			price: this.podData.variants[this.selectedVariant].prices.priceString,
			model: this.getCurrentSelections()['pod-model'],
		})
	}

	public trackViewAccessoryShop(price: string, size: string, path: string) {
		trackAccessoryShopPageVisit(
			{
				code: this.rootStore.settingsStore.globalPromoCode,
				amount: this.rootStore.priceStore.totalProductDiscountNumber,
				region: this.rootStore.settingsStore.currentRegion,
				price: price,
				size: size,
			},
			path as any
		)
	}

	@action handleSelectionChange = (selectorKey: SelectorKey, selectionKey: SelectionKey, optionKey: string) => {
		this.selectedSelections[selectionKey] = optionKey
		this.setPodShopData()

		if (selectorKey === 'size' || selectorKey === 'pod-model') {
			this.trackViewShopPage()
		}
		if (selectionKey === 'the-base' && optionKey === 'standard') {
			amTrackBaseSelected()
		}
		if (this.error && this.error.associatedSelectionId === selectionKey) {
			// TODO, there could be more finesse to be made here, depending on how we structure the error interface.
			//  Right now, the assumption is that if a selection is made on a section that has an error, we should probably remove the error.
			this.error = null
		}

		if (selectorKey !== this.highlightedSelector) {
			// If the selector that changed is not the highlighted selector (i.e. the one closest to the viewport)
			// we should scroll to the selector that changed

			// Disable this behavior on mobile due to the lack of screen real-estate.
			// We don't want to constantly be shoving the user away from product imagery.
			if (typeof window !== 'undefined' && window.innerWidth < 1024) {
				return
			}

			const currentSelectors = this.currentSelectors
			const index = currentSelectors.findIndex((selector) => selector === selectorKey)
			if (index !== -1 && typeof window !== 'undefined') {
				const selector = currentSelectors[index]
				const element = document.getElementById(`selector-${selector}`)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
			}
		}
	}

	@action setHighlightedSelector = (selectorKey: SelectorKey) => {
		this.highlightedSelector = selectorKey
	}

	@action setSubscriptionInformation = (info?: SubscriptionResponse) => {
		let si = info
		if (!si && typeof window !== 'undefined') {
			const saved = sessionStorage.getItem('subscriptionInformation')
			si = saved ? JSON.parse(sessionStorage.getItem('subscriptionInformation')) : undefined
		}
		this.subscriptionInformation = si
		this.setPodShopData()
	}

	private getLineItemAttributes(variant: VariantCore) {
		const attributes: Attribute[] = []
		if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id) {
			attributes.push({ key: 'warranty', value: 'extended warranty' })
		}

		if (variant.id === MembershipProducts.AutopilotEnhanced.variants.standard.id || variant.id === MembershipProducts.AutopilotStandard.variants.standard.id) {
			if (this.subscriptionInformation?.email) {
				const authToken = localStorage.getItem('authToken') || ''
				let userId = ''
				try {
					const jwt = jwtDecode(authToken)
					userId = jwt?.sub || ''
					attributes.push({ key: 'upgrade_eight_user_id', value: userId })
				} catch (e) {
					console.error('Error decoding JWT', e)
				}
			}
		}
		return attributes
	}

	@computed get stripeLineItems() {
		const podVariant = this.podVariant
		const podItem: LineItem = {
			quantity: 1,
			variantId: podVariant.id,
			attributes: [{ key: 'size', value: podVariant.name }],
		}
		const lines: LineItem[] = []
		const subInfo = this.subscriptionInformation

		for (const upsell of this.selectedUpsells) {
			if (subInfo && (upsell.handle === MembershipProducts.EightsleepProMembership.handle || upsell.handle === MembershipProducts.AutopilotStandard.handle)) {
				continue
			}
			const variant = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			const line: LineItem = {
				quantity: 1,
				variantId: variant.id,
				attributes: this.getLineItemAttributes(variant),
			}
			lines.push(line)
		}
		lines.push(podItem)
		return lines
	}

	@action checkout = (sourceId: string) => {
		const allRequiredSteps = this.checkRequiredSelections()
		if (!allRequiredSteps) return

		this.goingToCheckout = true

		const itemsToAdd: LineItem[] = this.stripeLineItems
		const itemsToRemove: LineItem[] = []

		const addedMembership = true
		if (this.rootStore.cartStoreNew.hasMembership) {
			const index = this.rootStore.cartStoreNew.getIndexOfCurrentMembership()
			const currentMembership = OFFERED_MEMBERSHIPS()[index]
			if (currentMembership) {
				itemsToRemove.push({
					variantId: currentMembership.variants.standard.id,
					sellingPlanId: currentMembership.sellingPlanId,
					quantity: 1,
					attributes: [],
				})
			}
		}

		sendTrackingEvents(createCartLineItem(this.podVariant, this.podData, { addMembership: addedMembership }), sourceId)

		const isValidPod = isPodBedProductId(this.podData.id)
		const isValidCover = isPodCoverProductId(this.podData.id)
		const isValidPodOrCoverAndNotUpgrade = (isValidPod || isValidCover) && !this.rootStore.cartStoreNew.hasMemberUpgradeInCart

		const hasBaseUpgrade = this.selectedSelections['the-base'] === 'standard'
		if (hasBaseUpgrade) {
			amTrackBaseAddedToCheckout()
		}

		this.rootStore.cartStoreNew.removeItems(itemsToRemove).then(() => {
			this.rootStore.cartStoreNew.addItems(itemsToAdd).then(() => {
				if (isValidPodOrCoverAndNotUpgrade && this.rootStore.cartStoreNew.hasMembership && this.rootStore.cartStoreNew.numCovers < 2 && this.rootStore.cartStoreNew.numPods < 2) {
					this.rootStore.cartStoreNew.redirectToCheckout()
				} else {
					this.rootStore.cartStoreNew.toggleCartOpen()
					this.goingToCheckout = false
				}
			})
		})
	}

	@computed get hasAccessory(): boolean {
		const upsells = this.selectedUpsells.filter((it) => !isMembershipProductId(it.id))
		return upsells.length > 0
	}

	/******************************************************************************
	Pricing and Product Data
	 ******************************************************************************/
	private addProductPrices(productCore: ProductCore): Product {
		const productPriceData = this.rootStore.priceStore.withPrices(productCore)
		const actualPrices: { [key in VariantKey]?: ProductVariant } = {}

		Object.values(productPriceData.variants).forEach((val) => {
			if (val.id && val.prices.price > 0) {
				actualPrices[val.key] = val
			}
		})

		productPriceData.variants = actualPrices

		return {
			...productPriceData,
		}
	}

	@computed get warrantyText(): string {
		let warrantyText = '2-year warranty'
		if (this.selectedSelections['auto-pilot'] === 'autopilot_enhanced') {
			warrantyText = '5-year warranty'
		}
		return warrantyText
	}

	@computed get benefits(): BenefitProps[] {
		const benefits: BenefitProps[] = []

		benefits.push(
			{
				icon: 'ShieldCheckLight',
				text: this.warrantyText,
			},
			{
				icon: 'MoonLight',
				text: '30-day risk-free trial',
			},
			{
				icon: 'EightBoxLight',
				text: 'Free returns',
			},
			{
				icon: 'DeliveryLight',
				text: 'Free shipping',
			}
		)

		return benefits
	}

	@computed get podVariant() {
		return this.podData.variants[this.selectedVariant]
	}

	@computed get productName() {
		return `${this.podData.name} (${this.podVariant.name})`
	}

	@computed get orderItemsString() {
		return [this.productName, ...this.selectedUpsells.map((u) => u.name)].join(' + ')
	}

	@computed get membershipProduct() {
		return this.getAssociatedProduct('auto-pilot')
	}

	@computed get totalPriceFinancing() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.shopTotal.price / 100), this.rootStore.priceStore.currency)
	}

	@computed get totalPriceFinancingCompare() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.shopTotal.comparePrice / 100), this.rootStore.priceStore.currency)
	}

	@computed get totalPriceFinancingNoMembership() {
		if (this.shouldHideAutopilotSection()) return this.totalPriceFinancing
		const membershipProduct = this.membershipProduct
		const membershipPrice = membershipProduct.variants['standard'].prices.price
		const priceWithoutMembership = this.shopTotal.price - membershipPrice
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(priceWithoutMembership / 100), this.rootStore.priceStore.currency)
	}

	@computed get totalPriceFinancingCompareNoMembership() {
		if (this.shouldHideAutopilotSection()) return this.totalPriceFinancingCompare
		const membershipProduct = this.membershipProduct
		const membershipPrice = membershipProduct.variants['standard'].prices.comparePrice
		const priceWithoutMembership = this.shopTotal.comparePrice - membershipPrice
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(priceWithoutMembership / 100), this.rootStore.priceStore.currency)
	}

	@computed get selectedPodVariantFinancing() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.podPrices.price / 100), this.rootStore.priceStore.currency)
	}

	@computed get selectedPodVariantCompareFinancing() {
		return PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getAffirmFinancingAmount(this.podPrices.comparePrice / 100), this.rootStore.priceStore.currency)
	}

	@computed get podPrices() {
		const price = this.podVariant.prices.price
		const comparePrice = this.podVariant.prices.comparePrice
		return {
			price,
			comparePrice,
		}
	}

	@computed get upsellTotalPrices() {
		const selectedUpsells = this.selectedUpsells

		let upsellPrice = 0
		let upsellComparePrice = 0

		for (const upsell of selectedUpsells) {
			const upsellItem = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			upsellPrice += upsellItem.prices.price
			upsellComparePrice += upsellItem.prices.comparePrice
		}

		return {
			price: upsellPrice,
			comparePrice: upsellComparePrice,
			savings: Math.max(upsellComparePrice - upsellPrice, 0),
		}
	}

	@computed get shopTotal(): ShopTotal {
		const podPrice = this.podPrices
		const upsellPrices = this.upsellTotalPrices

		const price = podPrice.price + upsellPrices.price
		const comparePrice = podPrice.comparePrice + upsellPrices.comparePrice

		const priceString = PriceManager.formatPriceToCurrencyNoDecimal(price / 100, this.rootStore.priceStore.currency)
		const comparePriceString = PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, this.rootStore.priceStore.currency)

		const savingsAmount = comparePrice > price ? comparePrice - price : 0
		const savingsAmountString = PriceManager.formatPriceToCurrencyNoDecimal(savingsAmount / 100, this.rootStore.priceStore.currency) + ' off'

		return {
			price,
			comparePrice,
			priceString,
			comparePriceString,
			savingsAmount,
			savingsAmountString,
		}
	}

	/******************************************************************************
	Current Selections
	 ******************************************************************************/
	public getAssociatedProduct(key: SelectionKey): Product | undefined {
		const selection = this.selectedSelections[key]
		if (!selection) {
			return undefined
		}

		// TODO abstract these checks like "selection === 'adjustable'" to better check the "truth value" of the selection.
		// Really these truth values should just be binary, but for now we're just checking for the string value.
		switch (key) {
			case 'pod-model':
				return this.podData
			case 'size':
				break
			case 'leg-kit':
				if (selection === 'adjustable') return this.getLegKitProduct()
				break
			case 'auto-pilot':
				if (selection === 'autopilot_standard') return this.addProductPrices(MembershipProducts.AutopilotStandard)
				if (selection === 'autopilot_enhanced') return this.addProductPrices(MembershipProducts.AutopilotEnhanced)
				break
			case 'essentials-bundle':
				if (selection === 'white') return this.getEssentialsProduct()
				break
			case 'the-base':
				if (selection === 'standard') return this.getBaseProduct(false)
				break
			case 'eight-mattress': {
				if (selection === 'yes_mattress') return this.getMattressProduct()?.product
				break
			}
			default:
				break
		}

		return undefined
	}

	@computed get filteredUpsellSelections(): SelectionEntry[] {
		const entries: SelectionEntry[] = []
		const selections = this.getCurrentSelectionEntries()
		for (const selection of selections) {
			if (isSelectionKeyUpsell(selection.key) && isSelectionAllowed(selection.key, this.selectedSelections, this.shouldHideAutopilotSection())) {
				entries.push(selection)
			}
		}
		return entries
	}

	@computed get selectedUpsells(): Product[] {
		const products: Product[] = []
		const selections = this.filteredUpsellSelections
		for (const selection of selections) {
			const product = this.getAssociatedProduct(selection.key)
			if (product) {
				products.push(product)
			}
		}

		return products
	}

	@computed get selectionKeys() {
		const keys: SelectionKey[] = []
		const selections = this.filteredUpsellSelections
		for (const selection of selections) {
			const product = this.getAssociatedProduct(selection.key)
			if (product) {
				keys.push(selection.key)
			}
		}

		return keys
	}

	@computed get selectedVariant(): VariantKey {
		// For pod only right now
		const sizeSelection = this.selectedSelections['size'] as VariantKey
		if (sizeSelection) {
			return sizeSelection
		}

		return currencyDefaultSizes[this.rootStore.priceStore.currency]
	}

	@action checkRequiredSelections = () => {
		const requiredSelectionIDs: SelectionKey[] = ['size', 'auto-pilot']

		const selectionsMade = Object.keys(this.selectedSelections)
		const missingSelections = requiredSelectionIDs.filter((id) => !selectionsMade.includes(id) || this.selectedSelections[id] === undefined)

		if (missingSelections.length !== 0) {
			const missingSelection = missingSelections[0]
			const missingSelectionElement = document.getElementById(`selector-${missingSelection}`)
			if (missingSelectionElement) {
				missingSelectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
			this.error = { associatedSelectionId: missingSelection, errorText: `${getErrorMessage(missingSelection)}` }
			return false
		}

		return true
	}

	public getCurrentSelections(): Selections {
		return this.selectedSelections
	}

	public getCurrentSelectionEntries(): SelectionEntry[] {
		const entries: SelectionEntry[] = []
		for (const _key in this.selectedSelections) {
			const key = _key as SelectionKey
			const value = this.selectedSelections[key]
			if (value) {
				entries.push({ key, value })
			}
		}
		entries.sort((a, b) => a.key.localeCompare(b.key))
		return entries
	}

	/******************************************************************************
	Visuals & Selectors
	 ******************************************************************************/

	public getCurrentVisuals(): VisualProps[] {
		const visuals: VisualProps[] = []

		const isPod3 = this.selectedSelections['pod-model'] === 'pod_3_cover_perfect'
		const podName = this.selectedSelections['pod-model'].replace(/[-_]/g, '').replace('coverperfect', '')
		const warrantyLength = this.selectedSelections['auto-pilot'] === 'autopilot_enhanced' ? 5 : 2

		visuals.push(
			getModelVisuals(this.selectedSelections['pod-model'], warrantyLength, this.rootStore.settingsStore.metricRegion),
			getSizeVisuals(this.rootStore.settingsStore.metricRegion, isPod3),
			getLegKitVisuals(this.rootStore.settingsStore.metricRegion),
			getAutopilotVisuals(this.rootStore.settingsStore.metricRegion),
			getEssentialBundleVisuals(),
			getMattressVisuals(),
			getAccessoriesVisuals(),
			getSummaryVisuals(podName, this.selectionKeys),
			getTheBaseVisuals()
		)

		return visuals
	}

	@computed get currentSelectors(): SelectorKey[] {
		const podModel = this.selectedSelections['pod-model'] as PodModel
		const theBaseModel = this.selectedSelections['the-base'] as 'standard' | 'none'

		const selections: SelectorKey[] = []

		const Pod4UltraVariants = Object.entries(PodCoverProducts.Pod4Ultra.variants)
			.filter((entry) => !!entry[1].id)
			.map((entry) => entry[0]) as VariantKey[]

		const baseVariantSelected = this.selectedVariant && Pod4UltraVariants.includes(this.selectedVariant)

		switch (podModel) {
			case 'pod_3_cover_perfect':
			case 'pod_4':
				this.offerBaseUpsell && baseVariantSelected
					? theBaseModel === 'standard'
						? selections.push('pod-model', 'size', 'auto-pilot', 'the-base', 'leg-kit', 'essentials-bundle', 'eight-mattress')
						: selections.push('pod-model', 'size', 'auto-pilot', 'the-base', 'essentials-bundle', 'eight-mattress')
					: selections.push('pod-model', 'size', 'auto-pilot', 'essentials-bundle', 'eight-mattress')
				break
			case 'pod_4_ultra':
				selections.push('pod-model', 'size', 'leg-kit', 'auto-pilot', 'essentials-bundle', 'eight-mattress')
				break
			default:
				break
		}

		return selections
	}

	@computed get currentSelectorsShopPageScrollAB(): SelectorKey[] {
		let selections = this.currentSelectors

		// Filter out all the accessories selectors
		selections = selections.filter((it) => !['leg-kit', 'eight-mattress', 'essentials-bundle'].includes(it))

		// Add the accessories selector before the summary
		const summaryIndex = selections.indexOf('summary')
		selections.splice(summaryIndex, 0, 'accessories')

		return selections
	}

	@computed get itemizedSelections(): ItemizedSelection[] {
		const podPrices = this.podPrices
		const selectedUpsells = this.selectedUpsells

		const itemizedSelections: ItemizedSelection[] = []

		itemizedSelections.push({
			productTitle: this.podData.name,
			price: podPrices.price,
			comparePrice: podPrices.comparePrice,
		})

		selectedUpsells.forEach((upsell) => {
			const upsellItem = this.selectedVariant in upsell.variants ? upsell.variants[this.selectedVariant] : upsell.variants['standard']
			const item = {
				productTitle: upsell.name,
				price: upsellItem.prices.price,
				comparePrice: upsellItem.prices.comparePrice,
				subscription: isMembershipProductId(upsell.id),
				tooltip: isMembershipProductId(upsell.id) && getMembershipTooltip(this.selectedSelections['auto-pilot'] === 'autopilot_enhanced' ? 5 : 2),
			}

			itemizedSelections.push(item)
		})

		return itemizedSelections
	}

	/******************************************************************************
	Modals
	******************************************************************************/

	@action selectModal = (modalKey: ModalKey) => {
		this.activeModal = modalKey
	}

	@action closeModal = () => {
		this.activeModal = ''
	}

	/******************************************************************************
	-----------------------------------------------------------------------------
	SELECTOR PROP FETCHING
	-----------------------------------------------------------------------------
	******************************************************************************/

	public getSelectorProps(selectorKey: SelectorKey): SelectorProps | undefined {
		switch (selectorKey) {
			case 'pod-model':
				return this.getPodModelProps()
			case 'size':
				return this.getPodSizeProps()
			case 'leg-kit':
				return this.getLegKitProps()
			case 'auto-pilot':
				return this.getAutoPilotProps()
			case 'essentials-bundle':
				return this.getEssentialsBundleProps()
			case 'the-base':
				return this.getTheBaseProps(false)
			case 'eight-mattress':
				return this.getEightMattressProps()
			case 'accessories':
				return this.getAccessoriesProps()
			case 'summary':
				return this.getSummaryProps()
		}
	}

	private toPriceString(unitAmount: number) {
		return PriceManager.formatPriceToCurrencyNoDecimal(unitAmount / 100, this.rootStore.priceStore.currency)
	}

	public getPricingData(variant: ProductVariant, discountText?: string): PricingData {
		let discount = ''
		if (variant.prices.comparePrice && variant.prices.price < variant.prices.comparePrice) {
			const diff = variant.prices.comparePrice - variant.prices.price
			discount = this.toPriceString(diff) + ' off'
		}
		return {
			price: this.toPriceString(variant.prices.price),
			strikePrice: this.toPriceString(variant.prices.comparePrice),
			discountText: discountText ? discountText : discount,
		}
	}

	/******************************************************************************
	Model
	******************************************************************************/
	private getPodModelProps(): StandardCardProps | undefined {
		const price = this.podVariant.prices
		const amountOff = price.comparePrice - price.price

		const displayPricing: PricingInfoProps = {
			price: '',
			comparePrice: '',
			discountText: '',
		}

		displayPricing.discountText = PriceManager.formatPriceToCurrencyNoDecimal(amountOff / 100, this.rootStore.priceStore.currency) + ' off'

		if (this.paymentOption === 'affirm') {
			displayPricing.price = this.selectedPodVariantFinancing + '/mo*'
			displayPricing.comparePrice = this.selectedPodVariantCompareFinancing + '/mo'
		} else {
			displayPricing.price = price.priceString
			displayPricing.comparePrice = price.comparePriceString
		}

		return {
			description: null,
			smallDescription: getModelDescription(this.paymentOption, this.selectedPodVariantFinancing + '/mo', price.price, this.rootStore.priceStore.currency),
			type: 'standard-cards',
			selectorKey: 'pod-model',
			title: 'Model',
			displayPricing: displayPricing,
			modalButtons: [
				{
					modalKey: 'model-modal',
					text: 'Need help choosing a model?',
				},
			],
			data: {
				selectionKey: 'pod-model',
				options: getModelOptions(),
			},
		}
	}

	/******************************************************************************
	Size
	******************************************************************************/
	private getPodSizeProps(): StandardCardProps | undefined {
		const productVariants = Object.values(this.podData.variants)
		if (!productVariants) return undefined
		if (!productVariants.length) return undefined

		const theBaseModel = this.selectedSelections['the-base'] as 'standard' | 'none'

		const Pod4UltraVariants = Object.entries(PodCoverProducts.Pod4Ultra.variants)
			.filter((entry) => !!entry[1].id)
			.map((entry) => entry[0]) as VariantKey[]

		const options = productVariants.map((variant): CardSelectionItemProps => {
			return {
				key: variant.key,
				type: 'standard',
				data: {
					title: variant.name,
					description: getVariantDimensions(variant.key as VariantKey),
					disabled: theBaseModel === 'standard' && !Pod4UltraVariants.includes(variant.key as VariantKey),
				},
			}
		})

		return {
			title: 'Pod Size',
			description: 'What size mattress will you be placing the Pod on?',

			selectorKey: 'size',

			type: 'standard-cards',
			data: {
				selectionKey: 'size',
				options,
			},

			modalButtons: [
				{
					modalKey: 'size-modal',
					text: 'Need help choosing a size?',
				},
			],
		}
	}

	/******************************************************************************
	Membership
	******************************************************************************/
	private getMembershipPricing(variant: ProductVariant): MembershipPricing {
		return {
			yearly: this.toPriceString(variant.prices.price),
			daily: PriceManager.formatPriceToCurrency(variant.prices.price / 365 / 100, this.rootStore.priceStore.currency),
			monthly: PriceManager.formatPriceToCurrencyNoDecimal(variant.prices.price / 12 / 100, this.rootStore.priceStore.currency),
		}
	}

	private getCurrentPlanData = (): PlanData => {
		const currentPlan = determineSubscriptionType(this.subscriptionInformation)

		const currentBenefits = getSubscriptionFeatures(currentPlan)
		const yearlyCost = getSubscriptionYearlyCost(currentPlan)[this.rootStore.priceStore.currency]
		const free = yearlyCost === 0

		const showStandardPlan = currentPlan === SubscriptionType.OPTIONAL_ERA_CHURNED || currentPlan === SubscriptionType.NO_SUBSCRIPTION

		const currentPriceMonthly = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost / 12, this.rootStore.priceStore.currency)}/mo` : 'Included'
		const currentPrice = free ? `${PriceManager.formatPriceToCurrencyNoDecimal(yearlyCost, this.rootStore.priceStore.currency)} billed annually` : ''

		return {
			currentBenefits,
			yearlyCost,
			showStandardPlan,
			currentPrice,
			currentPriceMonthly,
		}
	}

	private shouldHideAutopilotSection = (): boolean => {
		const subscriptionType = determineSubscriptionType(this.subscriptionInformation)
		return subscriptionType === SubscriptionType.MANDATORY_ERA_ENHANCED || subscriptionType === SubscriptionType.VIP
	}

	private getAutoPilotProps(): StandardCardProps | undefined {
		const standard = this.addProductPrices(MembershipProducts.AutopilotStandard)
		const enhanced = this.addProductPrices(MembershipProducts.AutopilotEnhanced)

		const currentPlanData = this.getCurrentPlanData()
		const standardPricing = this.getMembershipPricing(standard.variants['standard'])
		const enhancedPricing = this.getMembershipPricing(enhanced.variants['standard'])

		if (this.shouldHideAutopilotSection()) {
			return undefined
		}

		return {
			type: 'standard-cards',
			selectorKey: 'auto-pilot',
			title: 'Autopilot',
			description: 'Unlock the full features of the Pod with Autopilot. One plan for you and your partner.',
			modalButtons: [
				{
					modalKey: 'membership-modal',
					text: 'Need help choosing a plan?',
				},
			],
			data: {
				selectionKey: 'auto-pilot',
				options: [getStandardPlan(currentPlanData, standardPricing), getEnhancedPlan(enhancedPricing)],
			},
		}
	}

	/******************************************************************************
	Leg Kit
	******************************************************************************/
	private getLegKitProduct(): Product | undefined {
		const product = this.addProductPrices(Pod4Accessories.Pod4UltraLegKit)
		if (!product || !product.variants['standard']) {
			return undefined
		}
		return product
	}

	private getLegKitData(): PricingData | undefined {
		const product = this.getLegKitProduct()
		return this.getPricingData(product.variants['standard'])
	}

	private getLegKitProps(): StandardCardProps | undefined {
		const legKitData = this.getLegKitData()
		if (!legKitData) return undefined

		return {
			type: 'standard-cards',
			selectorKey: 'leg-kit',
			title: 'Optional leg kit',
			description:
				"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12 piece leg kit to have it stand on its own.",
			modalButtons: [
				{
					modalKey: 'legkit-modal',
					text: 'Do I need a leg kit?',
				},
			],
			data: {
				selectionKey: 'leg-kit',
				options: [
					{
						key: 'none',
						type: 'standard',
						data: {
							title: 'No leg kit',
							description: 'Keep your current bed frame',
						},
					},
					{
						key: 'adjustable',
						type: 'standard',
						data: {
							title: 'Add leg kit',
							subtitle: legKitData?.price,
							description: 'Replace your current bed frame with the Base',
						},
					},
				],
			},
		}
	}

	/**
	 * @deprecated Keeping around for the base shop, but this should be removed once we migrate accessories.
	 */
	public getLegKitUpsellData(): ShopSelection {
		const product = this.addProductPrices(Pod4Accessories.Pod4UltraLegKit)
		return {
			id: 'leg-kit',
			title: 'Optional leg kit',
			subtitle:
				"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12 piece leg kit to have it stand on its own.",
			images: [
				{
					type: 'image',
					data: {
						src: 'https://eightsleep.imgix.net/pod4_ultra_legkit_2024_2.png',
						alt: 'Leg Ket display',
					},
				},
			],
			modalButtons: [
				{
					modalKey: 'legkit-modal',
					text: 'Do I need a leg kit?',
				},
			],
			// displayPricing: this.getDisplayPricing(product.variants['standard']),
			options: [
				{
					id: 'none',
					key: 'none',
					type: 'standard',
					data: {
						title: 'No leg kit',
						description: 'Keep your current bed frame',
					},
				},
				{
					id: 'adjustable',
					key: 'adjustable',
					type: 'standard',
					data: {
						product: product,
						title: 'Add leg kit',
						subtitle: this.getPricingData(product.variants['standard']).price,
						description: 'Replace your current bed frame with the Base',
					},
				},
			],
		}
	}

	/******************************************************************************
	Essentials Bundle
	******************************************************************************/
	private getEssentialsProduct(): Product | undefined {
		const bundleWhite = this.addProductPrices(BundleProducts.SleepEssentialBundle)
		const bundleGray = this.addProductPrices(BundleProducts.SleepEssentialBundleGray)

		const numVariantsWhite = Object.keys(bundleWhite.variants).length
		const numVariantsGray = Object.keys(bundleGray.variants).length

		if (numVariantsWhite + numVariantsGray < 1) return undefined
		return numVariantsWhite > 0 ? bundleWhite : bundleGray
	}

	private getEssentialsData(): PricingData | undefined {
		const product = this.getEssentialsProduct()
		if (!product) return undefined

		return this.getPricingData(product.variants[this.selectedVariant])
	}

	private getEssentialsBundleProps(): StandardCheckboxProps | undefined {
		const pricingData = this.getEssentialsData()
		if (!pricingData) return undefined

		return {
			type: 'standard-checkboxes',
			selectorKey: 'essentials-bundle',
			title: 'Add an accessory and save more',
			description: 'The Sleep Essentials Bundle adds the latest in Sleep technology to your Pod.',
			data: {
				options: [
					{
						selectionKey: 'essentials-bundle',
						trueValue: 'white',
						falseValue: 'none',
						title: 'Sleep Essentials Bundle',
						description: 'Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Air Pillows.',
						pricingData,
					},
				],
			},
		}
	}

	/******************************************************************************
	The Base
	******************************************************************************/

	private getBaseProduct(member = true): Product | undefined {
		if (!member) {
			return this.addProductPrices(TheBaseProducts.TheBaseNonMember)
		}
		return this.addProductPrices(TheBaseProducts.TheBase)
	}

	private getBaseProductData(member = true): PricingData | undefined {
		const product = this.getBaseProduct(member)
		const variant = product?.variants[this.selectedVariant]
		return this.getPricingData(
			variant,
			'Get an extra ' + PriceManager.formatPriceToCurrencyNoDecimal((variant.prices.comparePrice - variant.prices.price) / 100, this.rootStore.priceStore.currency) + ' off'
		)
	}

	private getTheBaseProps(member = true): StandardCheckboxProps | undefined {
		const pricingData = this.getBaseProductData(member)
		if (!pricingData) return undefined

		return {
			type: 'standard-checkboxes',
			selectorKey: 'the-base',
			title: 'Complete your experience',
			description: 'Get all the benefits of the Pod with our 3-inch Base that fits between your mattress and bed frame',
			data: {
				options: [
					{
						selectionKey: 'the-base',
						trueValue: 'standard',
						falseValue: 'none',
						title: 'The Base',
						description: 'Fits between your current mattress and bed frame',
						icons: [
							{
								icon: 'CheckLight',
								text: 'Decreases back pain with a custom sleeping position',
							},
							{
								icon: 'CheckLight',
								text: 'Inclines automatically to reduce and stop snoring',
							},
							{
								icon: 'CheckLight',
								text: 'Preset and custom positions for reading and relaxing in bed',
							},
						],
						pricingData,
					},
				],
			},
		}
	}

	/******************************************************************************
	Mattress
	******************************************************************************/
	private getMattressProduct(): { numLayers: number; product: Product } | undefined {
		const _5LayerMattress = this.addProductPrices(MattressProducts.FiveLayerDiscounted)
		const _3LayerMattress = this.addProductPrices(MattressProducts.ThreeLayer)

		const numVariants5Layers = Object.keys(_5LayerMattress.variants).length
		const numVariants3Layers = Object.keys(_3LayerMattress.variants).length

		if (numVariants5Layers + numVariants3Layers < 1) return undefined

		const numLayers = numVariants5Layers > 0 ? 5 : 3
		const product = numVariants5Layers > 0 ? _5LayerMattress : _3LayerMattress
		return { numLayers, product }
	}

	private getMattressData(): { numLayers: number; pricingData: PricingData } | undefined {
		const mattressProduct = this.getMattressProduct()
		if (!mattressProduct) return undefined
		const { numLayers, product } = mattressProduct

		const pricingData = this.getPricingData(product.variants[this.selectedVariant])

		return {
			numLayers,
			pricingData,
		}
	}

	private getEightMattressProps(): StandardCheckboxProps | undefined {
		const mattressData = this.getMattressData()
		if (!mattressData) return undefined

		return {
			type: 'standard-checkboxes',
			selectorKey: 'eight-mattress',
			title: 'Add a mattress and save more',
			description: `With our ${mattressData.numLayers}-layer mattress, you can achieve the perfect mix of temperature control and comfort.`,
			modalButtons: [
				{
					modalKey: 'mattress-modal',
					text: 'Why add a mattress?',
				},
			],
			data: {
				options: [getMattressCheckboxProps(mattressData)],
			},
		}
	}

	/******************************************************************************
	Accessories Grouped Selector
	******************************************************************************/
	private getAccessoriesProps(): StandardCheckboxProps | undefined {
		const mattressData = this.getMattressData()
		const legKitData = this.getLegKitData()
		const essentialsData = this.getEssentialsData()
		if (!mattressData && !legKitData && !essentialsData) return undefined

		return {
			type: 'standard-checkboxes',
			selectorKey: 'accessories',
			title: 'Accessories',
			description: 'Make the most of your sleep with extras designed for the Pod',
			data: {
				options: getAccessoriesProps(mattressData, legKitData, essentialsData),
			},
		}
	}

	/******************************************************************************
	Summary
	******************************************************************************/
	public getShippingText(): string {
		return getShippingText(this.podVariant, this.rootStore.settingsStore.currentRegion, this.selectedSelections['eight-mattress'] as 'yes_mattress' | 'no_mattress', this.selectedSelections['the-base'] as 'standard' | 'none')
	}

	private getSummaryProps(): SummaryProps {
		return {
			description: '',
			selectorKey: 'summary',
			title: '',
			type: 'summary',
			data: {
				loading: this.goingToCheckout,
				handleCheckout: () => this.checkout('selection-summary'),
				price: this.shopTotal.priceString,
				strikePrice: this.shopTotal.comparePriceString,
				savings: this.shopTotal.savingsAmountString,
				financingProvider: this.rootStore.priceStore.financingProvider,
				financingAmount: this.totalPriceFinancing,
				orderItems: this.orderItemsString,
				buttonText: 'Checkout',
				shippingText: this.getShippingText(),
				benefits: this.benefits,
			},
		}
	}
}
