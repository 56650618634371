import { IconComponentType } from '../../types'

export const GenderCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox={'0 0 24 24'}
		width={size}
		height={size}
		fill={'none'}
	>
		<path
			d="M18.5555 2.62048L22.7436 6.80858L21.3475 8.20461L19.5501 6.40722L19.5327 11.939C20.0795 12.0437 20.5943 12.2211 21.0771 12.4712C21.5599 12.7214 21.999 13.0442 22.3946 13.4397C23.4649 14.51 24 15.7897 24 17.2788C24 18.7679 23.4649 20.0476 22.3946 21.1179C21.3243 22.1882 20.0446 22.7234 18.5555 22.7234C17.0664 22.7234 15.7867 22.1882 14.7164 21.1179C13.6461 20.0476 13.1109 18.7679 13.1109 17.2788C13.1109 15.7897 13.6461 14.51 14.7164 13.4397C15.1003 13.0558 15.5336 12.733 16.0164 12.4712C16.4992 12.2095 17.014 12.0379 17.5608 11.9565V6.40722L15.7634 8.20461L14.3674 6.80858L18.5555 2.62048ZM16.1124 14.8358C15.4377 15.5105 15.1003 16.3249 15.1003 17.2788C15.1003 18.2328 15.4377 19.0471 16.1124 19.7219C16.7872 20.3966 17.6015 20.734 18.5555 20.734C19.5094 20.734 20.3238 20.3966 20.9985 19.7219C21.6733 19.0471 22.0107 18.2328 22.0107 17.2788C22.0107 16.3249 21.6733 15.5105 20.9985 14.8358C20.3238 14.161 19.5094 13.8236 18.5555 13.8236C17.6015 13.8236 16.7872 14.161 16.1124 14.8358Z"
			fill={color}
		/>
		<path
			d="M4.44215 18.7815V16.8072H2.46786V14.8329H4.44215V12.7599C3.14241 12.5295 2.07712 11.9085 1.24627 10.8966C0.415423 9.88482 0 8.71258 0 7.37994C0 5.88277 0.53059 4.61182 1.59177 3.56709C2.65295 2.52236 3.93213 2 5.4293 2C6.92646 2 8.20564 2.52236 9.26682 3.56709C10.328 4.61182 10.8586 5.88277 10.8586 7.37994C10.8586 8.71258 10.4432 9.88482 9.61232 10.8966C8.78147 11.9085 7.71618 12.5295 6.41644 12.7599V14.8329H8.39073V16.8072H6.41644V18.7815H4.44215ZM5.4293 10.8843C6.38354 10.8843 7.19793 10.547 7.87248 9.87248C8.54703 9.19793 8.8843 8.38354 8.8843 7.4293C8.8843 6.47506 8.54703 5.66066 7.87248 4.98611C7.19793 4.31156 6.38354 3.97429 5.4293 3.97429C4.47506 3.97429 3.66066 4.31156 2.98611 4.98611C2.31156 5.66066 1.97429 6.47506 1.97429 7.4293C1.97429 8.38354 2.31156 9.19793 2.98611 9.87248C3.66066 10.547 4.47506 10.8843 5.4293 10.8843Z"
			fill={color}
		/>
	</svg>
)
