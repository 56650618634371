import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getAutopilotVisuals = (metric: boolean): VisualProps => {
	return {
		type: 'new_carousel',
		associatedSelectorKeys: ['auto-pilot'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Autopilot+%E2%80%93+Wide-4.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Autopilot+%E2%80%93+Mobile-2.png',
					alt: 'App showing autopilot',
				},
				associatedSelections: [],
				pills: [
					{
						icon: 'CoolLight',
						text: 'Sleep and health reports',
					},
					{
						icon: 'BedLight',
						text: 'Automatic temperature adjustments',
					},
					{
						icon: 'TwoUsersLight',
						text: 'One plan for you and your partner',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-2.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/In+situ+%E2%80%93+Mobile.png',
					alt: 'Autopilot explanation slide 2',
					objectFit: 'cover',
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: metric
						? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Temperature+Non-US+%E2%80%93+Wide.png'
						: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilotfinalwideslide.png',
					mobileSrc: metric
						? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Temperature+Non+US+%E2%80%93+Mobile-2.png'
						: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Temperature+%E2%80%93+Mobile-2.png',
					alt: 'Autopilot explanation slide 3',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-4-desktop.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Snoring+%E2%80%93+Mobile.png',
					alt: 'Autopilot explanation slide 4',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Alarm+%E2%80%93+Wide-2.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Alarm+%E2%80%93+Mobile.png',
					alt: 'Autopilot explanation slide 5',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-6.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Metrics+%E2%80%93+Mobile.png',
					alt: 'Autopilot explanation slide 6',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
					className: 'slide_offset_desktop',
				},
				associatedSelections: [],
			},
		],
	}
}
