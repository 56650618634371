/**
 * Linear interpolation between two numbers.
 * @param a The start value
 * @param b The end value
 * @param t The interpolation amount
 */
export const lerp = (a: number, b: number, t: number) => a + (b - a) * t

/**
 * Maps a number from one range to another.
 * @param n The number to map
 * @param start1 The lower bound of the value's current range
 * @param stop1 The upper bound of the value's current range
 * @param start2 The lower bound of the value's target range
 * @param stop2 The upper bound of the value's target range
 */
export const map = (n: number, start1: number, stop1: number, start2: number, stop2: number) => {
	const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2
	if (start2 < stop2) {
		return constrain(newval, start2, stop2)
	} else {
		return constrain(newval, stop2, start2)
	}
}

/**
 * Constrains a number to be within a range.
 * @param n The number to constrain
 * @param low The lower end of the range
 * @param high The upper end of the range
 */
export const constrain = (n: number, low: number, high: number) => Math.max(Math.min(n, high), low)
export const clamp = (n: number, min: number, max: number) => constrain(n, min, max)

/**
 * Modulo that works with negative numbers
 * @param n The number to mod
 * @param m The modulo
 */
export const mod = (n: number, m: number) => ((n % m) + m) % m

export const degToRad = (deg: number) => (deg * Math.PI) / 180

export const radToDeg = (rad: number) => (rad * 180) / Math.PI
