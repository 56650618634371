import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.87868 18.1213C4.44129 18.6839 5.20435 19 6 19H18C18.7956 19 19.5587 18.6839 20.1213 18.1213C20.6839 17.5587 21 16.7956 21 16V10.75H3V16C3 16.7956 3.31607 17.5587 3.87868 18.1213ZM3 9.25H21V8C21 7.20435 20.6839 6.44129 20.1213 5.87868C19.5587 5.31607 18.7956 5 18 5H6C5.20435 5 4.44129 5.31607 3.87868 5.87868C3.31607 6.44129 3 7.20435 3 8V9.25ZM7 14.25C6.58579 14.25 6.25 14.5858 6.25 15C6.25 15.4142 6.58579 15.75 7 15.75H8C8.41421 15.75 8.75 15.4142 8.75 15C8.75 14.5858 8.41421 14.25 8 14.25H7ZM12 14.25C11.5858 14.25 11.25 14.5858 11.25 15C11.25 15.4142 11.5858 15.75 12 15.75H13C13.4142 15.75 13.75 15.4142 13.75 15C13.75 14.5858 13.4142 14.25 13 14.25H12Z"
			fill={color}
		/>
	</svg>
)

export default icon
