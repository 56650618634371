import { redirectToRegion, RegionShort } from 'utils/internationalization'
import React, { FC } from 'react'
import styles from './Footer.module.scss'
import { EmailInput } from 'components/EmailInput/EmailInput'
import { CaptureLocation } from 'utils/events'
import { Icon, eight } from 'components/Icon'
import { getBlogItems, getCompanyItems, getLegalItems, getPhoneNumber, getSupportItems } from './utils'
import { FooterListItem, getSocialLinks } from './subcomponents'
import { PriceManager } from 'prices'
import { useRouter } from 'next/router'
import { trackClickSupport } from 'events/index'
import { VARIABLE_CURRENCY_MAP } from 'stripe_lib/scripts'
import { LoadingSkeleton } from 'components/LoadingSkeleton'
import { CountryPicker } from 'components/NavBar/CountryPicker'
import { RootStore } from 'stores'

export interface FooterProps {
	region: RegionShort
	baseDiscountAmount: number

	hideEmail?: boolean
	rootStore?: RootStore
}

/**
 * @deprecated Use /components/Footer instead
 */
export const Footer: FC<FooterProps> = (props) => {
	const router = useRouter()
	const phoneNumber = getPhoneNumber(props.region)

	const handleLocalize = props.region === 'eu' && typeof window !== 'undefined'
	const handleClick = handleLocalize ? props.rootStore.modalStore.toggleLocalizeModal : undefined

	const handleSetRegion = (region: RegionShort) => {
		redirectToRegion(region, router)
	}

	let amount = props.baseDiscountAmount

	if (props.region === 'se') {
		amount = VARIABLE_CURRENCY_MAP.SEK[amount]
	}
	if (props.region === 'dk') {
		amount = VARIABLE_CURRENCY_MAP.DKK[amount]
	}

	const discount = PriceManager.formatPriceToCurrencyNoDecimal(amount, PriceManager.getRegionCurrency(props.region))

	const hideEmail = router.query.utm_source === 'app' || props.hideEmail
	const est = new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' }))
	// TODO this should be moved out of the component and into a prop
	const showContactInfo = est.getHours() > 9 && est.getHours() < 17

	return (
		<footer
			className={styles.footer}
			id={'footer'}
		>
			<a
				className="skip-main"
				href="#header"
				role="link"
				aria-label="Anchor link to Header"
			>
				Header
			</a>
			<a
				className="skip-main"
				href="#main"
				role="link"
				aria-label="Anchor link to Main Content"
			>
				Main Content
			</a>

			<div>
				<div className={styles.logo}>
					<Icon icon={eight} />
				</div>
				<h2 className={'vh'}>Eight Sleep</h2>
			</div>

			<div className={styles.country_picker_container}>
				<CountryPicker
					region={props.region}
					clickCountry={handleSetRegion}
					onClick={handleClick}
					flipHorizontal
				/>
			</div>

			<div className={styles.inner}>
				<div className={styles.email_container}>
					{hideEmail ? (
						<></>
					) : (
						<>
							<h3 className={styles.email_header}>
								Get up to{' '}
								<LoadingSkeleton
									light
									height={'0.8em'}
									width={'6ch'}
								>
									{discount}
								</LoadingSkeleton>{' '}
								off
								<br />
								<span>Because extraordinary sleep makes a difference</span>
							</h3>
							<div className={styles.input_container}>
								<EmailInput
									location={CaptureLocation.FOOTER}
									placeholder={'Email address'}
									errorMessage={'Email is invalid, please try again.'}
								/>
								<p className={styles.disclaimer}>By signing up here I agree to receive Eight Sleep’s email newsletter and special promotional offers.</p>
							</div>
							<p className={styles.asterisk}>* Individual results may vary.</p>
						</>
					)}
				</div>
				<ul className={styles.footer_list}>
					<FooterListItem
						header={'Blog'}
						sublistItems={getBlogItems()}
					/>
					<FooterListItem
						header={'Company'}
						sublistItems={getCompanyItems()}
					/>
					<FooterListItem
						header={'Support'}
						sublistItems={getSupportItems()}
					/>
					<FooterListItem
						header={'Legal'}
						sublistItems={getLegalItems(props.region)}
					/>
				</ul>
			</div>

			<div className={styles.contact_container}>
				<div
					className={styles.call_us}
					style={{ visibility: showContactInfo ? 'visible' : 'hidden' }}
				>
					<h3>Contact Us</h3>
					<div className={styles.contact_links}>
						{/*
					<a
						href={'mailto: support@eightsleep.com'}
						onClick={() => trackClickSupport('Email')}
					>
						support@eightsleep.com
					</a>
					*/}
						<a
							href={phoneNumber.link}
							onClick={() => trackClickSupport('Phone Number')}
						>
							{phoneNumber.string}
						</a>
					</div>
				</div>
				<div className={styles.social_links}>
					<h3 className={'vh'}>Social Links</h3>
					<ul>{getSocialLinks()}</ul>
				</div>
			</div>

			<div className={styles.copyright_and_country}>
				<p className={styles.copyright}>&copy; {new Date().getFullYear()} Eight Sleep. All rights reserved.</p>
			</div>

			{hideEmail && <p className={styles.asterisk}>* Individual results may vary.</p>}
		</footer>
	)
}
