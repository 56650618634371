import { ReactNode, FC } from 'react'
import cx from 'classnames'
import styles from './Img.module.scss'
import { Img, ImgProps } from './Img'

export interface ImgBackgroundProps extends ImgProps {
	children?: ReactNode
}

export const ImgBackground: FC<ImgBackgroundProps> = (props) => {
	return (
		<div className={cx(styles.img_background, props.className)}>
			<Img
				className={cx('background-img')}
				src={props.src}
				alt={props.alt}
				sources={props.sources}
				lazy={props.lazy}
				id={props.id}
				onClick={props.onClick}
				dprHeight={props.dprHeight}
				simple={props.simple}
				href={props.href}
				objectFit={'cover'}
			/>
			<div className="content absolute-cover">{props.children}</div>
		</div>
	)
}
