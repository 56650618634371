import React, { FC } from 'react'
import styles from './LoadingIcon.module.scss'
import cx from 'classnames'

/*
	For accessbility should have aria-live="polite|assertive" and busy="true|false" 
	on whatever parent uses this component;
*/

export interface LoadingIconProps {
	isLoading: boolean
	width?: string
	stroke?: string
	bigColor?: string
	smallColor?: string
	speed?: string
	accessibilityTextLoading?: string
	accessibilityTextNotLoading?: string
}

export const LoadingCircle: FC<LoadingIconProps> = (props) => {
	const text = props.isLoading ? props.accessibilityTextLoading : props.accessibilityTextNotLoading
	return (
		<div
			role="alert"
			aria-live="assertive"
			className={cx(styles.c_loading, { loading: props.isLoading })}
			style={{
				['--stroke' as any]: props.stroke,
				['--width' as any]: props.width,
				['--big-color' as any]: props.bigColor,
				['--small-color' as any]: props.smallColor,
				['--speed' as any]: props.speed,
			}}
		>
			<p className="vh">{text}</p>
		</div>
	)
}

LoadingCircle.defaultProps = {
	stroke: '2px',
	width: '10px',
	bigColor: '#ddd',
	smallColor: '#F00',
	speed: '2s',
	accessibilityTextLoading: 'Content is loading.',
	accessibilityTextNotLoading: 'Content has loaded.',
	isLoading: false,
}
