import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M16.0004 20.0004V13.0004H20.0004V20.0004H16.0004ZM10.0004 20.0004V4.0004H14.0004V20.0004H10.0004ZM4.00037 20.0004V9.0004H8.00037V20.0004H4.00037Z"
			fill={color}
		/>
	</svg>
)

export default icon
