import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './EmailCapDrawer.module.scss'
import { emailCapShown, trackSubscribeViaEmailCap } from 'events/index'
import { useRouter } from 'next/router'
import { LoadingCircle } from 'components/basic/LoadingCircle'
import { CaptureLocation } from 'utils/events'
import { RootStore } from 'stores'
import { PriceManager } from 'prices'
import { inject, observer } from 'mobx-react'
import { EMAIL_SMS_DISCOUNT_CODE } from 'stripe_lib/discounts'

export interface EmailCapDrawerProps {
	hideEmailCapModal?: () => void
	rootStore?: RootStore
}

export const EmailCapDrawer: FC<EmailCapDrawerProps> = inject('rootStore')(
	observer((props) => {
		const router = useRouter()
		const [email, setEmail] = useState('')
		const [visible, setVisible] = useState(false)
		const [loading, setLoading] = useState(false)
		const ref = useRef<HTMLInputElement>(null)
		const { settingsStore, priceStore } = props.rootStore!

		const currency = PriceManager.getRegionCurrency(settingsStore.currentRegion)

		const totalAmount = priceStore.getUpToDiscountNumber
		const amount = PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.convertToCurrency(totalAmount, currency), currency)

		const handleDrawerEvent = useCallback(() => {
			const drawerWasClosed = !!window.sessionStorage.getItem('drawer-ecap-closed')
			if (!drawerWasClosed) {
				setVisible(true)
			}
			if (!window.sessionStorage.getItem('drawer-ecap-shown') && !drawerWasClosed) {
				emailCapShown()
				window.sessionStorage.setItem('drawer-ecap-shown', 'true')
			}
		}, [])

		useEffect(() => {
			window.addEventListener('drawer-ecap', handleDrawerEvent)
			window.addEventListener('scroll', handleDrawerEvent)
			return () => {
				window.removeEventListener('drawer-ecap', handleDrawerEvent)
				window.removeEventListener('scroll', handleDrawerEvent)
			}
		}, [handleDrawerEvent])

		const handleChange = (e: FormEvent<HTMLInputElement>) => {
			setEmail(e.currentTarget.value)
			if (ref.current) {
				ref.current.setCustomValidity('')
			}
		}

		const closeModal = () => {
			setVisible(false)
			props.hideEmailCapModal?.()
			window.sessionStorage.setItem('drawer-ecap-closed', 'true')
		}

		const handleSubmitBlog = async (e: FormEvent) => {
			setLoading(true)
			e.preventDefault()
			if (!email && ref.current) {
				ref.current.setCustomValidity('Email is invalid, please try again.')
				setLoading(false)
				return
			}

			const properties = {
				email: email,
				emailCapType: `Blog`,
				location: 'Blog React Modal',
				captureLocation: CaptureLocation.MODAL,
				vanityLink: window.location.href,
				totalDiscount: amount,
				discountCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
				currency: currency,
				region: settingsStore.currentRegion,
			}

			closeModal()
			await trackSubscribeViaEmailCap(properties)
			setLoading(false)
		}

		return (
			<div className={`${styles.container} ${visible && styles.container_visible}`}>
				<button
					className={styles.close}
					onClick={closeModal}
				>
					<X />
				</button>
				<div className={styles.inner}>
					<div className={styles.text}>
						<h3>Unlock up to {amount} off</h3>
						<p>Great sleep makes a difference. Join the Sleep Fitness Club and start your journey with an exclusive offer.</p>
					</div>
					<form
						className={styles.email_input}
						onSubmit={handleSubmitBlog}
					>
						<input
							type={'email'}
							ref={ref}
							placeholder={'Enter your email'}
							value={email}
							onChange={handleChange}
						/>
						{loading ? (
							<LoadingCircle
								width="32px"
								isLoading
								smallColor="#1862ff"
							/>
						) : (
							<button type={'submit'}>
								<Arrow />
							</button>
						)}
					</form>
				</div>
			</div>
		)
	})
)

const X: FC = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.3067 2.10768C15.6972 1.71716 15.6972 1.08399 15.3067 0.693467C14.9162 0.302943 14.283 0.302943 13.8925 0.693467L7.99999 6.58597L2.10748 0.693467C1.71696 0.302943 1.0838 0.302943 0.693271 0.693467C0.302746 1.08399 0.302746 1.71716 0.693271 2.10768L6.58577 8.00018L0.693162 13.8928C0.302638 14.2833 0.302637 14.9165 0.693162 15.307C1.08369 15.6975 1.71685 15.6975 2.10738 15.307L7.99999 9.4144L13.8926 15.307C14.2831 15.6975 14.9163 15.6975 15.3068 15.307C15.6973 14.9165 15.6973 14.2833 15.3068 13.8928L9.4142 8.00018L15.3067 2.10768Z"
			fill="black"
		/>
	</svg>
)

const Arrow: FC = () => (
	<svg
		width="33"
		height="32"
		viewBox="0 0 33 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.5 16L24.5 16M24.5 16L16.5 23M24.5 16L16.5 9"
			stroke="black"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
