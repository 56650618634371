import { FC } from 'react'
import styles from 'components/WebEv/Buttons/button.module.scss'

interface LoadingSpinnerProps {
	color?: string
	duration?: number
}

export const ButtonLoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
	const color = props.color || 'white'
	const duration = props.duration || 1
	const durationChunk = 1 / 8
	return (
		<div className={styles.loading_spinner}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.5 4.5V8.5"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
					}}
				/>
				<path
					d="M15.5 9.32837L18.3284 6.49994"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${durationChunk}s`,
					}}
				/>
				<path
					d="M15.5 12.5L19.5 12.5"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${2 * durationChunk}s`,
					}}
				/>
				<path
					d="M15.5 15.5L18.3284 18.3284"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${3 * durationChunk}s`,
					}}
				/>
				<path
					d="M12.5 16.5V20.5"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${4 * durationChunk}s`,
					}}
				/>
				<path
					d="M6.5 18.3284L9.32843 15.4999"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${5 * durationChunk}s`,
					}}
				/>
				<path
					d="M4.5 12.5L8.5 12.5"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${6 * durationChunk}s`,
					}}
				/>
				<path
					d="M6.5 6.5L9.32843 9.32843"
					stroke={color}
					strokeWidth="2"
					strokeLinecap="round"
					style={{
						animationDuration: `${duration}s`,
						animationDelay: `${7 * durationChunk}s`,
					}}
				/>
			</svg>
		</div>
	)
}
