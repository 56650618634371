import { icons } from 'components/Phantom/Icon/mapping'
import { getTemperature } from 'components/_utils/temperatureUtils'

export const getPod4FeatureMatrix = (metric = false) => {
	const lowTemp = getTemperature(55, metric)
	const highTemp = getTemperature(110, metric)

	const features: { title: string; description: string; values: number[] }[] = [
		{ title: 'Automatic temperature', description: `Automatic temperature changes, with temperatures between ${lowTemp} - ${highTemp}.`, values: [1, 1, 1] },
		{ title: 'Dual climate control', description: 'Individualized heating and cooling for each side of the bed.', values: [1, 1, 1] },
		{ title: 'Vibration and thermal alarm', description: 'A chest-level vibration and gradual thermal change to wake you up', values: [1, 1, 1] },
		{ title: 'Sleep and health reports', description: 'Reports on your sleep time, sleep phases, HRV, heart rate, respiratory rate, and more.', values: [1, 1, 1] },
		{ title: 'Fits on any bed', description: 'Designed to be added onto any mattress like a fitted sheet', values: [1, 1, 1] },
		{ title: 'Snoring detection', description: 'Detailed reports of when you (or your partner) snore and how strong the snoring is.', values: [1, 1, 1] },
		{
			title: 'Clinical-grade sleep and health tracking',
			description: 'Key metrics tracked such as heart rate, HRV, sleep stages, snoring and respiratory rate. Now 99% accurate, when tested against gold standards. ',
			values: [1, 1, 1],
		},
		{ title: 'Dynamic contouring surface', description: 'A new design with contouring materials makes the Cover 20% thinner, more dynamic and more comfortable.', values: [0, 1, 1] },
		{
			title: "Maintains your mattress' comfort",
			description: 'New Cover design makes the Pod’s technology virtually imperceptible, with no impact to the current feeling of your mattress.',
			values: [0, 1, 1],
		},
		{ title: '2x more cooling power', description: 'A redesign of the Hub and Cover enables double the cooling power of the Pod. ', values: [0, 1, 1] },
		{ title: 'Silent performance', description: 'Powerful thermal performance, yet silent. Stable at 30dB decibels, which is 40% quieter than Pod 3', values: [0, 1, 1] },
		{ title: 'Control without a phone', description: 'Tap the zones on each side of the Pod to control temperature and elevation.', values: [0, 1, 1] },

		{ title: 'Snoring mitigation', description: 'Automatic elevation of the head area to reduce or stop snoring without you waking up.', values: [0, 0, 1] },
		{ title: 'Custom elevation for sleeping', description: 'Expertly designed Base position for reduced back pressure and improved circulation.', values: [0, 0, 1] },
		{ title: 'Custom elevation for reading', description: 'Use the Base for the ideal position while reading or watching TV before bedtime.', values: [0, 0, 1] },
		{ title: 'Custom elevation for relaxing', description: 'Experience the best comfort while lounging in bed with a slight head and foot elevation of the Base.', values: [0, 0, 1] },
	]

	return features
}

export const featureIcons: Record<string, { name: keyof typeof icons; color?: string }> = {
	'Automatic temperature': { name: 'TemperatureLight' },
	'Dual climate control': { name: 'DualTempLight' },
	'Dynamic contouring surface': { name: 'ComfortCustom' },
	'Vibration and thermal alarm': { name: 'VibrationLight' },
	'Sleep and health reports': { name: 'HeartReportLight' },
	'Fits on any bed': { name: 'BedLight' },
	'Snoring detection': { name: 'SnoringLight' },
	"Maintains your mattress' comfort": { name: 'SurfaceLight' },
	'2x more cooling power': { name: 'CoolDark' },
	'Silent performance': { name: 'SoundOffLight' },
	'Clinical-grade sleep and health tracking': { name: 'HeartLight' },
	'Control without a phone': { name: 'TapCustom' },
	'Snoring mitigation': { name: 'SnoreMitigationCustom' },
	'Custom elevation for sleeping': { name: 'SleepingDark' },
	'Custom elevation for reading': { name: 'ReadingDark' },
	'Custom elevation for relaxing': { name: 'RelaxingDark' },
}
