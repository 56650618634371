import styles from './BenefitsContainer.module.scss'
import { Icon } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'
import * as React from 'react'
import { getBenefits } from 'components/WebEv/Shop/utils/productBenefits'
import { FC } from 'react'

interface BenefitsProps {
	warrantyAmount: number
	shippingTimeline: string
}

export const BenefitsContainer: FC<BenefitsProps> = (props) => {
	const benefits = getBenefits(props.warrantyAmount)

	return (
		<div className={styles.benefits}>
			<h3>{props.shippingTimeline}</h3>
			<ul className={styles.benefits_list}>
				{benefits.map((benefit, index) => (
					<li key={index}>
						<Icon
							icon={benefit.icon}
							color={Colors.Neutral.black.hex}
							className={styles.benefit_icon}
						/>
						<p>{benefit.description}</p>
					</li>
				))}
			</ul>
		</div>
	)
}
