import { CarouselContent, ContentWithSteps, ShopAction, ShopState, UpsellSelectionData } from 'components/WebEv/Shop/types'
import { amSelectedBundleUpsell, amSelectedMattress, amSelectedMembership, amSelectedProduct, amSelectedVariant } from 'events/amplitude'
import { getMembershipCards, getMembershipStep } from './data_handling/coverData'
import { getAutopilotABStringMap } from 'utils/autopilotABUtil'

export const shopReducer = (state: ShopState, action: ShopAction): ShopState => {
	switch (action.type) {
		case 'updateSelection': {
			const { groupID, selectionValue } = action.data
			const activeSelections = { ...state.activeSelections }
			const newSelections = [...state.data.selections]

			const selectionIndex = state.data.selections.findIndex((selection) => selection.id === groupID)
			const selection = state.data.selections[selectionIndex]
			selection.error = ''

			if (!activeSelections[groupID]) {
				activeSelections[groupID] = {
					field: action.data.field,
					value: '',
				}
			}

			if (action.data.field === 'product') {
				amSelectedProduct(selectionValue, -1)
			}

			if (action.data.field === 'variant') {
				amSelectedVariant(selectionValue)
			}

			if (action.data.field === 'membership') {
				amSelectedMembership(selectionValue, -1)
			}

			if (action.data.field === 'sleep-starter-bundle-upsell') {
				amSelectedBundleUpsell(selectionValue, -1)
			}

			if (action.data.field === 'mattress-upsell') {
				amSelectedMattress(selectionValue, -1)
			}

			activeSelections[groupID].value = selectionValue
			return { ...state, activeSelections, data: { ...state.data, selections: newSelections } }
		}

		case 'updateModal': {
			const { modal } = action.data
			const prevModal = state.modal

			return { ...state, modal, prevModal }
		}

		case 'resetState': {
			return action.data
		}

		case 'updateProduct': {
			const { price, strikePrice, discount, productName } = action.data

			return {
				...state,
				price,
				strikePrice,
				discount,
				productName,
			}
		}

		case 'updateMattressUpsell': {
			const { price, strikePrice } = action.data

			const mattressUpsellIndex = state.data.selections.findIndex((selection) => selection.field === 'mattress-upsell')

			const mattressUpsell = state.data.selections[mattressUpsellIndex] as UpsellSelectionData
			if (!mattressUpsell) return state

			const newSelections = [...state.data.selections]

			mattressUpsell.data.price = price
			mattressUpsell.data.strikePrice = strikePrice

			newSelections[mattressUpsellIndex] = mattressUpsell
			return {
				...state,
				data: {
					...state.data,
					selections: newSelections,
				},
			}
		}

		case 'updateStarterBundleUpsell': {
			const { price, strikePrice } = action.data

			const starterBundleUpsellIndex = state.data.selections.findIndex((selection) => selection.field === 'sleep-starter-bundle-upsell')

			const starterBundleUpsell = state.data.selections[starterBundleUpsellIndex] as UpsellSelectionData
			if (!starterBundleUpsell) return state

			const newSelections = [...state.data.selections]

			starterBundleUpsell.data.price = price
			starterBundleUpsell.data.strikePrice = strikePrice

			newSelections[starterBundleUpsellIndex] = starterBundleUpsell
			return {
				...state,
				data: {
					...state.data,
					selections: newSelections,
				},
			}
		}

		case 'togglePodGiftMembership': {
			const { groupID, selectionValue } = action.data
			const activeSelections = { ...state.activeSelections }
			const newSelections = [...state.data.selections]
			const desktopStickyContent = state.data.desktopStickyContent as CarouselContent
			const mobileSelectionPairings = state.data.mobileSelectionPairings as ContentWithSteps[]

			activeSelections[groupID].value = selectionValue

			const isPodGift = selectionValue === 'someone-else'

			const membershipIndex = state.data.selections.findIndex((selection) => selection.field === 'membership')

			if (membershipIndex === -1 && isPodGift) {
				// add membership selection

				const variantIndex = state.data.selections.findIndex((selection) => selection.field === 'variant')
				newSelections.splice(
					variantIndex + 1,
					0,
					getMembershipCards(state.currency, typeof window !== 'undefined' ? window.location.pathname : '', getAutopilotABStringMap('membership_description_alt'))
				)
				const membershipSlide = getMembershipStep(state.currency)
				const newSlides = [...desktopStickyContent.data.slides]
				newSlides.splice(2, 0, membershipSlide)
				desktopStickyContent.data.slides = newSlides
				mobileSelectionPairings.forEach((pairing) => {
					const content = pairing.content as CarouselContent
					const slides = [...content.data.slides]
					slides.splice(2, 0, membershipSlide)
					content.data.slides = slides
				})
			} else if (membershipIndex !== -1 && !isPodGift) {
				// remove membership selection
				newSelections.splice(membershipIndex, 1)
				const newSlides = [...desktopStickyContent.data.slides]
				newSlides.splice(2, 1)
				desktopStickyContent.data.slides = newSlides
				mobileSelectionPairings.forEach((pairing) => {
					const content = pairing.content as CarouselContent
					const slides = [...content.data.slides]
					slides.splice(2, 1)
					content.data.slides = slides
				})
			}

			const selectionIndex = state.data.selections.findIndex((selection) => selection.id === groupID)
			const selection = state.data.selections[selectionIndex]
			selection.error = ''

			if (!activeSelections[groupID]) {
				activeSelections[groupID] = {
					field: action.data.field,
					value: '',
				}
			}

			activeSelections[groupID].value = selectionValue
			state.giftMembership = isPodGift

			return { ...state, activeSelections, data: { ...state.data, selections: newSelections } }
		}

		case 'setError': {
			const { error, selectionID } = action.data
			const newSelections = [...state.data.selections]
			const selectionIndex = newSelections.findIndex((selection) => selection.id === selectionID)
			newSelections[selectionIndex].error = error

			return {
				...state,
				data: {
					...state.data,
					selections: newSelections,
				},
			}
		}

		case 'setFloatingCheckoutOpen': {
			return {
				...state,
				floatingCheckoutOpen: action.data.open,
			}
		}
	}

	return state
}
