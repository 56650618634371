import { FC } from 'react'
import styles from './StickyATC.module.scss'
import { Currency } from 'prices/types'
import { PriceManager } from 'prices'
import cx from 'classnames'
import { Button } from 'components/WebEv/Buttons'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export interface StickyATCProps {
	price: number
	comparePrice: number
	currency: Currency

	cartLoading?: boolean
	buyNowLoading?: boolean

	showFinancing: boolean

	atcClick: () => void

	size?: string

	selectedSize: string
	outOfStock?: boolean
}

export const StickyATC: FC<StickyATCProps> = (props) => {
	const calculatedSavings = props.comparePrice - props.price
	const areThereSavings = calculatedSavings > 0

	const monthlyPrice = PriceManager.getAffirmFinancingAmount(props.price / 100)

	const handleATCClick = () => {
		props.atcClick()
	}

	return (
		<div
			className={cx(styles.container)}
			id="sticky-atc"
		>
			<div className={styles.product_container}>
				<div className={styles.pricing_container}>
					<div>
						<div>
							<p className={styles.price_text}>{PriceManager.formatPriceToCurrencyNoDecimal(props.price / 100, props.currency)}</p>
							{areThereSavings ? (
								<>
									<p className={cx(styles.price_text, styles.compare_price)}>{PriceManager.formatPriceToCurrencyNoDecimal(props.comparePrice / 100, props.currency)}</p>
									{/*<p className={styles.save_price}>Save {PriceManager.formatPriceToCurrencyNoDecimal(calculatedSavings / 100, props.currency)}</p>*/}
								</>
							) : null}

							{props.showFinancing ? <p className={styles.price_text}>or</p> : null}
						</div>

						{props.showFinancing ? (
							<div>
								<p className={styles.financing_text}>{`${PriceManager.formatPriceToCurrencyNoDecimal(monthlyPrice, props.currency)}/mo`}</p>
							</div>
						) : null}
					</div>
					{isValidSize(props.size) && (
						<p className={styles.variant_text}>
							{props.selectedSize ? (
								props.size + ' size'
							) : (
								<AnchorLink
									offset={400}
									href="#variant_select"
								>
									Select a size
								</AnchorLink>
							)}
						</p>
					)}
				</div>
			</div>
			<div className={styles.sticky_button_container}>
				<Button.Primary
					className={styles.atc_button}
					onClick={handleATCClick}
					id={'sticky-add-to-cart-button'}
					disabled={props.outOfStock ? true : props.cartLoading}
					loading={props.cartLoading}
				>
					{props.outOfStock ? 'Out of stock' : 'Add to cart'}
				</Button.Primary>
			</div>
		</div>
	)
}

const isValidSize = (size: string | undefined) => {
	if (!size) return false
	const _size = size.toLowerCase()
	return _size.includes('king') || _size.includes('queen') || _size.includes('full') || _size.includes('double')
}
