export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
	let timeout: any
	return (...args: Parameters<T>): ReturnType<T> => {
		let result: any
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			result = callback(...args)
		}, waitFor)
		return result
	}
}

export const getKeyboardFocusableElements = (element: Element) => {
	return Array.from(element.querySelectorAll('a[href], button, input, textarea, select, details, iframe, [tabindex]:not([tabindex="-1"]), [data-tabindex]')).filter(
		(el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
	)
}

export const clamp = (n: number, low: number, high: number) => {
	return Math.max(Math.min(n, high), low)
}

export enum SaleDate {
	ERROR,
	PAST,
	VALID,
	FUTURE,
}

export const MS_IN_DAY = 1000 * 60 * 60 * 24
export const MS_IN_HOUR = 1000 * 60 * 60
export const MS_IN_MINUTE = 1000 * 60
export const MS_IN_SECOND = 1000

export const validateSaleEndDate = (saleEndDate: Date | string, minDaysToShow: number) => {
	const givenDate = saleEndDate ? new Date(saleEndDate) : null
	if (!givenDate) {
		return SaleDate.ERROR
	} else {
		const timeDif = givenDate.getTime() - Date.now()
		const days = Math.floor(timeDif / MS_IN_DAY)
		if (timeDif < 0) {
			return SaleDate.PAST
		}

		if (days > minDaysToShow) {
			return SaleDate.FUTURE
		}

		return SaleDate.VALID
	}
}

/**
 * Takes an input timestamp in milliseconds and returns the number of days, hours, minutes, and seconds
 * @param time Amount of time in milliseconds
 */
export const getDHMS = (time: number) => {
	const days = Math.floor(time / MS_IN_DAY)
	time -= days * MS_IN_DAY
	const hours = Math.floor(time / MS_IN_HOUR)
	time -= hours * MS_IN_HOUR
	const minutes = Math.floor(time / MS_IN_MINUTE)
	time -= minutes * MS_IN_MINUTE
	const seconds = Math.floor(time / MS_IN_SECOND)
	return { days, hours, minutes, seconds }
}
