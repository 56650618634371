import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M13.2091 20.9999L12.2692 19.8875C10.3852 17.6578 10.2745 14.4157 11.9997 12.0001V12.0001C13.7251 9.58444 13.6142 6.34211 11.73 4.11239L10.79 3"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.7065 20.9999L17.7665 19.8875C15.8826 17.6578 15.7718 14.4157 17.4971 12.0001V12.0001C19.2224 9.58444 19.1115 6.34211 17.2273 4.11239L16.2873 3"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.71183 20.9999L6.7719 19.8875C4.88793 17.6578 4.77717 14.4157 6.50243 12.0001V12.0001C8.22779 9.58444 8.11691 6.34211 6.23268 4.11239L5.29265 3"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
