import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_4830)">
			<path
				d="M12 16.1667C14.3012 16.1667 16.1667 14.3012 16.1667 12C16.1667 9.69882 14.3012 7.83334 12 7.83334C9.69885 7.83334 7.83337 9.69882 7.83337 12C7.83337 14.3012 9.69885 16.1667 12 16.1667Z"
				fill={color}
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 2.83334V4.50001"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 19.5V21.1667"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.51672 5.51666L6.70006 6.7"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.3 17.3L18.4834 18.4833"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.83337 12H4.50004"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.5 12H21.1667"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.51672 18.4833L6.70006 17.3"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.3 6.7L18.4834 5.51666"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_4830">
				<rect
					width="20"
					height="20"
					fill={color}
					transform="translate(2 2)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
