import React, { PropsWithChildren, useEffect } from 'react'
import { Header } from 'sections/Header'
import { Footer } from 'components/Footer_LEGACY'
import { ClientOnly } from 'components/ClientOnly'
import { EmailCapDrawer } from 'components/EmailCapDrawer'
import { useRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { RootStore } from 'stores'

interface BlogProps extends PropsWithChildren {
	rootStore?: RootStore
}

const dispatchDrawerEcap = () => {
	if (typeof window !== 'undefined' && !window.sessionStorage.getItem('drawer-ecap-shown')) {
		setTimeout(() => {
			window.dispatchEvent(new Event('drawer-ecap'))
		}, 10 * 1000)
	}
}

const BlogMainComponent = (props: BlogProps) => {
	const { settingsStore, priceStore } = props.rootStore!

	const router = useRouter()
	useEffect(() => {
		dispatchDrawerEcap()
	}, [])

	const baseDiscountAmount = priceStore.getUpToDiscountNumber
	const ignoreEcap = true // router.query.utm_source === 'app' || router.query.utm_source === 'Klaviyo' || router.query.utm_source === 'klaviyo' || router.query.utm_medium === 'email'

	return (
		<>
			<Header rootStore={props.rootStore} />
			<div className={`root`}>{props.children}</div>
			{!ignoreEcap && (
				<ClientOnly>
					<EmailCapDrawer />
				</ClientOnly>
			)}
			<Footer
				region={settingsStore.currentRegion}
				baseDiscountAmount={baseDiscountAmount}
				rootStore={props.rootStore}
			/>
		</>
	)
}

export const BlogMain = inject('rootStore')(observer(BlogMainComponent))

export default BlogMain
