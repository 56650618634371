import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M17.3334 15.3548C17.3334 17.9203 14.9456 20 12.0001 20C9.05456 20 6.66675 17.9203 6.66675 15.3548C6.66675 13.3913 9.79116 7.78152 11.2572 5.25744C11.5922 4.68054 12.4079 4.68054 12.743 5.25744C14.209 7.78152 17.3334 13.3913 17.3334 15.3548Z"
			stroke={color}
			strokeWidth="1.5"
		/>
	</svg>
)

export default icon
