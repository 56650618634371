import React, { FC } from 'react'
import { RadioButton, RadioButtonValues } from './RadioButton'
import cx from 'classnames'

export interface RadioButtonGroupStatelessProps {
	options: RadioButtonValues[]
	legend: string
	selected: string
	onSelect: (value: string) => void
	className?: string
}

export const RadioButtonGroupStateless: FC<RadioButtonGroupStatelessProps> = (props) => {
	const renderOption: FC<RadioButtonValues> = (option: RadioButtonValues) => {
		return (
			<RadioButton
				key={option.value}
				label={option.label}
				value={option.value}
				groupName={option.groupName}
				onSelect={props.onSelect}
				checked={props.selected === option.value}
			/>
		)
	}

	return (
		<fieldset className={cx(props.className)}>
			<legend>{props.legend}</legend>
			{props.options.map((option, i) => renderOption(option, i))}
		</fieldset>
	)
}
