import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM11.8525 13.7817C11.7194 13.7256 11.5908 13.6601 11.4677 13.5858C11.4497 13.6085 11.4303 13.6303 11.4093 13.6513L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7625 7.46967 15.4696L10.3487 12.5906C10.3726 12.5667 10.3978 12.5447 10.424 12.5247C10.158 12.067 10.0155 11.543 10.0178 11.0047C10.0211 10.2133 10.337 9.45525 10.8967 8.8957C11.4564 8.33615 12.2145 8.02043 13.0059 8.0173C13.7973 8.01417 14.5579 8.32389 15.122 8.879C15.4043 9.15677 15.6287 9.48768 15.7825 9.85264C15.9362 10.2176 16.0162 10.6094 16.0177 11.0054C16.0193 11.4014 15.9425 11.7938 15.7916 12.16C15.6408 12.5262 15.4189 12.8588 15.1388 13.1388C14.8588 13.4188 14.5261 13.6406 14.1599 13.7914C13.7936 13.9421 13.4012 14.0189 13.0052 14.0172C12.6092 14.0156 12.2174 13.9355 11.8525 13.7817Z"
			fill={color}
		/>
	</svg>
)

export default icon
