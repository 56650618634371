import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 12L9.414 18.414C9.78899 18.7891 10.2976 18.9999 10.828 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H10.828C10.2976 5.00011 9.78899 5.2109 9.414 5.586L3 12ZM12.5303 9.46967C12.2374 9.17678 11.7626 9.17678 11.4697 9.46967C11.1768 9.76256 11.1768 10.2374 11.4697 10.5303L12.9393 12L11.4697 13.4697C11.1768 13.7626 11.1768 14.2374 11.4697 14.5303C11.7626 14.8232 12.2374 14.8232 12.5303 14.5303L14 13.0607L15.4697 14.5303C15.7626 14.8232 16.2374 14.8232 16.5303 14.5303C16.8232 14.2374 16.8232 13.7626 16.5303 13.4697L15.0607 12L16.5303 10.5303C16.8232 10.2374 16.8232 9.76256 16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L14 10.9393L12.5303 9.46967Z"
			fill={color}
		/>
	</svg>
)

export default icon
