import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M18.9815 19.75H5.01854C3.46665 19.75 2.49723 18.109 3.27317 16.7968L10.2546 4.98414C11.0306 3.67195 12.9694 3.67195 13.7454 4.98414L20.7268 16.7968C21.5028 18.109 20.5333 19.75 18.9815 19.75Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 9.90607V11.8748M12 15.8124H12.0101"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
