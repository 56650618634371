import { withSlash } from 'utils/index'
import { POD_LANDING_URL_STRIPPED } from 'constants/index'
import * as eventNames from './eventNames'
import { isCustomerSupportOnline } from 'utils/geotime'

const BREAKPOINT = 1024

export default class ModalEvent {
	hasBeenTriggered: boolean
	canBeTriggered: boolean

	constructor() {
		this.hasBeenTriggered = false
		this.canBeTriggered = false
	}

	run = (pagesVisited: any) => {
		if (this.hasBeenTriggered) {
			this.canBeTriggered = false
			return
		}
		this.updateCanTrigger(pagesVisited)
	}

	updateCanTrigger = (pagesVisited): boolean => {
		let done = this.getHasBeenTriggered() || sessionStorage.getItem(eventNames.assistedSaleModalTriggerDone)

		const excludedPages = ['/mercedesamgf1/', '/mercedesamgf1', '/product']
		const currentPage = window.location.pathname
		const viewingExcludedPage = excludedPages.includes(currentPage)
		const isDuringBusinessHours = isCustomerSupportOnline()

		if (!isDuringBusinessHours) {
			return false
		}

		if (done || viewingExcludedPage) {
			return false
		}
		const pages = ['/' + POD_LANDING_URL_STRIPPED, '/podlanding']
		let totalTime = 0

		// const _pages = pages // loop through only pages specified
		const _pages = Object.keys(pagesVisited) // loop through ALL pages
		const hasVisitedMemberAccessories = _pages.some((page) => page.includes('member-accessories'))
		if (hasVisitedMemberAccessories) {
			return false
		}

		_pages.map((path) => {
			// make sure to have trailing slash
			const _path = withSlash(path)
			if (pagesVisited.hasOwnProperty(_path)) {
				totalTime = totalTime + pagesVisited[_path]
			}
		})

		let passed = false

		if (window.innerWidth >= BREAKPOINT) {
			passed = totalTime > 5 // 2 seconds on desktop + exit intent in ouibounce.
		}

		if (passed && !this.hasBeenTriggered) {
			this.canBeTriggered = true
		}

		return passed
	}

	action = () => {
		const emailCapAlreadyViewed = sessionStorage.getItem(eventNames.emailCapModalTriggerDone)
		// allow 5 mins to pass from seeing the email cap before showing another modal
		const modalTimeThrottle = emailCapAlreadyViewed && Date.now() - parseInt(emailCapAlreadyViewed) > 1000 * 60 * 5

		if (!this.hasBeenTriggered && modalTimeThrottle) {
			window.dispatchEvent(new Event(eventNames.assistedSaleModalTrigger))
			this.setTriggered()
		}
	}

	setTriggered = () => {
		this.hasBeenTriggered = true
		this.canBeTriggered = false

		try {
			sessionStorage.setItem(eventNames.assistedSaleModalTriggerDone, JSON.stringify(Date.now()))
			sessionStorage.setItem(eventNames.lastEventTime, JSON.stringify(Date.now()))
		} catch (e) {
			console.log(e)
		}
	}

	getHasBeenTriggered = () => {
		const sessionItem = sessionStorage.getItem(eventNames.assistedSaleModalTriggerDone)
		const alreadyTriggered = (sessionItem && sessionItem.length) || this.hasBeenTriggered
		return alreadyTriggered
	}
}
