const BRAND_PRIMARY: Color = {
	name: 'Primary',
	hex: '#ffffff',
	sass: '$brand-primary',
}

const BRAND_LIGHTEN: Color = {
	name: 'Lighten',
	hex: '#fff9f2',
	sass: '$brand-lighten',
}

const BRAND_SECONDARY: Color = {
	name: 'Secondary',
	hex: '#1862ff',
	sass: '$brand-secondary',
}

const BLACK: Color = {
	name: 'Black',
	hex: '#000000',
	sass: '$neutral-black',
}

const NEUTRAL_1: Color = {
	name: 'Neutral 1',
	hex: '#171920',
	sass: '$neutral-1',
}

const NEUTRAL_2: Color = {
	name: 'Neutral 2',
	hex: '#636363',
	sass: '$neutral-2',
}

const NEUTRAL_3: Color = {
	name: 'Neutral 3',
	hex: '#a0a0a0',
	sass: '$neutral-3',
}

const NEUTRAL_4: Color = {
	name: 'Neutral 4',
	hex: '#d0d0d0',
	sass: '$neutral-4',
}

const NEUTRAL_5: Color = {
	name: 'Neutral 5',
	hex: '#edebe9',
	sass: '$neutral-5',
}

const NEUTRAL_6: Color = {
	name: 'Neutral 6',
	hex: '#eeeeee',
	sass: '$neutral-6',
}

const NEUTRAL_7: Color = {
	name: 'Neutral 7',
	hex: '#f2f0eb',
	sass: '$neutral-7',
}

const NEUTRAL_8: Color = {
	name: 'Neutral 8',
	hex: '#f2f4f6',
	sass: '$neutral-8',
}

const WHITE: Color = {
	name: 'White',
	hex: '#ffffff',
	sass: '$neutral-white',
}

const ACCENT_1: Color = {
	name: 'Accent 1',
	hex: '#d33545',
	sass: '$accent-1',
}

const ACCENT_2: Color = {
	name: 'Accent 2',
	hex: '#ab01ff',
	sass: '$accent-2',
}

const ACCENT_3: Color = {
	name: 'Accent 3',
	hex: '#4c39f2',
	sass: '$accent-3',
}

const ACCENT_4: Color = {
	name: 'Accent 4',
	hex: '#1862ff',
	sass: '$accent-4',
}

const ACCENT_5: Color = {
	name: 'Accent 5',
	hex: '#002173',
	sass: '$accent-5',
}

export const Brand = {
	primary: BRAND_PRIMARY,
	lighten: BRAND_LIGHTEN,
	secondary: BRAND_SECONDARY,
}

export const Neutral = {
	black: BLACK,
	neutral1: NEUTRAL_1,
	neutral2: NEUTRAL_2,
	neutral3: NEUTRAL_3,
	neutral4: NEUTRAL_4,
	neutral5: NEUTRAL_5,
	neutral6: NEUTRAL_6,
	neutral7: NEUTRAL_7,
	neutral8: NEUTRAL_8,
	white: WHITE,
}

export const Accent = {
	accent1: ACCENT_1,
	accent2: ACCENT_2,
	accent3: ACCENT_3,
	accent4: ACCENT_4,
	accent5: ACCENT_5,
}

export const Colors = {
	Brand,
	Neutral,
	Accent,
}

export interface Color {
	name: string
	hex: string
	sass: string
}
