import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M7.61257 15.5872C6.72398 15.5882 5.86627 15.2613 5.20375 14.6691C4.54124 14.0769 4.12051 13.2611 4.02217 12.378C3.92384 11.4949 4.15481 10.6065 4.67083 9.88309C5.18685 9.15969 5.95165 8.65211 6.81869 8.45762C6.56783 7.28763 6.79201 6.06591 7.44193 5.06121C8.09185 4.05652 9.11426 3.35115 10.2842 3.10029C11.4542 2.84942 12.676 3.0736 13.6807 3.72352C14.6853 4.37344 15.3907 5.39585 15.6416 6.56584H15.7318C16.8504 6.56471 17.9295 6.97927 18.7597 7.72903C19.5898 8.47878 20.1117 9.51025 20.2242 10.6232C20.3366 11.7361 20.0315 12.8511 19.368 13.7518C18.7046 14.6524 17.7302 15.2744 16.6339 15.497M9.41684 18.2936L12.1232 21M12.1232 21L14.8297 18.2936M12.1232 21V10.1744"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
