import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.6171 16.3928C12.6171 16.6035 12.435 16.7744 12.2104 16.7744H11.0762C10.8516 16.7744 10.6696 16.6035 10.6696 16.3928V15.6847C10.6696 15.4739 10.8516 15.3031 11.0762 15.3031H12.2104C12.435 15.3031 12.6171 15.4739 12.6171 15.6847V16.3928ZM10.6696 12.8588C10.6696 12.648 10.8516 12.4772 11.0762 12.4772H12.2104C12.435 12.4772 12.6171 12.648 12.6171 12.8588V13.3712C12.6171 13.582 12.435 13.7528 12.2104 13.7528H11.0762C10.8516 13.7528 10.6696 13.582 10.6696 13.3712V12.8588ZM13.7948 13.4131V12.5952C13.7948 12.0113 13.291 11.5386 12.6687 11.5386H10.6153C9.99296 11.5386 9.48901 12.0113 9.48901 12.5952V13.4131C9.48901 13.8432 9.76118 14.2096 10.1516 14.3752V14.5264C9.76118 14.6919 9.48901 15.0606 9.48901 15.4884V16.6585C9.48901 17.2424 9.99296 17.7152 10.6153 17.7152H12.6687C13.291 17.7152 13.7948 17.2424 13.7948 16.6585V15.4884C13.7948 15.0606 13.5228 14.6919 13.1322 14.5264V14.3752C13.5228 14.2096 13.7948 13.841 13.7948 13.4131ZM6.89368 3.23071C6.26814 3.23071 5.67863 3.52339 5.30048 4.0217L3.4068 6.51714C3.24047 6.73633 3.12222 6.98602 3.05751 7.25H10.6416V3.23071H6.89368ZM12.1416 3.23071V7.25L20.738 7.25C20.6768 6.98989 20.5637 6.74313 20.4038 6.52515L18.5864 4.04775C18.2097 3.53414 17.6108 3.23071 16.9738 3.23071H12.1416ZM20.7911 8.75L3 8.75V19.0001C3 20.1047 3.89543 21.0001 5 21.0001H18.7911C19.8957 21.0001 20.7911 20.1047 20.7911 19.0001V8.75Z"
			fill={color}
		/>
	</svg>
)

export default icon
