import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_6387)">
			<path
				d="M2.90151 5.79999V10.2H7.30151"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.16047 14.7C6.65447 18.921 11.2805 21.126 15.4925 19.641C19.7135 18.147 21.9275 13.521 20.4335 9.30001C18.9485 5.08801 14.3225 2.87401 10.1015 4.36801C8.95847 4.77301 7.92347 5.42101 7.06847 6.27601L2.90147 10.2"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 8V12L16 15"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_6387">
				<rect
					width="19.8"
					height="18"
					fill={color}
					transform="translate(2 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
