import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.27317 16.7968C2.49723 18.109 3.46665 19.75 5.01854 19.75H18.9815C20.5333 19.75 21.5028 18.109 20.7268 16.7968L13.7454 4.98414C12.9694 3.67195 11.0306 3.67195 10.2546 4.98414L3.27317 16.7968ZM12.75 9.90607C12.75 9.49185 12.4142 9.15607 12 9.15607C11.5858 9.15607 11.25 9.49185 11.25 9.90607V11.8748C11.25 12.2891 11.5858 12.6248 12 12.6248C12.4142 12.6248 12.75 12.2891 12.75 11.8748V9.90607ZM12 15.0624C11.5858 15.0624 11.25 15.3982 11.25 15.8124C11.25 16.2266 11.5858 16.5624 12 16.5624H12.0101C12.4243 16.5624 12.7601 16.2266 12.7601 15.8124C12.7601 15.3982 12.4243 15.0624 12.0101 15.0624H12Z"
			fill={color}
		/>
	</svg>
)

export default icon
