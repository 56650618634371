import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M2.97212 15.9581L6.37072 10.6175C6.61546 10.2329 7.03973 10 7.4956 10H16.48C16.9638 10 17.4096 10.262 17.6449 10.6847L20.5953 15.9833C20.7057 16.1816 20.7637 16.4049 20.7637 16.6319V19.6667C20.7637 20.403 20.1667 21 19.4303 21H4.09701C3.36063 21 2.76367 20.403 2.76367 19.6667V16.674C2.76367 16.4204 2.83598 16.1721 2.97212 15.9581Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M20.7637 16.1111H2.76367"
			stroke={color}
			strokeWidth="1.5"
			strokeLinejoin="round"
		/>
		<path
			d="M3.76367 8.01008L5.09701 6.72221C5.84075 6.00382 7.01993 6.00382 7.76367 6.72221V6.72221C8.50741 7.44059 9.6866 7.44059 10.4303 6.72221V6.72221C11.1741 6.00382 12.3533 6.00382 13.097 6.72221V6.72221C13.8407 7.44059 15.0199 7.44059 15.7637 6.72221V6.72221C16.5074 6.00382 17.6866 6.00382 18.4303 6.72221L19.7637 8.01008"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.76367 4.57576L5.09701 3.28788C5.84075 2.56949 7.01993 2.56949 7.76367 3.28788V3.28788C8.50741 4.00627 9.6866 4.00627 10.4303 3.28788V3.28788C11.1741 2.56949 12.3533 2.56949 13.097 3.28788V3.28788C13.8407 4.00627 15.0199 4.00627 15.7637 3.28788V3.28788C16.5074 2.56949 17.6866 2.56949 18.4303 3.28788L19.7637 4.57576"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
