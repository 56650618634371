import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import cx from 'classnames'
import { formatLink } from 'utils'
import { trackLinkClick } from 'events/index'
import styles from './LinkWithArrow.module.scss'
import { UrlObject } from 'url'
import { PREFETCH_DISABLE_LIST } from 'config/preload'

type Rel = 'alternate' | 'author' | 'bookmark' | 'external' | 'help' | 'license' | 'next' | 'nofollow' | 'noreferrer' | 'noopener' | 'prev' | 'search' | 'tag'

export interface LinkWithArrowProps {
	href?: string | UrlObject
	children: ReactNode
	target?: string
	className?: string
	rel?: Rel
	onClick?: MouseEventHandler<HTMLAnchorElement>
	id?: string
	hover?: boolean
	color?: string
}

export const LinkWithArrow: FC<LinkWithArrowProps> = (props) => {
	function handleClick(event: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) {
		trackLinkClick(props.href, props.id)
		if (props.onClick) {
			props.onClick(event)
		}
	}

	const arrow = (
		<a
			id={props.id}
			className={cx(styles.animated_arrow, props.hover === false ? styles.no_hover : '', props.className)}
			target={props.target}
			rel={props.target === '_blank' ? 'noopener' : props.rel}
			onClick={handleClick}
			style={
				{
					'--baseColor': props.color || '#0249a0',
				} as CSSProperties
			}
		>
			<span className={cx(styles.the_arrow, styles.pos_left)}>
				<span className={styles.shaft}></span>
			</span>
			<span className={styles.main}>
				<span className={styles.text}>{props.children}</span>
				<span className={cx(styles.the_arrow, styles.pos_right)}>
					<span className={styles.shaft}></span>
				</span>
			</span>
		</a>
	)

	const href = typeof props.href === 'string' ? formatLink(props.href) || '/' : props.href

	if (!href) {
		return arrow
	}

	return (
		<Link
			href={href}
			prefetch={PREFETCH_DISABLE_LIST.includes(href.toString()) ? false : undefined}
			legacyBehavior
		>
			{arrow}
		</Link>
	)
}
