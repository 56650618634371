import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M3.35975 9.12563C3.12941 9.35984 3 9.67751 3 10.0087V10.6332C3 15.8062 7.12456 20.0001 12.2121 20.0001H12.8262C13.152 20.0001 13.4644 19.8685 13.6948 19.6343C13.9251 19.4001 14.0545 19.0824 14.0545 18.7512V16.7036C14.0545 16.5724 14.014 16.4446 13.9386 16.3383C13.8632 16.2319 13.7568 16.1524 13.6344 16.111L10.8751 15.1755C10.7349 15.1281 10.5825 15.1337 10.4461 15.1913C10.3096 15.2489 10.1981 15.3546 10.132 15.489L9.43803 16.8984C7.93428 16.2075 6.72987 14.9829 6.05043 13.4539L7.43655 12.7482C7.56874 12.6811 7.67269 12.5677 7.72932 12.4289C7.78595 12.2901 7.79146 12.1352 7.74484 11.9926L6.82486 9.18696C6.78415 9.06266 6.70602 8.95454 6.60153 8.87788C6.49705 8.80123 6.3715 8.75993 6.24266 8.75983H4.22828C3.90252 8.75983 3.5901 8.89141 3.35975 9.12563Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.1833 7.07171C7.44462 6.57237 7.77462 6.1067 8.16265 5.68528C9.39946 4.34206 11.2259 3.44839 13.2974 3.34708C17.2159 3.15545 20.5291 5.87169 20.6967 9.41353C20.7564 10.6754 20.4084 11.872 19.7591 12.8985L21 15.827L17.2229 15.2492C16.7557 15.5028 16.2657 15.7064 15.7608 15.858"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
