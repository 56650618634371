import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M14.0003 14.1213C14.531 13.5587 14.8291 12.7956 14.8291 11.9999C14.8291 11.2043 14.531 10.4412 14.0003 9.87862C13.4697 9.31601 12.75 8.99994 11.9995 8.99994C11.2491 8.99994 10.5293 9.31601 9.99869 9.87862C9.46804 10.4412 9.16992 11.2043 9.16992 11.9999C9.16992 12.7956 9.46804 13.5587 9.99869 14.1213C10.5293 14.6839 11.2491 14.9999 11.9995 14.9999C12.75 14.9999 13.4697 14.6839 14.0003 14.1213Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 12C4.20163 7.943 7.7773 5 12 5C16.2236 5 19.7984 7.943 21 12C19.7984 16.057 16.2236 19 12 19C7.7773 19 4.20163 16.057 3 12Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
