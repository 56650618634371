import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M16.7863 7.57683L10.6904 5.25L10.6904 9.2056L5.06347 9.2056L5.06347 12.4632L2.25 12.4632L2.25 19.6763L7.17357 19.6763C9.04922 22.2824 12.4879 22.6237 13.9728 22.4685C15.7703 22.4685 19.6466 21.2586 20.772 16.4188C21.8974 11.579 18.5838 8.50756 16.7863 7.57683Z"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.375 4.125L8.25 6M4.5 6L6.375 4.125L4.5 6ZM6.375 4.125L8.25 2.25L6.375 4.125ZM6.375 4.125L4.5 2.25L6.375 4.125Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
