import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getMattressVisuals = (): VisualProps => {
	return {
		type: 'image',
		associatedSelectorKeys: ['eight-mattress'],
		data: {
			src: 'https://eightsleep.imgix.net/mattress_white_2024.png',
			alt: 'Cover parts plus mattress',
		},
	}
}
