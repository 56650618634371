import React, { FC, Fragment, useRef, useState } from 'react'
import { RegionShort } from 'utils/internationalization'
import styles from 'components/WebEv/Shop/Modals/Modals.module.scss'
import { bed, check_circle, chevron_down, cool, dual_temp, heart_report, Icon, IconGenWithLabel, shopping_bag, temperature, vibration, warranty } from 'components/Icon'
import cx from 'classnames'
import { PriceManager, SubscriptionKeys } from 'prices'
import { Colors } from 'components/WebEv/Colors/colors'
import { Button } from 'components/WebEv/Buttons'
import { getAutopilotABStringMap } from 'utils/autopilotABUtil'

export const MembershipModal = (region: RegionShort) => {
	return (
		<div className={styles.modal_inner}>
			<h2 className={styles.title}>Which plan is right for you?</h2>
			<p className={styles.description}>One plan for you and your partner. A plan is required with purchase, but can be canceled any time.</p>

			<MembershipModalContent region={region} />

			<div className={styles.membership_why}>
				<>
					<h2>{getAutopilotABStringMap('why_membership')}</h2>
					<p>Autopilot is the intelligence behind the Pod that improves your sleep over time, leading to better health, more energy, and increased longevity.</p>
					<p>
						Autopilot learns what temperature is best for you during each phase of sleep and automatically changes it. This results in you staying asleep longer and sleeping deeper throughout the
						night.
					</p>
					<p>A plan is required for the first 12 months, and covers two separate users per Pod.</p>
				</>
			</div>
		</div>
	)
}

export const MembershipModalDisconnected = (props: { region: RegionShort }) => {
	return (
		<div className={styles.modal_inner}>
			<h2 className={styles.title}>Which plan is right for you?</h2>
			<p className={styles.description}>One plan for you and your partner. A plan is required with purchase, but can be canceled any time.</p>

			<MembershipModalContent region={props.region} />

			<div className={styles.membership_why}>
				<>
					<h2>{getAutopilotABStringMap('why_membership')}</h2>
					<p>Autopilot is the intelligence behind the Pod that improves your sleep over time, leading to better health, more energy, and increased longevity.</p>
					<p>
						Autopilot learns what temperature is best for you during each phase of sleep and automatically changes it. This results in you staying asleep longer and sleeping deeper throughout the
						night.
					</p>
					<p>A plan is required for the first 12 months, and covers two separate users per Pod.</p>
				</>
			</div>
		</div>
	)
}

export const MembershipModalContent: FC<{ region: RegionShort; hidePricing?: boolean }> = (props) => {
	const getMembershipSubtitleText = (susbcriptionId: SubscriptionKeys) => {
		return `${PriceManager.getRegionSubscriptionPrice(susbcriptionId, props.region)}/mo`
	}

	return (
		<>
			<div className={cx(styles.membership_grid_row)}>
				<div />
				<div className={styles.membership_title}>
					<h3>Standard</h3>
					{!props.hidePricing && (
						<>
							<p className={styles.membership_monthly}>{getMembershipSubtitleText('8-pro-membership')}</p>
							<p className={styles.membership_yearly}>({PriceManager.getRegionSubscriptionPriceYearly('8-pro-membership', props.region)} billed annually)</p>
						</>
					)}
				</div>
				<div className={styles.membership_title}>
					<strong>Most popular</strong>
					<h3>Enhanced</h3>
					{!props.hidePricing && (
						<>
							<p className={styles.membership_monthly}>{getMembershipSubtitleText('8-plus-membership')}</p>
							<p className={styles.membership_yearly}>({PriceManager.getRegionSubscriptionPriceYearly('8-plus-membership', props.region)} billed annually)</p>
						</>
					)}
				</div>
			</div>
			<ul className={styles.membership_features_list}>
				{getPlanFeatures(props.region).map((feature, index) => (
					<Fragment key={index}>
						<PlanFeatureCard {...feature} />
					</Fragment>
				))}
			</ul>
		</>
	)
}

interface PlanFeature {
	icon: IconGenWithLabel
	title: string
	description: string
	checks: boolean[]
	specialMessages?: string[]
}

const getPlanFeatures = (region: RegionShort) => {
	const planFeatures: PlanFeature[] = []

	if (region === '') {
		planFeatures.push(automaticAdjustments)
	} else {
		planFeatures.push(automaticAdjustmentsMetric)
	}

	planFeatures.push(scheduledTemperature, vibrationAlarm, sleepInsights, discountsUpgrades, discountAccessories, twoMemberships, warrantyFeature)

	return planFeatures
}

const automaticAdjustments: PlanFeature = {
	icon: temperature,
	title: 'Automatic temperature adjustments',
	description: 'Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 55°F - 110°F.',
	checks: [true, true],
}

const automaticAdjustmentsMetric: PlanFeature = {
	icon: temperature,
	title: 'Automatic temperature adjustments',
	description: 'Learns your ideal thermal environment on each bed side and automatically adjusts to optimize your sleep, with temperatures between 12°C - 43°C.',
	checks: [true, true],
}

const scheduledTemperature: PlanFeature = {
	icon: cool,
	title: 'Scheduled temperature',
	description: 'scheduled_temperature',
	checks: [true, true],
}

const vibrationAlarm: PlanFeature = {
	icon: vibration,
	title: 'Vibration and thermal alarm',
	description: 'Silently wakes you up with chest-level vibration and gradual thermal change.',
	checks: [true, true],
}

const sleepInsights: PlanFeature = {
	icon: heart_report,
	title: 'Sleep and health insights',
	description: 'Sleep and health reports for each side of the bed including sleep stages, sleep time, heart rate, and HRV.',
	checks: [true, true],
}

const discountsUpgrades: PlanFeature = {
	icon: bed,
	title: 'Discounts on upgrades',
	description: 'Members receive $500 off Pod upgrades in the member shop, so you can always be sleeping on the latest Eight Sleep technology.',
	checks: [true, true],
}

const discountAccessories: PlanFeature = {
	icon: shopping_bag,
	title: 'Discounts on accessories',
	description: 'Members can receive up to 20% off accessories in the member shop, including Hyperice products, sleep supplements, and more.',
	checks: [true, true],
}

const twoMemberships: PlanFeature = {
	icon: dual_temp,
	title: 'one_membership',
	description: 'share_membership',
	checks: [true, true],
}

const warrantyFeature: PlanFeature = {
	icon: warranty,
	title: '5-year extended warranty',
	description: 'For up to 5 years following your initial purchase date, we will replace your Pod as long as you purchased through this site and have an active plan (terms apply).',
	checks: [false, true],
	specialMessages: ['(Includes only 2-year warranty)', null],
}

const PlanFeatureCard: FC<PlanFeature> = (props) => {
	const [open, setOpen] = useState(false)
	const descriptionRef = useRef<HTMLParagraphElement>(null)

	return (
		<li className={cx(styles.plan_feature_card, { [styles.feature_open]: open })}>
			<div className={styles.plan_icon_and_title}>
				<div className={styles.plan_feature_icon_container}>
					<Icon
						icon={props.icon}
						color={'black'}
					/>
				</div>
				<div className={styles.plan_feature_text}>
					<Button.Empty
						id={`chevron-${props.icon.label}`}
						className={styles.feature_toggle}
						onClick={() => setOpen((prev) => !prev)}
					>
						<p className={styles.plan_feature_title}>{getAutopilotABStringMap(props.title)}</p>
						<div className={styles.chevron}>
							<Icon
								icon={chevron_down}
								color={Colors.Neutral.black.hex}
							/>
						</div>
					</Button.Empty>
					<div
						style={{
							maxHeight: open ? `${descriptionRef?.current.clientHeight}px` : '0px',
							overflow: 'hidden',
							transition: 'all 0.25s ease-in-out',
							marginTop: open ? '0.5rem' : '0',
						}}
					>
						<p
							className={styles.plan_feature_desc}
							ref={descriptionRef}
						>
							{getAutopilotABStringMap(props.description)}
						</p>
					</div>
				</div>
			</div>

			{props.checks.map((check, index) => {
				if (check) {
					return (
						<div
							key={index}
							className={styles.plan_feature_check}
						>
							<Icon
								icon={check_circle}
								color={'black'}
							/>
						</div>
					)
				}

				if (props.specialMessages && props.specialMessages[index]) {
					return (
						<div
							key={index}
							className={styles.plan_feature_special_message}
						>
							<p>{props.specialMessages[index]}</p>
						</div>
					)
				}

				return <div />
			})}
		</li>
	)
}
