import { useEffect, useLayoutEffect, useState } from 'react'
import debounce from 'lodash/debounce'

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const useIsMobile = (width = 768): boolean => {
	const [isMobile, setIsMobile] = useState(false)

	useIsomorphicLayoutEffect(() => {
		const updateSize = (): void => {
			setIsMobile(window.innerWidth < width)
		}
		window.addEventListener('resize', debounce(updateSize, 250))
		updateSize()
		return (): void => window.removeEventListener('resize', updateSize)
	}, [width])

	return isMobile
}

export default useIsMobile
