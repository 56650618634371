/* eslint-disable @next/next/no-img-element */
import React, { cloneElement, FC, ReactElement, useRef, useState } from 'react'
import styles from './CoverModelExplanationModal.module.scss'
import cx from 'classnames'
import { amClickedButton, amCloseMembershipModal, amOpenMembershipModal } from 'events/amplitude'
import { HP } from 'components/basic/HP'
import { useClickOutsideModal } from 'hooks/useClickOutsideModal'
import FocusTrapWrapper from 'components/FocusTrapWrapper'
import { RegionShort } from 'utils/internationalization'
import { getUnitForRegion } from 'constants/units'
import { IconMap } from 'components/_const_assets/icons'
import { Icon, plus_circle } from 'components/Icon'
import { Button } from 'components/WebEv/Buttons'

interface ModalProps {
	rootElementId: string
	region: RegionShort
	triggerElement: ReactElement
	onOpen?: () => void
	onClose?: () => void
	selectedIndex: number
	prices: string[]
}

export interface CoverModelExplanationModalProps {
	region: RegionShort
	selectedIndex: number
	prices: string[]
}

interface ModalTriggerProps {
	onClick: () => void
	id: string
	topText: string
	bottomText: string
}

export const ModalTriggerNew: FC<ModalTriggerProps> = (props) => (
	<Button.Empty
		className={styles.modal_trigger_new}
		id={props.id}
		onClick={props.onClick}
	>
		<div>
			<p>{props.topText}</p>
			{/*<p>{props.bottomText}</p>*/}
			<Icon
				icon={plus_circle}
				color={'black'}
			/>
		</div>
	</Button.Empty>
)

export const CoverModelExplanationModal: FC<CoverModelExplanationModalProps> = (props) => {
	const id = 'cover-model-explanation-modal'

	return (
		<Modal
			region={props.region}
			rootElementId="__next"
			triggerElement={
				<ModalTriggerNew
					onClick={() => amClickedButton('Compare models link', id)}
					topText={'Need help choosing a model?'}
					bottomText={'Explore the differences in features'}
					id={id}
				/>
			}
			onOpen={amOpenMembershipModal}
			onClose={amCloseMembershipModal}
			selectedIndex={props.selectedIndex}
			prices={props.prices}
		/>
	)
}

const DynamicTable = (props: ModalProps) => {
	return (
		<div className={styles.table_container}>
			<div>
				<span className={styles.subline}></span>
				<span>
					<strong>Pod 2 Cover</strong> fits {getUnitForRegion('10"-11"', props.region)} mattress.
				</span>
				<span>{props.prices[0]}</span>
			</div>
			<div>
				<span className={styles.subline}></span>
				<span>
					<strong>Pod 3 Cover</strong> fits {getUnitForRegion('10"-11"', props.region)} mattress. Features our best tracking & comfort.
				</span>
				<span>{props.prices[1]}</span>
			</div>
			<div>
				<span className={styles.subline}>Most popular</span>
				<span>
					<strong>Pod 3 Cover with PerfectFit</strong> fits all beds. Features our best tracking & comfort.
				</span>
				<span>{props.prices[2]}</span>
			</div>
		</div>
	)
}

const Modal: FC<ModalProps> = (props) => {
	const [modalVisible, setModalVisible] = useState(false)
	const pageWrapper = useRef(null)

	function showModal() {
		setModalVisible(true)
		if (props.onOpen) {
			props.onOpen()
		}
	}
	function hideModal() {
		setModalVisible(false)
		props.onClose?.()
	}

	useClickOutsideModal(pageWrapper, hideModal, false)

	return (
		<div className={cx('modal')}>
			{cloneElement(props.triggerElement, { onClick: showModal })}
			{modalVisible && (
				<>
					<FocusTrapWrapper active={true}>
						<div className={'modal_container'}>
							<div
								className={styles.modal_content_container}
								ref={pageWrapper}
							>
								<button
									role="button"
									aria-label="Close modal button"
									className={styles.close_button}
									onClick={hideModal}
								>
									<img
										src={IconMap.close}
										alt="close icon"
									/>
								</button>
								<div className={cx(styles.content)}>
									<HP
										title={'Which model is right for you?'}
										type="h2"
										titleClass={cx('h5', styles.modal_h)}
										paragraphs={[]}
									/>
									<DynamicTable {...props} />
								</div>
							</div>
						</div>
					</FocusTrapWrapper>
				</>
			)}
		</div>
	)
}
