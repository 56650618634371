import React, { FC, ReactNode } from 'react'
import { ResponsiveVideo, ResponsiveVideoProps } from 'components/basic/Video'
import cx from 'classnames'
import styles from './Video.module.scss'

export interface Props extends ResponsiveVideoProps {
	children?: ReactNode
	className?: string
	showControls?: boolean
	autoplay?: boolean
	caption: string
	thumb?: string
	aspectRatio?: any
	preload?: string
}

export const VideoBackground: FC<Props> = (props) => {
	return (
		<div className={cx(styles.video_background, props.className)}>
			<ResponsiveVideo
				videos={props.videos}
				caption={props.caption}
				showControls={props.showControls}
				autoplay={props.autoplay}
				preload={props.preload}
			/>
			<div className="absolute-cover">{props.children}</div>
		</div>
	)
}
