export const emailCapModalTriggerDone = 'emailCapModalTriggered'
export const emailCapShortcutButtonTriggerDone = 'emailCapShortcutButtonTriggerDone'
export const emailCapModalTrigger = 'MODAL_EMAILCAP_TRIGGER'

export const secondaryEmailCapModalTriggerDone = 'secondaryEmailCapModalTriggered'
export const secondaryEmailCapModalTrigger = 'SECONDARY_MODAL_EMAILCAP_TRIGGER'

export const tertiaryEmailCapModalTriggerDone = 'tertiaryEmailCapModalTriggered'
export const tertiaryEmailCapModalTrigger = 'TERTIARY_MODAL_EMAILCAP_TRIGGER'

export const assistedSaleModalTriggerDone = 'assistedSaleModalEventTriggered'
export const assistedSaleModalTrigger = 'MODAL_ASSISTEDSALE_TRIGGER'

export const referralWelcomeTriggerDone = 'referralWelcomeTriggered'
export const referralWelcomeTrigger = 'REFERRAL_WELCOME_TRIGGER'

export const lastEventTime = 'last_modal_time'
