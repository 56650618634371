import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './Carousel.module.scss'
import { CarouselHandle, CarouselKeyProps, CarouselProps } from './types'
import { Icon } from 'components/Phantom/Icon'
import { Button } from 'components/Phantom/Button'
import { mod } from 'components/_utils/mathUtils'
import { CarouselMapping } from 'components/Phantom/_shop/Carousel/CarouselMapping'
import cx from 'classnames'
import { useSwipe } from 'components/_hooks/useSwipe'

const resolveProps = (props: CarouselProps | CarouselKeyProps): CarouselProps => {
	if ('carouselKey' in props) {
		const carouselData = CarouselMapping[props.carouselKey]
		if (!carouselData) {
			console.error(`Carousel key "${props.carouselKey}" not found in CarouselMapping`)
			return { items: [] }
		}
		return CarouselMapping[props.carouselKey]
	}

	return props
}

export const Carousel = forwardRef<CarouselHandle, CarouselProps | CarouselKeyProps>((props, ref) => {
	const { items, startingIndex } = resolveProps(props)

	const sliderRef = useRef<HTMLUListElement>(null)
	const [currentSlide, setCurrentSlide] = useState(startingIndex ?? 0)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			if (direction === 'left') {
				setCurrentSlide((prev) => Math.min(items.length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentSlide((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500
	)

	const goToSlide = useCallback((slide: number) => {
		setCurrentSlide(slide)
	}, [])

	useEffect(() => {
		if (startingIndex) goToSlide(startingIndex) // TODO maybe we should add an argument to useSlider to set the starting index
	}, [])

	useImperativeHandle(
		ref,
		() => ({
			goToSlide,
		}),
		[goToSlide]
	)

	if (!items) {
		console.error('Carousel component requires an items prop')
		return null
	}

	if (items.length === 0) {
		console.error('Carousel component requires at least one item')
		return null
	}

	return (
		<div className={styles.container}>
			<div className={styles.slider_wrapper}>
				<ul
					className={styles.slider}
					ref={sliderRef}
				>
					{items.map((item, i) => (
						<li
							key={i}
							style={{ opacity: i === currentSlide ? 1 : 0 }}
						>
							{item}
						</li>
					))}
				</ul>
			</div>

			<CarouselDots
				currentSlide={currentSlide}
				numSlides={items.length}
				setCurrentSlide={goToSlide}
			/>

			<CarouselControls
				showPrevious={currentSlide > 0}
				showNext={currentSlide < items.length - 1}
				previousSlide={() => goToSlide(mod(currentSlide - 1, items.length))}
				nextSlide={() => goToSlide(mod(currentSlide + 1, items.length))}
			/>
		</div>
	)
})

const CarouselControls = ({ previousSlide, nextSlide, showPrevious, showNext }) => (
	<div className={styles.controls}>
		<Button.Empty
			id={'carousel-prev'}
			className={cx(styles.control_prev, { [styles.hide]: !showPrevious })}
			onClick={previousSlide}
		>
			<Icon
				name={'ChevronLeftDark'}
				color={'black'}
				size={16}
			/>
		</Button.Empty>

		<Button.Empty
			id={'carousel-next'}
			className={cx(styles.control_next, { [styles.hide]: !showNext })}
			onClick={nextSlide}
		>
			<Icon
				name={'ChevronRightDark'}
				color={'black'}
				size={16}
			/>
		</Button.Empty>
	</div>
)

const CarouselDots = ({ currentSlide, numSlides, setCurrentSlide }) => {
	const dots = Array.from({ length: numSlides }, (_, i) => i)
	return (
		<div className={styles.dots}>
			{dots.map((dot, i) => (
				<Button.Empty
					id={`carousel-dot-${dot}`}
					key={i}
					className={cx(styles.dot, { [styles.dot_selected]: currentSlide === dot })}
					onClick={() => setCurrentSlide(dot)}
				>
					{dot + 1}
				</Button.Empty>
			))}
		</div>
	)
}
