import modalStyles from '../Modals.module.scss'
import styles from './MattressModal.module.scss'
import React from 'react'
import { Img } from 'components/basic/Img'
import { bed, firm, Icon, pressure, warm } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'
import cx from 'classnames'
import { RegionShort } from 'utils/internationalization'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

const items = [
	{
		icon: firm,
		description: 'Medium firmness with spinal support',
	},
	{
		icon: bed,
		description: '11" height',
	},
	{
		icon: pressure,
		description: 'Pressure relieving materials',
	},
	{
		icon: warm,
		description: 'Excellent airflow for cooler sleep',
	},
]

export const MattressModalConnected = observer(() => {
	const { settingsStore } = useRootStore()
	const region = settingsStore.currentRegion
	return MattressModal(region)
})

export const MattressModal = (region: RegionShort) => {
	return (
		<div className={cx(modalStyles.full_bleed_inner)}>
			<div className={cx(styles.mm)}>
				<h2 className={cx(styles.mattresstitle)}>A breathable, supportive mattress designed for the Pod</h2>
				<p className={styles.description}>We’ve designed a breathable, supportive mattress to pair perfectly with the Pod Cover’s technology, so that you can create the ultimate sleep setup.</p>
				<ul className={styles.features}>
					{items.map((item) => (
						<li key={item.icon.label}>
							<Icon
								icon={item.icon}
								color={Colors.Neutral.black.hex}
							/>
							<p>{item.description}</p>
						</li>
					))}
				</ul>
				<Img
					src={region === 'ca' ? 'https://eightsleep.imgix.net/mattress_layersca.png?v=1694791235' : 'https://eightsleep.imgix.net/modal_desktop_f46e6587-e882-44c0-af48-b97a2772137b.png?v=1694724253'}
					alt={
						'A cross section of the Eight Sleep mattress showing off the Airflow Top Layer, the Max Chill Layer, the Tranquility Transition Foam layer, the FlexSpring Technology Core, and the Durable Premium Base'
					}
					className={styles.mattress_layers}
				/>
			</div>
		</div>
	)
}
