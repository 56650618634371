import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M17.3021 11.3H4.8406V6.75C4.84196 6.2863 5.02475 5.84198 5.34904 5.51409C5.67332 5.1862 6.11276 5.00138 6.57136 5H16.956C17.4146 5.00138 17.854 5.1862 18.1783 5.51409C18.5026 5.84198 18.6854 6.2863 18.6867 6.75V11.3H17.3021ZM2.76367 19V14.1C2.76584 13.3581 3.05829 12.6472 3.57716 12.1225C4.09602 11.5979 4.79912 11.3022 5.5329 11.3H17.9944C18.7282 11.3022 19.4313 11.5979 19.9502 12.1225C20.469 12.6472 20.7615 13.3581 20.7637 14.1V19"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.7637 10.6001C11.7627 10.2291 11.6165 9.8736 11.357 9.61127C11.0976 9.34895 10.746 9.20111 10.3791 9.20007H6.91757C6.55067 9.20111 6.19908 9.34895 5.93964 9.61127C5.6802 9.8736 5.53399 10.2291 5.53296 10.6001V11.3001H11.1802M11.7637 10.6001V11.3001H11.1802M11.7637 10.6001C11.7648 10.2291 11.911 9.8736 12.1704 9.61127C12.4299 9.34895 12.7814 9.20111 13.1483 9.20007H14.8791H16.6099C16.9768 9.20111 17.3284 9.34895 17.5878 9.61127C17.8473 9.8736 17.9935 10.2291 17.9945 10.6001V11.3001H11.1802"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
