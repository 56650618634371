import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getAccessoriesVisuals = (): VisualProps => {
	return {
		type: 'new_carousel',
		associatedSelectorKeys: ['accessories'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/essentials-sheets-new_pillow_update.png',
					alt: 'The Pod Cover with the Sleep Essentials Bundle',
				},
				associatedSelections: [
					{
						key: 'essentials-bundle',
						value: 'white',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eightsleep.imgix.net/mattress_white_2024.png',
					alt: 'Cover parts plus mattress',
				},
				associatedSelections: [
					{
						key: 'eight-mattress',
						value: 'yes_mattress',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+Extended.png',
					alt: 'Leg Ket display',
				},
				associatedSelections: [
					{
						key: 'leg-kit',
						value: 'adjustable',
					},
				],
			},
		],
	}
}
