import { ExampleModal } from 'components/Phantom/_shop/Modals/ExampleModal'
import { MattressModalConnected, SizesModal } from 'components/WebEv/Shop/Modals'
import { MembershipModalConnected } from 'components/Phantom/_shop/Modals/MembershipModal'
import { ModelModalConnected } from 'components/Phantom/_shop/Modals/ModelModal'
import { LegKitModalConnected } from 'components/Phantom/_shop/Modals/LegKitModal'
import { FinancingTabModalConnected } from 'components/FinancingTabModal'

export type ModalKey = 'example' | '' | 'model-modal' | 'size-modal' | 'legkit-modal' | 'membership-modal' | 'mattress-modal' | 'financing-modal'

export const getConnectedModal = (key: ModalKey) => {
	switch (key) {
		case 'example':
			return <ExampleModal />
		case 'legkit-modal':
			return <LegKitModalConnected />
		case 'mattress-modal':
			return <MattressModalConnected />
		case 'membership-modal':
			return <MembershipModalConnected />
		case 'model-modal':
			return <ModelModalConnected />
		case 'size-modal':
			return <SizesModal />
		case 'financing-modal':
			return <FinancingTabModalConnected />
		default:
			return null
	}
}
