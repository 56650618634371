import { ICheckout } from 'pages/api/estimate_invoice'
import { getProductByVariantId, getProductVariantByVariantId } from 'products/utils'
import { StripeCheckout } from './types'
import { Currency } from 'prices/types'

export const INVOICE_EXPAND_FIELDS: string[] = ['payment_intent', 'lines.data.discounts', 'lines.data.price', 'lines.data.price.product', 'customer']

export const convertShopifyToStripe = (input: ICheckout): StripeCheckout => {
	const lines = input.lines.map((line) => {
		const attributes: Record<string, any> = line.attributes.reduce((acc, it) => ({ ...acc, [it.key]: it.value }), {})
		const product = getProductByVariantId(line.variantId)
		if (!product) return null

		const variant = getProductVariantByVariantId(product, line.variantId)
		if (!variant) return null

		const price = variant.id as string
		return {
			price,
			quantity: line.quantity,
			metadata: attributes,
		}
	})

	return {
		// email: input.email,
		currency: input.currency?.toLowerCase() as Currency,
		coupons: input.coupons.map((it) => it.trim().toUpperCase()).filter((it) => !!it && it.length > 1),
		lines,
	}
}
