import { CardProps } from './types'

export const getDefaultCards = (): CardProps[] => {
	return [
		{
			img: {
				src: 'https://eightsleep.imgix.net/assets/closer_look_2_1_1216.png',
				alt: '',
			},
		},
		{
			img: {
				src: 'https://eightsleep.imgix.net/assets/closer_look_2_2_1218.png',
				alt: '',
			},
		},
		{
			img: {
				src: 'https://eightsleep.imgix.net/assets/closer_look_2_3_1219.png',
				alt: '',
			},
		},
		{
			img: {
				src: 'https://eightsleep.imgix.net/assets/closer_look_2_4_1221.png',
				alt: '',
			},
		},
	]
}
