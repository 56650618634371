import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<g clipPath="url(#clip0_3538_4863)">
			<path
				d="M11.1374 5.53888H5.44121C4.54609 5.53888 3.81372 6.27125 3.81372 7.16637V18.5588C3.81372 19.4539 4.54609 20.1863 5.44121 20.1863H16.8336C17.7287 20.1863 18.4611 19.4539 18.4611 18.5588V12.8626"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.2405 4.31826C17.9159 3.64285 19.0063 3.64285 19.6817 4.31826C20.3571 4.99367 20.3571 6.08408 19.6817 6.75949L11.9511 14.4901L8.69617 15.3038L9.50991 12.0488L17.2405 4.31826Z"
				fill={color}
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3538_4863">
				<rect
					width="18"
					height="18"
					fill={color}
					transform="translate(3 3)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default icon
