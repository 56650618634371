/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */

declare global {
	interface Window {
		klaviyo?: any
		_klOnsite?: any
	}
}

export const loadKlaviyoObject = () => {
	if (!window.klaviyo) {
		window._klOnsite = window._klOnsite || []
		try {
			window.klaviyo = new Proxy(
				{},
				{
					get: function (n, i) {
						return 'push' === i
							? function () {
									let n
									;(n = window._klOnsite).push.apply(n, arguments)
							  }
							: function () {
									for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++) o[w] = arguments[w]
									const t = 'function' == typeof o[o.length - 1] ? o.pop() : void 0,
										e = new Promise(function (n) {
											window._klOnsite.push(
												[i].concat(o, [
													function (i) {
														t && t(i), n(i)
													},
												])
											)
										})
									return e
							  }
					},
				}
			)
		} catch (n) {
			;(window.klaviyo = window.klaviyo || []),
				(window.klaviyo.push = function () {
					let n
					;(n = window._klOnsite).push.apply(n, arguments)
				})
		}
	}
}

export const KLAVIYO_EVENTS_ENDPOINT = 'https://a.klaviyo.com/api/events/'
export const KLAVIYO_LIST_SUBSCRIBE_ENDPOINT = 'https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/'
export const KLAVIYO_PROFILE_IMPORT_ENDPOINT = 'https://a.klaviyo.com/api/profile-bulk-import-jobs/'

export const KLAVIYO_API_HEADERS = {
	'content-type': 'application/json',
	Authorization: `Klaviyo-API-Key ${process.env.KLAVIYO_PRIVATE_API_KEY}`,
	revision: '2024-06-15',
}
