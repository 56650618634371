import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.31803 5.3726C3.90017 5.80776 3.56869 6.32437 3.34255 6.89294C3.1164 7.46151 3 8.07089 3 8.68631C3 9.30172 3.1164 9.91111 3.34255 10.4797C3.52913 10.9488 3.7874 11.3825 4.10673 11.7643H9.46359C9.66718 11.7643 9.86203 11.8471 10.0034 11.9936L10.5898 12.6015L11.5821 9.75325C11.6808 9.46983 11.939 9.27256 12.2384 9.2518C12.5378 9.23103 12.8208 9.39076 12.9577 9.65784L14.0416 11.7719C14.0767 11.7669 14.1124 11.7643 14.1484 11.7643H19.8933C20.6046 10.9139 21 9.82132 21 8.68631C21 7.44343 20.5259 6.25145 19.682 5.3726C18.8381 4.49375 17.6935 4.00001 16.5 4.00001C15.3065 4.00001 14.1619 4.49375 13.318 5.3726L12 6.74515L10.682 5.3726C10.2641 4.93743 9.76807 4.59224 9.2221 4.35673C8.67614 4.12122 8.09097 4 7.50002 4C6.90907 4 6.32391 4.12122 5.77794 4.35673C5.23197 4.59224 4.7359 4.93743 4.31803 5.3726ZM18.468 13.2643H14.5062L14.383 13.4166C14.2234 13.6138 13.9749 13.7169 13.7226 13.6907C13.4703 13.6646 13.2483 13.5126 13.1326 13.2869L12.4222 11.9012L11.605 14.2467C11.5193 14.4928 11.3121 14.6767 11.0576 14.7326C10.8031 14.7884 10.5379 14.7082 10.357 14.5207L9.14499 13.2643H5.53204L12 20L18.468 13.2643Z"
			fill={color}
		/>
	</svg>
)

export default icon
