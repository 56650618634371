import { IconComponentType } from '../../types'

export const ComfortCustom: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		viewBox="0 0 19 22"
		width={size}
		height={size}
		fill={'none'}
	>
		<circle
			cx="9.2479"
			cy="5.59336"
			r="3.91196"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.21875 12.1012C2.17965 10.6599 4.88455 10.3927 7.29863 11.8954C8.42901 12.5991 10.0536 12.6005 11.1341 11.8224C12.8254 10.6044 15.2326 9.78267 17.2784 12.1012"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.25781 17.9427L5.50781 20.4219M5.50781 20.4219L7.75781 17.9427M5.50781 20.4219L5.50781 14.0469"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.1328 17.9427L13.3828 20.4219M13.3828 20.4219L15.6328 17.9427M13.3828 20.4219L13.3828 14.0469"
			stroke={color}
			strokeWidth="1.535"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
