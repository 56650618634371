export function trackEmailSubscribe(email: string) {
	try {
		window['uetq'] = window['uetq'] || []
		window['uetq'].push('set', {
			pid: {
				em: email,
				// 'ph': phoneNumberVariable
			},
		})
	} catch (e) {
		console.log('Could not sign up for bing')
	}
}
