import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M8.69962 15.6437C9.57483 14.681 10.7619 14.1402 11.9996 14.1402C13.2373 14.1402 14.4243 14.681 15.2995 15.6437M3 9.09997C7.96988 3.63334 16.0293 3.63334 21 9.09997"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.9996 19H12.0081M6.00049 12.4003C9.31317 8.75557 14.6861 8.75557 17.9996 12.4003"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
