import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.13875 3.08372C3.84723 2.78947 3.37236 2.78725 3.07811 3.07878C2.78385 3.3703 2.78164 3.84517 3.07316 4.13942L7.59619 8.70484H3.60596C3.19174 8.70484 2.85596 9.04062 2.85596 9.45484V10.9157C2.85596 16.0027 6.94333 20.1384 12 20.1384C17.0568 20.1384 21.1441 16.0027 21.1441 10.9157V9.45484C21.1441 9.04062 20.8083 8.70484 20.3941 8.70484H9.69967C9.68091 8.68056 9.66047 8.65717 9.63833 8.63482L4.13875 3.08372ZM4.35596 10.9157V10.2048H19.6441V10.9157C19.6441 15.1874 16.2152 18.6384 12 18.6384C7.78491 18.6384 4.35596 15.1874 4.35596 10.9157Z"
			fill="black"
		/>
	</svg>
)

export default icon
