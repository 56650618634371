import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15 7C15 7.79565 14.6839 8.55871 14.1213 9.12132C13.5587 9.68393 12.7956 10 12 10C11.2044 10 10.4413 9.68393 9.87868 9.12132C9.31607 8.55871 9 7.79565 9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7ZM20.4142 11.4142C20.7893 11.0391 21 10.5304 21 10C21 9.46957 20.7893 8.96086 20.4142 8.58579C20.0391 8.21071 19.5304 8 19 8C18.4696 8 17.9609 8.21071 17.5858 8.58579C17.2107 8.96086 17 9.46957 17 10C17 10.5304 17.2107 11.0391 17.5858 11.4142C17.9609 11.7893 18.4696 12 19 12C19.5304 12 20.0391 11.7893 20.4142 11.4142ZM7 10C7 10.5304 6.78929 11.0391 6.41421 11.4142C6.03914 11.7893 5.53043 12 5 12C4.46957 12 3.96086 11.7893 3.58579 11.4142C3.21071 11.0391 3 10.5304 3 10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8C5.53043 8 6.03914 8.21071 6.41421 8.58579C6.78929 8.96086 7 9.46957 7 10ZM9.47629 14.2732C10.2213 13.7688 11.1003 13.4993 12 13.4993C12.8997 13.4993 13.7787 13.7688 14.5237 14.2732C15.2687 14.7775 15.8456 15.4937 16.1799 16.329C16.3865 16.8445 16.5 17.4085 16.5 18V19.5H7.5V18C7.5 17.4085 7.61363 16.8443 7.82021 16.3288C8.15445 15.4935 8.73128 14.7775 9.47629 14.2732ZM21.5 19.5H17.5V18C17.5 17.389 17.4004 16.7999 17.2156 16.2492C17.5139 15.9452 17.8861 15.7214 18.2977 15.6009C18.7964 15.4549 19.3281 15.468 19.819 15.6382C20.3098 15.8084 20.7355 16.1273 21.0368 16.5506C21.338 16.9738 21.5 17.4805 21.5 18V19.5ZM5.70228 15.6009C6.1139 15.7214 6.4861 15.9452 6.78439 16.2492C6.59958 16.7999 6.5 17.389 6.5 18V19.5H2.5V18C2.50005 17.4805 2.66195 16.9738 2.96322 16.5506C3.26449 16.1273 3.69015 15.8084 4.18104 15.6382C4.67193 15.468 5.20365 15.4549 5.70228 15.6009Z"
			fill={color}
		/>
	</svg>
)

export default icon
