import { FC } from 'react'

interface KlarnaLogoProps {
	color?: string
	className?: string
}

export const KlarnaLogo: FC<KlarnaLogoProps> = (props) => {
	const { color = 'black', className } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 42 9"
			className={className}
		>
			<path
				fill={color}
				d="M7.9 0H5.916a5.07 5.07 0 01-2.051 4.094l-.786.588L6.12 8.835h2.506l-2.802-3.82A7.023 7.023 0 007.901 0zM.803 8.834h2.033V0H.803v8.834zM9.217 8.831h1.914V.001H9.217v8.83zM27.923 2.56c-.73 0-1.421.227-1.883.845v-.681H24.22v6.107h1.843v-3.21c0-.93.622-1.385 1.372-1.385.805 0 1.266.48 1.266 1.37v3.225h1.827V4.944c0-1.42-1.13-2.387-2.604-2.387v.003zM15.253 7.35a1.616 1.616 0 01-1.656-1.572 1.659 1.659 0 013.314 0 1.616 1.616 0 01-1.658 1.571zm1.663-4.626v.39A3.222 3.222 0 1015.104 9a3.192 3.192 0 001.812-.55v.39h1.83V2.723h-1.83zM21.573 3.52v-.796h-1.872v6.107h1.876v-2.85c0-.964 1.042-1.481 1.767-1.481h.02V2.724c-.741 0-1.424.318-1.79.796zM34.544 7.35a1.616 1.616 0 01-1.656-1.572 1.66 1.66 0 013.314 0 1.615 1.615 0 01-1.658 1.571zm1.665-4.626v.39a3.223 3.223 0 100 5.327v.39h1.828V2.724H36.21zM39.979 6.665a1.15 1.15 0 10-.003 2.298 1.15 1.15 0 00.003-2.298z"
			/>
		</svg>
	)
}

export const KlarnaPinkBadge: FC<KlarnaLogoProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 53 38"
			fill="none"
			className={props.className}
		>
			<rect
				width="53"
				height="37.4118"
				rx="6.23529"
				fill="#FEB4C7"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.21323 23.974H6.05664V14.0293H8.21323V23.974ZM13.5979 14.0293H11.4881C11.4881 15.8594 10.693 17.5391 9.30687 18.638L8.47099 19.3005L11.7097 23.9744H14.3726L11.3925 19.6737C12.8051 18.185 13.5979 16.1823 13.5979 14.0293ZM17.0367 23.9713H15.0001V14.0312H17.0367V23.9713ZM23.1911 17.0953V17.5352C22.6416 17.1384 21.9783 16.9053 21.2629 16.9053C19.3695 16.9053 17.8346 18.5297 17.8346 20.5335C17.8346 22.5373 19.3695 24.1617 21.2629 24.1617C21.9783 24.1617 22.6416 23.9285 23.1911 23.5318V23.9713H25.1364V17.0953H23.1911ZM23.1852 20.5334C23.1852 21.5105 22.3961 22.3026 21.4226 22.3026C20.4492 22.3026 19.66 21.5105 19.66 20.5334C19.66 19.5564 20.4492 18.7645 21.4226 18.7645C22.3961 18.7645 23.1852 19.5564 23.1852 20.5334ZM43.7129 17.5353V17.0955H45.6581V23.9714H43.7129V23.532C43.1633 23.9286 42.5 24.1618 41.7845 24.1618C39.8911 24.1618 38.3562 22.5374 38.3562 20.5336C38.3562 18.5298 39.8911 16.9054 41.7845 16.9054C42.5 16.9054 43.1633 17.1386 43.7129 17.5353ZM41.9441 22.3026C42.9176 22.3026 43.7066 21.5105 43.7066 20.5334C43.7066 19.5564 42.9176 18.7645 41.9441 18.7645C40.9706 18.7645 40.1815 19.5564 40.1815 20.5334C40.1815 21.5105 40.9706 22.3026 41.9441 22.3026ZM46.5023 22.8246C46.5023 22.111 47.0488 21.5327 47.723 21.5327C48.3971 21.5327 48.9437 22.111 48.9437 22.8246C48.9437 23.5379 48.3971 24.1164 47.723 24.1164C47.0488 24.1164 46.5023 23.5379 46.5023 22.8246ZM34.8992 16.9102C34.1222 16.9102 33.3868 17.1655 32.8951 17.8699V17.0956H30.9582V23.9711H32.9189V20.3578C32.9189 19.3122 33.5814 18.8002 34.3791 18.8002C35.234 18.8002 35.7255 19.3406 35.7255 20.3435V23.9711H37.6685V19.5986C37.6685 17.9984 36.4663 16.9102 34.8992 16.9102ZM28.1455 17.0956V17.9911C28.5355 17.4539 29.262 17.096 30.052 17.096V19.0968L30.0469 19.0966L30.0411 19.0963C30.0371 19.0961 30.033 19.0959 30.0289 19.0959C29.2592 19.0959 28.15 19.678 28.15 20.7611V23.9714H26.1539V17.0956H28.1455Z"
				fill="#17120F"
			/>
		</svg>
	)
}
