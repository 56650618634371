import { CheckboxSelectionItemProps } from 'components/Phantom/_shop/CheckboxSelection'
import { getMattressCheckboxProps } from './mattressProps'
import { getEssentialsCheckboxProps } from './essentialsProps'
import { PricingData } from 'shop/Shop.types'
import { getLegKitCheckboxProps } from './legkitProps'

export const getAccessoriesProps = (mattressData?: { numLayers: number; pricingData: PricingData }, legKitPricing?: PricingData, essentialsPricing?: PricingData): CheckboxSelectionItemProps[] => {
	const data: CheckboxSelectionItemProps[] = []

	if (essentialsPricing) {
		data.push(getEssentialsCheckboxProps(essentialsPricing))
	}

	if (mattressData) {
		data.push(getMattressCheckboxProps(mattressData, true))
	}

	if (legKitPricing) {
		data.push(getLegKitCheckboxProps(legKitPricing, true))
	}

	return data
}
