import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 3C8.89543 3 8 3.89543 8 5V7.52779C6.7725 8.62643 6 10.223 6 12C6 13.777 6.7725 15.3736 8 16.4722V19C8 20.1046 8.89543 21 10 21H14C15.1046 21 16 20.1046 16 19V16.4722C17.2275 15.3736 18 13.777 18 12C18 10.223 17.2275 8.62643 16 7.52779V5C16 3.89543 15.1046 3 14 3H10ZM12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V12.38L14.0924 13.519C14.4082 13.787 14.447 14.2603 14.179 14.5761C13.911 14.892 13.4378 14.9308 13.1219 14.6628L11.5148 13.2992C11.3468 13.1566 11.25 12.9475 11.25 12.7273V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
			fill={color}
		/>
	</svg>
)

export default icon
