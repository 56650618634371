import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M15.3161 7.34199C15.4923 7.69439 15.7362 8.00863 16.0339 8.26677C16.3316 8.5249 16.6772 8.72188 17.051 8.84645C17.4248 8.97102 17.8195 9.02074 18.2125 8.99278C18.6055 8.96482 18.9892 8.85973 19.3416 8.68349C19.694 8.50726 20.0082 8.26334 20.2664 7.96566C20.5245 7.66798 20.7215 7.32238 20.846 6.94858C20.9706 6.57477 21.0203 6.1801 20.9924 5.78708C20.9644 5.39406 20.8593 5.01039 20.6831 4.65799C20.3272 3.94628 19.7031 3.40511 18.9482 3.15353C18.1932 2.90195 17.3693 2.96057 16.6576 3.31649C15.9459 3.67241 15.4047 4.29648 15.1531 5.05141C14.9015 5.80634 14.9602 6.63028 15.3161 7.34199ZM15.3161 16.658C14.9602 17.3698 14.9016 18.1939 15.1533 18.9489C15.4049 19.704 15.9462 20.3281 16.6581 20.684C17.3699 21.0399 18.194 21.0985 18.949 20.8468C19.704 20.5951 20.3282 20.0538 20.6841 19.342C21.04 18.6302 21.0986 17.8061 20.8469 17.0511C20.5952 16.296 20.0539 15.6719 19.3421 15.316C18.9896 15.1398 18.6059 15.0347 18.2128 15.0067C17.8197 14.9788 17.425 15.0286 17.0511 15.1532C16.2961 15.4049 15.672 15.9462 15.3161 16.658Z"
			fill={color}
		/>
		<path
			d="M8.68408 13.342C8.88608 12.938 9.00008 12.482 9.00008 12C9.00008 11.518 8.88608 11.062 8.68408 10.658M8.68408 13.342C8.38178 13.9464 7.88428 14.431 7.27217 14.7174C6.66007 15.0037 5.96921 15.075 5.31152 14.9197C4.65384 14.7644 4.06785 14.3916 3.64849 13.8617C3.22914 13.3317 3.00098 12.6758 3.00098 12C3.00098 11.3242 3.22914 10.6682 3.64849 10.1383C4.06785 9.60841 4.65384 9.2356 5.31152 9.08029C5.96921 8.92499 6.66007 8.99628 7.27217 9.28263C7.88428 9.56898 8.38178 10.0536 8.68408 10.658M8.68408 13.342L15.3161 16.658M8.68408 10.658L15.3161 7.34199M15.3161 16.658C14.9602 17.3698 14.9016 18.1939 15.1533 18.9489C15.4049 19.704 15.9462 20.3281 16.6581 20.684C17.3699 21.0399 18.194 21.0985 18.949 20.8468C19.704 20.5951 20.3282 20.0538 20.6841 19.342C21.04 18.6302 21.0986 17.8061 20.8469 17.0511C20.5952 16.296 20.0539 15.6719 19.3421 15.316C18.9896 15.1398 18.6059 15.0347 18.2128 15.0067C17.8197 14.9788 17.425 15.0286 17.0511 15.1532C16.2961 15.4049 15.672 15.9462 15.3161 16.658ZM15.3161 7.34199C15.4923 7.69439 15.7362 8.00863 16.0339 8.26677C16.3316 8.5249 16.6772 8.72188 17.051 8.84645C17.4248 8.97102 17.8195 9.02074 18.2125 8.99278C18.6055 8.96482 18.9892 8.85973 19.3416 8.68349C19.694 8.50726 20.0082 8.26334 20.2664 7.96566C20.5245 7.66798 20.7215 7.32238 20.846 6.94858C20.9706 6.57477 21.0203 6.1801 20.9924 5.78708C20.9644 5.39406 20.8593 5.01039 20.6831 4.65799C20.3272 3.94628 19.7031 3.40511 18.9482 3.15353C18.1932 2.90195 17.3693 2.96057 16.6576 3.31649C15.9459 3.67241 15.4047 4.29648 15.1531 5.05141C14.9015 5.80634 14.9602 6.63028 15.3161 7.34199Z"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="6"
			cy="12"
			r="3"
			fill={color}
		/>
	</svg>
)

export default icon
