import { Context, createContext } from 'react'
import SettingsStore from 'settings/store'
import NavStore from './NavStore'
import TimerStore from './TimerStore'
import ModalStore from 'modals/store'
import { MainStoreFront } from 'cart/storeFront'
import { CartManager, NewCartStore } from 'cart'
import { PriceManager, PriceStore } from 'prices'
import { ProductManager, ProductStore } from 'products'
import { PromoStore } from 'promos'
import { enableStaticRendering } from 'mobx-react'
import ProductPrices from '../product_prices.json'
import { ProductPriceMap } from 'prices/types'
import { ShopStore } from 'shop/store'
import { ShopManager } from 'shop/shopManager'
import { ParsedUrlQuery } from 'querystring'

enableStaticRendering(typeof window === 'undefined')

export interface RootStoreInitializationData {
	query?: ParsedUrlQuery
	pathname?: string
	asPath?: string
}

export class RootStore {
	settingsStore: SettingsStore
	navStore: NavStore
	promoStore: PromoStore
	timerStore: TimerStore
	modalStore: ModalStore
	priceStore: PriceStore
	cartStoreNew: NewCartStore
	productStore: ProductStore
	shopStore: ShopStore

	constructor(initializationData?: RootStoreInitializationData) {
		this.settingsStore = new SettingsStore(this)
		this.navStore = new NavStore(this)
		this.promoStore = new PromoStore(this)
		this.timerStore = new TimerStore(this)
		this.modalStore = new ModalStore(this)

		const prices = ProductPrices as ProductPriceMap
		const priceManager = new PriceManager(prices)
		this.priceStore = new PriceStore(this, priceManager)

		const storeFront = new MainStoreFront()
		const cartManager = new CartManager(storeFront)
		this.cartStoreNew = new NewCartStore(this, cartManager)

		const pageManager = new ProductManager(priceManager)
		this.productStore = new ProductStore(this, pageManager)

		const shopManager = new ShopManager()
		this.shopStore = new ShopStore(this, shopManager, initializationData)
	}
}

export let rootStore: RootStore | undefined
export let RootStoreContext: Context<RootStore | undefined>

export const initializeStore = (initializationData?: RootStoreInitializationData, forceSSRRefresh = false) => {
	if (typeof window === 'undefined' && forceSSRRefresh) {
		// Always create a new root store in server-side rendering
		rootStore = new RootStore(initializationData)
		RootStoreContext = createContext(rootStore)
	}

	if (!rootStore) {
		rootStore = new RootStore(initializationData)
		RootStoreContext = createContext(rootStore)
	}

	return rootStore
}
