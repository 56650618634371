import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M12 8V21M12 8V6C12 5.60444 12.1173 5.21776 12.3371 4.88886C12.5568 4.55996 12.8692 4.30362 13.2346 4.15224C13.6001 4.00087 14.0022 3.96126 14.3902 4.03843C14.7781 4.1156 15.1345 4.30608 15.4142 4.58579C15.6939 4.86549 15.8844 5.22186 15.9616 5.60982C16.0387 5.99778 15.9991 6.39992 15.8478 6.76537C15.6964 7.13082 15.44 7.44318 15.1111 7.66294C14.7822 7.8827 14.3956 8 14 8H12ZM12 8V5.5C12 5.00555 11.8534 4.5222 11.5787 4.11108C11.304 3.69995 10.9135 3.37952 10.4567 3.1903C9.99989 3.00108 9.49723 2.95157 9.01227 3.04804C8.52732 3.1445 8.08186 3.3826 7.73223 3.73223C7.3826 4.08187 7.1445 4.52732 7.04804 5.01228C6.95157 5.49723 7.00108 5.99989 7.1903 6.45671C7.37952 6.91352 7.69995 7.30397 8.11107 7.57867C8.5222 7.85338 9.00555 8 9.5 8H12ZM5 12H19M5 12C4.46957 12 3.96086 11.7893 3.58579 11.4142C3.21071 11.0391 3 10.5304 3 10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8H19C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10C21 10.5304 20.7893 11.0391 20.4142 11.4142C20.0391 11.7893 19.5304 12 19 12M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
