import { createContext, Dispatch, FC, PropsWithChildren, useContext, useEffect, useReducer } from 'react'
import { Discount, ShopAction, ShopState } from 'components/WebEv/Shop/types'
import { shopReducer } from '../shopReducer'
import { PropsWithRootStore } from 'constants/types'
import { RootStore } from 'stores'
import { getRegionalCoverShop } from 'components/WebEv/Shop/data_handling/coverData'
import { BundleProducts, MattressProducts, PodCoverProducts } from 'products/allProducts'
import { getRegionCoverName } from 'prices'
import { VariantKey } from 'products/types'
import { createCartLineItem, isPodBedProductId, isPodCoverProductId, OFFERED_MEMBERSHIPS } from 'products/utils'
import { LineItem } from 'cart'
import { SKIP_MEMBERSHIP_CHECKOUT_KEY } from 'constants/index'
import {
	checkRequiredSteps,
	generateMembershipLineItem,
	generateProductLineItem,
	getMattressUpsell,
	grabSelectionFromField,
	handleCheckout,
	sendTrackingEvents,
} from 'components/WebEv/Shop/context/checkoutUtils'
import { useScrollThreshold } from 'components/_hooks/useScrollThreshold'
import { scrollLocked } from 'utils/jss'

interface ShopContextProps {
	state: ShopState
	dispatch: Dispatch<ShopAction>
	checkout: (sourceID: string) => void
	rootStore: RootStore
}

const ShopContext = createContext<ShopContextProps | undefined>(undefined)

interface ShopProviderProps extends PropsWithChildren, PropsWithRootStore {
	pagePath?: string
}

export const ShopProvider: FC<ShopProviderProps> = ({ children, rootStore, pagePath }) => {
	const [state, dispatch] = useReducer(shopReducer, getRegionalCoverShop(rootStore, pagePath))

	const headerWiped = useScrollThreshold()

	useEffect(() => {
		// This was added to fix a bug found on 2023-11-10 whereby a user on Safari or a mobile browser
		// could return from shopify checkout by pressing the back button, which would save the state of the page.
		// This would cause (I think, but unconfirmed) the cart to be in a malformed state and cause the line items
		// to not exist in the shopify cart. This would cause the user to be able to check out with two memberships.
		window.onpageshow = function (event) {
			if (event.persisted) {
				window.location.reload()
			}
		}
	}, [])

	useEffect(() => {
		dispatch({
			type: 'setFloatingCheckoutOpen',
			data: {
				open: headerWiped,
			},
		})
	}, [headerWiped])

	useEffect(() => {
		const header = document.querySelector('header')

		const resizeListener = () => {
			if (header && window.innerWidth >= 1024 && headerWiped) {
				document.body.style.setProperty('--nav-translate', `-100%`)
			} else if (!scrollLocked) {
				document.body.style.setProperty('--nav-translate', `0px`)
			}
		}

		resizeListener()

		window.addEventListener('resize', resizeListener)
		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [headerWiped])

	const checkout = (sourceID: string) => {
		const { priceStore, cartStoreNew, promoStore } = rootStore
		const isMember = promoStore.discountCode === 'members'

		const allRequiredSteps = checkRequiredSteps(state, dispatch)
		if (!allRequiredSteps) return

		const { productToBeAdded, variantObject, item } = generateProductLineItem(state, priceStore)

		const itemsToAdd: LineItem[] = []
		const itemsToRemove: LineItem[] = []

		itemsToAdd.push(item)

		const mattressProduct = getMattressUpsell(state, priceStore.currency)
		if (mattressProduct) {
			itemsToAdd.push({
				variantId: mattressProduct.variants[variantObject.key].id,
				attributes: [{ key: 'size', value: variantObject.key }],
				quantity: 1,
			})
		}

		const starterBundleSelection = grabSelectionFromField('sleep-starter-bundle-upsell', state.activeSelections)
		const sleepStarterBundleProduct = BundleProducts.SleepEssentialBundle
		if (starterBundleSelection && starterBundleSelection.value === 'true') {
			itemsToAdd.push({
				variantId: sleepStarterBundleProduct.variants[variantObject.key].id,
				attributes: [{ key: 'size', value: variantObject.key }],
				quantity: 1,
			})
		}

		let addedMembership = true
		const membershipLineItem = generateMembershipLineItem(state)

		if (cartStoreNew.hasMembership) {
			const index = cartStoreNew.getIndexOfCurrentMembership()
			const currentMembership = OFFERED_MEMBERSHIPS()[index]
			if (currentMembership) {
				itemsToRemove.push({
					variantId: currentMembership.variants.standard.id,
					sellingPlanId: currentMembership.sellingPlanId,
					quantity: 1,
					attributes: [],
				})
			}
		}

		itemsToAdd.push(membershipLineItem)
		if (sessionStorage.getItem(SKIP_MEMBERSHIP_CHECKOUT_KEY) !== null && !state.giftMembership) {
			itemsToAdd.pop()
			addedMembership = false
		}

		if (state.giftMembership) {
			sessionStorage.setItem('giftMembership', 'true')
		}

		sendTrackingEvents(createCartLineItem(variantObject, productToBeAdded, { addMembership: addedMembership }), sourceID)

		const isValidPod = isPodBedProductId(productToBeAdded.id)
		const isValidCover = isPodCoverProductId(productToBeAdded.id)
		const isValidPodOrCoverAndNotUpgrade = (isValidPod || isValidCover) && !cartStoreNew.hasMemberUpgradeInCart
		handleCheckout(cartStoreNew, itemsToAdd, itemsToRemove, addedMembership, isValidPodOrCoverAndNotUpgrade)
	}

	const value = { state, dispatch, checkout, rootStore }

	useEffect(() => {
		dispatch({ type: 'resetState', data: getRegionalCoverShop(rootStore, pagePath) })
	}, [rootStore.settingsStore.currentRegion])

	useEffect(() => {
		/**
		 * TODO this is all created solely to support the cover at the moment.
		 * After the test is over, we should refactor this to be more generic.
		 */
		const { priceStore, productStore } = rootStore

		const productSelection = grabSelectionFromField('product', state.activeSelections)
		if (!productSelection) return

		const product = Object.values(PodCoverProducts).find((p) => p.handle === productSelection.value)
		const productWithPrices = rootStore.priceStore.withPrices(product)
		const currency = priceStore.currency

		const variantSelection = grabSelectionFromField('variant', state.activeSelections)
		let variant: VariantKey = getRegionCoverName(rootStore.settingsStore.currentRegion)
		if (variantSelection) {
			variant = variantSelection.value as VariantKey
		}

		const baseProductPrice = productWithPrices.variants[variant].prices.price / 100
		const baseProductStrikePrice = productWithPrices.variants[variant].prices.comparePrice / 100

		let price = baseProductPrice
		let strikePrice = baseProductStrikePrice

		const mattressUpsellSelection = grabSelectionFromField('mattress-upsell', state.activeSelections)
		const mattressUpsell = mattressUpsellSelection ? mattressUpsellSelection.value === 'true' : false
		const regionSellsMattress = productStore.regionSellsMattress()
		if (regionSellsMattress) {
			const mattressPrices = currency === 'USD' ? priceStore.withPrices(MattressProducts.FiveLayerDiscounted) : priceStore.withPrices(MattressProducts.ThreeLayer)

			const mattressPrice = mattressPrices.variants[variant].prices.price / 100
			const mattressStrikePrice = mattressPrices.variants[variant].prices.comparePrice / 100

			const mattressPriceIncrease = mattressPrice
			const mattressStrikePriceIncrease = mattressStrikePrice

			if (mattressUpsell) {
				if (!isNaN(mattressPriceIncrease)) price += mattressPriceIncrease
				if (!isNaN(mattressStrikePriceIncrease)) strikePrice += mattressStrikePriceIncrease
			}

			dispatch({
				type: 'updateMattressUpsell',
				data: {
					price: mattressPriceIncrease,
					strikePrice: mattressStrikePriceIncrease,
				},
			})
		}

		const starterBundleUpsellSelection = grabSelectionFromField('sleep-starter-bundle-upsell', state.activeSelections)
		const starterBundleUpsell = starterBundleUpsellSelection ? starterBundleUpsellSelection.value === 'true' : false
		if (currency === 'USD') {
			const sleepStarterBundleProduct = BundleProducts.SleepEssentialBundle
			const starterBundlePrices = priceStore.withPrices(sleepStarterBundleProduct)

			const starterBundlePrice = starterBundlePrices.variants[variant].prices.price / 100
			const starterBundleStrikePrice = starterBundlePrices.variants[variant].prices.comparePrice / 100

			const starterBundlePriceIncrease = starterBundlePrice
			const starterBundleStrikePriceIncrease = starterBundleStrikePrice

			if (starterBundleUpsell) {
				if (!isNaN(starterBundlePriceIncrease)) price += starterBundlePriceIncrease
				if (!isNaN(starterBundleStrikePriceIncrease)) strikePrice += starterBundleStrikePriceIncrease
			}

			dispatch({
				type: 'updateStarterBundleUpsell',
				data: {
					price: starterBundlePriceIncrease,
					strikePrice: starterBundleStrikePriceIncrease,
				},
			})
		}

		const productName = 'Pod Cover'
		// if (mattressUpsell) {
		// 	productName = 'Pod Cover & Mattress'
		// }
		// if (starterBundleUpsell) {
		// 	productName = 'Pod Cover & Others'
		// }

		const discount: Discount = {
			type: 'amount', // TODO add percentage support, just match current discount type
			value: strikePrice - price,
		}

		dispatch({
			type: 'updateProduct',
			data: {
				price,
				strikePrice,
				productName,
				discount,
			},
		})
	}, [state.activeSelections])

	return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>
}

export function useShop() {
	const context = useContext(ShopContext)
	if (!context) {
		throw new Error('useShop must be used within a ShopProvider')
	}
	return context
}
