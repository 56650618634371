import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M7 15C9.42474 17.5251 12 20 12 20L17.5 15M3.19903 10.0646C3.06732 9.61886 3 9.15433 3 8.68631C3 8.07089 3.1164 7.46151 3.34255 6.89294C3.56869 6.32437 3.90017 5.80776 4.31803 5.3726C4.7359 4.93743 5.23197 4.59224 5.77794 4.35673C6.32391 4.12122 6.90907 4 7.50002 4C8.09097 4 8.67614 4.12122 9.2221 4.35673C9.76807 4.59224 10.2641 4.93743 10.682 5.3726L12 6.74515L13.318 5.3726C14.1619 4.49375 15.3065 4.00001 16.5 4.00001C17.6935 4.00001 18.8381 4.49375 19.682 5.3726C20.5259 6.25145 21 7.44342 21 8.68631C21 9.15784 20.9318 9.62204 20.801 10.0646"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 12.5143H9.46359L10.8968 14L12.2903 10L13.8 12.9447L14.1484 12.5143H21"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
