import { CheckboxSelectionItemProps } from 'components/Phantom/_shop/CheckboxSelection'
import { PricingData } from 'shop/Shop.types'

export const getLegKitCheckboxProps = (pricingData: PricingData, showModal = false): CheckboxSelectionItemProps => {
	return {
		selectionKey: 'leg-kit',
		trueValue: 'adjustable',
		falseValue: 'none',
		title: 'Optional leg kit',
		description:
			"The Base doesn't replace your existing bed frame, it fits over it. However, if you'd like to replace your bed frame with the Base, add the 12-piece leg kit to have it stand on its own.",
		pricingData,
		modal: showModal && {
			modalKey: 'legkit-modal',
			text: 'Do I need a leg kit?',
		},
	}
}
