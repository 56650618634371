import React, { FC } from 'react'
import cx from 'classnames'
import { A } from 'components/basic/A'
import styles from './AccessoryCard.module.scss'
import { IAccessoryCard } from 'constants/types'
import { PriceManager } from 'prices/priceManager'
import { Img } from 'components/basic/Img'
import { Badge } from 'components/Badge'

function getPriceSaveText(item: IAccessoryCard) {
	const discount = Math.floor(item.prices.comparePrice - item.prices.price) / 100
	if (!discount) {
		return ''
	}

	const discountFormatted = PriceManager.formatPriceToCurrencyNoDecimal(discount, item.prices.currency)
	const TENTH_A_CENT = 0.1 // Fix floating point rounding errors where a 20% discount gets set as a 19.99999% and floored to 19%
	const saveText =
		item.saveText && discount > 0
			? item.saveText.replace('###', discountFormatted)
			: item.prices.comparePrice && item.prices.price
			? `SAVE ${Math.floor(((item.prices.comparePrice - item.prices.price) / item.prices.comparePrice) * 100 + TENTH_A_CENT)}%`
			: ''
	return saveText
}

export const AccessoryCard: FC<IAccessoryCard> = (props) => {
	const saveText = getPriceSaveText(props)

	return (
		<A
			className={styles.accessory_card}
			href={props.buttonTo}
			id={`accesory-button-to-${props.buttonTo}`}
		>
			<Img
				src={props.productImage}
				alt={props.alt || `image of ${props.productTitle} that links to the corresponding shop page`}
			/>

			<div className={styles.accessory_card_content}>
				<div className={styles.accessory_card_title}>{props.productTitle}</div>
				<div className={styles.accessory_card_subtitle}>{props.productDescription}</div>

				<div className={styles.accessory_card_price_container}>
					<span className={styles.accessory_card_new_price}>{props.prices.price === 0 ? 'FREE' : props.prices.priceString}</span>
					{props.prices.comparePrice && props.prices.comparePrice > 0 && props.prices.price !== props.prices.comparePrice ? (
						<span className={styles.accessory_card_old_price}>{props.prices.comparePriceString}</span>
					) : null}
					{saveText ? <Badge.BFCMLight type={'discount'}>{saveText}</Badge.BFCMLight> : null}
				</div>

				{props.productImageDark ? (
					<div className={styles.color_picker_items}>
						<div className={cx(styles.color_picker_item, styles.color_picker_item_light)} />
						<div className={cx(styles.color_picker_item, styles.color_picker_item_dark)} />
					</div>
				) : null}
			</div>
		</A>
	)
}
