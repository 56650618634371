import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 24"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M16.7142 8.95001H17.5714C18.4807 8.95001 19.3528 9.30822 19.9958 9.94585C20.6387 10.5835 21 11.4483 21 12.35C21 13.2517 20.6387 14.1165 19.9958 14.7542C19.3528 15.3918 18.4807 15.75 17.5714 15.75H16.7142"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3 8.95001H16.7143V16.6C16.7143 17.5017 16.3531 18.3665 15.7101 19.0041C15.0671 19.6418 14.1951 20 13.2857 20H6.42858C5.51927 20 4.64719 19.6418 4.00421 19.0041C3.36122 18.3665 3 17.5017 3 16.6V8.95001Z"
			fill={color}
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.42883 3V5.54999"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.85767 3V5.54999"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.2854 3V5.54999"
			stroke={color}
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default icon
