import React, { FC } from 'react'
import styles from './SizeGuideBed.module.scss'

export interface SizeGuideBedProps {
	length: number
	width: number
	unit: 'in' | 'cm'
	sizeMultiplier?: number
}

type BedSize = 'full' | 'queen' | 'king' | 'caliKing'

const ratios: Record<BedSize, number> = {
	full: 53 / 75,
	queen: 60 / 80,
	king: 76 / 80,
	caliKing: 72 / 84,
}

const findBedSizeWithClosestRatio = (ratio: number) => {
	const ratioEntries = Object.entries(ratios)
	const closestRatio = ratioEntries.reduce((prev, curr) => {
		const currRatio = curr[1]
		const prevRatio = prev[1]
		return Math.abs(currRatio - ratio) < Math.abs(prevRatio - ratio) ? curr : prev
	})

	return closestRatio[0] as BedSize
}

const getBedShape = (size: BedSize) => {
	switch (size) {
		case 'full':
			return <FullShape />
		case 'queen':
			return <QueenShape />
		case 'king':
			return <KingShape />
		case 'caliKing':
			return <CaliKingShape />
	}
}

const INCH_REM = 0.1
const CM_REM = 0.04
export const SizeGuideBed: FC<SizeGuideBedProps> = (props) => {
	const bedShapeName = findBedSizeWithClosestRatio(props.width / props.length)
	const bedShape = getBedShape(bedShapeName)

	const unitInRems = props.unit === 'in' ? INCH_REM : CM_REM
	const unitShorthand = props.unit === 'in' ? '"' : 'cm'

	const sizeMultiplier = props.sizeMultiplier || 1
	const width = props.width * sizeMultiplier
	const length = props.length * sizeMultiplier

	return (
		<div className={styles.bed_sizing}>
			<p className={styles.width}>
				{props.width}
				{unitShorthand}
			</p>
			<p className={styles.length}>
				{props.length}
				{unitShorthand}
			</p>
			<div
				className={styles.bed_svg}
				style={{ width: `${width * unitInRems}rem`, height: `${length * unitInRems}rem` }}
			>
				{bedShape}
			</div>
		</div>
	)
}

export const FullShape = () => (
	<svg
		viewBox="0 0 71 102"
		fill={'none'}
	>
		<path
			stroke="#000"
			d="M2.625 3.852l3.081-2.165a5 5 0 012.875-.91H65.5a5 5 0 015 5v87.398a5 5 0 01-1.4 3.471l-2.51 2.602a5.001 5.001 0 01-3.6 1.529H5.5a5 5 0 01-5-5V7.943a5 5 0 012.125-4.09z"
		/>
		<rect
			width="66"
			height="95"
			x="4"
			y="1.277"
			stroke="#000"
			rx="4.5"
		/>
	</svg>
)

export const QueenShape = () => (
	<svg
		fill="none"
		viewBox="0 0 80 105"
	>
		<path
			stroke="#000"
			d="M2.8 4.007L6.602 1.57a5 5 0 012.7-.792H74.5a5 5 0 015 5v90.097a5 5 0 01-1.563 3.631l-3.068 2.904a4.999 4.999 0 01-3.437 1.368H5.5a5 5 0 01-5-5V8.216a5 5 0 012.3-4.209z"
		/>
		<rect
			width="75"
			height="98"
			x="4"
			y="1.277"
			stroke="#000"
			rx="4.5"
		/>
	</svg>
)

export const KingShape = () => (
	<svg
		fill="none"
		viewBox="0 0 95 113"
	>
		<path
			stroke="#000"
			d="M2.757 4.177l4.223-2.77a5 5 0 012.742-.82H89.5a5 5 0 015 5v97.502c0 1.46-.638 2.847-1.746 3.797l-4.08 3.497a4.999 4.999 0 01-3.254 1.204H5.5a5 5 0 01-5-5V8.357a5 5 0 012.257-4.18z"
		></path>
		<rect
			width="89"
			height="106"
			x="5"
			y="1.087"
			stroke="#000"
			rx="4.5"
		></rect>
	</svg>
)

export const CaliKingShape = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 129"
	>
		<path
			stroke="#000"
			d="M2.078 5.098L5.24 2.131A5 5 0 018.66.777H94.5a5 5 0 015 5v111.926a5 5 0 01-1.359 3.427l-4.776 5.074a5 5 0 01-3.64 1.573H5.5a5 5 0 01-5-5V8.744a5 5 0 011.578-3.646z"
		></path>
		<rect
			width="94"
			height="121"
			x="5"
			y="1.277"
			stroke="#000"
			rx="4.5"
		></rect>
	</svg>
)
